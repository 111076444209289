import { useEffect } from "react"

// Forms
import { FormikProvider, useFormik } from "formik"
import { Yup } from "@/lib/forms"
import { FormikError } from "../form-controls/Errors"
import { FormikInput, FormikSubmitButton } from "../form-controls/formik"
import { Label } from "../form-controls/Label"

// Context
import {
	ColumnId,
	PaymentPatchesActionType,
	usePaymentPatchList,
} from "@/context/paymentPatches"

// Translations
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/ui"

// Graphql
import { PaymentEntryEnergySupplierFragment } from "@/api/graphql"

/**
 * PaymentItem
 * @param param0
 * @returns
 */
export const PaymentItem = ({
	values: valuesFromProps,
	paymententryId,
	collapseAllRows = () => {},
}: {
	values: PaymentEntryEnergySupplierFragment
	paymententryId: number
	collapseAllRows: Function
}) => {
	const t = useTrans("payments")
	const { patchList, onPatchCell } = usePaymentPatchList()
	const rowData = patchList.get(paymententryId)

	const initialValues = () => {
		if (rowData?.type === PaymentPatchesActionType.Edit) {
			const valuesFromPatch = Object.fromEntries(rowData.data.entries())
			return {
				...valuesFromProps,
				...valuesFromPatch,
			}
		}

		return valuesFromProps
	}

	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object().shape({
			supplier_account: Yup.string()
				.required("Client number is required")
				.min(1),
			email: Yup.string().email("Please enter in a valid email"),
		}),
		enableReinitialize: true,
		onSubmit: (values) => {
			Object.keys(values).forEach((key) => {
				// If changed, edit
				if (key === "supplier_account") {
					// Only works with supplier_account for now
					void form.setTouched({ [key]: true })
					if (values[key] !== valuesFromProps[key]) {
						onPatchCell({
							paymententryId: Number(form.values.id),
							type: PaymentPatchesActionType.Edit,
							value: values[key] || "",
							columnId: (key as ColumnId) ?? "",
						})
					} else {
						// Undo
						onPatchCell({
							paymententryId: Number(form.values.id),
							type: PaymentPatchesActionType.Undo,
							columnId: (key as ColumnId) ?? "",
						})
					}
				}
			})

			// Collapse all react-table rows
			collapseAllRows()
		},
	})

	// On change set field value
	function onChange(
		field: keyof PaymentEntryEnergySupplierFragment,
		value: string,
	) {
		void form.setFieldValue(field, value)
	}

	function hasEditedField(field: keyof PaymentEntryEnergySupplierFragment) {
		return form.values[field] !== valuesFromProps[field]
	}

	const { resetForm } = form
	useEffect(() => {
		if (rowData?.type === PaymentPatchesActionType.Delete) {
			resetForm()
		}
	}, [rowData?.type, resetForm])

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit}>
				<p className="mb-6 text-sm text-gray-700">
					{valuesFromProps.name}
				</p>
				<div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-x-7 lg:gap-y-3 2xl:grid-cols-3 2xl:gap-x-14 2xl:gap-y-6">
					<div>
						<Label
							htmlFor="supplier_account"
							className="text-base font-medium text-gray-700"
						>
							{t(
								"payments:payments.payment_item.label.client_number",
							)}
						</Label>
						<FormikInput
							name="supplier_account"
							required
							className={classNames(
								"block w-full",
								hasEditedField("supplier_account") &&
									"border-primary-500 bg-yellow-50",
							)}
							onChange={(e) => {
								onChange(
									"supplier_account",
									e.currentTarget.value,
								)
							}}
							data-testid={`paymentitem-${valuesFromProps.supplier_account}-inputsupplieraccount`}
						/>
						<FormikError
							field="supplier_account"
							namespace="payments"
						/>
					</div>
					<div>
						<p className="mb-1 text-base font-medium text-gray-700">
							{t("payments:payments.payment_item.label.postcode")}
						</p>
						<p className="py-2 text-gray-900 sm:text-sm">
							{valuesFromProps.postcode}
						</p>
					</div>

					{/** Housenumber + Suffix */}
					<div>
						<p className="mb-1 text-base font-medium text-gray-700">
							{t(
								"payments:payments.payment_item.label.house-number-plus-suffix",
							)}
						</p>
						<p className="py-2 text-gray-900 sm:text-sm">
							{`${valuesFromProps.number}${valuesFromProps.suffix}`}
						</p>
					</div>

					<div>
						<p className="mb-1 text-base font-medium text-gray-700">
							{t("payments:payments.payment_item.label.email")}
						</p>
						<p className="py-2 text-gray-900 sm:text-sm">
							{valuesFromProps.investor_email}
						</p>
					</div>
					<div>
						<p className="mb-1 text-base font-medium text-gray-700">
							{t("payments:payments.payment_item.label.tel")}
						</p>
						<p className="py-2 text-gray-900 sm:text-sm">
							{valuesFromProps.investor_phone_number}
						</p>
					</div>

					<FormikSubmitButton className="h-10">
						{t("payments:payments.payment_item.action.update")}
					</FormikSubmitButton>
				</div>
			</form>
		</FormikProvider>
	)
}
