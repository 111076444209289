import { useState, useEffect } from "react"

// DateTime
import { DateTime, ms, DEFAULT_TIMEZONE } from "@/lib/dates"
import { apiDateFormat } from "@/constants/constants"

// UI
import { Card, CardBody, CardWrapper } from "@/components/Card"
import { Odometer } from "@/components/Odometer"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"
import {
	FinancialOverviewTable,
	FinancialOverviewContext,
	FinancialOverviewContextType,
} from "./_components/FinancialOverviewTable"
import { StatisticsYourSavings } from "./_components/StatisticsYourSavings"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Queries
import {
	ProjectTypeEnum,
	useStatisticsMySavingsQuery,
	useStatisticsMySavingsTotalsQuery,
	useStatisticsMySavingsTotalsSlowQuery,
} from "@/api/graphql"

// Hooks
import {
	useCurrentUserInvestmentsInProductionCount,
	useFeatureFlags,
} from "@/context/user"

// Icons
import { FiInfo } from "@/lib/icons"

/**
 * InvestmentsProjectsCurrentOverview
 * @returns
 */
export function InvestmentsProjectsCurrentOverview() {
	const { getFeatureFlagValue } = useFeatureFlags()
	const t = useTrans(["dashboard", "investments"])

	// Hooks
	const investmentsInProductionCount =
		useCurrentUserInvestmentsInProductionCount()

	// State
	const mode: FinancialOverviewContextType = {
		type: "year",
		year: String(DateTime.local().year),
	}

	return (
		<>
			<div className={`space-y-8`} data-testid="current-overview">
				{/** Odometer if we have production */}
				{getFeatureFlagValue("ENABLE_STATS_MY_SAVINGS") === true &&
					investmentsInProductionCount > 0 && (
						<div className="mb-8 mt-8 flex justify-center gap-4">
							<div>
								<Heading as="h5" className="mb-2">
									{t("dashboard:dashboard.energy_produced")}
								</Heading>

								<OdometerContainer />
							</div>
						</div>
					)}

				<TotalStats />

				{/** Your savings */}
				{getFeatureFlagValue("ENABLE_STATS_MY_SAVINGS") === true &&
				investmentsInProductionCount > 0 ? (
					<CardWrapper className="col-span-1 mb-4 lg:col-span-4 2xl:col-span-4">
						<StatisticsYourSavings />
					</CardWrapper>
				) : null}

				{/** Returns today */}
				<ReturnsToday />
				{/** Financial overview of current state */}
				<FinancialOverviewContext.Provider value={mode}>
					<CardWrapper>
						<CardBody>
							<Heading
								as="h3"
								styleAs="h6"
								className="mb-4 mr-2 lg:mr-0"
							>
								{t("dashboard:dashboard.financial_overview")}
							</Heading>

							<FinancialOverviewTable />
						</CardBody>
					</CardWrapper>
				</FinancialOverviewContext.Provider>
			</div>
		</>
	)
}

/**
 * TotalStats
 * @returns
 */
function TotalStats() {
	// Translations
	const t = useTrans(["dashboard", "investments", "common"])

	// Query
	const { data, isLoading } = useStatisticsMySavingsTotalsQuery({
		date: DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.toFormat(apiDateFormat),
	})

	// Data
	const totalProjectsSolarShares =
		data?.me?.investment_projects?.results?.filter(
			(project) => project?.type === ProjectTypeEnum.SolarShares,
		).length || 0
	const totalProjectsPro =
		data?.me?.investment_projects?.results?.filter(
			(project) => project?.type === ProjectTypeEnum.Portfolio,
		).length || 0
	const totalProjectsBonds =
		data?.me?.investment_projects?.results?.filter(
			(project) => project?.type === ProjectTypeEnum.SolarBonds,
		).length || 0

	// Loading state
	if (isLoading) {
		return <TotalStatsLoading />
	}

	// Data state
	return (
		<div className="mb-4 grid grid-cols-4 gap-4">
			<Card>
				<div>
					<div className="mb-1 text-sm font-medium text-gray-500">
						{t("dashboard:dashboard.total_projects")}
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<dt className="text-sm font-medium text-gray-500">
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl">
							{t("common:common.project.type.SOLAR_SHARES")}
						</div>
					</dt>
					<dd className="mt-1 text-right text-sm text-gray-900">
						{totalProjectsSolarShares}
					</dd>
					<dt className="text-sm font-medium text-gray-500">
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl">
							{t("common:common.project.type.PORTFOLIO")}
						</div>
					</dt>
					<dd className="mt-1 text-right text-sm text-gray-900">
						{totalProjectsPro}
					</dd>
					<dt className="text-sm font-medium text-gray-500">
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl">
							{t("common:common.project.type.SOLAR_BONDS")}
						</div>
					</dt>
					<dd className="mt-1 text-right text-sm text-gray-900">
						{totalProjectsBonds}
					</dd>
					<dt className="text-sm font-medium text-gray-500">
						{t("dashboard:dashboard.total")}
					</dt>
					<dd className="border-t-2 text-right text-sm font-bold text-gray-900 ">
						{data?.me?.investment_projects?.results?.length || 0}
					</dd>
				</div>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.total_shares")}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">
						{data?.me?.investor_shares_value_stats?.total_shares}
					</Heading>
				</dd>
			</Card>
		</div>
	)
}

/**
 * TotalStatsLoading
 * @returns
 */
function TotalStatsLoading() {
	const t = useTrans(["dashboard"])

	return (
		<div className="mb-4 grid grid-cols-4 gap-4">
			<Card>
				<div>
					<div className="mb-1 text-sm font-medium text-gray-500">
						{t("dashboard:dashboard.total_projects")}
					</div>
				</div>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.total_shares")}
				</dt>
				<dd className="text-sm text-gray-900">
					<div className="h-8 w-24 animate-pulse rounded bg-gray-200" />
				</dd>
			</Card>
		</div>
	)
}

/**
 * ReturnsToday
 * @returns
 */
function ReturnsToday() {
	const t = useTrans(["investments", "finance", "dashboard"])
	const { formatCurrency } = useLang()

	// Query
	const { data, isLoading } = useStatisticsMySavingsTotalsSlowQuery({
		date: DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.toFormat(apiDateFormat),
	})

	// Loading state
	if (isLoading) {
		return <ReturnsTodayLoading />
	}

	// Data state
	return (
		<div className="mb-4 grid grid-cols-3 gap-4">
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.original_investment")}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">
						{formatCurrency(
							Number(
								data?.me?.investor_shares_value_stats
									?.total_nominal_investment,
							),
						)}
					</Heading>
				</dd>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t(
						"investments:investments.fiscal.hero.returns_today.title",
					)}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">
						{formatCurrency(
							data?.me?.investor_shares_value_stats
								?.total_investment_value
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_investment_value,
								  )
								: 0,
						)}
					</Heading>
				</dd>
			</Card>
			<Card>
				<div className="mb-2 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.blocks.paid_out.title")}
				</div>
				<div className="grid grid-cols-2 gap-2">
					{/** Last payment Amortization */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"finance:finance.bonds-loan.block.interest_periods.last.amortization",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">
						{formatCurrency(
							data?.me?.investor_shares_value_stats
								?.total_amortization
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_amortization,
								  )
								: 0,
						)}
					</dd>

					{/** Last Interest amount */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"finance:finance.bonds-loan.block.interest_periods.last.interest_amount",
						)}
						<Tooltip
							text={t("dashboard:calculation.interest.tooltip")}
						>
							<FiInfo className="ml-1" />
						</Tooltip>
					</dt>
					<dd className="text-right text-sm text-gray-900">
						{formatCurrency(
							data?.me?.investor_shares_value_stats?.total_cost
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_cost,
								  )
								: 0,
						)}
					</dd>

					{/** Last repayment amount */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard:dashboard.blocks.paid_out.subtotal.repayment",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">
						{formatCurrency(
							data?.me?.investor_shares_value_stats
								?.total_repayment
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_repayment,
								  )
								: 0,
						)}
					</dd>

					{/** Last total sum */}
					<dt className="text-sm font-medium text-gray-500">
						{t("dashboard:dashboard.total")}
					</dt>
					<dd className="border-t-2 text-right text-sm text-gray-900 ">
						{formatCurrency(
							data?.me?.investor_shares_value_stats?.total_repaid
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_repaid,
								  )
								: 0,
						)}
					</dd>
				</div>
			</Card>
		</div>
	)
}

/**
 * ReturnsTodayLoading
 * @returns
 */
function ReturnsTodayLoading() {
	const t = useTrans(["investments", "finance", "dashboard"])

	// Template
	return (
		<div className="mb-4 grid grid-cols-3 gap-4">
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.original_investment")}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">-</Heading>
				</dd>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t(
						"investments:investments.fiscal.hero.returns_today.title",
					)}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">-</Heading>
				</dd>
			</Card>
			<Card>
				<div className="mb-1 text-sm font-medium text-gray-500">
					{t("dashboard:dashboard.blocks.paid_out.title")}
				</div>
				<div className="grid grid-cols-2 gap-2">
					{/** Last payment Amortization */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"finance:finance.bonds-loan.block.interest_periods.last.amortization",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">-</dd>

					{/** Last Interest amount */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"finance:finance.bonds-loan.block.interest_periods.last.interest_amount",
						)}
						<Tooltip
							text={t("dashboard:calculation.interest.tooltip")}
						>
							<FiInfo className="ml-1" />
						</Tooltip>
					</dt>
					<dd className="text-right text-sm text-gray-900">-</dd>

					{/** Last repayment amount */}
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard:dashboard.blocks.paid_out.subtotal.repayment",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">-</dd>

					{/** Last total sum */}
					<dt className="text-sm font-medium text-gray-500">
						{t("dashboard:dashboard.total")}
					</dt>
					<dd className="border-t-2 text-right text-sm text-gray-900 ">
						-
					</dd>
				</div>
			</Card>
		</div>
	)
}

/**
 * OdometerContainer
 * @returns
 */
function OdometerContainer() {
	// Config
	const timeTillRenderInSeconds = 10

	const { data, isLoading } = useStatisticsMySavingsQuery()
	const [value, set] = useState(
		data?.me?.investor_production_stats?.total_production_for_counter
			? parseFloat(
					data?.me?.investor_production_stats
						.total_production_for_counter,
			  ) // This is in WH
			: 0,
	)

	useEffect(() => {
		function work() {
			set((value) => {
				const currentProductionSpeed = data?.me
					?.investor_production_stats?.production_speed
					? parseFloat(
							data?.me?.investor_production_stats
								?.production_speed, // This is watts per 250ms?
					  ) *
					  4 * // Watt/second
					  timeTillRenderInSeconds
					: 0
				return value + currentProductionSpeed
			})
		}
		// 10 seconds re-render
		const timer = setInterval(work, ms(`${timeTillRenderInSeconds}s`))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.me?.investor_production_stats?.production_speed])

	// Loading state
	if (isLoading) {
		return (
			<span className="dummy-text block w-full animate-pulse rounded-md bg-gray-200"></span>
		)
	}

	// Data state
	return (
		<Odometer
			unit="wh"
			value={value}
			format="d"
			duration={timeTillRenderInSeconds * 1000}
			auto={false}
			theme="mijnstroom"
		/>
	)
}
