import { ReactNode } from "react"

// UI
import {
	Tooltip as BaseTooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"

interface TooltipProps {
	content: ReactNode
	children: ReactNode
	className?: string
}

export function Tooltip({ content, children, className = "" }: TooltipProps) {
	return (
		<TooltipProvider>
			<BaseTooltip>
				<TooltipTrigger className={className}>
					{children}
				</TooltipTrigger>
				<TooltipContent>{content}</TooltipContent>
			</BaseTooltip>
		</TooltipProvider>
	)
}
