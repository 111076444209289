// React
import { useMemo } from "react"

// Router
import { useParams } from "@/lib/router"

// UI
import { Card } from "@/components/Card"
import { Heading, P } from "@/components/Typography"
import { Text } from "@visx/text"
import {
	GraphData,
	MultiLineGraphLinearScale,
	TooltipComponentProps,
	TooltipComponentType,
} from "@/components/graphs/types/multi-line/MultiLineGraphLinearScale"

// I18n
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Queries
import { useProjectInternalStateOfReturnQuery } from "@/api/graphql"

// Utils
import { emptyValue } from "@/utils/helpers"

/**
 * InternalStateOfReturnGraph
 * @returns
 */
export function InternalStateOfReturnGraph() {
	// Router
	const params = useParams()

	const { data, isLoading } = useProjectInternalStateOfReturnQuery({
		id: String(params.id),
	})

	const graphData = useMemo(
		() =>
			data?.project_statistics?.solar_interest_chart_data?.roi_of_interest_periods
				?.filter(Boolean)
				.map((entry, index) => {
					return {
						x: String(index),
						y: Math.max(Number(entry), 0), // Should never go below zero
					} as GraphData
				}) ?? [],
		[
			data?.project_statistics?.solar_interest_chart_data
				?.roi_of_interest_periods,
		],
	)

	const expectedData = useMemo(
		() =>
			data?.project_statistics?.solar_interest_chart_data?.expected_roi_of_interest_periods
				?.filter(Boolean)
				.map((entry, index) => {
					return {
						x: String(index),
						y: Number(entry) || 0, // // Should never go below zero
					} as GraphData
				}) ?? [],
		[
			data?.project_statistics?.solar_interest_chart_data
				?.expected_roi_of_interest_periods,
		],
	)

	const lineDatas = useMemo(
		() => [
			{
				data: graphData,
				id: "roi_of_interest_periods",
				variant: "primary" as const,
			},
			{
				data: expectedData,
				id: "expected_roi_of_interest_periods",
			},
		],
		[graphData, expectedData],
	)

	// Loading state
	if (isLoading) {
		return <InternalStateOfReturnGraphLoading />
	}

	// Data state
	return (
		<Card className="row mx-md-5 mt-5">
			<Heading as="h5" className="mb-3 sm:truncate">
				Geproduceerde elektriciteit
			</Heading>
			<div className="col-md-6 px-md-0 px-lg-5">
				<div>
					kWh in totaal:{" "}
					<strong>
						{" "}
						{Number(
							data?.project?.overall_statistics?.total_production,
						).toFixed(0)}
					</strong>
				</div>
				<div>
					∆ Productie t.o.v. verwachting (IM):{" "}
					<strong>
						{Number(
							data?.project_statistics?.delta_production,
						).toFixed(2)}{" "}
						%
					</strong>
				</div>
				<div>
					∆ Huidige stroomprijs t.o.v. uitgangswaarde (IM):{" "}
					<strong>
						{Number(
							data?.project_statistics?.delta_energy_price,
						).toFixed(2)}{" "}
						%
					</strong>
				</div>
			</div>

			{data?.project_statistics?.solar_interest_chart_data ? (
				<div className="h-[200px]">
					<MultiLineGraphLinearScale
						lineDatas={lineDatas}
						margin={{ left: 66, right: 25, top: 25, bottom: 50 }}
						TooltipComponent={InternalStateOfReturnGraphTooltip}
						xTickComponent={({ formattedValue, ...props }) => {
							return (
								<Text {...props} x={props.x + 5}>
									{formattedValue}
								</Text>
							)
						}}
						yTickComponent={({ formattedValue, ...props }) => (
							<Text {...props}>
								{formattedValue
									? Number(formattedValue)
									: emptyValue()}
							</Text>
						)}
					/>
				</div>
			) : null}
		</Card>
	)
}

/**
 * InternalStateOfReturnGraphTooltip
 * @param param0
 * @returns
 */
const InternalStateOfReturnGraphTooltip: TooltipComponentType = ({
	label,
	value,
	value2,
}: TooltipComponentProps) => {
	const t = useTrans("dashboard")
	const { formatCurrency } = useLang()

	function getValue(value: number | string) {
		return formatCurrency(Number(value))
	}

	// only add decimal points if necessary
	if (typeof value === "number" && Math.round(value) !== value) {
		value = value.toFixed(2)
	}
	if (typeof value2 === "number" && Math.round(value2) !== value2) {
		value2 = value2.toFixed(2)
	}

	return (
		<div className="px-4 py-3">
			<Heading as="h3" styleAs="h5" className="text-black">
				{label}
			</Heading>
			<P className="grid grid-cols-[10px_auto_1fr] gap-x-3 gap-y-1 text-black md:mt-1">
				{/** roi_of_interest_periods */}
				{value !== null && (
					<>
						<svg width={10} height={10} viewBox="0 0 10 10">
							<circle
								r={5}
								cx={5}
								cy={5}
								fill="rgb(20, 154, 229)"
							/>
						</svg>
						{t(
							"dashboard:dashboard.stats.project_stats.blocks.rate_of_return_graph.data.interest",
						)}
						<span className="font-bold">{getValue(value)}%</span>
					</>
				)}

				{/** expected_roi_of_interest_periods */}
				{value2 !== null && value !== undefined && (
					<>
						<svg width={10} height={10} viewBox="0 0 10 10">
							<circle r={5} cx={5} cy={5} fill="#4e5155" />
						</svg>
						{t(
							"dashboard:dashboard.stats.project_stats.blocks.rate_of_return_graph.data.memorandum",
						)}
						<span className="font-bold">
							{getValue(value2 as number)}%
						</span>
					</>
				)}
			</P>
		</div>
	)
}

/**
 * InternalStateOfReturnGraphLoading
 * @returns
 */
export function InternalStateOfReturnGraphLoading() {
	return (
		<Card className="row mx-md-5 mt-5">
			<Heading as="h5" className="mb-3 sm:truncate">
				Geproduceerde elektriciteit
			</Heading>
			<div className="col-md-6 px-md-0 px-lg-5">
				<div>kWh in totaal: -</div>
				<div>∆ Productie t.o.v. verwachting (IM): </div>
				<div>∆ Huidige stroomprijs t.o.v. uitgangswaarde (IM): -</div>
			</div>
			<div className="h-[200px]">
				<div className="h-full w-full animate-pulse rounded-md bg-gray-100"></div>
			</div>
		</Card>
	)
}
