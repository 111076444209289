// React
import { Fragment, useMemo, useEffect, useCallback } from "react"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"
import { emptyValue } from "@/utils/helpers"

// GraphQL
import {
	PaymentEntryType,
	PaymentStateEnum,
	ProjectTypeEnum,
	ProjectAmortizationScheduleEnum,
	PayOutState,
	useAllInvestorPaymentsQuery,
} from "@/api/graphql"

// Tables
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
	Table,
} from "@/components/table-controls/TableItems"

// UI
import { classNames } from "@/lib/ui"
import { Heading } from "@/components/Typography"

// Icons
import { FiHash } from "@/lib/icons"

interface PayoutsTablePrintProps {
	onLoadingChange?: (isLoading: boolean) => void
}

/**
 * PayoutsTablePrint
 * @returns
 */
export function PayoutsTablePrint({ onLoadingChange }: PayoutsTablePrintProps) {
	// Translations
	const t = useTrans("project")
	const { formatCurrency, formatNumber } = useLang()

	// Queries
	const { data, isLoading } = useAllInvestorPaymentsQuery({
		ordering: "-payment__interestperiod__end", // Uses internal python model structure
		limit: null, // This will return alls results
	})

	// Memoize the loading state change handler
	const handleLoadingChange = useCallback(
		(loading: boolean) => {
			onLoadingChange?.(loading)
		},
		[onLoadingChange],
	)

	// Handle loading state changes
	useEffect(() => {
		handleLoadingChange(isLoading)

		// Cleanup function to reset loading state if component unmounts
		return () => {
			handleLoadingChange(false)
		}
	}, [isLoading, handleLoadingChange])

	// Memo
	const investments = useMemo(() => {
		return data?.me?.payment_entries?.results?.map((payment) => {
			return {
				...payment,
			}
		})
	}, [data?.me?.payment_entries?.results])

	// Table Data
	const columnHelper = createColumnHelper<PaymentEntryType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor(
				(data: PaymentEntryType) => data?.project?.name,
				{
					id: "project.name",
					header: () => (
						<TableHeading variant="static">
							{t("project:project.revenue.table.headers.project")}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell className="font-medium" multiline>
							{info.getValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) => data?.transaction_id,
				{
					id: "transaction_id",
					header: () => (
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.transaction_id",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell
							fieldName={t(
								"project:project.revenue.table.headers.transaction_id",
							)}
						>
							<span className="flex flex-row">
								<FiHash
									className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
									aria-hidden="true"
								/>
								{info.getValue()}
							</span>
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					DateTime.fromISO(data.interest_period?.start).toFormat(
						dateFormat,
					),
				{
					id: "interest_period.start",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.interest_period",
							)}
						</TableHeading>
					),
					cell: (info) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor((data: PaymentEntryType) => data, {
				id: "payment_deadline_date",
				header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payment_deadline_date",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<PaymentEntryType, PaymentEntryType>,
				) => {
					return (
						<TableDataCell>
							<span
								className={classNames(
									info.getValue().payment_state ===
										PaymentStateEnum.Done &&
										"text-green-500",
									info.getValue()?.payment_state !==
										PaymentStateEnum.Done &&
										DateTime.fromISO(
											info.getValue()?.interest_period
												?.payment_deadline_date,
										) < DateTime.now() &&
										"text-red-500",
								)}
							>
								{DateTime.fromISO(
									info.getValue()?.interest_period
										?.payment_deadline_date,
								).toFormat(dateFormat) || emptyValue()}
							</span>
						</TableDataCell>
					)
				},
			}),
			columnHelper.accessor((data: PaymentEntryType) => data?.state, {
				id: "payment_entry.state",
				header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payout_state",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, PayOutState>) => (
					<TableDataCell multiline>
						{info.getValue()
							? t(
									`project:project.revenue.payment_entry_states.${info.getValue()}`,
							  )
							: t(
									`project:project.revenue.payment_entry_states.unknown`,
							  )}
					</TableDataCell>
				),
			}),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					data?.share_count
						? formatNumber(data.share_count)
						: emptyValue(),
				{
					id: "share_count",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.solar_shares",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					data?.cost ? formatCurrency(data.cost) : emptyValue(),
				{
					id: "cost",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.interest",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					data?.amortization
						? formatCurrency(data.amortization)
						: emptyValue(),
				{
					id: "amortization",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.amortization",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					data?.repayment
						? formatCurrency(data.repayment)
						: emptyValue(),
				{
					id: "repayment",

					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.compensation",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					Number(data?.cost) +
					Number(data?.amortization) +
					Number(data?.repayment),
				{
					id: "paymentSum",
					header: () => (
						<TableHeading>
							{t("project:project.revenue.table.headers.payment")}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, number>) => (
						<TableDataCell>
							{info.getValue()
								? formatCurrency(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) => data?.project?.type,
				{
					id: "project.type",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.project_type",
							)}
						</TableHeading>
					),
					cell: (
						info: CellContext<PaymentEntryType, ProjectTypeEnum>,
					) => (
						<TableDataCell>
							{t(`common:common.project.type.${info.getValue()}`)}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					data?.project?.amortization_schedule,
				{
					id: "project.amortization_schedule",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.project_amortization_schedule",
							)}
						</TableHeading>
					),
					cell: (
						info: CellContext<
							PaymentEntryType,
							ProjectAmortizationScheduleEnum
						>,
					) => (
						<TableDataCell multiline>
							{t(
								`common:common.project.amortization_schedule.${info.getValue()}`,
							)}
						</TableDataCell>
					),
				},
			),
		],
		[],
	)
	const table = useReactTable({
		columns,
		data: investments as PaymentEntryType[],
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	// Loading state
	if (isLoading) {
		return <PayoutsTablePrintLoading />
	}

	// Data state
	return (
		<>
			<div
				className={
					"grid-rows-[minmax(20px,1fr)_1fr] rounded-md bg-gray-50 px-4 py-3"
				}
			>
				<Heading as="h3">
					{t("project:project.revenue.all.title")}
				</Heading>
			</div>
			<Table className="w-full">
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>
				<TableBody>
					{table.getRowModel().rows.map((row) => {
						const isOdd = row.index % 2 === 0
						let className = ""

						return (
							<Fragment key={row.id}>
								<TableRowCell
									isOdd={isOdd}
									className={classNames(className)}
								>
									{row.getAllCells().map((cell) => {
										return (
											<Fragment key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</Fragment>
										)
									})}
								</TableRowCell>
							</Fragment>
						)
					})}
				</TableBody>
			</Table>
		</>
	)
}

/**
 * PayoutsTablePrintLoading
 * @returns
 */
export function PayoutsTablePrintLoading() {
	const t = useTrans("project")

	return (
		<>
			<div
				className={
					"grid-rows-[minmax(20px,1fr)_1fr] rounded-md bg-gray-50 px-4 py-3"
				}
			>
				<Heading as="h3">
					{t("project:project.revenue.all.title")}
				</Heading>
			</div>
			<Table className="w-full">
				<TableHead>
					<TableRowCell>
						<TableHeading variant="static">
							{t("project:project.revenue.table.headers.project")}
						</TableHeading>
						<TableHeading variant="static">
							{t(
								"project:project.revenue.table.headers.transaction_id",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.interest_period",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.payment_deadline_date",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.payout_state",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.solar_shares",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.interest",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.amortization",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.compensation",
							)}
						</TableHeading>
						<TableHeading>
							{t("project:project.revenue.table.headers.payment")}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.project_type",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.project_amortization_schedule",
							)}
						</TableHeading>
					</TableRowCell>
				</TableHead>
				<TableBody>
					{[...Array(5)].map((_, index) => (
						<TableRowCell key={index} isOdd={index % 2 === 0}>
							<TableDataCell>
								<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-16 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
						</TableRowCell>
					))}
				</TableBody>
			</Table>
		</>
	)
}
