import { useEffect, useState } from "react"
import { Link } from "@/lib/router"
import { CardBody, CardWrapper } from "@/components/Card"
import { FormGroup } from "@/components/form-controls/FormGroup"
import { TwoFactorAuthSettings } from "./2FA/TwoFactorAuthSettings"
import { Heading } from "@/components/Typography"
import { useProfileSecurityIntroQuery } from "@/api/graphql"

import { Routes } from "@/constants/routes"

// Translation
import { Trans, useTrans } from "@/i18n"

// Types
import { ConfigResponse, UserMFAMethod } from "@/api/rest/generated/@types"

// Queries
import {
	useApiAuthMfaUserActiveMethodsList,
	useApiAuthMfaConfigRetrieve,
} from "@/api/rest/generated/api/api"

export const ProfileSecurityIndex = () => {
	const t = useTrans("profile")
	const [userMfaState, setUserMfaState] = useState<UserMFAMethod[]>([])
	const [mfaState, setMfaState] = useState<ConfigResponse>()

	// this is prefetching user info (phone number)
	// while user is in progress of enabling 2FA via SMS
	useProfileSecurityIntroQuery()

	const { isSuccess, isPending, data } = useApiAuthMfaUserActiveMethodsList()

	useEffect(() => {
		if (isSuccess) {
			setUserMfaState(data)
		}
	}, [data, isSuccess])

	const {
		isSuccess: isMfaQuerySuccess,
		isPending: isMfaQueryLoading,
		data: dataMfa,
	} = useApiAuthMfaConfigRetrieve()

	useEffect(() => {
		if (isMfaQuerySuccess) {
			setMfaState(dataMfa)
		}
	}, [dataMfa, isMfaQuerySuccess])

	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h5" className="mb-3 sm:truncate">
					{t("profile:profile.security.heading")}
				</Heading>

				<FormGroup
					heading={
						<>
							{isPending === false && (
								<p className="whitespace-pre-wrap">
									{userMfaState.length > 0
										? t("profile:profile.security.copy")
										: t(
												"profile:profile.security.two_factor_auth_intro.copy",
										  )}
								</p>
							)}
							{isMfaQueryLoading === false &&
								mfaState?.allow_backup_codes_regeneration && (
									<p className="whitespace-pre-wrap">
										<Trans
											className="mt-2"
											ns="profile"
											i18nKey={
												"profile.security.regenerate_codes"
											}
											components={{
												Link: (
													<Link
														className="font-bold"
														to={
															Routes.SettingsProfileSecurityRegenerateBackupCodes
														}
													></Link>
												),
											}}
										/>
									</p>
								)}
						</>
					}
				>
					<div className="space-y-8">
						<div className="xl:w-3/4">
							<TwoFactorAuthSettings />
						</div>
					</div>
				</FormGroup>
			</CardBody>
		</CardWrapper>
	)
}
