// React
import { ReactNode, useContext, useEffect } from "react"
import { createPortal } from "react-dom"

// Providers
import {
	AuthProvider,
	AuthInactivityIndicator,
	useAuth,
	AuthStateStatusEnum,
} from "@/context/auth"
import { RedirectProvider, RedirectContext } from "@/context/redirectContext"
import { HiddenGetParamsProvider } from "@/context/hiddenGetParams"

// React Query
import {
	queryClient,
	QueryClientProvider,
	ReactQueryDevtools,
} from "@/lib/query"

// Router
import { Navigate, Outlet, useNavigate, useParams } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Layouts
import { AuthLayout } from "@/layouts/AuthLayout"
import { NoAuthLayout } from "./layouts/NoAuthLayout"
import { LoadingScreen } from "@/screens/LoadingScreens"

// Context
import { useCurrentUserInvestmentsCount, useFeatureFlags } from "@/context/user"

// Error boundary
import { GlobalErrorBoundary } from "@/components/errors/GlobalErrorBoundary"

// Translations
import "@/i18n/browser"
import { LanguageProvider } from "@/context/lang"

// UI
import { ToastProvider, Toasts } from "@/context/toasts"

// State
import { StateProvider, useSelector } from "@/state/StateProvider"
import { AuthSliceState } from "@/state/features/authSlice"

// Environment
import { AUTH_LOGIN_URL } from "@/lib/env"

/**
 * App
 *
 * Renders all Providers and loads either <Outlet> or children
 * @returns
 */
export function App({ children }: { children?: ReactNode }) {
	return (
		<StateProvider>
			<LanguageProvider>
				<ToastProvider>
					<QueryClientProvider client={queryClient}>
						<GlobalErrorBoundary>
							<RedirectProvider>
								<AuthProvider queryClient={queryClient}>
									<HiddenGetParamsProvider>
										<AuthInactivityIndicator />
										<AuthContent>
											{children ?? <Outlet />}
										</AuthContent>
									</HiddenGetParamsProvider>
								</AuthProvider>
							</RedirectProvider>

							{/** When ?debug param is set, load react-query-devtools */}
							{new URL(window.location.href).searchParams.get(
								"debug",
							) !== null ? (
								<ReactQueryDevtools initialIsOpen={false} />
							) : null}
							<ModalContainer />
							<ToastContainer />
						</GlobalErrorBoundary>
					</QueryClientProvider>
				</ToastProvider>
			</LanguageProvider>
		</StateProvider>
	)
}

// New component to handle auth-dependent content
function AuthContent({ children }: { children: ReactNode }) {
	// Get auth status
	const { status } = useAuth()

	return (
		<>
			{/** Show loading screen if authenticating or redirecting */}
			{status === AuthStateStatusEnum.AUTHENTICATING && (
				<div className="flex h-full flex-grow items-center justify-center">
					<LoadingScreen />
				</div>
			)}

			{/** Logged in */}
			{status === AuthStateStatusEnum.LOGGED_IN && (
				<AuthLayout>{children}</AuthLayout>
			)}

			{/** Logged out */}
			{status === AuthStateStatusEnum.LOGGED_OUT && (
				<NoAuthLayout>{children}</NoAuthLayout>
			)}
		</>
	)
}

/**
 * Logic to redirect to homepage
 *
 * This is the only place where this code is allowed: <Navigate to={Routes.InvestmentsProjects} />
 * @returns
 */
export function RedirectToHomePage() {
	const investmentsCount = useCurrentUserInvestmentsCount()
	// Show 'portfolio' if we have investments, otherwise show Platform stats
	return investmentsCount > 0 ? (
		<Navigate to={Routes.InvestmentsProjects} />
	) : (
		<Navigate to={Routes.StatisticsPlatform} />
	)
}

/**
 * RedirectToLogin
 *
 * This is the only place this code is allowed: to={Routes.Login} / redirect(`${AUTH_LOGIN_URL}?next=${location.href}`)
 * @returns
 */
export function RedirectToLogin() {
	// Feature flags
	const { getFeatureFlagValue } = useFeatureFlags()

	// State
	const authToken = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.token,
	)
	const { redirect } = useContext(RedirectContext)
	const navigate = useNavigate()

	// Either redirect to Horizon cookie-based login or to dashboard token-based login
	useEffect(() => {
		if (
			getFeatureFlagValue("ENABLE_DASHBOARD_TOKEN_LOGIN") === true &&
			authToken === null
		) {
			navigate(Routes.Login)
		} else {
			redirect(`${AUTH_LOGIN_URL}?next=${location.href}`)
		}
	}, [getFeatureFlagValue, authToken, redirect])

	// Loadingscreen
	return <LoadingScreen />
}

/**
 * RedirectToNewMonthlyOverview
 *
 * Redirect to the new monthly overview page
 * @returns
 */
export const RedirectToNewMonthlyOverview = () => {
	const params = useParams()

	// Redirect to the new URL
	return (
		<Navigate
			to={Routes.InvestmentsProjectsMonthlyOverview.replace(
				":date",
				params.date || "",
			)}
			replace
		/>
	)
}

function ModalContainer() {
	return createPortal(
		<div />,
		document.getElementById("modals") ?? document.body,
	)
}

function ToastContainer() {
	return createPortal(
		<div className="fixed bottom-0 right-0 z-[160] flex flex-col items-end gap-4 pb-4 pr-4 sm:pb-6 sm:pr-6">
			<Toasts />
		</div>,
		document.getElementById("toasts") ?? document.body,
	)
}
