// React
import { useEffect, useState } from "react"

// UI
import { classNames } from "@/lib/ui"
import { Button } from "@/components/Button"
import { CloudDownloadIcon } from "@/lib/icons"

// Translations
import { useTrans } from "@/i18n"

// Analytics
import { sendEvent } from "@/lib/analytics"

/**
 * Types
 *
 * DOCS: https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
 */
interface IBeforeInstallPromptEvent extends Event {
	readonly platforms: string[]
	readonly userChoice: Promise<{
		outcome: "accepted" | "dismissed"
		platform: string
	}>
	prompt(): Promise<void>
}

interface WebappInstallButtonProps {
	className?: string
}

/**
 * WebappInstallButton
 *
 * ALTERNATIVES:
 * https://github.com/pwa-builder/pwa-install
 * https://github.com/shnaveen25/react-pwa-installer-prompt
 * https://www.npmjs.com/package/react-pwa-install
 * https://github.com/docluv/add-to-homescreen
 *
 * @returns
 */
export function WebappInstallButton({
	className = "",
}: WebappInstallButtonProps) {
	const t = useTrans("common")

	// State
	const [showButton, setShowButton] = useState<boolean>(false)
	const [deferredPrompt, setDeferredPrompt] =
		useState<IBeforeInstallPromptEvent | null>(null)

	// When PWA is installed
	function onPWAInstalled() {
		setShowButton(false)
		setDeferredPrompt(null)

		// Track event
		sendEvent("app", "install")
	}

	/**
	 * Show IOS instructions
	 *
	 * DOCS: https://medium.com/ngconf/installing-your-pwa-on-ios-d1c497968e62
	 *
	 * @param localStorageKey
	 * @returns
	 */
	// @ts-ignore
	function showIosInstallModal(localStorageKey: string): boolean {
		// detect if the device is on iOS
		const isIos = () => {
			const userAgent = window.navigator.userAgent.toLowerCase()
			return /iphone|ipad|ipod/.test(userAgent)
		}

		// check if the device is in standalone mode
		const isInStandaloneMode = () => {
			return (
				"standalone" in (window as any).navigator &&
				(window as any).navigator.standalone
			)
		}

		// show the modal only once
		const localStorageKeyValue = localStorage.getItem(localStorageKey)
		const iosInstallModalShown = localStorageKeyValue
			? JSON.parse(localStorageKeyValue)
			: false
		const shouldShowModalResponse =
			isIos() && !isInStandaloneMode() && !iosInstallModalShown
		if (shouldShowModalResponse) {
			localStorage.setItem(localStorageKey, "true")
		}
		return shouldShowModalResponse
	}

	// When mounted
	useEffect(() => {
		const ready = (event: IBeforeInstallPromptEvent) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			event.preventDefault()

			// Stash the event so it can be triggered later.
			setDeferredPrompt(event as IBeforeInstallPromptEvent)
			setShowButton(true)
		}

		/**
		 * Event: BeforeInstallPrompt
		 *
		 * NOTE: Non-standard: This feature is non-standard and is not on a standards track.
		 * Do not use it on production sites facing the Web: it will not work for every user.
		 * There may also be large incompatibilities between implementations and the behavior
		 * may change in the future.
		 * SOURCE: https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent/prompt#return_value
		 *
		 * COMPATIBILITY *
		 * NOT COMPATIBLE: Safari + Firefox
		 *
		 * On desktop:
		 * Chromium browsers support installing PWAs that have a manifest file on all supported desktop operating systems.
		 * Firefox and Safari do not support installing PWAs using a manifest file.
		 *
		 * On mobile:
		 * - On Android, Firefox, Chrome, Edge, Opera, and Samsung Internet Browser all support installing PWAs.
		 * - On iOS 16.3 and earlier, PWAs can only be installed with Safari.
		 * - On iOS 16.4 and later, PWAs can be installed from the Share menu in Safari, Chrome, Edge, Firefox, and Orion.
		 * - Chrome and Edge on iOS and iPadOS do not support PWA installation,
		 * 	 so the beforeinstallprompt event can't fire. In this case, the only
		 *   option is to open the PWA using Safari, where it is installable from the share,
		 *   add to the home screen menu.
		 *
		 * TRIGGER CRITERIA:
		 * In Chrome, your Progressive Web App must meet the following criteria before it will fire the beforeinstallprompt event and show the in-browser install promotion:
		 * - The web app is not already installed
		 * - The user needs to have clicked or tapped on the page at least once (at any time, even during a previous page load)
		 * - The user needs to have spent at least 30 seconds viewing the page (at any time)
		 * - Chrome release is NOT Beta/Dev/Canary,
		 *
		 * DOCS: https://web.dev/articles/install-criteria
		 * DOCS: https://developer.chrome.com/blog/app-install-banners-native
		 */
		window.addEventListener("beforeinstallprompt", ready as any)

		/**
		 * Detect when the PWA was successfully installed.
		 *
		 * NOTE: You can use the userChoice property
		 * to determine whether the user installed your app from within your user interface.
		 * But, if the user installs your PWA from the address bar or another browser component,
		 * userChoice won't help. Instead, you should listen for the appinstalled event, which
		 * fires whenever your PWA is installed, no matter what mechanism is used to install it.
		 */
		window.addEventListener("appinstalled", onPWAInstalled)

		// Remove event listener once unmounted
		return () => {
			window.removeEventListener("beforeinstallprompt", ready as any)
			window.removeEventListener("appinstalled", onPWAInstalled)
		}
	}, [])

	return (
		<>
			{showButton === true && (
				<div className={classNames("flex items-center", className)}>
					<Button
						className="inline-block"
						onClick={() => {
							if (typeof deferredPrompt?.prompt === "function") {
								// Hide button
								setShowButton(false)

								// Show prompt
								void deferredPrompt.prompt()
							}
						}}
					>
						{t("common:common.app.banner.install")}
						<CloudDownloadIcon className="ml-1 w-4" />
					</Button>
				</div>
			)}
		</>
	)
}

// @ts-ignore
function IOSInstructionsBanner() {
	return (
		<div>
			Install this application on your home screen for quick, easy and
			offline access when you’re on the go. Close this modal, tap the
			“share” icon, and then tap on “Add to home screen”.
		</div>
	)
}
