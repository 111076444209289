// D3 graphs
import { bisector, extent, max } from "d3-array"
import { timeFormat, timeFormatDefaultLocale } from "d3-time-format"

// HighCharts
import {
	Highcharts,
	HighchartsReact,
	defaultOptions,
	miniOptions,
	Highchart,
	Stockchart,
	MiniChart,
} from "@/lib/highcharts"

// Initialize
export const setLang = (lang: string) => {
	return new Promise<void>((resolve) => {
		// Set locale
		let locale
		switch (lang) {
			case "nl":
				void import("@/i18n/locales/nl/d3-time-format.json").then(
					(result) => {
						locale = result
						// @ts-ignore
						timeFormatDefaultLocale(locale)
						resolve()
					},
				)
				break
			default:
				void import("@/i18n/locales/en/d3-time-format.json").then(
					(result) => {
						locale = result
						// @ts-ignore
						timeFormatDefaultLocale(locale)
						resolve()
					},
				)
		}
	})
}

// Time formats commonly used throughout the app
export const formatYear = timeFormat("%Y")
export const formatMonth = timeFormat("%b %y")
export const formatDay = timeFormat("%d")

// Export D3
export { bisector, extent, max, timeFormat }

// HighCharts exports
export {
	Highcharts,
	HighchartsReact,
	defaultOptions,
	miniOptions,
	Highchart,
	Stockchart,
	MiniChart,
}
