import {
	createContext,
	Fragment,
	useContext,
	useState,
	useMemo,
	useEffect,
} from "react"

// Router
import { Link } from "@/lib/router"

// DateTime
import { DateTime } from "@/lib/dates"

// Queries
import { keepPreviousData } from "@/lib/query"
import {
	InterestPaymentType,
	PaymentStateEnum,
	usePaymentsQuery,
} from "@/api/graphql"

// Icons
import {
	CheckIcon,
	ChevronDownIcon,
	FiX,
	CurrencyEuroIcon,
	FiHash,
} from "@/lib/icons"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Tables
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import {
	useReactTable,
	flexRender,
	createColumnHelper,
	getPaginationRowModel,
	getCoreRowModel,
	CellContext,
} from "@/lib/table"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import { useTableMetaData } from "@/hooks/useTableMetaData"

// UI
import {
	classNames,
	Dialog,
	DialogBackdrop,
	Disclosure,
	Popover,
	Transition,
	TransitionChild,
} from "@/lib/ui"
import { Button } from "@/components/Button"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"
import { SearchInput } from "@/components/form-controls/Input"
import { CardFooter, CardHeader } from "@/components/Card"
import { Radio } from "@/components/form-controls"
import { Disclaimer } from "@/components/Disclaimer"
import { P } from "@/components/Typography"

// Animations
import {
	motion,
	AnimatePresence,
	getTransitionBackgroundProps,
	getTransitionPopoverProps,
	getTransitionSidebarProps,
} from "@/lib/animations"

// Constants
import { UserRoles, dateFormat } from "@/constants/constants"

// Auth
import { useCurrentUserRoles } from "@/context/user"

const DISPLAY_ACTIVE_FILTERS = false

const i18nStateMap: { [key in PaymentStateEnum]: string } & {
	UNKNOWN: string
} = {
	DONE: "payments:payments.list.state.done",
	NEW: "payments:payments.list.state.new",
	PENDING_SUPPLIER: "payments:payments.list.state.pending_supplier",
	PENDING_EMPLOYEE: "payments:payments.list.state.pending_employee",
	PENDING_INVESTOR_PAYMENT:
		"payments:payments.list.state.pending_investor_payment",
	PENDING_OWNER_PAYMENT: "payments:payments.list.state.pending_owner_payment",
	PENDING_OWNER: "payments:payments.list.state.pending_owner",
	VERIFIED: "payments:payments.list.state.verified",
	UNKNOWN: "payments:payments.list.state.unknown",
	PENDING_APPROVAL_BY_ZPD:
		"payments:payments.list.state.pending_approval_by_zpd",
}

/**
 * Map of all available state selector options
 * TODO: the 'state' filter is currently lowercased in backend but should be UPPERCASED enums like InterestPaymentType.PaymentStateEnum
 * maybe in the future these will be uppercase for now
 * however, api returns uppercase states, yet the request for filtering
 * expects the state to be lowercase
 */
const stateSelectorOptions = {
	ALL: [],
	DONE: ["done"],
	ACTION_ENERGY_SUPPLIER: ["pending_supplier"],
	ACTION_PROJECT_OWNER: ["pending_owner", "pending_owner_payment"],
}
type StateSelectorOptionType =
	| "ALL"
	| "DONE"
	| "ACTION_ENERGY_SUPPLIER"
	| "ACTION_PROJECT_OWNER"

const PaymentsContext = createContext<{
	selectedStateFilter: StateSelectorOptionType
	setSelectedStateFilter(old: StateSelectorOptionType): void
	states?: string[] | []
	setStates(old: string[] | []): void
}>(null!)

export const Payments = () => {
	// Translations
	const { formatCurrency } = useLang()
	const t = useTrans(["common", "payments", "project"])

	// Context
	const { hasRole } = useCurrentUserRoles()

	// State
	const [data, setData] = useState<InterestPaymentType[] | null>([])

	// Set default selectedStateFilter on mount with certain roles
	const initialState = useMemo(() => {
		if (hasRole(UserRoles.admin)) {
			return {
				selectedStateFilter: "ALL" as StateSelectorOptionType,
				states: stateSelectorOptions.ALL,
			}
		}
		if (hasRole(UserRoles.energyProvider)) {
			return {
				selectedStateFilter:
					"ACTION_ENERGY_SUPPLIER" as StateSelectorOptionType,
				states: stateSelectorOptions.ACTION_ENERGY_SUPPLIER,
			}
		}
		if (hasRole(UserRoles.projectOwner)) {
			return {
				selectedStateFilter:
					"ACTION_PROJECT_OWNER" as StateSelectorOptionType,
				states: stateSelectorOptions.ACTION_PROJECT_OWNER,
			}
		}

		return {
			selectedStateFilter: "ALL" as StateSelectorOptionType,
			states: [],
		}
	}, [hasRole])

	// State
	const [selectedStateFilter, setSelectedStateFilter] =
		useState<StateSelectorOptionType>(initialState.selectedStateFilter)
	const [states, setStates] = useState<string[]>(initialState.states)

	// Table
	const { setFilter, setSort, setPage, filter, sort, page, pageSize } =
		useTableMetaData({ initialSort: "-interestperiod__end" })

	// Tables
	const columnHelper = createColumnHelper<InterestPaymentType>()
	const columns = [
		columnHelper.accessor((data) => data?.project?.name, {
			id: "project_name",
			header: () => (
				<TableHeading
					fieldName="interestperiod__project__name"
					sort={sort}
					setSort={setSort}
				>
					{t("payments:payments.list.project.title")}
				</TableHeading>
			),
			cell: (info: CellContext<InterestPaymentType, string>) => (
				<TableDataCell className="!p-0 xl:max-w-[30%]">
					<span className="block h-full px-6 py-4 font-bold text-gray-700 hover:text-gray-900">
						{info.getValue()}
					</span>
				</TableDataCell>
			),
		}),
		columnHelper.accessor((data) => data?.transaction_id, {
			id: "transaction_id",
			header: () => (
				<TableHeading
					fieldName="interestperiod__transaction_id"
					sort={sort}
					setSort={setSort}
				>
					{t("project:project.revenue.table.headers.transaction_id")}
				</TableHeading>
			),
			cell: (info: CellContext<InterestPaymentType, string>) => (
				<TableDataCell className="!p-0 xl:max-w-[30%]">
					<span className="flex h-full px-6 py-4">
						<FiHash
							className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
							aria-hidden="true"
						/>
						{info.getValue()}
					</span>
				</TableDataCell>
			),
		}),
		columnHelper.accessor((data) => data, {
			id: "interest_period",
			header: () => (
				<TableHeading
					fieldName="interestperiod__start"
					sort={sort}
					setSort={setSort}
				>
					{t("payments:payments.list.interest_period.title")}
				</TableHeading>
			),
			cell: (
				info: CellContext<InterestPaymentType, InterestPaymentType>,
			) => (
				<TableDataCell>
					{t("payments:payments.list.interest_period.copy", {
						start: DateTime.fromSQL(
							info.getValue()?.interestperiod?.start,
						).toFormat(dateFormat),
						end: DateTime.fromSQL(
							info.getValue()?.interestperiod?.end,
						).toFormat(dateFormat),
					})}
				</TableDataCell>
			),
		}),
		columnHelper.accessor(
			(data: InterestPaymentType) => {
				const amoritization = data?.interestperiod?.amortization ?? 0
				const interestAmount = data?.interest_amount
					? parseFloat(data?.interest_amount)
					: 0
				const repayment = data?.interestperiod?.repayment ?? 0
				const sum = interestAmount + amoritization + repayment

				return formatCurrency(sum)
			},
			{
				id: "amount",
				header: () => (
					<TableHeading fieldName="interestperiod__end">
						{t("payments:payments.list.amount.title")}
					</TableHeading>
				),
				cell: (info: CellContext<InterestPaymentType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			},
		),
		columnHelper.accessor((data) => data.state, {
			id: "state",
			header: () => (
				<TableHeading>
					{t("payments:payments.list.state.title")}
				</TableHeading>
			),
			cell: (
				info: CellContext<InterestPaymentType, PaymentStateEnum>,
			) => (
				<TableDataCell multiline>
					<span title={t(i18nStateMap[info.getValue() ?? "UNKNOWN"])}>
						{t(i18nStateMap[info.getValue() ?? "UNKNOWN"])}
					</span>
				</TableDataCell>
			),
		}),
		columnHelper.accessor((data) => data, {
			id: "actions",
			header: () => (
				<TableHeading>
					{t("payments:payments.list.actions.title")}
				</TableHeading>
			),
			cell: (
				info: CellContext<InterestPaymentType, InterestPaymentType>,
			) => (
				<TableDataCell>
					<Actions data={info.getValue()} />
				</TableDataCell>
			),
		}),
		columnHelper.accessor(
			(data: InterestPaymentType) => {
				return DateTime.fromSQL(
					data?.interestperiod?.payment_deadline_date || "",
				).toFormat(dateFormat)
			},
			{
				id: "payment_deadline_date",
				header: () => (
					<TableHeading>
						{t(
							"payments:payments.list.payment_deadline_date.title",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<InterestPaymentType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			},
		),
		columnHelper.accessor("progress_percentage", {
			id: "progress_percentage",
			header: () => (
				<TableHeading>
					{t("payments:payments.list.progress_percentage.title")}
				</TableHeading>
			),
			cell: (info) => (
				<TableDataCell>
					<ProgressBar percent={info.getValue() ?? 0} />,
				</TableDataCell>
			),
		}),
	]
	const table = useReactTable({
		columns,
		data: data || [],
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		state: {
			pagination: {
				pageIndex: 0,
				pageSize: 10,
			},
		},
	})

	return (
		<PaymentsContext.Provider
			value={{
				selectedStateFilter,
				setSelectedStateFilter,
				states,
				setStates,
			}}
		>
			<CardHeader>
				{/* header and search */}
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:space-y-0">
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							onChange={(evt) =>
								setFilter(evt.currentTarget.value)
							}
							label={t("payments:payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
					<div className="order-1 flex space-x-4 md:hidden">
						{/* mobile filters */}
						<FiltersMobile />
					</div>
					<div className="order-2 hidden space-x-4 md:flex">
						{/* desktop filters */}
						<FiltersDesktop />
					</div>
				</div>
			</CardHeader>
			<div className="md:hidden">
				<Disclaimer
					message={t("common:common.table.mobile_warning.copy")}
				/>
			</div>
			<Table
				className="min-w-[48rem] lg:min-w-0"
				data-testid="table"
				layout="auto"
			>
				{/* table header */}
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>
				{/* table body and table cells, plus table footer */}
				<PaymentsView
					table={table}
					setData={setData}
					setPage={setPage}
					filter={filter}
					sort={sort}
					page={page}
					pageSize={pageSize}
				/>
			</Table>
			<PaymentsPagination
				setPage={setPage}
				filter={filter}
				sort={sort}
				page={page}
				pageSize={pageSize}
			/>
		</PaymentsContext.Provider>
	)
}

/**
 * Actions that can be taken on interest payments
 * @param param0
 * @returns
 */
function Actions({ data }: { data?: InterestPaymentType | undefined }) {
	const t = useTrans("payments")
	const { hasRole } = useCurrentUserRoles()

	// Energy supplier approve
	if (data?.state === PaymentStateEnum.PendingSupplier) {
		return (
			<Link to={`/finance/interest-payments/supplier/${data?.id}`}>
				<Button variant="secondary" size="small">
					{t("payments:payments.list.actions.review")}
				</Button>
			</Link>
		)
	}

	// Project owner approve
	else if (data?.state === PaymentStateEnum.PendingOwner) {
		return (
			<Link to={`/finance/interest-payments/owner/${data?.id}`}>
				<Button variant="secondary" size="small">
					{t("payments:payments.list.actions.review")}
				</Button>
			</Link>
		)
	}

	// Project owner payment link
	else if (
		data?.state === PaymentStateEnum.PendingOwnerPayment &&
		data?.payment_link
	) {
		return (
			<a href={data.payment_link}>
				<Button variant="primary" size="small">
					<CurrencyEuroIcon
						aria-hidden="true"
						className="mr-1 h-5 w-5"
					/>
					{t("payments:payments.list.actions.payment")}
				</Button>
			</a>
		)
	}

	// View history (owners)
	else if (hasRole(UserRoles.projectOwner) || hasRole(UserRoles.admin)) {
		return (
			<Link to={`/finance/interest-payments/owner/${data?.id}`}>
				<Button variant="transparent" size="small">
					{data?.state === PaymentStateEnum.Done && (
						<CheckIcon
							aria-hidden="true"
							className="mr-1 h-5 w-5 text-green-500"
						/>
					)}

					{t("payments.list.actions.view")}
				</Button>
			</Link>
		)
	}

	// View history (suppliers)
	else if (hasRole(UserRoles.energyProvider)) {
		return (
			<Link to={`/finance/interest-payments/supplier/${data?.id}`}>
				<Button variant="transparent" size="small">
					{data?.state === PaymentStateEnum.Done && (
						<CheckIcon
							aria-hidden="true"
							className="mr-1 h-5 w-5 text-green-500"
						/>
					)}

					{t("payments:payments.list.actions.view")}
				</Button>
			</Link>
		)
	}

	// Default
	return null
}

function FiltersDesktop() {
	const t = useTrans("payments")
	const { selectedStateFilter, setSelectedStateFilter } =
		useContext(PaymentsContext)

	return (
		<>
			{/* Filters */}
			<section aria-labelledby="filters-heading">
				<h2 id="filters-heading" className="sr-only">
					{t("payments:payments.list.filters")}
				</h2>

				<div className="relative z-10">
					<Popover.Group className="flex items-center divide-x divide-gray-200">
						<Popover className="relative inline-block text-left">
							<Popover.Button
								as="div"
								className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
							>
								<Button variant="transparent">
									<span className="mr-2">
										{t(
											"payments:payments.list.filter.by_state",
										)}
									</span>
									<AnimatePresence
										initial={false}
										key="filter-state-amount"
									>
										<motion.span
											className="bg-primary-500 overflow-hidden rounded text-xs tabular-nums text-black"
											initial={{
												width: 0,
											}}
											animate={{
												width: "auto",
											}}
											exit={{
												width: 0,
											}}
										>
											<span
												className="px-1.5 py-0.5"
												data-testid="payments.list.filter.by_state.count"
											>
												{t(
													`payments:payments.list.state.${selectedStateFilter?.toLowerCase()}`,
												)}
											</span>
										</motion.span>
									</AnimatePresence>
									<ChevronDownIcon
										className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
								</Button>
							</Popover.Button>

							<Transition {...getTransitionPopoverProps()}>
								<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
									<div
										className="min-w-[300px] space-y-4"
										data-testid="filter-payment-states-desktop"
									>
										<FiltersPaymentStates />
									</div>
								</Popover.Panel>
							</Transition>
						</Popover>
					</Popover.Group>
				</div>

				{/* active filters */}
				{!!selectedStateFilter && DISPLAY_ACTIVE_FILTERS ? (
					<div className="mt-4">
						<h3 className="sr-only">
							{t(
								"payments:payments.list.filter.by_state.active.heading",
							)}
						</h3>

						<div className="flex flex-wrap items-center gap-x-3 gap-y-2">
							<Button
								variant="transparent"
								size="small"
								onClick={() =>
									setSelectedStateFilter(selectedStateFilter)
								}
							>
								<span className="sr-only">
									{t(
										"payments:payments.list.filter.by_state.remove_filter",
										{
											state: `payments:payments.list.state.${selectedStateFilter?.toLowerCase()}`,
										},
									)}
								</span>
								<span>
									{t(
										`payments:payments.list.state.${selectedStateFilter?.toLowerCase()}`,
									)}
								</span>
								<FiX className="ml-2" />
							</Button>
						</div>
					</div>
				) : null}
			</section>
		</>
	)
}

/**
 * FiltersMobile
 */
function FiltersMobile() {
	const t = useTrans()

	const [open, setOpen] = useState(false)

	return (
		<>
			{/* mobile filters start */}
			<Transition show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-40 flex"
					onClose={setOpen}
				>
					<TransitionChild {...getTransitionBackgroundProps()}>
						<DialogBackdrop className="fixed inset-0 bg-black bg-opacity-25" />
					</TransitionChild>
					<TransitionChild {...getTransitionSidebarProps()}>
						<div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
							<div className="flex items-center justify-between px-4">
								<h2 className="text-lg font-medium text-gray-900">
									{t("payments:payments.list.filters")}
								</h2>
								<button
									type="button"
									className="focus:ring-secondary-500 -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2"
									onClick={() => setOpen(false)}
								>
									<span className="sr-only">
										{t(
											"common:common.navigation.menu.close",
										)}
									</span>
									<FiX
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</button>
							</div>

							{/* filters */}
							<Disclosure
								as="form"
								className="mt-4 border-t border-gray-200 px-4 py-6"
								defaultOpen={true}
							>
								{({ open }) => (
									<>
										<h3 className="-mx-2 -my-3 flow-root">
											<Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
												<span className="font-medium text-gray-900">
													{t(
														"payments:payments.list.state.title",
													)}
												</span>
												<span className="ml-6 flex items-center">
													<ChevronDownIcon
														className={classNames(
															open
																? "-rotate-180"
																: "rotate-0",
															"h-5 w-5 transform",
														)}
														aria-hidden="true"
													/>
												</span>
											</Disclosure.Button>
										</h3>
										<Disclosure.Panel className="pt-6">
											<div className="space-y-6">
												<FiltersPaymentStates />
											</div>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						</div>
					</TransitionChild>
				</Dialog>
			</Transition>
			{/* mobile filters end */}

			{/* button to open mobile menu filters */}
			<Button
				type="button"
				variant="transparent"
				className="md:hidden"
				onClick={() => setOpen(true)}
			>
				{t("payments:payments.list.filters")}
			</Button>
		</>
	)
}

/**
 * FiltersPaymentStates
 * @returns
 */
function FiltersPaymentStates() {
	const t = useTrans("payments")
	const { setStates, selectedStateFilter, setSelectedStateFilter } =
		useContext(PaymentsContext)
	const { hasOneOfRoles } = useCurrentUserRoles()

	return (
		<>
			{/** Action Energy supplier */}
			{hasOneOfRoles(UserRoles.energyProvider, UserRoles.admin) && (
				<label className="group flex cursor-pointer items-center">
					<Radio
						defaultChecked={
							selectedStateFilter === "ACTION_ENERGY_SUPPLIER"
						}
						onClick={() => {
							setSelectedStateFilter("ACTION_ENERGY_SUPPLIER")
							setStates(
								stateSelectorOptions.ACTION_ENERGY_SUPPLIER,
							)
						}}
					/>
					<P className="ml-3 text-sm text-gray-700 group-hover:text-gray-900">
						{t(
							`payments:payments.list.state.action_energy_supplier`,
						)}
					</P>
				</label>
			)}

			{/** Action Project Owner */}
			{hasOneOfRoles(UserRoles.projectOwner, UserRoles.admin) && (
				<label className="group flex cursor-pointer items-center">
					<Radio
						id={`filter-payment-states-pending-owner`}
						data-testid={`filter-payment-states-pending-owner`}
						name="state"
						defaultValue={"PENDING_OWNER"}
						defaultChecked={
							selectedStateFilter === "ACTION_PROJECT_OWNER"
						}
						onClick={() => {
							setSelectedStateFilter("ACTION_PROJECT_OWNER")
							setStates(stateSelectorOptions.ACTION_PROJECT_OWNER)
						}}
					/>
					<P className="ml-3 text-sm text-gray-700 group-hover:text-gray-900">
						{t(`payments:payments.list.state.action_project_owner`)}
					</P>
				</label>
			)}

			{/** Done */}
			<label className="group flex cursor-pointer items-center">
				<Radio
					id="filter-payment-states-done"
					data-testid="filter-payment-states-done"
					name="state"
					defaultChecked={selectedStateFilter === "DONE"}
					onClick={() => {
						setSelectedStateFilter("DONE")
						setStates(stateSelectorOptions.DONE)
					}}
				/>
				<P className="ml-3 text-sm text-gray-700 group-hover:text-gray-900">
					{t("payments:payments.list.state.done")}
				</P>
			</label>

			{/** All payments */}
			<label className="group flex cursor-pointer items-center">
				<Radio
					id="filter-payment-states-all"
					data-testid="filter-payment-states-all"
					name="state"
					defaultChecked={selectedStateFilter === "ALL"}
					onClick={() => {
						setSelectedStateFilter("ALL")
						setStates(stateSelectorOptions.ALL)
					}}
				/>
				<P className="ml-3 text-sm text-gray-700 group-hover:text-gray-900">
					{t("payments:payments.list.state.all")}
				</P>
			</label>
		</>
	)
}

function LoadingState({
	columnCount = 8,
	pageSize = 10,
}: {
	columnCount?: number
	pageSize?: number
}) {
	return (
		<TableBody>
			{Array(pageSize)
				.fill(true)
				.map((_, index) => {
					return (
						<TableRowCell
							withHover={false}
							key={index}
							isOdd={index % 2 === 0}
							className="animate-pulse"
						>
							<TableDataCell colSpan={1}>
								<p className="dummy-text rounded-md bg-gray-100" />
							</TableDataCell>
							<TableDataCell colSpan={columnCount - 2}>
								<p className="dummy-text rounded-md bg-gray-100" />
							</TableDataCell>
							<TableDataCell colSpan={1}>
								<ProgressBarPlaceholder />
							</TableDataCell>
						</TableRowCell>
					)
				})}
		</TableBody>
	)
}

interface PaymentsViewProps {
	pageSize: number
	page: number
	sort: string | undefined
	filter: string | undefined
	setPage: (page: number) => void
	table: ReturnType<typeof useReactTable<InterestPaymentType>>
	setData: (data: InterestPaymentType[] | null) => void
}

function usePaymentsQueryData(
	{
		pageSize,
		page,
		sort,
		filter,
	}: Pick<PaymentsViewProps, "pageSize" | "page" | "sort" | "filter">,
	options?: { placeholderData: typeof keepPreviousData },
) {
	const { states } = useContext(PaymentsContext)

	return usePaymentsQuery(
		{
			limit: pageSize,
			offset: page * pageSize,
			ordering: sort,
			q: filter,
			state: states,
		},
		options,
	)
}

function PaymentsPagination({
	page,
	pageSize,
	sort,
	filter,
	setPage,
}: Pick<
	PaymentsViewProps,
	"page" | "pageSize" | "filter" | "sort" | "setPage"
>) {
	const t = useTrans("payments")
	const { data } = usePaymentsQueryData(
		{
			page,
			pageSize,
			sort,
			filter,
		},
		{ placeholderData: keepPreviousData },
	)

	return (
		<div>
			{data?.interest_payments?.results?.length === 0 && (
				<CardFooter>
					<TableEmptyState>
						{t(
							"payments:payments.outstanding_payments.empty_state",
						)}
					</TableEmptyState>
				</CardFooter>
			)}
			{data?.interest_payments?.results?.length !== 0 && (
				<CardFooter>
					<PaginationAsButtons
						countPerPage={pageSize}
						totalCount={data?.interest_payments?.totalCount ?? 0}
						itemType={"common.pagination.item_types.payment"}
						currentPage={page + 1}
						currentItemsAmount={
							data?.interest_payments?.results?.length ?? 0
						}
						onNextPage={() => setPage(page + 1)}
						onPrevPage={() => setPage(Math.max(page - 1, 0))}
						canGoPrev={page > 0}
						analyticsId="payments"
					/>
				</CardFooter>
			)}
		</div>
	)
}

/**
 * PaymentsView
 */
function PaymentsView({
	table,
	pageSize,
	page,
	sort,
	filter,
	setData,
}: PaymentsViewProps) {
	const { data, isLoading, isPlaceholderData } = usePaymentsQueryData({
		page,
		pageSize,
		sort,
		filter,
	})

	// Update table data when query results change
	useEffect(() => {
		setData(
			(data?.interest_payments?.results as InterestPaymentType[]) ?? [],
		)
	}, [data?.interest_payments?.results, setData])

	// Loading state
	if (isLoading) {
		return <LoadingState columnCount={8} pageSize={pageSize} />
	}

	// Data state
	return (
		<>
			{/* table body and table cells */}
			<TableBody
				data-testid="tablebody"
				className={isPlaceholderData ? "opacity-50" : ""}
			>
				{table.getRowModel().rows.map((row, index) => {
					return (
						<TableRowCell
							key={row.id}
							isOdd={index % 2 === 0}
							data-testid={`tablerow-${row?.id}`}
						>
							{row.getAllCells().map((cell) => {
								return (
									<Fragment key={cell.id}>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					)
				})}
			</TableBody>
		</>
	)
}

/**
 * ProgressBar
 */
function ProgressBar({ percent }: { percent: number }) {
	const t = useTrans("payments")

	let className = "bg-green-500"

	if (percent < 33) {
		className = "bg-red-500"
	} else if (percent < 78) {
		className = "bg-orange-500"
	}

	return (
		<>
			<p className="mb-1">
				{t("payments:payments.list.progress_percentage.copy", {
					count: percent,
				})}
			</p>
			<div className="relative h-3 max-w-xl overflow-hidden rounded-full">
				<div className="absolute h-full w-full bg-gray-200" />
				<div
					className={classNames(
						className,
						"relative h-full transition-all duration-1000 ease-out",
					)}
					style={{ width: `${percent}%` }}
				/>
			</div>
		</>
	)
}

function ProgressBarPlaceholder() {
	return (
		<>
			<p className="dummy-text mb-1 w-2/5 overflow-hidden rounded-md bg-gray-100" />
			<div className="relative h-3 max-w-xl overflow-hidden rounded-full">
				<div className="absolute h-full w-full bg-gray-100" />
			</div>
		</>
	)
}
