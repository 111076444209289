// React
import { Fragment, useMemo, useState } from "react"

// Router
import { Link } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat, apiDateFormat } from "@/constants/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"
import { emptyValue } from "@/utils/helpers"

// GraphQL
import {
	PaymentEntryType,
	PaymentStateEnum,
	PayOutState,
	ProjectType,
	ProjectTypeEnum,
	useAllInvestorPaymentsQuery,
} from "@/api/graphql"

// Tables
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { classNames } from "@/lib/ui"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"
import { Tooltip } from "@/components/Tooltip"

// Device info
import { useDeviceCssType } from "@/lib/device"

// Icons
import { FiHash, FiInfo } from "@/lib/icons"

/*
 * PayoutsTable on webpage
 * @returns
 */
interface PayoutsTableWebProps {
	showPagination?: boolean
	startTime?: DateTime
	endTime?: DateTime
	transactionId?: string
}
export function PayoutsTableWeb({
	showPagination = true,
	startTime,
	endTime,
	transactionId,
}: PayoutsTableWebProps) {
	// Translations
	const t = useTrans("project")
	const { formatCurrency } = useLang()

	// Device Type (based on CSS)
	const deviceCssType = useDeviceCssType()

	// State
	const limit = 10
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Queries
	const { data, isLoading } = useAllInvestorPaymentsQuery({
		limit,
		offset: currentPage * limit,
		startTime: startTime?.toFormat(apiDateFormat),
		endTime: endTime?.toFormat(apiDateFormat),
		transactionId: transactionId,
		ordering: "-payment__interestperiod__end", // Uses internal python model structure
	})

	// Memo
	const investments = useMemo(() => {
		return data?.me?.payment_entries?.results?.map((payment) => {
			return {
				...payment,
			}
		})
	}, [data?.me?.payment_entries?.results])

	// PaginatorInfo
	const totalPayoutCount = useMemo(
		() => data?.me?.payment_entries?.totalCount ?? 0,
		[data?.me?.payment_entries?.totalCount],
	)

	// Table Data
	const columnHelper = createColumnHelper<PaymentEntryType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor("project", {
				cell: (info: CellContext<PaymentEntryType, ProjectType>) => (
					<TableDataCell
						showHeaderOnlyOnMobile
						className="font-medium"
						fieldName={t(
							"project:project.revenue.table.headers.project",
						)}
					>
						<Link
							to={`${Routes.InvestmentsProjects}/${
								info.getValue()?.id
							}/revenue`}
						>
							{info.getValue()?.name}
						</Link>
					</TableDataCell>
				),
				header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.project")}
					</TableHeading>
				),
			}),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					DateTime.fromISO(data.interest_period?.start).toFormat(
						dateFormat,
					),
				{
					id: "interest_period.start",
					header: () => (
						<TableHeading>
							{t(
								"project:project.revenue.table.headers.interest_period",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, string>) => (
						<TableDataCell
							fieldName={t(
								"project:project.revenue.table.headers.interest_period",
							)}
						>
							{info.getValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor((data: PaymentEntryType) => data, {
				id: "payment_deadline_date",
				header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payment_deadline_date",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<PaymentEntryType, PaymentEntryType>,
				) => {
					return (
						<TableDataCell
							fieldName={t(
								"project:project.revenue.table.headers.payment_deadline_date",
							)}
						>
							<span
								className={classNames(
									info.getValue().payment_state ===
										PaymentStateEnum.Done &&
										"text-green-500",
									info.getValue().payment_state !==
										PaymentStateEnum.Done &&
										DateTime.fromISO(
											info.getValue().interest_period
												?.payment_deadline_date,
										) < DateTime.now() &&
										"text-red-500",
								)}
							>
								{DateTime.fromISO(
									info.getValue().interest_period
										?.payment_deadline_date,
								).toFormat(dateFormat) || emptyValue()}
							</span>
						</TableDataCell>
					)
				},
			}),
			columnHelper.accessor((data: PaymentEntryType) => data?.state, {
				id: "payment_entry.state",
				header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payout_state",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, PayOutState>) => (
					<TableDataCell
						multiline
						fieldName={t(
							"project:project.revenue.table.headers.payout_state",
						)}
					>
						<Tooltip
							content={
								info.getValue()
									? t(
											`project:project.revenue.payment_entry_states.${info.getValue()}.tooltip`,
									  )
									: t(
											"project:project.revenue.payment_entry_states.unknown.tooltip",
									  )
							}
						>
							<div className="flex cursor-pointer items-center">
								<FiInfo className="mr-1" />
								{info.getValue()
									? t(
											`project:project.revenue.payment_entry_states.${info.getValue()}`,
									  )
									: t(
											`project:project.revenue.payment_entry_states.unknown`,
									  )}
							</div>
						</Tooltip>
					</TableDataCell>
				),
			}),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					Number(data?.cost) +
					Number(data?.amortization) +
					Number(data?.repayment),
				{
					id: "paymentSum",
					header: () => (
						<TableHeading>
							{t("project:project.revenue.table.headers.payment")}
						</TableHeading>
					),
					cell: (info: CellContext<PaymentEntryType, number>) => (
						<TableDataCell
							fieldName={t(
								"project:project.revenue.table.headers.payment",
							)}
						>
							{info.getValue()
								? formatCurrency(info.getValue())
								: emptyValue()}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor("transaction_id", {
				cell: (info: CellContext<PaymentEntryType, string>) => (
					<TableDataCell
						fieldName={t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					>
						<span className="flex flex-row">
							<FiHash
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{info.getValue()}
						</span>
					</TableDataCell>
				),
				header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
				),
			}),
			columnHelper.accessor("project.type", {
				header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.project_type",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<PaymentEntryType, ProjectTypeEnum>,
				) => (
					<TableDataCell
						fieldName={t(
							"project:project.revenue.table.headers.project_type",
						)}
					>
						{t(`common:common.project.type.${info.getValue()}`)}
					</TableDataCell>
				),
			}),
		],
		[],
	)
	const table = useReactTable({
		columns,
		data: investments as PaymentEntryType[],
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		enableHiding: true,
		state: {
			pagination: {
				pageIndex: 0,
				pageSize: limit,
			},
			columnVisibility: {
				project_type: deviceCssType === "desktop",
			},
		},
	})

	// Loading state
	if (isLoading) {
		return <PayoutsTableWebLoading />
	}

	// Data state
	return (
		<>
			{/** When user has no payouts */}
			{totalPayoutCount === 0 && (
				<Card>
					<p className="text-sm text-gray-500">
						<div className="z-2 relative px-8">
							<Heading as="h4" className="text-center">
								{t("project:project.revenue.no-data.title")}
							</Heading>
							<p className="mt-2 text-center text-sm text-gray-500">
								{t("project:project.revenue.no-data.copy")}
							</p>
						</div>
					</p>
				</Card>
			)}

			{/** Show table if we have payouts */}
			{totalPayoutCount !== 0 && (
				<Table data-testid="tablebody" layout="responsive">
					<TableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRowCell key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Fragment key={header.id}>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
										</Fragment>
									)
								})}
							</TableRowCell>
						))}
					</TableHead>
					<TableBody
						data-testid="tablebody-overview"
						data-pageindex={table.getState().pagination.pageIndex}
					>
						{table.getRowModel().rows.map((row) => {
							const isOdd = row.index % 2 === 0
							let className = ""

							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										className={classNames(className)}
									>
										{row.getVisibleCells().map((cell) => {
											return (
												<Fragment key={cell.id}>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext(),
													)}
												</Fragment>
											)
										})}
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{table.getRowModel().rows.length <
							table.getState().pagination.pageSize &&
						table.getState().pagination.pageIndex !== 0 ? (
							<>
								{Array(
									Math.max(
										table.getState().pagination.pageSize -
											table.getRowModel().rows.length,
										1,
									),
								)
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>
			)}

			{/** Show pagination */}
			{showPagination === true && investments?.length !== 0 && (
				<div className="mt-6 flex w-full justify-center px-4 py-5 sm:p-6 md:mt-8">
					<PaginationAsButtons
						countPerPage={limit}
						totalCount={totalPayoutCount ?? 0}
						itemType={"common.pagination.item_types.payment"}
						currentPage={currentPage + 1}
						currentItemsAmount={investments?.length ?? 0}
						onNextPage={() =>
							setCurrentPage((currentPage) => currentPage + 1)
						}
						onPrevPage={() =>
							setCurrentPage((currentPage) =>
								Math.max(currentPage - 1, 0),
							)
						}
						analyticsId="investments"
					/>
				</div>
			)}
		</>
	)
}

/**
 * PayoutsTableWebLoading
 * @returns
 */
export function PayoutsTableWebLoading() {
	const t = useTrans("project")

	return (
		<Table data-testid="tablebody" layout="responsive">
			<TableHead>
				<TableRowCell>
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.project")}
					</TableHeading>
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</TableHeading>
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payment_deadline_date",
						)}
					</TableHeading>
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payout_state",
						)}
					</TableHeading>
					<TableHeading>
						{t("project:project.revenue.table.headers.payment")}
					</TableHeading>
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.project_type",
						)}
					</TableHeading>
				</TableRowCell>
			</TableHead>
			<TableBody data-testid="tablebody-overview">
				{[...Array(10)].map((_, index) => (
					<TableRowCell key={index} isOdd={index % 2 === 0}>
						<TableDataCell>
							<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
					</TableRowCell>
				))}
			</TableBody>
		</Table>
	)
}
