// React
import { useMemo } from "react"

// Context
import { useProductionGraphContext } from "@/components/graphs/types/bar/ProductionGraph"

// DateTime
import { DateTime } from "@/lib/dates"

// I18n
import { useTrans } from "@/i18n"

// CSV
import { exportToCsv, getCsvFileName } from "@/lib/csv"

// GraphQL
import {
	StatsProductionGeneric,
	useInvestmentsGraphProductionQuery,
	InvestmentsGraphProductionQuery,
} from "@/api/graphql"

// UI
import { DownloadSelector } from "@/components/DownloadSelector"

// Types
import { DownloadableFileType } from "@/components/DownloadSelector/types"

function useInvestmentsGraphProductionData() {
	const { graphInterval, startTime, endTime } = useProductionGraphContext()

	return useInvestmentsGraphProductionQuery({
		startTime,
		endTime,
		interval: graphInterval,
	})
}

/**
 * ProjectMonthlyProductionGraphActions
 * @returns
 */
export function ProjectMonthlyProductionGraphActions() {
	const { startTime } = useProductionGraphContext()
	const t = useTrans(["common", "investments"])
	const { data: rawData, isLoading } = useInvestmentsGraphProductionData()

	const data = useMemo(() => {
		return mergeProductionData(rawData)
	}, [rawData])

	/**
	 * mergeProductionData
	 * @param data
	 * @returns
	 */
	function mergeProductionData(
		data: InvestmentsGraphProductionQuery | undefined,
	) {
		const productionData =
			data?.me?.investor_production_stats?.production_data || []
		const expectedData =
			data?.me?.investor_production_stats?.expected_production_data || []

		const mergedData = productionData
			.filter((prod): prod is StatsProductionGeneric => prod !== null)
			.map((prod) => {
				const expectedProd = expectedData.find(
					(exp) => exp?.date === prod?.date,
				)
				return {
					date: prod?.date,
					production: prod?.production,
					expectedProduction: expectedProd?.production,
				}
			})

		return mergedData
	}

	/**
	 * handleDownload
	 * @param fileType
	 */
	const handleDownload = (fileType: DownloadableFileType) => {
		if (fileType === DownloadableFileType.Csv) {
			const fileName = getCsvFileName(
				t(
					"investments:investments.monthly_overview.block.shares.production.title",
					{
						date: DateTime.fromJSDate(startTime)
							.toFormat("yyyy-MM")
							.toString(),
					},
				),
			)
			exportToCsv({
				filename: fileName,
				data: data.map((point) => ({
					[t(
						"investments:investments.monthly_overview.block.shares.production.headers.date",
					)]: point?.date,
					[t("common.multi_graph.tooltip.total_production")]:
						point?.production,
					[t("common.multi_graph.tooltip.expected_production")]:
						point?.expectedProduction,
				})),
				headers: [
					t(
						"investments:investments.monthly_overview.block.shares.production.headers.date",
					),
					t("common.multi_graph.tooltip.total_production"),
					t("common.multi_graph.tooltip.expected_production"),
				],
			})
		}
	}

	return (
		<div className="mt-4 flex flex-col gap-4 md:flex-row lg:mt-0">
			<div>
				<DownloadSelector
					onDownload={
						isLoading ? () => {} : (type) => handleDownload(type)
					}
					options={isLoading ? [] : [{ name: "CSV", value: "csv" }]}
					disabled={isLoading}
				/>
			</div>
		</div>
	)
}
