import {
	ReactNode,
	Dispatch,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useState,
} from "react"

// Graphql
import { PaymentEntryType } from "@/api/graphql"

export type ColumnId =
	| keyof Omit<PaymentEntryType, "supplier_account">
	| "supplier_account"
type Value = string | number

interface PatchEdit {
	type: "edit"
	approved?: boolean
	data: Map<ColumnId, Value>
}
interface PatchUndo {
	type: "undo"
	columnId: ColumnId
	approved?: boolean
}

interface PatchDelete {
	type: "delete"
	approved?: boolean
}
interface PatchClear {
	type: "clear"
	approved?: boolean
}

interface PatchApprove {
	approved: boolean
	type?: typeof PaymentPatchesActionType
	data?: Map<ColumnId, Value>
}

type PaymentEntryId = number
type OnPatchCellType =
	| OnPatchEditType
	| OnPatchUndoType
	| OnPatchClearType
	| OnPatchDeleteType
	| OnPatchApproveType

interface OnPatchEditType {
	paymententryId: PaymentEntryId
	type: "edit"
	value?: any
	columnId?: ColumnId
	approved?: boolean
}

interface OnPatchUndoType {
	paymententryId: PaymentEntryId
	type: "undo"
	columnId: ColumnId
	approved?: boolean
}
interface OnPatchClearType {
	paymententryId: PaymentEntryId
	type: "clear"
	approved?: boolean
	columnId?: ColumnId
}
interface OnPatchDeleteType {
	paymententryId: PaymentEntryId
	type: "delete"
	approved?: boolean
	columnId?: ColumnId
}

interface OnPatchApproveType {
	paymententryId: PaymentEntryId
	approved: boolean
	type: string
	value?: any
	columnId?: ColumnId
}

export type Patches =
	| PatchEdit
	| PatchUndo
	| PatchDelete
	| PatchClear
	| PatchApprove
export type PatchList = Map<PaymentEntryId, Patches>
export type OnPatchCell = (args: OnPatchCellType) => void

// What types of actions can be performed on the rows?
export const PaymentPatchesActionType = {
	Edit: "edit",
	Undo: "undo",
	Clear: "clear",
	Delete: "delete",
	Approve: "approve",
} as const

const PaymentPatchesContext = createContext<{
	patchList: PatchList
	setPatchList: Dispatch<SetStateAction<PatchList>>
	onPatchCell: OnPatchCell
}>(null!)

export const usePaymentPatchList = () => {
	const value = useContext(PaymentPatchesContext)

	if (!value) {
		throw new Error(
			"`usePaymentPatchList` must be used within `PaymentPatchesProvider`",
		)
	}

	return value
}

export const PaymentPatchesProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	const [patchList, setPatchList] = useState<PatchList>(new Map())

	const onPatchCell: OnPatchCell = useCallback(
		(args) => {
			setPatchList((old) => {
				const patchData = new Map(old)
				const rowData = patchData.get(args.paymententryId)

				// EDIT
				if (args.type === PaymentPatchesActionType.Edit) {
					let colData =
						rowData?.type === PaymentPatchesActionType.Edit &&
						rowData.data
					if (!colData) colData = new Map()

					// @ts-ignore
					colData.set(args.columnId, args.value)

					patchData.set(args.paymententryId, {
						type: PaymentPatchesActionType.Edit,
						data: colData,
					})
				}
				// UNDO
				if (args.type === PaymentPatchesActionType.Undo) {
					let colData =
						rowData?.type === PaymentPatchesActionType.Edit &&
						rowData.data
					if (!colData) colData = new Map()

					// @ts-ignore
					colData.delete(args.columnId)

					// if this col has no entries left, let's just clear it
					if (colData.size === 0) {
						patchData.delete(args.paymententryId)
					} else {
						patchData.set(args.paymententryId, {
							type: PaymentPatchesActionType.Edit,
							data: colData,
						})
					}
				}

				// CLEAR
				if (args.type === PaymentPatchesActionType.Clear) {
					patchData.delete(args.paymententryId)
				}

				// DELETE
				if (args.type === PaymentPatchesActionType.Delete) {
					patchData.set(args.paymententryId, {
						type: PaymentPatchesActionType.Delete,
					})
				}

				// Approving only when args.approved is provided
				if (
					args.approved !== undefined &&
					rowData?.approved !== args.approved
				) {
					patchData.set(args.paymententryId, {
						...rowData, // Maintain existing data
						approved: Boolean(args.approved),
					})
				}

				return patchData
			})
		},
		[setPatchList],
	)

	return (
		<PaymentPatchesContext.Provider
			value={{ patchList, setPatchList, onPatchCell }}
		>
			{children}
		</PaymentPatchesContext.Provider>
	)
}
