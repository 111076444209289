// Charts
import { Highcharts, HighchartsReact } from "@/lib/charts"
import type {
	AxisLabelsFormatterContextObject,
	TooltipFormatterContextObject,
} from "highcharts"

// Queries
import { useLifeTimeProductionChartQuery } from "@/api/graphql"

/**
 * LifetimeProductionChartLoading
 * @returns
 */
export const LifetimeProductionChartLoading = () => {
	return (
		<div className="col-12 card mx-4">
			<div className="card-body">
				<div className="mx-auto" style={{ height: "300px" }}>
					<div className="h-full w-full animate-pulse rounded-md bg-gray-100">
						<div className="flex h-full items-center justify-center"></div>
					</div>
				</div>
			</div>
		</div>
	)
}

/**
 * LifetimeProductionChart
 * @param param0
 */
export const LifetimeProductionChart = () => {
	const { data, isLoading } = useLifeTimeProductionChartQuery()

	if (isLoading) {
		return <LifetimeProductionChartLoading />
	}

	const options = {
		chart: {
			type: "column",
			height: 300,
		},
		title: {
			text: "Huidige productieverwachting looptijd project t.o.v. productieverwachting looptijd project Informatie Memoranda (som van alle projecten)",
		},
		xAxis: {
			categories: [
				"Huidige totale productieverwachting",
				"Verwachte productieverwachting Informatie Memoranda",
			],
			labels: {
				formatter: function (
					this: AxisLabelsFormatterContextObject,
				): string {
					return window.screen.width > 760
						? this.value // @ts-ignore
						: this.value.split(" ")[0]
				},
			},
		},
		yAxis: {
			min: 0,
			title: {
				text: "Production (GWh)",
			},
			labels: {
				formatter: function (
					this: AxisLabelsFormatterContextObject,
				): string {
					return this.value + " GWh"
				},
			},
		},
		series: [
			{
				name: "Production",
				data: [
					data?.platform_statistics?.total_production_in_gwh,
					data?.platform_statistics?.total_expected_production_in_gwh,
				],
				colorByPoint: true,
				colors: ["#f6d73b", "#cccccc"],
			},
		],
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true, // @ts-ignore
					formatter: function () {
						// @ts-ignore
						return this.y + " GWh"
					},
				},
			},
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			formatter: function (this: TooltipFormatterContextObject): string {
				return this.y?.toLocaleString("nl-NL") + " GWh"
			},
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 760,
					},
					chartOptions: {
						xAxis: {
							labels: {
								formatter: function (
									this: AxisLabelsFormatterContextObject,
								) {
									// @ts-ignore
									return this.value.split(" ")[0]
								},
							},
						},
					},
				},
			],
		},
	}

	return (
		<div className="col-12 card mx-4">
			<div className="card-body">
				<div className="mx-auto" style={{ height: "300px" }}>
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
					/>
				</div>
			</div>
		</div>
	)
}
