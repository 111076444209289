// React
import { useEffect } from "react"
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
	createBrowserRouter,
} from "@/lib/router"

// Sentry
import * as Sentry from "@sentry/react"
import { NetworkError } from "@/api/rest/network-errors"

// Env variables
import {
	VERCEL_ENV,
	SENTRY_DSN,
	SENTRY_DEBUG,
	SENTRY_ENABLED,
	VERCEL_GIT_COMMIT_SHA,
} from "./env"

// Ignore these errors
const IGNORE_ERRORS = [
	NetworkError.NETWORK_ERROR,
	NetworkError.FAILED_TO_FETCH,
	NetworkError.NETWORK_ERROR_RESOURCE,
]

// Init Sentry
export const initSentry = () => {
	Sentry.init({
		dsn: SENTRY_DSN,
		debug: SENTRY_DEBUG === "true",
		enabled: SENTRY_ENABLED === "true",
		environment: VERCEL_ENV,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// Extra integrations
		integrations: [
			/**
			 * Performance Tracing
			 * This also tracks all core web-vitals (https://docs.sentry.io/product/performance/web-vitals/)
			 * DOCS: https://docs.sentry.io/platforms/javascript/guides/react/performance/
			 * DOCS: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			 */
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
				enableInp: true,
			}),

			/**
			 * Record session replay videos
			 * DOCS: https://docs.sentry.io/platforms/javascript/session-replay/
			 */
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],

		/**
		 * Ensure trace propagation to our backend domain
		 */
		tracePropagationTargets: ["zonhub.com", "app.zonhub.com"],

		/**
		 * Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
		 * We recommend adjusting this value in production
		 */
		tracesSampleRate: VERCEL_ENV === "production" ? 0.2 : 1.0,

		// Release info
		release: VERCEL_GIT_COMMIT_SHA,

		// Beforesend hook
		beforeSend: async (event) => {
			try {
				for (const error of event?.exception?.values ?? []) {
					for (const matcher of IGNORE_ERRORS) {
						if (error.value?.match(matcher)) {
							return null
						}
					}
				}
			} catch {}
			return event
		},
	})
}

/**
 * Use these classnames to block, ignore or mask certain DOM elements from Sentry Session Replay
 * DOCS: https://docs.sentry.io/platforms/javascript/guides/remix/session-replay/privacy/#masking
 */
export type SentryReplayPrivacyClassNames =
	| "sentry-block"
	| "sentry-ignore"
	| "sentry-mask"

// Set user
export const setSentryUser = ({
	id,
	name,
	email,
}: {
	id: string
	name: string
	email: string
}) => {
	Sentry.setUser({
		id,
		name,
		email,
	})
}

// Capture exception
export const captureException = (
	...args: Parameters<typeof Sentry.captureException>
) => {
	try {
		return Sentry.captureException(...args)
	} catch (e) {
		console.error("Failed to log exception", e)
	}
}

// Add breadcrumb
export const addBreadcrumb = (args: Sentry.Breadcrumb) => {
	try {
		return Sentry.addBreadcrumb({
			...args,
			level: "info",
		})
	} catch (e) {
		console.error("Failed to set breadcrumb", e)
	}
}

// Error Boundary
export const ErrorBoundary = Sentry.ErrorBoundary
export type { ErrorBoundaryProps } from "@sentry/react"

/**
 * Instrument 'Routes' with Sentry Tracing
 *
 * NOTE: Works only with react-router-dom v6.
 * DOCS: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/v6/
 */
export const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)
