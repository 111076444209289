// React
import { useMemo, createContext, Fragment } from "react"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { getShareNumberRange } from "@/utils/helpers"

// GraphQL
import { keepPreviousData } from "@/lib/query"
import { useCurrentFinancialOverviewQuery, ProjectType } from "@/api/graphql"

// UI
import { CardBody } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import {
	useReactTable,
	flexRender,
	getPaginationRowModel,
	getCoreRowModel,
	createColumnHelper,
	CellContext,
} from "@/lib/table"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/Tooltip"

// Utils
import { isWholeNumber } from "@/lib/math"

// Environment variables
import { EXTERNAL_PLATFORM_ZONHUB_INVESTMENT_CERTIFICATE } from "@/lib/env"

// Icons
import { FiDownload } from "@/lib/icons"

// Types
export type FinancialOverviewContextType =
	| { type: "custom"; from: string; to: string }
	| { type: "year"; year: string }

export const FinancialOverviewContext =
	createContext<FinancialOverviewContextType>(null!)

/**
 * FinancialOverviewTable
 * @returns
 */
export function FinancialOverviewTable() {
	// Translations
	const { formatCurrency } = useLang()
	const t = useTrans(["investments", "dashboard", "project"])

	const { data, isPlaceholderData, isLoading } =
		useCurrentFinancialOverviewQuery(undefined, {
			placeholderData: keepPreviousData,
		})

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return data.me.investment_projects?.results
	}, [data?.me?.investment_projects])

	// Tables
	const columnHelper = createColumnHelper<ProjectType>()
	const columns = [
		columnHelper.accessor("name", {
			id: "name",
			header: () => (
				<TableHeading>
					{t(
						"investments:investments.fiscal.table_heading.project_name",
					)}
				</TableHeading>
			),
		}),
		columnHelper.accessor(
			(data: ProjectType) =>
				data?.investor_shares_value_stats?.total_shares
					? isWholeNumber(
							data.investor_shares_value_stats?.total_shares,
					  )
						? data.investor_shares_value_stats?.total_shares
						: Number(
								data.investor_shares_value_stats
									?.total_shares ?? 0,
						  ).toFixed(2)
					: "-",
			{
				id: "total_shares",
				header: () => (
					<TableHeading>
						{t(
							"dashboard:financial_overview_table.heading.total_shares",
						)}
					</TableHeading>
				),
			},
		),
		columnHelper.accessor(
			(data: ProjectType) =>
				formatCurrency(
					Number(
						data.investor_shares_value_stats
							?.total_nominal_investment,
					) ?? 0,
				),
			{
				id: "original_value",
				header: () => (
					<TableHeading>
						{t(
							"dashboard:financial_overview_table.heading.original_value",
						)}
					</TableHeading>
				),
			},
		),
		columnHelper.accessor(
			(data: ProjectType) =>
				data.investor_shares_value_stats?.total_investment_value
					? formatCurrency(
							parseFloat(
								data.investor_shares_value_stats
									?.total_investment_value,
							) ?? 0,
					  )
					: "-",
			{
				id: "total_investment_value",
				header: () => (
					<TableHeading>
						{t("project:project.dashboard.hero.share_value.title")}
					</TableHeading>
				),
			},
		),
		columnHelper.accessor((data) => data, {
			id: "share_numbers",
			header: () => (
				<TableHeading>
					{t(
						"investments:investments.fiscal.table_heading.share_numbers_at_end_of_year",
					)}
				</TableHeading>
			),
			cell: (info: CellContext<ProjectType, ProjectType>) => (
				<Tooltip
					content={`Download certificaat voor obligatienummers: ${
						getShareNumberRange(
							info
								.getValue()
								?.investor_shares_value_stats?.shares?.map(
									(share) => Number(share?.share_number),
								),
							t,
						) ?? undefined
					}`}
				>
					<a
						href={`${EXTERNAL_PLATFORM_ZONHUB_INVESTMENT_CERTIFICATE}${
							info.getValue()?.id
						}`}
						className="flex text-sm underline hover:text-gray-500"
					>
						<FiDownload className="mr-2" />
						<span className="flex-1">
							{getShareNumberRange(
								info
									.getValue()
									?.investor_shares_value_stats?.shares?.map(
										(share) => Number(share?.share_number),
									),
								t,
							) ?? undefined}
						</span>
					</a>
				</Tooltip>
			),
		}),
	]
	const table = useReactTable({
		columns,
		data: rows as ProjectType[],
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	})

	// Loading state
	if (isLoading) {
		return <FinancialOverviewTableLoading />
	}

	// Data state
	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					className={isPlaceholderData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{table.getRowModel().rows.map((row, index) => {
						return (
							<TableRowCell
								key={row.id}
								isOdd={index % 2 === 0}
								data-testid={`tablerow-${row.id}`}
							>
								{row.getAllCells().map((cell) => {
									return (
										<TableDataCell
											key={cell.id}
											className="break-word whitespace-pre-wrap"
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
			</Table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}

const FinancialOverviewTableLoading = () => {
	const t = useTrans(["investments", "dashboard", "project"])

	return (
		<Table className="min-w-[48rem] lg:min-w-0">
			<TableHead>
				<TableRowCell>
					<TableHeading>
						{t(
							"investments:investments.fiscal.table_heading.project_name",
						)}
					</TableHeading>
					<TableHeading>
						{t(
							"dashboard:financial_overview_table.heading.total_shares",
						)}
					</TableHeading>
					<TableHeading>
						{t(
							"dashboard:financial_overview_table.heading.original_value",
						)}
					</TableHeading>
					<TableHeading>
						{t("project:project.dashboard.hero.share_value.title")}
					</TableHeading>
					<TableHeading>
						{t(
							"investments:investments.fiscal.table_heading.share_numbers_at_end_of_year",
						)}
					</TableHeading>
				</TableRowCell>
			</TableHead>
			<TableBody>
				{[...Array(5)].map((_, index) => (
					<TableRowCell key={index} isOdd={index % 2 === 0}>
						<TableDataCell>
							<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
						<TableDataCell>
							<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
						</TableDataCell>
					</TableRowCell>
				))}
			</TableBody>
		</Table>
	)
}
const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments:investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments:investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}
