export interface LangConfig {
	[key: string]: string
	path: string
	display: string
	locale: string
	currency: string
	siteLanguage: string
	textDirection: string
	title: string
	defaultTitle: string
	dateFormat: string
	dateFormatMonthAndDay: string
	projectCardInterestPeriodDateFormat: string
}

export const defaultLanguage = "nl"
export type Languages = "en" | "nl"
export const supportedLanguages = new Set<Languages>(["en", "nl"])

export type LangConfigs = {
	[K in Languages]: LangConfig
}

const configs: LangConfigs = {} as LangConfigs

const loadConfigs = async (config: LangConfigs) => {
	const renameKeys = (obj: Record<string, string>): LangConfig =>
		Object.keys(obj).reduce(
			(acc, key) => ({
				...acc,
				[key.replace("config.", "")]: obj[key],
			}),
			{} as LangConfig,
		)

	const languagePromises = Array.from(supportedLanguages).map(
		async (language) => {
			const localConfigRaw = await import(
				`../../src/i18n/locales/${language}/config.json`
			)
			const localConfig = renameKeys(localConfigRaw.default)
			config[language] = localConfig
		},
	)

	await Promise.all(languagePromises)
}

// No top-level awaits
void (async () => {
	await loadConfigs(configs)
})()

export { configs }
