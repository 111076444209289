// Translations
import { useTrans } from "@/i18n"

// Generic UI
import { Heading } from "@/components/Typography"
import { CardWrapper, Card } from "@/components/Card"
import { GraphLabels } from "@/components/graphs/GraphLabels"
import { AreaGraph } from "@/components/graphs/types/area/AreaGraph"
import { ProductionGraph } from "@/components/graphs/types/bar/ProductionGraph"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"

// Contextual UI
import { InvestmentsGraphCumulativeProduction } from "./_components/InvestmentsGraphCumulativeProduction"
import { InvestmentsGraphProductionDaily } from "../components/InvestmentsGraphDailyProduction"
import { InvestmentsGraphProduction } from "../components/InvestmentsGraphProduction"
import { InvestmentsGraphProductionHeader } from "../components/InvestmentsGraphProductionHeader"
// Graphs
import { ProductionCumulative } from "@/components/graphs/types/stacked-area/ProductionCumulative"

/**
 * Production
 * url: investments/projects/dashboard/production
 * @returns
 */
export function Production() {
	// Translations
	const t = useTrans(["project", "investments"])

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("investments:investments.production.title"),
				})}
			</title>

			<Card className="mb-8">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t("project:project.data.production.title")}
				</Heading>
				<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
					{t("project:project.data.production.copy")}
				</p>
			</Card>

			<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
				{/** Production of all projects hours of a specific day */}
				<CardWrapper className="md:col-span-4">
					<ErrorBoundaryWithErrorState>
						<AreaGraph>
							<AreaGraph.Content>
								<InvestmentsGraphProductionDaily />
							</AreaGraph.Content>
						</AreaGraph>
					</ErrorBoundaryWithErrorState>
				</CardWrapper>

				{/** Production of all projects per period */}
				<CardWrapper className="md:col-span-4">
					<ErrorBoundaryWithErrorState>
						<ProductionGraph>
							<ProductionGraph.Header>
								<InvestmentsGraphProductionHeader />
							</ProductionGraph.Header>
							<ProductionGraph.Content>
								<InvestmentsGraphProduction />
							</ProductionGraph.Content>
							<ProductionGraph.Footer>
								<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
									<GraphLabels.TotalProduction />
									<GraphLabels.ExpectedProduction />
								</GraphLabels>
							</ProductionGraph.Footer>
						</ProductionGraph>
					</ErrorBoundaryWithErrorState>
				</CardWrapper>

				{/** Cumulative production of all projects */}
				<CardWrapper className="md:col-span-4">
					<ErrorBoundaryWithErrorState>
						<ProductionCumulative>
							<ProductionCumulative.Content>
								<InvestmentsGraphCumulativeProduction />
							</ProductionCumulative.Content>
							<ProductionCumulative.Footer>
								<div className="px-4 pb-3 sm:px-6 sm:pb-5">
									<GraphLabels>
										<GraphLabels.TotalProduction />
										<GraphLabels.ExpectedProduction />
									</GraphLabels>
								</div>
							</ProductionCumulative.Footer>
						</ProductionCumulative>
					</ErrorBoundaryWithErrorState>
				</CardWrapper>
			</div>
		</>
	)
}
