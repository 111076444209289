import {
	Fragment,
	memo,
	MouseEventHandler,
	useCallback,
	useMemo,
	useState,
} from "react"

// Icons
import { FiChevronDown, FiTrash2 } from "@/lib/icons"

// Animations
import { AnimatePresence, motion } from "@/lib/animations"

// Tables
import {
	flexRender,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
	getCoreRowModel,
	TableOptions,
} from "@/lib/table"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Disclaimer } from "@/components/Disclaimer"
import { TablePagination } from "@/components/table-controls/TablePagination"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// Context
import {
	PaymentPatchesActionType,
	usePaymentPatchList,
} from "@/context/paymentPatches"

import { normaliseString } from "@/lib/js"

// Hooks
import { useCurrentPaymentDetail } from "../PaymentDetailEnergySupplier"

// UI
import { classNames } from "@/lib/ui"
import { Heading } from "@/components/Typography"
import { Card, CardBody } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { PaymentItem } from "@/components/forms/PaymentItem"

// GraphQL
import { PaymentEntryEnergySupplierFragment } from "@/api/graphql"

type Data = PaymentEntryEnergySupplierFragment

function generateTableRowId(
	row: Pick<Data, "name" | "supplier_account">,
	suffix = "tablerow",
) {
	return `${suffix}-${row.supplier_account}-${normaliseString(
		row.name ?? "",
	)}`
}

// Types
interface CustomTableOptions<T> extends TableOptions<T> {
	onPatchCell: (args: any) => void
	patchList: Map<any, any>
	autoResetPage: boolean
}

export const PaymentOverviewForEnergySupplier = memo(() => {
	const { formatCurrency, formatNumber } = useLang()

	// Translations
	const t = useTrans(["common", "payments"])

	const [skipPageReset, setSkipPageReset] = useState(false)
	const { patchList, onPatchCell } = usePaymentPatchList()

	const onPatchCellProxy: typeof onPatchCell = useCallback(
		(args) => {
			// We also turn on the flag to not reset the page
			setSkipPageReset(true)

			onPatchCell(args)

			// Enable flag to be able to reset page again
			setSkipPageReset(false)
		},
		[onPatchCell],
	)

	const { data } = useCurrentPaymentDetail()

	// Tables
	const columnHelper =
		createColumnHelper<PaymentEntryEnergySupplierFragment>()
	const columns = useMemo(
		() => [
			columnHelper.accessor((data) => data, {
				id: "expander",
				header: () => <TableHeading className="w-10" />,
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						PaymentEntryEnergySupplierFragment
					>,
				) => {
					const originalData = info.row
						.original as PaymentEntryEnergySupplierFragment
					const paymententryId = Number(originalData.id)
					const rowData = patchList.get(paymententryId)

					// Toggle expanded when clicked
					const handleClick: MouseEventHandler<
						HTMLTableCellElement
					> = (e) => {
						e.preventDefault()
						info.row.toggleExpanded()
					}

					// Return cell
					return (
						<TableDataCell
							className={classNames(
								rowData?.type ===
									PaymentPatchesActionType.Delete
									? "bg-red-200 hover:bg-red-300"
									: info.row.getIsExpanded() ||
									  rowData?.type ===
											PaymentPatchesActionType.Edit
									? "hover:bg-yellow-300"
									: "hover:bg-gray-200",
								"cursor-pointer text-gray-500 hover:text-gray-700",
							)}
							onClick={handleClick}
						>
							<FiChevronDown
								className={classNames(
									"mx-auto block transform transition",
									info.row.getIsExpanded() && "rotate-180",
								)}
							/>
						</TableDataCell>
					)
				},
			}),
			columnHelper.accessor("share_count", {
				header: () => (
					<TableHeading>
						{t(
							"payments:payments.overview.header.number_of_shares",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						number
					>,
				) => <TableDataCell>{info.getValue()}</TableDataCell>,
			}),
			columnHelper.accessor("name", {
				header: () => (
					<TableHeading>
						{t("payments:payments.overview.header.name")}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						string
					>,
				) => <TableDataCell>{info.getValue()}</TableDataCell>,
			}),
			columnHelper.accessor("supplier_account", {
				header: () => (
					<TableHeading>
						{t("payments:payments.overview.header.client_number")}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						string
					>,
				) => <TableDataCell>{info.getValue()}</TableDataCell>,
			}),
			columnHelper.accessor("production", {
				header: () => (
					<TableHeading>
						{t(
							"payments:payments.overview.header.energy_generated",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						number
					>,
				) => (
					<TableDataCell>
						{info.getValue() ? formatNumber(info.getValue()) : 0}{" "}
						kWh
					</TableDataCell>
				),
			}),
			columnHelper.accessor("cost", {
				header: () => (
					<TableHeading>
						{t("payments:payments.overview.header.interest")}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						number
					>,
				) => (
					<TableDataCell>
						{formatCurrency(info.getValue() ?? 0)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor((data) => data?.energy_supplier_bonus || 0, {
				id: "bonus",
				header: () => (
					<TableHeading>
						{t("payments:payments.overview.header.bonus")}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						number
					>,
				) => (
					<TableDataCell>
						{formatCurrency(info.getValue())}
					</TableDataCell>
				),
			}),
			columnHelper.accessor((data) => data, {
				id: "delete",
				header: () => <TableHeading className="w-12 text-center" />,
				cell: (
					info: CellContext<
						PaymentEntryEnergySupplierFragment,
						PaymentEntryEnergySupplierFragment
					>,
				) => {
					const originalData = info.row
						.original as PaymentEntryEnergySupplierFragment
					const paymententryId = Number(originalData.id)
					const rowData = patchList.get(paymententryId)

					const onClick: MouseEventHandler<HTMLTableCellElement> = (
						e,
					) => {
						e.preventDefault()
						// if it has already been flagged for deletion
						// then just clear the row
						if (rowData?.type === PaymentPatchesActionType.Delete) {
							onPatchCellProxy({
								paymententryId,
								type: PaymentPatchesActionType.Clear,
							})
						} else {
							onPatchCellProxy({
								paymententryId,
								type: PaymentPatchesActionType.Delete,
							})
						}
					}

					return (
						<TableDataCell
							className={classNames(
								"group cursor-pointer !p-0 text-center",
								rowData?.type ===
									PaymentPatchesActionType.Delete
									? "bg-red-200 hover:bg-red-300"
									: "hover:bg-red-200",
							)}
							onMouseDown={onClick}
							onClick={onClick}
						>
							<FiTrash2
								className="mx-auto block group-hover:text-red-700"
								size={16}
							/>
						</TableDataCell>
					)
				},
			}),
		],
		[t, formatNumber, formatCurrency, patchList, onPatchCellProxy],
	)

	// Table
	const table = useReactTable<PaymentEntryEnergySupplierFragment>({
		columns,
		data: useMemo(
			() =>
				data?.interest_payments?.results?.[0]
					?.payment_entries_with_supplier ?? [],
			[data?.interest_payments],
		) as PaymentEntryEnergySupplierFragment[],
		onPatchCell: onPatchCellProxy,
		patchList,
		autoResetPage: !skipPageReset,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	} as CustomTableOptions<PaymentEntryEnergySupplierFragment>)

	// Function to collapse all rows. We can not use toggleAllRowsExpanded for this.
	const collapseAllRows = () => {
		for (const row of table.getRowModel().rows) {
			if (row.getIsExpanded()) {
				if (typeof row.toggleExpanded() === "function") {
					row.toggleExpanded(false)
				}
			}
		}
	}

	return (
		<>
			<CardBody>
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
					<Heading as="h2" styleAs="h5" className="sm:truncate">
						{t("payments:payments.overview_energy_supplier.title", {
							energySupplier:
								data?.interest_payments?.results?.[0]?.project
									?.supplier?.name,
						})}
					</Heading>
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							onChange={(evt) =>
								table.setGlobalFilter(evt.currentTarget.value)
							}
							label={t("payments:payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
				</div>
			</CardBody>
			<div className="md:hidden">
				<Disclaimer
					message={t("common:common.table.mobile_warning.copy")}
				/>
			</div>
			<Table className="min-w-[64rem] lg:min-w-0" data-testid="tablebody">
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>
				<TableBody
					data-testid="tablebody-overview"
					data-pageindex={table.getState().pagination.pageIndex}
				>
					{table.getRowModel().rows.map((row) => {
						const isOdd = row.index % 2 === 0

						// Get row data
						const originalData =
							row.original as PaymentEntryEnergySupplierFragment
						const paymententryId = Number(originalData.id)
						const patchData = patchList.get(paymententryId)

						let className = ""
						if (
							patchData?.type === PaymentPatchesActionType.Delete
						) {
							className =
								"bg-red-100 text-gray-900 hover:bg-red-200"
						} else if (
							patchData?.type === PaymentPatchesActionType.Edit ||
							row.getIsExpanded()
						) {
							className =
								"bg-yellow-100 hover:bg-yellow-200 text-gray-900"
						}

						return (
							<Fragment key={row.id}>
								<TableRowCell
									isOdd={isOdd}
									variant={
										patchData?.type ===
										PaymentPatchesActionType.Delete
											? "invalid"
											: "neutral"
									}
									className={classNames(className)}
									data-testid={generateTableRowId(
										row.original,
									)}
								>
									{row.getAllCells().map((cell) => {
										return (
											<Fragment key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</Fragment>
										)
									})}
								</TableRowCell>
								<TableRowCell
									isOdd
									withHover={false}
									className="!p-0"
									data-testid={generateTableRowId(
										row.original,
										"paymentitem-container",
									)}
								>
									<TableDataCell
										colSpan={
											row._getAllVisibleCells().length
										}
										className="!p-0"
									>
										<AnimatePresence
											// we use initial false, as if a row is expanded
											// and you navigate from one page to the next, and this next
											// page has a row that is expanded, it will animate in and we do not want that
											initial={false}
										>
											{row.getIsExpanded() ? (
												<motion.div
													initial={{
														height: 0,
													}}
													animate={{
														height: "auto",
													}}
													exit={{
														height: 0,
													}}
													transition={{
														ease: "easeOut",
													}}
													className="flex overflow-hidden"
													data-testid={`tablerow-expanded-${row.original.supplier_account}`}
												>
													<PaymentItemContainer
														values={row.original}
														index={row.index}
														collapseAllRows={() =>
															collapseAllRows()
														}
													/>
												</motion.div>
											) : null}
										</AnimatePresence>
									</TableDataCell>
								</TableRowCell>
							</Fragment>
						)
					})}
					{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
					{table.getRowModel().rows.length <
						table.getState().pagination.pageSize &&
					table.getState().pagination.pageIndex !== 0 ? (
						<>
							{Array(
								Math.max(
									table.getState().pagination.pageSize -
										table.getRowModel().rows.length,
									1,
								),
							)
								.fill(true)
								.map((_, index) => (
									<TableRowCell
										key={index}
										withHover={false}
										isOdd={index % 2 === 0}
									>
										<TableDataCell colSpan={columns.length}>
											<span className="dummy-text" />
										</TableDataCell>
									</TableRowCell>
								))}
						</>
					) : null}
				</TableBody>
			</Table>
			{table.getRowModel().rows.length === 0 && (
				<CardBody>
					<TableEmptyState>
						{t(
							"payments:payments.overview_energy_supplier.empty_state",
						)}
					</TableEmptyState>
				</CardBody>
			)}
			{table.getRowModel().rows.length !== 0 && (
				<CardBody>
					<TablePagination
						amountOfRows={
							table.getPrePaginationRowModel().rows.length
						}
						pageIndex={table.getState().pagination.pageIndex}
						pageSize={table.getState().pagination.pageSize}
						previousPage={() => table.previousPage()}
						gotoPage={(page: number) => table.setPageIndex(page)}
						canPreviousPage={table.getCanPreviousPage()}
						canNextPage={table.getCanNextPage()}
						nextPage={() => table.nextPage()}
						setPageSize={(size: number) => table.setPageSize(size)}
						totalNumberOfItems={
							data?.interest_payments?.results?.[0]
								?.payment_entries_with_supplier?.length
						}
					/>
				</CardBody>
			)}
		</>
	)
})
PaymentOverviewForEnergySupplier.displayName =
	"PaymentOverviewForEnergySupplier"

/**
 * Wrap paymentItem inside styled container
 * @param param0
 * @returns
 */
const PaymentItemContainer = ({
	values,
	index,
	collapseAllRows,
}: {
	index: number
	values: Data
	collapseAllRows: Function
}) => {
	return (
		<div className="px-10 pb-7 pt-5">
			<Card data-testid={generateTableRowId(values, "paymentitem")}>
				<PaymentItem
					values={values}
					paymententryId={index}
					collapseAllRows={collapseAllRows}
				/>
			</Card>
		</div>
	)
}

/**
 * PaymentOverviewForEnergySupplierLoading
 * @returns
 */
export function PaymentOverviewForEnergySupplierLoading() {
	const t = useTrans(["common", "payments"])

	return (
		<>
			<CardBody>
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
					<Heading as="h2" styleAs="h5" className="sm:truncate">
						<div className="h-6 w-64 animate-pulse rounded bg-gray-200" />
					</Heading>
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							disabled
							label={t("payments:payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
				</div>
			</CardBody>
			<div className="md:hidden">
				<Disclaimer
					message={t("common:common.table.mobile_warning.copy")}
				/>
			</div>
			<Table className="min-w-[64rem] lg:min-w-0" data-testid="tablebody">
				<TableHead>
					<TableRowCell>
						<TableHeading className="w-10" />
						<TableHeading>
							{t(
								"payments:payments.overview.header.number_of_shares",
							)}
						</TableHeading>
						<TableHeading>
							{t("payments:payments.overview.header.name")}
						</TableHeading>
						<TableHeading>
							{t(
								"payments:payments.overview.header.client_number",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"payments:payments.overview.header.energy_generated",
							)}
						</TableHeading>
						<TableHeading>
							{t("payments:payments.overview.header.interest")}
						</TableHeading>
						<TableHeading>
							{t("payments:payments.overview.header.bonus")}
						</TableHeading>
						<TableHeading className="w-12 text-center" />
					</TableRowCell>
				</TableHead>
				<TableBody data-testid="tablebody-overview">
					{[...Array(10)].map((_, index) => (
						<TableRowCell key={index} isOdd={index % 2 === 0}>
							<TableDataCell>
								<div className="h-4 w-4 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-16 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
							<TableDataCell>
								<div className="h-4 w-8 animate-pulse rounded bg-gray-200" />
							</TableDataCell>
						</TableRowCell>
					))}
				</TableBody>
			</Table>
		</>
	)
}
