import {
	ReactNode,
	createContext,
	useContext,
	useState,
	useEffect,
	Suspense,
} from "react"
import { getChildByType } from "react-nanny"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// UI
import { classNames, Popover, Transition } from "@/lib/ui"
import { Button } from "@/components/Button"
import { ChevronDownIcon } from "@heroicons/react/outline"
import {
	DatePicker,
	transformToDatePickerDate,
} from "@/components/form-controls/DatePicker"
import { CardBody } from "@/components/Card"
import { GraphHeader } from "@/components/graphs/header/GraphHeader"
import { EmptyGridStateAnimated } from "@/components/EmptyGridState"

// Animations
import { getTransitionPopoverProps } from "@/lib/animations"

// Translations
import { useTrans } from "@/i18n"

// Context
import { FinancePerformanceContext } from "@/pages/finance/pages/Performance"

type ProductionCumulativeContextType = {
	graphInterval: GraphIntervalType
	setGraphInterval(
		graphInterval: ProductionCumulativeContextType["graphInterval"],
	): void
	startTime: any
	setStartTime(startTime: any): void
	endTime: any
	setEndTime(endTime: any): void
	minimumTime: any
	setMinimumTime(minimumTime: any): void
}

export function useProductionCumulativeGraphContext() {
	const context = useContext(ProductionCumulativeContext)

	if (!context) {
		throw new Error(
			"ProductionCumulativeContext must be used within a ProductionCumulativeContextProvider",
		)
	}

	return context
}

export const ProductionCumulativeContext =
	createContext<ProductionCumulativeContextType>(null!)

type ProductionCumulativeProps = {
	children: ReactNode
}

export type GraphIntervalType = "year" | "month" | "all"
export const AvailableDatePeriods = {
	MONTH: "month",
	YEAR: "year",
	ALL: "all",
}

/// useFinancePerformanceContextOrState
const useFinancePerformanceContextOrState = () => {
	const context = useContext(FinancePerformanceContext)

	const [localStartTime, setLocalStartTime] = useState(
		DateTime.local().toJSDate(),
	)
	const [localEndTime, setLocalEndTime] = useState(
		DateTime.local().toJSDate(),
	)

	if (context) {
		return {
			startTime: context.selectedDateRangeStart,
			setStartTime: context.setSelectedDateRangeStart,
			endTime: context.selectedDateRangeEnd,
			setEndTime: context.setSelectedDateRangeEnd,
		}
	} else {
		return {
			startTime: localStartTime,
			setStartTime: setLocalStartTime,
			endTime: localEndTime,
			setEndTime: setLocalEndTime,
		}
	}
}

/**
 * ProductionCumulative
 * @param param0
 * @returns
 */
export function ProductionCumulative({ children }: ProductionCumulativeProps) {
	const content = getChildByType(children, ProductionCumulative.Content)
	const footer = getChildByType(children, ProductionCumulative.Footer)
	const t = useTrans("project")

	// State
	const [graphInterval, setGraphInterval] =
		useState<GraphIntervalType>("year")
	const [minimumTime, setMinimumTime] = useState(DateTime.local().toJSDate())

	// Hooks
	const { startTime, setStartTime, endTime, setEndTime } =
		useFinancePerformanceContextOrState()

	// When changing graphInterval, update dates
	useEffect(() => {
		// YEAR
		if (graphInterval === AvailableDatePeriods.YEAR) {
			setStartTime(
				DateTime.local()
					.toUTC()
					.startOf("year")
					.minus({ day: 1 }) // Subtract 1 day because data points are cummulated at the end of every month
					.toJSDate(),
			)
			setEndTime(DateTime.local().toUTC().endOf("year").toJSDate())
		}

		// MONTH
		if (graphInterval === AvailableDatePeriods.MONTH) {
			setStartTime(DateTime.local().toUTC().startOf("month").toJSDate())
			setEndTime(DateTime.local().toUTC().endOf("month").toJSDate())
		}

		// ALL
		if (graphInterval === AvailableDatePeriods.ALL) {
			setStartTime(minimumTime)
			setEndTime(DateTime.local().startOf("day").toJSDate())
		}
	}, [graphInterval, minimumTime])

	return (
		<ProductionCumulativeContext.Provider
			value={{
				graphInterval,
				setGraphInterval,
				startTime,
				setStartTime,
				endTime,
				setEndTime,
				minimumTime,
				setMinimumTime,
			}}
		>
			<CardBody className="lg:flex lg:items-center lg:justify-between">
				<div className="w-full lg:w-72">
					<GraphHeader
						title={t("project.data.energy_producted.title")}
						subtitle={t("project.data.energy_producted.subtitle")}
					/>
				</div>
				<div className="mt-4 flex flex-col gap-4 md:flex-row lg:mt-0">
					{/** DateRangePicker */}
					<DateRangePicker />

					{/** Buttons */}
					<div>
						{Object.values(AvailableDatePeriods).map(
							(interval, index) => {
								return (
									<Button
										key={interval}
										onClick={() =>
											setGraphInterval(
												interval as GraphIntervalType,
											)
										}
										className={classNames(
											index === 3
												? "rounded-l-none border-l-0"
												: index === 0
												? "rounded-r-none"
												: "rounded-none",
											graphInterval === interval &&
												"relative z-10",
										)}
										variant={
											graphInterval === interval
												? "primary"
												: "transparent"
										}
									>
										{t(
											`project.data.energy_producted.button.${interval}`,
										)}
									</Button>
								)
							},
						)}
					</div>
				</div>
			</CardBody>
			<div className="relative h-96">
				<Suspense
					fallback={
						<EmptyGridStateAnimated className={"h-[30%] w-full"} />
					}
				>
					{content}
				</Suspense>
			</div>
			{footer}
		</ProductionCumulativeContext.Provider>
	)
}

const Content = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}
Content.displayName = "ProductionCumulative.Content"

const Footer = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}
Footer.displayName = "ProductionCumulative.Footer"

ProductionCumulative.Content = Content
ProductionCumulative.Footer = Footer

/**
 * DateRangePicker
 * @param param0
 * @returns
 */
function DateRangePicker({ className }: { className?: string }) {
	// Context
	const { startTime, setStartTime, endTime, setEndTime } =
		useProductionCumulativeGraphContext()

	return (
		<div className={classNames("flex items-center gap-4", className)}>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromJSDate(startTime).toFormat(
									dateFormat,
								)}
							</span>

							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-from"
							>
								<DatePicker
									selectedDate={transformToDatePickerDate(
										startTime,
									)}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										setStartTime(newDate.toJSDate())
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
			<span className="text-sm text-gray-500">to</span>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromJSDate(endTime).toFormat(
									dateFormat,
								)}
							</span>
							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-from"
							>
								<DatePicker
									selectedDate={transformToDatePickerDate(
										endTime,
									)}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										setEndTime(newDate.toJSDate())
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>
	)
}
