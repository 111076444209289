import { createContext, Suspense, ReactNode } from "react"
import { getChildByType } from "react-nanny"
import { CardBody } from "@/components/Card"
import { GraphHeaderPreloader } from "@/components/graphs/header/GraphHeader"
import { EmptyGridStateAnimated } from "@/components/EmptyGridState"

type SolarInterestTariffGraphContextType = {}

const SolarInterestTariffGraphContext =
	createContext<SolarInterestTariffGraphContextType>(null!)

type SolarInterestTariffGraphProps = {
	children: ReactNode
}

// Define the type for components with displayName
type SolarInterestTariffGraphComponent = {
	({ children }: { children: ReactNode }): React.ReactElement
	displayName?: string
}

export function SolarInterestTariffGraph({
	children,
}: SolarInterestTariffGraphProps) {
	const content = getChildByType(children, SolarInterestTariffGraph.Content)
	const footer = getChildByType(children, SolarInterestTariffGraph.Footer)
	const header = getChildByType(children, SolarInterestTariffGraph.Header)

	return (
		<SolarInterestTariffGraphContext.Provider value={{}}>
			<CardBody className="lg:flex lg:items-center lg:justify-between">
				<div className="w-full lg:w-72">
					<Suspense fallback={<GraphHeaderPreloader />}>
						{header}
					</Suspense>
				</div>
			</CardBody>
			<div className="h-96">
				<Suspense
					fallback={
						<EmptyGridStateAnimated className="h-[30%] w-full" />
					}
				>
					{content}
				</Suspense>
			</div>
			{footer}
		</SolarInterestTariffGraphContext.Provider>
	)
}

function Footer({ children }: { children: ReactNode }) {
	return <>{children}</>
}
SolarInterestTariffGraph.Footer = Footer as SolarInterestTariffGraphComponent
SolarInterestTariffGraph.Footer.displayName = "SolarInterestTariffGraph.Footer"

function Content({ children }: { children: ReactNode }) {
	return <>{children}</>
}
SolarInterestTariffGraph.Content = Content as SolarInterestTariffGraphComponent
SolarInterestTariffGraph.Content.displayName =
	"SolarInterestTariffGraph.Content"

function Header({ children }: { children: ReactNode }) {
	return <>{children}</>
}
SolarInterestTariffGraph.Header = Header as SolarInterestTariffGraphComponent
SolarInterestTariffGraph.Header.displayName = "SolarInterestTariffGraph.Header"

function Actions({ children }: { children: ReactNode }) {
	return <>{children}</>
}
SolarInterestTariffGraph.Actions = Actions as SolarInterestTariffGraphComponent
SolarInterestTariffGraph.Actions.displayName =
	"SolarInterestTariffGraph.Actions"

function LineGraph({ children }: { children: ReactNode }) {
	return <>{children}</>
}
SolarInterestTariffGraph.LineGraph =
	LineGraph as SolarInterestTariffGraphComponent
SolarInterestTariffGraph.LineGraph.displayName =
	"SolarInterestTariffGraph.LineGraph"
