// Analytics
import { sendEvent } from "@/lib/analytics"

// Template
import { PageTemplate } from "@/templates/PageTemplate"

// Constants
import { mapCoordinatesCenterOfNetherlands } from "@/constants/constants"

// Router
import { useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

import { ProjectSelector } from "@/components/ProjectSelector"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { CardWrapper } from "@/components/Card"

// Specific UI
import { FinanceProjectsTable } from "./_components/FinanceProjectsTable"
import { FinanceProjectsMapsOverview } from "../components/FinanceProjectsMapsOverview"

// Translations
import { useTrans } from "@/i18n"

/**
 * FinanceDashboard
 * @returns
 */
export function FinanceDashboard() {
	const t = useTrans("finance")
	const navigate = useNavigate()

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("finance:finance.finance_dashboard.title"),
				})}
			</title>
			<PageTemplate title={t("finance:finance.finance_dashboard.title")}>
				<div className="flex md:gap-8">
					<CardWrapper className="w-full md:w-1/2">
						<ErrorBoundaryWithErrorState>
							<FinanceProjectsMapsOverview
								center={{
									lat: Number(
										mapCoordinatesCenterOfNetherlands.lat,
									),
									lng: Number(
										mapCoordinatesCenterOfNetherlands.lng,
									),
								}}
								zoom={6}
							/>
						</ErrorBoundaryWithErrorState>
					</CardWrapper>
					<div className="w-full md:w-1/2">
						<div className="mb-2 flex flex-col items-end">
							<ProjectSelector
								onChange={(id: string) => {
									sendEvent(
										"finance",
										"performance_project_filter",
										{
											label: id,
										},
									)
									navigate(`${Routes.FinanceProjects}/${id}`)
								}}
							/>
						</div>

						<CardWrapper>
							<FinanceProjectsTable />
						</CardWrapper>
					</div>
				</div>
			</PageTemplate>
		</>
	)
}
