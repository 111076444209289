import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsStock from "highcharts/highstock"
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js"
import { memo } from "react"
import { graphConfig } from "../components/Graphs"
import { clone, merge } from "./js"

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const defaultOptions: Highcharts.Options = {
	exporting: {
		enabled: false,
	},
	credits: { enabled: false },
	chart: {
		style: {
			fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial ,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
		},
	},
}

export const miniOptions: Highcharts.Options = {
	chart: {
		height: 150,
		spacing: [0, 0, 0, 0],
		plotBorderWidth: 0,
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		enabled: false,
	},
	title: {
		text: "",
	},
	yAxis: {
		visible: false,
	},
}

export const Highchart = (props: HighchartsReact.Props) => {
	return <HighchartsReact highcharts={Highcharts} {...props} />
}

export const Stockchart = (props: HighchartsReact.Props) => {
	return (
		<HighchartsReact
			constructorType="stockChart"
			highcharts={HighchartsStock}
			{...props}
		/>
	)
}

export const MiniChart = memo((props: HighchartsReact.Props) => {
	return (
		<Highchart
			options={merge(
				{
					chart: {
						animation: {
							enabled: true,
							duration: 1000,
							easing: "linear",
						},
						height: 150,
						spacingLeft: 0,
						spacingBottom: 0,
						spacingRight: 0,
						plotBorderWidth: 0,
						style: {
							fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial ,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
						},
					},
					credits: {
						enabled: false,
					},
					exporting: {
						enabled: false,
					},
					yAxis: {
						visible: false,
					},
					xAxis: {
						visible: false,
						minPadding: 0,
						maxPadding: 0,
						crosshair: {
							width: 30,
							color: `rgba(0,0,0,0.05)`,
						},
					},
					title: {
						text: "",
					},
					tooltip: {
						enabled: false,
					},
					legend: { enabled: false },
					plotOptions: {
						spline: { marker: { enabled: false } },
						areaspline: {
							marker: { enabled: false },
							...clone(graphConfig.primary),
						},
					},
				} as Highcharts.Options,
				props.options || {},
			)}
		/>
	)
})
MiniChart.displayName = "MiniChart"

export { Highcharts, HighchartsReact }
