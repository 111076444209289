import { ReactNode } from "react"

// Translations
import { useTrans } from "@/i18n"

// Templates
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Pages
import { Routes } from "@/constants/routes"
import { Investments as InvestmentsView } from "./components/Investments"

// UI
import { InvestmentOpportunityBanner } from "@/components/banners/InvestmentOpportunityBanner"
import { Card } from "@/components/Card"
import { Tooltip } from "@/components/Tooltip"

// Icons
import { FiInfo } from "@/lib/icons"

// Context
import {
	useCurrentUserMessagesCount,
	useCurrentUserPayoutsCount,
	useCurrentUserProvisonalInvestmentsCount,
	useCurrentUserInvestmentsCount,
	useCurrentUserInvestmentsInProductionCount,
} from "@/context/user"

/**
 * InvestmentsPage
 * @returns
 */
export function InvestmentsLayout({ children }: { children: ReactNode }) {
	const t = useTrans(["common", "investments", "project"])

	// Context
	const messagesCount = useCurrentUserMessagesCount()
	const payoutsCount = useCurrentUserPayoutsCount()
	const provisionalInvestmentsCount =
		useCurrentUserProvisonalInvestmentsCount()
	const investmentsCount = useCurrentUserInvestmentsCount()
	const investmentsInProductionCount =
		useCurrentUserInvestmentsInProductionCount()

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("investments:investments.title"),
				})}
			</title>
			<PageTemplate
				title={t("investments:investments.title")}
				tabs={
					<>
						{/** Current overview */}
						{investmentsCount > 0 ? (
							<PageTemplateLink
								to={Routes.InvestmentsProjectsCurrentOverview}
								name={t("project:current-overview.title")}
								analyticsId={t(
									"project:current-overview.title",
								)}
							/>
						) : null}

						{/** Project overview */}
						{investmentsCount > 0 ? (
							<PageTemplateLink
								to={Routes.InvestmentsProjects}
								data-testid="investments.header"
								name={t("investments:investments.header")}
								analyticsId="investments"
								badge={{
									type: "element",
									element: (
										<Tooltip
											content={
												<p>
													{t(
														"investments:investments.copy",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												<FiInfo className="ml-1" />
											</span>
										</Tooltip>
									),
								}}
							/>
						) : null}

						{/** Provisional investments */}
						{provisionalInvestmentsCount > 0 ? (
							<PageTemplateLink
								to={Routes.ProvisionalInvestments}
								data-testid="investments.provisional_investments"
								name={t(
									"common:common.nav.provisional_investments",
								)}
								analyticsId="common.nav.provisional_investments"
								badge={{
									type: "element",
									element: (
										<Tooltip
											content={
												<p>
													{t(
														"project:project.provisional_investments.title.tooltip",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												<FiInfo className="ml-1" />
											</span>
										</Tooltip>
									),
								}}
							/>
						) : null}

						{/** Fiscal year overview */}
						{investmentsCount > 0 ? (
							<PageTemplateLink
								to={Routes.InvestmentsProjectsFiscalOverview}
								data-testid="investments.fiscal.header"
								name={t("common:common.nav.fiscal")}
								analyticsId="investments.fiscal"
							/>
						) : null}

						{/** Show production when atleast 1 project has Production data */}
						{investmentsInProductionCount > 0 ? (
							<PageTemplateLink
								to={Routes.InvestmentsProjectsProduction}
								data-testid="investments.production.header"
								name={t("common:common.nav.total_production")}
								analyticsId="investments.production"
							/>
						) : null}

						{/** Payouts */}
						{payoutsCount > 0 ? (
							<PageTemplateLink
								to={Routes.InvestmentsProjectsPayments}
								data-testid="investments.payouts"
								name={t("common:common.nav.payouts")}
								analyticsId="investments.payouts"
							/>
						) : null}

						{/** Monthly overview */}
						{investmentsCount > 0 ? (
							<PageTemplateLink
								to={
									Routes.InvestmentsProjectsMonthlyOverviewMain
								}
								data-testid="investments.monthly_overview"
								name={t(
									"investments:investments.monthly_overview.nav.title",
								)}
								analyticsId="investments.monthly_overview"
								end={false} // This ensures that children routes also match active page
							/>
						) : null}

						{/** Communication hub */}
						{messagesCount > 0 ? (
							<PageTemplateLink
								to={Routes.Communications}
								data-testid="investments.all_messages"
								name={t("common:common.nav.all_messages")}
								analyticsId="investments.all_messages"
							/>
						) : null}
					</>
				}
			>
				{children}
			</PageTemplate>
		</>
	)
}

export function InvestmentsIndex() {
	return (
		<>
			<InvestmentOpportunityBanner className="mb-8" />
			<Card variant="gray">
				<InvestmentsView />
			</Card>
		</>
	)
}
