// React
import { forwardRef, ReactNode } from "react"

/**
 * PDF generation
 * ALTERNATIVES: using this instead of react-to-pdf because it doesnt break content
 */

import domToPdf from "dom-to-pdf"

// UI
import { Logo } from "@/components/navigation/UserMenu"
import { classNames } from "@/lib/ui"
import { Heading } from "@/components/Typography"

/**
 * PDFViewWrapper
 * Wrap anything you want to print inside this wrapper to apply brand styling
 * It creates a component thats visually outside of the view
 *
 * @param param0
 * @returns
 */
interface PDFViewWrapperProps {
	children: ReactNode
	title: string
	width: number
}
export const PDFViewWrapper = forwardRef<HTMLDivElement, PDFViewWrapperProps>(
	({ children, title, width }, ref) => (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: `-${width}px`,
				width: `${width}px`,
			}}
		>
			<div ref={ref}>
				<div
					className={classNames(
						"bg-primary-500 flex h-14 flex-shrink-0 items-center shadow-[0px_1px_3px_rgba(0,0,0,0.25)] 2xl:h-16",
						"flex justify-end pl-2",
					)}
				>
					<div className="flex-shrink">
						<Logo />
					</div>
					<Heading
						as="h3"
						className="font-static ml-auto pr-2 text-lg font-extrabold uppercase"
					>
						{title}
					</Heading>
				</div>
				<div className="px-4 py-6 pt-6">{children}</div>
			</div>
		</div>
	),
)
PDFViewWrapper.displayName = "PDFViewWrapper"

/**
 * getPdfFileName
 * @param title
 * @returns
 */
export function getPdfFileName(title: string) {
	return `Zonhub - ${title}.pdf`
}

// Export
export { domToPdf as generatePDF }
