/* 🚨🚨🚨 DO NOT EDIT: This file has been auto generated by graphql-code-generator */
import {
	useMutation,
	useQuery,
	UseMutationOptions,
	UseQueryOptions,
} from "@tanstack/react-query"
import { queryFetcher } from "../../api/graphql/codegen.fetcher"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends " $fragmentName" | "__typename"
				? T[P]
				: never
	  }
export type EntireFieldWrapper<T> = T
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	CustomDate: { input: any; output: any }
	CustomDateTime: { input: any; output: any }
	Date: { input: any; output: any }
	DateTime: { input: any; output: any }
	Decimal: { input: string; output: string }
	FileField: { input: any; output: any }
	GeoJsonField: { input: any; output: any }
	JSONString: { input: any; output: any }
	UUID: { input: any; output: any }
}

export type AddressInput = {
	address_line_primary: Scalars["String"]["input"]
	address_line_secondary: Scalars["String"]["input"]
	latitude: Scalars["Float"]["input"]
	longitude: Scalars["Float"]["input"]
	postal_code: Scalars["String"]["input"]
}

export type ConnectionContactInput = {
	name?: InputMaybe<Scalars["String"]["input"]>
	phone?: InputMaybe<Scalars["String"]["input"]>
	type: ContactTypeChoiceEnum
}

export type ConnectionInput = {
	connection_contact_info: ConnectionContactInput
	inverter_brand: Scalars["String"]["input"]
}

export const ContactTypeChoiceEnum = {
	Installer: "INSTALLER",
	Me: "ME",
} as const

export type ContactTypeChoiceEnum =
	(typeof ContactTypeChoiceEnum)[keyof typeof ContactTypeChoiceEnum]
/** An enumeration. */
export const CoreEnergySupplierCustomerFeePerKwhCurrencyChoices = {
	/** Euro */
	Eur: "EUR",
} as const

export type CoreEnergySupplierCustomerFeePerKwhCurrencyChoices =
	(typeof CoreEnergySupplierCustomerFeePerKwhCurrencyChoices)[keyof typeof CoreEnergySupplierCustomerFeePerKwhCurrencyChoices]
/** An enumeration. */
export const CoreInvestmentTestCategoryChoices = {
	/** Experience */
	Experience: "EXPERIENCE",
	/** Loss Calculator */
	LossCalculator: "LOSS_CALCULATOR",
	/** Questions */
	Questions: "QUESTIONS",
} as const

export type CoreInvestmentTestCategoryChoices =
	(typeof CoreInvestmentTestCategoryChoices)[keyof typeof CoreInvestmentTestCategoryChoices]
/** An enumeration. */
export const CoreInvestmentTestExperienceChoices = {
	/** Expert */
	Expert: "EXPERT",
	/** Novice */
	Novice: "NOVICE",
} as const

export type CoreInvestmentTestExperienceChoices =
	(typeof CoreInvestmentTestExperienceChoices)[keyof typeof CoreInvestmentTestExperienceChoices]
/** An enumeration. */
export const CoreLocationCountryChoices = {
	/** Oostenrijk */
	At: "AT",
	/** België */
	Be: "BE",
	/** Bulgarije */
	Bg: "BG",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Denemarken */
	Dk: "DK",
	/** Estland */
	Ee: "EE",
	/** Spanje */
	Es: "ES",
	/** Finland */
	Fi: "FI",
	/** Frankrijk */
	Fr: "FR",
	/** Griekenland */
	Gr: "GR",
	/** Kroatië */
	Hr: "HR",
	/** Hongarije */
	Hu: "HU",
	/** Ierland */
	Ie: "IE",
	/** Italië */
	It: "IT",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Malta */
	Mt: "MT",
	/** Nederland */
	Nl: "NL",
	/** Polen */
	Pl: "PL",
	/** Portugal */
	Pt: "PT",
	/** Roemenië */
	Ro: "RO",
	/** Zweden */
	Se: "SE",
	/** Slovenië */
	Si: "SI",
	/** Slowakije */
	Sk: "SK",
} as const

export type CoreLocationCountryChoices =
	(typeof CoreLocationCountryChoices)[keyof typeof CoreLocationCountryChoices]
/** An enumeration. */
export const CoreLocationRegionChoices = {
	/** Drenthe */
	Dr: "DR",
	/** Flevoland */
	Fl: "FL",
	/** Friesland */
	Fr: "FR",
	/** Gelderland */
	Gl: "GL",
	/** Groningen */
	Gr: "GR",
	/** Limburg */
	Lb: "LB",
	/** Noord-Brabant */
	Nb: "NB",
	/** Noord-Holland */
	Nh: "NH",
	/** Overijssel */
	Ov: "OV",
	/** Utrecht */
	Ut: "UT",
	/** Zeeland */
	Ze: "ZE",
	/** Zuid-Holland */
	Zh: "ZH",
} as const

export type CoreLocationRegionChoices =
	(typeof CoreLocationRegionChoices)[keyof typeof CoreLocationRegionChoices]
/** An enumeration. */
export const CrowdfundingCampaignAmountRaisedCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignAmountRaisedCurrencyEnum =
	(typeof CrowdfundingCampaignAmountRaisedCurrencyEnum)[keyof typeof CrowdfundingCampaignAmountRaisedCurrencyEnum]
/** An enumeration. */
export const CrowdfundingCampaignAmountRequestedCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignAmountRequestedCurrencyEnum =
	(typeof CrowdfundingCampaignAmountRequestedCurrencyEnum)[keyof typeof CrowdfundingCampaignAmountRequestedCurrencyEnum]
/** An enumeration. */
export const CrowdfundingCampaignMaxInvestmentAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignMaxInvestmentAmountCurrencyEnum =
	(typeof CrowdfundingCampaignMaxInvestmentAmountCurrencyEnum)[keyof typeof CrowdfundingCampaignMaxInvestmentAmountCurrencyEnum]
/** An enumeration. */
export const CrowdfundingCampaignMinInvestmentAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignMinInvestmentAmountCurrencyEnum =
	(typeof CrowdfundingCampaignMinInvestmentAmountCurrencyEnum)[keyof typeof CrowdfundingCampaignMinInvestmentAmountCurrencyEnum]
/** An enumeration. */
export const CrowdfundingCampaignStateEnum = {
	/** Gesloten */
	Closed: "CLOSED",
	/** Concept */
	Draft: "DRAFT",
	/** Verborgen */
	Hidden: "HIDDEN",
	/** Live */
	Live: "LIVE",
	/** Succesvol */
	Successful: "SUCCESSFUL",
} as const

export type CrowdfundingCampaignStateEnum =
	(typeof CrowdfundingCampaignStateEnum)[keyof typeof CrowdfundingCampaignStateEnum]
/** An enumeration. */
export const CrowdfundingCampaignStepInvestmentAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignStepInvestmentAmountCurrencyEnum =
	(typeof CrowdfundingCampaignStepInvestmentAmountCurrencyEnum)[keyof typeof CrowdfundingCampaignStepInvestmentAmountCurrencyEnum]
/** An enumeration. */
export const CrowdfundingCampaignUnitShareAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type CrowdfundingCampaignUnitShareAmountCurrencyEnum =
	(typeof CrowdfundingCampaignUnitShareAmountCurrencyEnum)[keyof typeof CrowdfundingCampaignUnitShareAmountCurrencyEnum]
export type CrowdfundingTransactionType = {
	__typename?: "CrowdfundingTransactionType"
	amount: EntireFieldWrapper<Scalars["Decimal"]["output"]>
	created_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	customer_BIC_provided_by_bank?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	customer_IBAN_provided_by_bank?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	customer_legal_name_provided_by_bank?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["DateTime"]["output"]>>
	error_reason_description: EntireFieldWrapper<Scalars["String"]["output"]>
	fee_amount: EntireFieldWrapper<Scalars["Decimal"]["output"]>
	fee_amount_currency: EntireFieldWrapper<PaymentsFinancialTransactionFeeAmountCurrencyChoices>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	interoperable_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	modified_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	payment_amount: EntireFieldWrapper<Scalars["Decimal"]["output"]>
	payment_amount_currency: EntireFieldWrapper<PaymentsFinancialTransactionPaymentAmountCurrencyChoices>
	payment_type?: EntireFieldWrapper<
		Maybe<PaymentsFinancialTransactionPaymentTypeChoices>
	>
	processor_context_data: EntireFieldWrapper<Scalars["JSONString"]["output"]>
	/** The ID of the Mangopay remote wallet associated with this project. */
	project_wallet_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	refunded_interoperable_id?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	state: EntireFieldWrapper<PaymentsFinancialTransactionStateChoices>
	transaction_type: EntireFieldWrapper<PaymentsFinancialTransactionTransactionTypeChoices>
	/** Universeel unieke identificatie. */
	uuid: EntireFieldWrapper<Scalars["UUID"]["output"]>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short: EntireFieldWrapper<Scalars["String"]["output"]>
}

/** Debugging information for the current query. */
export type DjangoDebug = {
	__typename?: "DjangoDebug"
	/** Raise exceptions for this API query. */
	exceptions?: EntireFieldWrapper<Maybe<Array<Maybe<DjangoDebugException>>>>
	/** Executed SQL queries for this API query. */
	sql?: EntireFieldWrapper<Maybe<Array<Maybe<DjangoDebugSql>>>>
}

/** Represents a single exception raised. */
export type DjangoDebugException = {
	__typename?: "DjangoDebugException"
	/** The class of the exception */
	exc_type: EntireFieldWrapper<Scalars["String"]["output"]>
	/** The message of the exception */
	message: EntireFieldWrapper<Scalars["String"]["output"]>
	/** The stack trace */
	stack: EntireFieldWrapper<Scalars["String"]["output"]>
}

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
	__typename?: "DjangoDebugSQL"
	/** The Django database alias (e.g. 'default'). */
	alias: EntireFieldWrapper<Scalars["String"]["output"]>
	/** Duration of this database query in seconds. */
	duration: EntireFieldWrapper<Scalars["Float"]["output"]>
	/** Postgres connection encoding if available. */
	encoding?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Whether this database query was a SELECT. */
	is_select: EntireFieldWrapper<Scalars["Boolean"]["output"]>
	/** Whether this database query took more than 10 seconds. */
	is_slow: EntireFieldWrapper<Scalars["Boolean"]["output"]>
	/** Postgres isolation level if available. */
	iso_level?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** JSON encoded database query parameters. */
	params: EntireFieldWrapper<Scalars["String"]["output"]>
	/** The raw SQL of this query, without params. */
	raw_sql: EntireFieldWrapper<Scalars["String"]["output"]>
	/** The actual SQL sent to this database. */
	sql?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Start time of this database query. */
	start_time: EntireFieldWrapper<Scalars["Float"]["output"]>
	/** Stop time of this database query. */
	stop_time: EntireFieldWrapper<Scalars["Float"]["output"]>
	/** Postgres transaction ID if available. */
	trans_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Postgres transaction status if available. */
	trans_status?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** The type of database being used (e.g. postrgesql, mysql, sqlite). */
	vendor: EntireFieldWrapper<Scalars["String"]["output"]>
}

export type EnergySupplierType = {
	__typename?: "EnergySupplierType"
	/** Email address to send communications related to interest period payouts. */
	billing_email?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Klantkosten berekend op basis van deze waarde en direct betaald aan de investeerder. */
	customer_fee_per_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	customer_fee_per_kwh_currency: EntireFieldWrapper<CoreEnergySupplierCustomerFeePerKwhCurrencyChoices>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	is_partner: EntireFieldWrapper<Scalars["Boolean"]["output"]>
	is_solar_interest_paid_to_supplier: EntireFieldWrapper<
		Scalars["Boolean"]["output"]
	>
	kwh_cap?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	logo?: EntireFieldWrapper<Maybe<Scalars["FileField"]["output"]>>
	name: EntireFieldWrapper<Scalars["String"]["output"]>
	url?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type ErrorDictType = {
	__typename?: "ErrorDictType"
	field?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	messages?: EntireFieldWrapper<
		Maybe<Array<Maybe<Scalars["String"]["output"]>>>
	>
}

export type ErrorType = {
	__typename?: "ErrorType"
	field: EntireFieldWrapper<Scalars["String"]["output"]>
	messages: EntireFieldWrapper<Array<Scalars["String"]["output"]>>
}

export type FiscalOverviewType = {
	__typename?: "FiscalOverviewType"
	value_end?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	value_start?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	year?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InstallerType = {
	__typename?: "InstallerType"
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	logo?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	name: EntireFieldWrapper<Scalars["String"]["output"]>
}

export type InterestPaymentListType = {
	__typename?: "InterestPaymentListType"
	/** Payment list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<InterestPaymentType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type InterestPaymentListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

export type InterestPaymentType = {
	__typename?: "InterestPaymentType"
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	download_csv_url?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	download_pdf_url?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	energy_supplier_acceptance_date_time?: EntireFieldWrapper<
		Maybe<Scalars["DateTime"]["output"]>
	>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	interest_amount?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** CAP/FLOOR Limiet */
	interest_per_share_limit_applied?: EntireFieldWrapper<
		Maybe<PaymentInterestPerShareLimitAppliedEnum>
	>
	/** Renteperiode */
	interestperiod?: EntireFieldWrapper<Maybe<InterestPeriodType>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	owner_verification?: EntireFieldWrapper<Maybe<OwnerVerificationType>>
	payment_date?: EntireFieldWrapper<Maybe<Scalars["Date"]["output"]>>
	/** PaymentEntry list */
	payment_entries?: EntireFieldWrapper<Maybe<Array<PaymentEntryType>>>
	/** Betaallink */
	payment_link?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Betalingspartner gekozen voor de jaarlijkse betalingen */
	payment_service_provider?: EntireFieldWrapper<
		Maybe<PaymentPaymentServiceProviderEnum>
	>
	progress_percentage?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	project?: EntireFieldWrapper<Maybe<ProjectType>>
	/** Project's CAP voor rente per aandeel (Euro) */
	project_cap_eur_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Project's FLOOR voor rente per aandeel (Euro) */
	project_floor_eur_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	project_owner_acceptance_date_time?: EntireFieldWrapper<
		Maybe<Scalars["DateTime"]["output"]>
	>
	/** Betalingsstaat */
	state?: EntireFieldWrapper<Maybe<PaymentStateEnum>>
	supplier_verification?: EntireFieldWrapper<Maybe<SupplierVerificationType>>
	/** Totale productie op basis van metingen */
	total_production?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	total_production_investor?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	total_production_investor_limited?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	total_production_of_investors_with_supplier?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	total_production_of_investors_with_supplier_limited?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Transactiekosten factuur verzonden naar projecteigenaar? */
	transaction_fee_invoiced?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	transaction_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InterestPaymentTypePayment_EntriesArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	partner_supplier_only?: InputMaybe<Scalars["Boolean"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
	transaction_id?: InputMaybe<Scalars["String"]["input"]>
}

export type InterestPeriodType = {
	__typename?: "InterestPeriodType"
	/** Total amortization for this interest period. */
	amortization?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Avg. Endex tarif (euros per mWh) for this period. Calculated automatically when the period starts. */
	average_endex_tariff?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Cost in eurocent per kWh */
	cost_kwh?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Einddatum (tot en met) */
	end?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	interest_amount?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	is_current_period?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_first_period?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	payment_deadline_date?: EntireFieldWrapper<
		Maybe<Scalars["DateTime"]["output"]>
	>
	/** Total repayment for this interest period. */
	repayment?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Startdatum (vanaf) */
	start?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
}

/** An enumeration. */
export const InvestmentAmountTotalCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type InvestmentAmountTotalCurrencyEnum =
	(typeof InvestmentAmountTotalCurrencyEnum)[keyof typeof InvestmentAmountTotalCurrencyEnum]
export type InvestmentCampaignDocument = {
	__typename?: "InvestmentCampaignDocument"
	description?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	document_type?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	file_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	title?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	url?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InvestmentCampaignProjectType = {
	__typename?: "InvestmentCampaignProjectType"
	/** Totale waarde van het project in euro's. */
	amount_total?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** amount total currency */
	amount_total_currency?: EntireFieldWrapper<
		Maybe<ProjectHorizonAmountTotalCurrencyEnum>
	>
	/** Categorie */
	category?: EntireFieldWrapper<Maybe<ProjectHorizonCategoryEnum>>
	/** Gemaakt op */
	created_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Verwijderd op */
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Beschrijving */
	description?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	investment_campaigns?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestmentCampaignType>>>
	>
	/** Flexibele financiering maximaal bedrag. */
	maximum_amount_total?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** maximum amount total currency */
	maximum_amount_total_currency?: EntireFieldWrapper<
		Maybe<ProjectHorizonMaximumAmountTotalCurrencyEnum>
	>
	/** Gewijzigd op */
	modified_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Totaal aantal zonnepanelen van project. */
	panels?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** partners */
	partners?: EntireFieldWrapper<Maybe<Array<Maybe<PartnerType>>>>
	project?: EntireFieldWrapper<Maybe<ProjectType>>
	/** Voucher code kan niet gebruikt worden voor dit project. */
	project_wallet_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Unieke project titel */
	title?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InvestmentCampaignProjectTypePartnersArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
}

export type InvestmentCampaignType = {
	__typename?: "InvestmentCampaignType"
	/** Totaal bedrag opgehaald tot nu toe */
	amount_raised?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** amount raised currency */
	amount_raised_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignAmountRaisedCurrencyEnum>
	>
	/** Bedrag gevraagd in euro's */
	amount_requested?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** amount requested currency */
	amount_requested_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignAmountRequestedCurrencyEnum>
	>
	/** Campagne einddatum en -tijd */
	campaign_end_at?: EntireFieldWrapper<
		Maybe<Scalars["CustomDateTime"]["output"]>
	>
	/** Campagne startdatum en -tijd */
	campaign_start_at?: EntireFieldWrapper<
		Maybe<Scalars["CustomDateTime"]["output"]>
	>
	/** Als er geen vaste startdatum is, selecteer dan deze optie. */
	coming_soon?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Gemaakt op */
	created_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Verwijderd op */
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Sta bankoverschrijvingen toe voor het investeringsbedrag groter dan 10000. */
	enable_bank_transfers_button?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	/** Verwachte productie in kWh per aandeel per jaar */
	estimated_annual_production_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** Externe ID van het geïmporteerde record */
	imported_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Datum van het informatiememorandum document gerelateerd aan deze campagne. */
	information_memorandum_date?: EntireFieldWrapper<
		Maybe<Scalars["CustomDate"]["output"]>
	>
	is_imported?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Maximum investeringsbedrag in euro's voor de slider. */
	max_investment_amount?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** max investment amount currency */
	max_investment_amount_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignMaxInvestmentAmountCurrencyEnum>
	>
	/** Minimum investeringsbedrag in euro's voor de slider. */
	min_investment_amount?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** min investment amount currency */
	min_investment_amount_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignMinInvestmentAmountCurrencyEnum>
	>
	/** Gewijzigd op */
	modified_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Financiering zal worden toegestaan over 100% (campagne zal niet automatisch slagen). */
	overfunding_allowed?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	page_url?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Deel van het totaal aantal zonnepanelen waarvoor ZonneDelen worden uitgegeven. */
	panels?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** project */
	project?: EntireFieldWrapper<Maybe<InvestmentCampaignProjectType>>
	/** Totaal aantal uit te geven ZonneDelen voor dit project. */
	shares?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Aantal ZonneDelen per zonnepaneel. */
	shares_per_panel?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Status financiering. */
	state?: EntireFieldWrapper<Maybe<CrowdfundingCampaignStateEnum>>
	/** Bedrag per stap in euro's voor de slider. */
	step_investment_amount?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** step investment amount currency */
	step_investment_amount_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignStepInvestmentAmountCurrencyEnum>
	>
	/** Bedrag per ZonneDeel. */
	unit_share_amount?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** unit share amount currency */
	unit_share_amount_currency?: EntireFieldWrapper<
		Maybe<CrowdfundingCampaignUnitShareAmountCurrencyEnum>
	>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InvestmentListType = {
	__typename?: "InvestmentListType"
	/** Investment list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<InvestmentType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type InvestmentListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

/** An enumeration. */
export const InvestmentPaymentAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type InvestmentPaymentAmountCurrencyEnum =
	(typeof InvestmentPaymentAmountCurrencyEnum)[keyof typeof InvestmentPaymentAmountCurrencyEnum]
/** An enumeration. */
export const InvestmentRefundAmountCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type InvestmentRefundAmountCurrencyEnum =
	(typeof InvestmentRefundAmountCurrencyEnum)[keyof typeof InvestmentRefundAmountCurrencyEnum]
/** An enumeration. */
export const InvestmentRefundReasonEnum = {
	/** No KYC ID provided */
	NoKycId: "NO_KYC_ID",
	/** Other reason */
	Other: "OTHER",
	/** Project overfunded */
	Overfunding: "OVERFUNDING",
} as const

export type InvestmentRefundReasonEnum =
	(typeof InvestmentRefundReasonEnum)[keyof typeof InvestmentRefundReasonEnum]
/** An enumeration. */
export const InvestmentStateEnum = {
	/** Geannuleerd */
	Cancelled: "CANCELLED",
	/** Concept */
	Draft: "DRAFT",
	/** Fout */
	Error: "ERROR",
	/** Partial Refund Complete */
	PartialRefundComplete: "PARTIAL_REFUND_COMPLETE",
	/** Partial Refund Failed */
	PartialRefundFailed: "PARTIAL_REFUND_FAILED",
	/** Processing Partial Refund */
	PartialRefundProcessing: "PARTIAL_REFUND_PROCESSING",
	/** Betaling compleet */
	PaymentComplete: "PAYMENT_COMPLETE",
	/** Betaling gedeeltelijk compleet */
	PaymentPartial: "PAYMENT_PARTIAL",
	/** Verwerken betaling */
	PaymentProcessing: "PAYMENT_PROCESSING",
	/** Fout bij betaalpartner */
	PaymentProcessorError: "PAYMENT_PROCESSOR_ERROR",
	/** Terugbetaling compleet */
	RefundComplete: "REFUND_COMPLETE",
	/** Refund Failed */
	RefundFailed: "REFUND_FAILED",
	/** Verwerken terugbetaling */
	RefundProcessing: "REFUND_PROCESSING",
	/** Geannuleerd door gebruiker */
	UserCancelled: "USER_CANCELLED",
} as const

export type InvestmentStateEnum =
	(typeof InvestmentStateEnum)[keyof typeof InvestmentStateEnum]
export type InvestmentTestType = {
	__typename?: "InvestmentTestType"
	category: EntireFieldWrapper<CoreInvestmentTestCategoryChoices>
	created_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	/** Optional extra data for specific tests */
	data?: EntireFieldWrapper<Maybe<Scalars["JSONString"]["output"]>>
	experience?: EntireFieldWrapper<Maybe<CoreInvestmentTestExperienceChoices>>
	expiry_date?: EntireFieldWrapper<Maybe<Scalars["Date"]["output"]>>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
}

export type InvestmentType = {
	__typename?: "InvestmentType"
	agreement_file?: EntireFieldWrapper<Maybe<Scalars["FileField"]["output"]>>
	/** Totaalbedrag voor deze investering inclusief korting of voucher codes etc. */
	amount_total?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** amount total currency */
	amount_total_currency?: EntireFieldWrapper<
		Maybe<InvestmentAmountTotalCurrencyEnum>
	>
	/** Als waar, dan wordt de naam van de investeerder niet getoond op de website. */
	anonymous?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Motivatie bij investering */
	comment?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Gemaakt op */
	created_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** crowdfunding campaign */
	crowdfunding_campaign?: EntireFieldWrapper<Maybe<InvestmentCampaignType>>
	/** Custom text for the refund reason in cases where the refund reason is anything other than No_KYC/Overfunding. */
	custom_refund_reason_text?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Verwijderd op */
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** email */
	email?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** kyc validation state */
	kyc_validation_state?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Gewijzigd op */
	modified_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Bedrag te betalen via betalingsopties */
	payment_amount?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** payment amount currency */
	payment_amount_currency?: EntireFieldWrapper<
		Maybe<InvestmentPaymentAmountCurrencyEnum>
	>
	personal_data?: EntireFieldWrapper<Maybe<InvestorPersonalDataType>>
	/** Amount to refund via a payment method. */
	refund_amount?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** refund amount currency */
	refund_amount_currency?: EntireFieldWrapper<
		Maybe<InvestmentRefundAmountCurrencyEnum>
	>
	/** Reason for refunding the full investment. Based on this reason, we are sending a custom email to the user. */
	refund_reason?: EntireFieldWrapper<Maybe<InvestmentRefundReasonEnum>>
	/** Opmerkingen bij investering (voor interne administratie) */
	remarks?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Huidige stand van de investering. */
	state?: EntireFieldWrapper<Maybe<InvestmentStateEnum>>
	transaction?: EntireFieldWrapper<Maybe<CrowdfundingTransactionType>>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

/** An enumeration. */
export const InvestorCountryEnum = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type InvestorCountryEnum =
	(typeof InvestorCountryEnum)[keyof typeof InvestorCountryEnum]
/** An enumeration. */
export const InvestorHomeTypeEnum = {
	/** Appartement */
	App: "APP",
	/** Twee-onder-één-kap-woning */
	A_21K: "A_21K",
	/** Hoekwoning */
	Hoe: "HOE",
	/** Onbekend / overig */
	Onb: "ONB",
	/** Tussenwoning */
	Tus: "TUS",
	/** Vrijstaande woning */
	Vry: "VRY",
} as const

export type InvestorHomeTypeEnum =
	(typeof InvestorHomeTypeEnum)[keyof typeof InvestorHomeTypeEnum]
export type InvestorInternalRateOfReturns = {
	__typename?: "InvestorInternalRateOfReturns"
	expected_internal_rate_of_return?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	internal_rate_of_return?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

/** An enumeration. */
export const InvestorPersonalDataCountryEnum = {
	/** Oostenrijk */
	At: "AT",
	/** België */
	Be: "BE",
	/** Bulgarije */
	Bg: "BG",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Denemarken */
	Dk: "DK",
	/** Estland */
	Ee: "EE",
	/** Spanje */
	Es: "ES",
	/** Finland */
	Fi: "FI",
	/** Frankrijk */
	Fr: "FR",
	/** Griekenland */
	Gr: "GR",
	/** Kroatië */
	Hr: "HR",
	/** Hongarije */
	Hu: "HU",
	/** Ierland */
	Ie: "IE",
	/** Italië */
	It: "IT",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Malta */
	Mt: "MT",
	/** Nederland */
	Nl: "NL",
	/** Polen */
	Pl: "PL",
	/** Portugal */
	Pt: "PT",
	/** Roemenië */
	Ro: "RO",
	/** Zweden */
	Se: "SE",
	/** Slovenië */
	Si: "SI",
	/** Slowakije */
	Sk: "SK",
} as const

export type InvestorPersonalDataCountryEnum =
	(typeof InvestorPersonalDataCountryEnum)[keyof typeof InvestorPersonalDataCountryEnum]
/** An enumeration. */
export const InvestorPersonalDataIdentityTypeEnum = {
	/** Zakelijk */
	Business: "BUSINESS",
	/** Privé */
	Private: "PRIVATE",
	/** Niet beschikbaar */
	Unavailable: "UNAVAILABLE",
} as const

export type InvestorPersonalDataIdentityTypeEnum =
	(typeof InvestorPersonalDataIdentityTypeEnum)[keyof typeof InvestorPersonalDataIdentityTypeEnum]
export type InvestorPersonalDataType = {
	__typename?: "InvestorPersonalDataType"
	/** Straat, Postbus, Bedrijfsnaam, t.a.v. */
	address_line_primary?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Huisnummer, toevoeging */
	address_line_secondary?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Naam zoals inschrijving bij KVK */
	business_legal_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** KVK nummer */
	business_nr?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Woonplaats, gebied */
	city?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Momenteel kunnen alleen projecten in de Nederland worden gefaciliteerd.  */
	country?: EntireFieldWrapper<Maybe<InvestorPersonalDataCountryEnum>>
	/** Gemaakt op */
	created_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Bijvoorbeeld 31/01/1980, 31-12-1980 */
	date_of_birth?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** Verwijderd op */
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Voornaam */
	first_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** identity type */
	identity_type?: EntireFieldWrapper<
		Maybe<InvestorPersonalDataIdentityTypeEnum>
	>
	/** Initialen zoals op bankpas */
	initials?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Achternaam zoals op bankpas */
	last_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Gewijzigd op */
	modified_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Postcode */
	postal_code?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Bijvoorbeeld +31612345678 */
	telephone_nr?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InvestorProductionStatsType = {
	__typename?: "InvestorProductionStatsType"
	cumulative_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	/** Percentage delta production per investor. */
	delta_production_per_investor?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	expected_cumulative_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	expected_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	expected_production_per_project_in_kwh?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsExpectedProductionPerProjectInKwh>>>
	>
	/**
	 *
	 *         Total CO2 saved by this investor.
	 *
	 */
	investor_co2_saving?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total cost saved by this investor. */
	investor_cost_saving?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Total production generated for this investor, in every of their projects, corrected for their share in each.
	 *         A same method as 'investor_generated_power', but returns data dict
	 *         grouped by Project. Returned number is in kWhs.
	 *
	 */
	investor_generated_power?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	investor_generated_power_by_project_kwh?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsInvestorGeneratedPowerByProjectKwh>>>
	>
	investor_generated_power_in_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** used for UI graph */
	max_production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	production_by_time?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsProductionByTimeType>>>
	>
	production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	production_for_day?: EntireFieldWrapper<Maybe<StatsProductionForDay>>
	/** Investor aggregation production speed with there shares weight */
	production_speed?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	return_on_investment?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total expected production in kWh. */
	total_expected_production_in_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Investor aggregated production for counter.
	 *         i.e. including the estimated production since the last measurement
	 */
	total_production_for_counter?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	total_production_percentage?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

export type InvestorProductionStatsTypeCumulative_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type InvestorProductionStatsTypeExpected_Cumulative_Production_DataArgs =
	{
		grouped_by?: Scalars["String"]["input"]
	}

export type InvestorProductionStatsTypeExpected_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type InvestorProductionStatsTypeProduction_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type InvestorProductionStatsTypeProduction_For_DayArgs = {
	day?: InputMaybe<Scalars["Date"]["input"]>
}

export type InvestorSharesValueStatsType = {
	__typename?: "InvestorSharesValueStatsType"
	/** Gives the expected and actual internal rate of return(IRR)s. */
	internal_rate_of_returns?: EntireFieldWrapper<
		Maybe<InvestorInternalRateOfReturns>
	>
	number_of_shares_per_project?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsNumberOfSharesPerProject>>>
	>
	projects?: EntireFieldWrapper<Maybe<Array<Maybe<ProjectType>>>>
	/** Retrieves and caches current share value for project and date range of this InvestorStatistics. */
	share_value?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	share_value_per_project?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsInvestorShareValuePerProject>>>
	>
	/** Shares associated with the investor excluding shares related to paid off projects before the start date. */
	shares?: EntireFieldWrapper<Maybe<Array<Maybe<SolarShareType>>>>
	shares_ratio_per_project?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsSharesRatioPerProject>>>
	>
	/** Total paid amortization for investor */
	total_amortization?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Total paid interest for investor */
	total_cost?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Retrieves total investment value based on the current share value and total shares, for a single project and date range of this investor statistics. */
	total_investment_for_project?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Retrieves aggregated current share value for all projects and date range of this investor statistics. */
	total_investment_value?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Retrieves total share value per project and date range of this investor statistics. */
	total_investment_value_per_project?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsInvestorShareValuePerProject>>>
	>
	/** Retrieves total share value per project type and date range of this investor statistics. */
	total_investment_value_per_project_type?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestorStatsInvestorShareValuePerProjectType>>>
	>
	total_nominal_investment?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total sum of everything paid to investor */
	total_repaid?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Retrieves the total statistics of repayment and amortization for a single project till date. */
	total_repaid_for_project?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total paid repayments for investor */
	total_repayment?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	total_shares?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

/** Total expected production per project in kWh. */
export type InvestorStatsExpectedProductionPerProjectInKwh = {
	__typename?: "InvestorStatsExpectedProductionPerProjectInKwh"
	expected_production?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	project_id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
}

/**
 * Total production generated for this investor, in every of their projects, corrected for their share in each.
 *         A same method as 'investor_generated_power', but returns data dict
 *         grouped by Project. Returned number is in kWhs.
 *
 */
export type InvestorStatsInvestorGeneratedPowerByProjectKwh = {
	__typename?: "InvestorStatsInvestorGeneratedPowerByProjectKwh"
	power?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	project_id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
}

/** Retrieves and caches current share value for project and date range of this InvestorStatistics. */
export type InvestorStatsInvestorShareValuePerProject = {
	__typename?: "InvestorStatsInvestorShareValuePerProject"
	project_id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
	project_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	share_value?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

/** Retrieves total share value per project type and date range of this investor statistics. */
export type InvestorStatsInvestorShareValuePerProjectType = {
	__typename?: "InvestorStatsInvestorShareValuePerProjectType"
	project_type?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	share_value?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

export type InvestorStatsNumberOfSharesPerProject = {
	__typename?: "InvestorStatsNumberOfSharesPerProject"
	project_id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
	shares_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type InvestorStatsProductionByTimeType = {
	__typename?: "InvestorStatsProductionByTimeType"
	datestamp?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	production?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	project_ids?: EntireFieldWrapper<
		Maybe<Array<Maybe<Scalars["ID"]["output"]>>>
	>
	timestamp?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type InvestorStatsSharesRatioPerProject = {
	__typename?: "InvestorStatsSharesRatioPerProject"
	project_id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
	shares_ratio?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

export type InvestorTestMutationsInput = {
	category: Scalars["String"]["input"]
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>
	/** Optional extra data for specific tests */
	data?: InputMaybe<Scalars["String"]["input"]>
	experience?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	investor: Scalars["ID"]["input"]
}

export type InvestorTestMutationsPayload = {
	__typename?: "InvestorTestMutationsPayload"
	clientMutationId?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorType>>>>
	investor_test?: EntireFieldWrapper<Maybe<InvestmentTestType>>
}

export type InvestorType = {
	__typename?: "InvestorType"
	/** Totaal aantal volwassenen */
	adult_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Geboortedatum */
	birth_date?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** Totale aantal kinderen */
	child_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Woonplaats */
	city?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Land */
	country?: EntireFieldWrapper<Maybe<InvestorCountryEnum>>
	/** Huis Type */
	home_type?: EntireFieldWrapper<Maybe<InvestorHomeTypeEnum>>
	/** IBAN */
	iban?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	masked_iban?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	notification_configuration?: EntireFieldWrapper<
		Maybe<NotificationConfigurationType>
	>
	/** Huis Nr. */
	number?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** PaymentEntry list */
	paymententry?: EntireFieldWrapper<Maybe<Array<Maybe<PaymentEntryType>>>>
	/** Postcode */
	postcode?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** SolarShare list */
	solarshare?: EntireFieldWrapper<Maybe<Array<Maybe<SolarShareType>>>>
	/** Straatnaam */
	street?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Huisnummer toevoeging */
	suffix?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	supplier?: EntireFieldWrapper<Maybe<EnergySupplierType>>
	/** Energieleverancier Klantnummer */
	supplier_account?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type InvestorTypePaymententryArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	partner_supplier_only?: InputMaybe<Scalars["Boolean"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
	transaction_id?: InputMaybe<Scalars["String"]["input"]>
}

export type InvestorTypeSolarshareArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	project?: InputMaybe<Scalars["ID"]["input"]>
	project__name__icontains?: InputMaybe<Scalars["String"]["input"]>
}

export type InvestorUpdateInput = {
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>
	iban?: InputMaybe<Scalars["String"]["input"]>
	notification_configuration?: InputMaybe<NotificationConfigurationSerializerInput>
	supplier_account?: InputMaybe<Scalars["String"]["input"]>
	supplier_id?: InputMaybe<Scalars["String"]["input"]>
}

export type InvestorUpdatePayload = {
	__typename?: "InvestorUpdatePayload"
	clientMutationId?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** May contain more than one error for same field. */
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorType>>>>
	masked_iban?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	notification_configuration?: EntireFieldWrapper<
		Maybe<NotificationConfigurationType>
	>
	supplier?: EntireFieldWrapper<Maybe<EnergySupplierType>>
	supplier_account?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	supplier_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type KycType = {
	__typename?: "KycType"
	/** A personal KYC declined reason that we want shown in the KYC Failed email template. Should be in Dutch. */
	human_readable_kyc_error?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	last_verified_on?: EntireFieldWrapper<Maybe<Scalars["Date"]["output"]>>
	state: EntireFieldWrapper<RiskKycStateChoices>
}

export type LayoutInput = {
	orientation: Scalars["String"]["input"]
	panels: Scalars["Int"]["input"]
	slope: Scalars["Float"]["input"]
}

export type LocationSerializerInput = {
	/** Straat, Postbus, Bedrijfsnaam, t.a.v. etc. */
	address_line_primary: Scalars["String"]["input"]
	/** Huisnummer, toevoeging */
	address_line_secondary?: InputMaybe<Scalars["String"]["input"]>
	/** Woonplaats, gebied */
	city?: InputMaybe<Scalars["String"]["input"]>
	/** Momenteel kunnen alleen projecten in de Nederland worden gefaciliteerd.  */
	country?: InputMaybe<Country>
	postal_code?: InputMaybe<Scalars["String"]["input"]>
	/** Regio, Provincie, Staat etc. */
	region?: InputMaybe<Region>
}

export type LocationType = {
	__typename?: "LocationType"
	/** Straat, Postbus, Bedrijfsnaam, t.a.v. etc. */
	address_line_primary: EntireFieldWrapper<Scalars["String"]["output"]>
	/** Huisnummer, toevoeging */
	address_line_secondary: EntireFieldWrapper<Scalars["String"]["output"]>
	/** Woonplaats, gebied */
	city: EntireFieldWrapper<Scalars["String"]["output"]>
	/** Weergegeven als (longitude, latitude). */
	coordinates?: EntireFieldWrapper<Maybe<Scalars["GeoJsonField"]["output"]>>
	/** Momenteel kunnen alleen projecten in de Nederland worden gefaciliteerd.  */
	country: EntireFieldWrapper<CoreLocationCountryChoices>
	created_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["DateTime"]["output"]>>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** Breedtegraag decimale waardes tussen +90 en -90. */
	latitude?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Lengtegraag decimale waardes tussen +180 en -180. */
	longitude?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	modified_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	postal_code: EntireFieldWrapper<Scalars["String"]["output"]>
	/** Regio, Provincie, Staat etc. */
	region?: EntireFieldWrapper<Maybe<CoreLocationRegionChoices>>
	userprofile?: EntireFieldWrapper<Maybe<UserProfileType>>
	/** Universeel unieke identificatie. */
	uuid: EntireFieldWrapper<Scalars["UUID"]["output"]>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short: EntireFieldWrapper<Scalars["String"]["output"]>
}

export type MessageConnectionType = {
	__typename?: "MessageConnectionType"
	results?: EntireFieldWrapper<Maybe<Array<Maybe<MessageType>>>>
	total_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type MessageType = {
	__typename?: "MessageType"
	attachments?: EntireFieldWrapper<Maybe<Scalars["JSONString"]["output"]>>
	html_body?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	message_events?: EntireFieldWrapper<Maybe<Scalars["JSONString"]["output"]>>
	message_id?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	received_at?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	subject?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	text_body?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type MessagesType = {
	__typename?: "MessagesType"
	detail?: EntireFieldWrapper<Maybe<MessageType>>
	list?: EntireFieldWrapper<Maybe<MessageConnectionType>>
}

export type MessagesTypeDetailArgs = {
	message_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type MessagesTypeListArgs = {
	count?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	subject?: InputMaybe<Scalars["String"]["input"]>
}

/**
 *
 *     Returns project monitoring status dictionary with 'status' and 'data' keys.
 *     Sensor needs to be online and has had recent data post.
 *
 *     STATUS:
 *     - If all the sensors are not ok - returns offline status.
 *     - If only part of sensors are offline (not ok) - then these sensors are appended to the result's "check list".
 *     - If some sensors report malformed data(see corresponding method) - these sensors are appended to the "check list".
 *     - If everything's OK, returns 2 ('Online')
 *     - If first solar interest period for the project hasn't started yet, returns 3
 *     - If project has no sensors at all, returns 4 (Project is being built)
 *
 *     DATA:
 *     - some additional info about this status (f. ex. malformed sensors types)
 *     - If one of the (same) sensors are malformed, the method adds a counter to
 *     the sensor short name, f. ex. ['pwr', 'pwr2', 'rad', 'rad2'] and etc.
 *
 */
export type MonitoringStatusStatsType = {
	__typename?: "MonitoringStatusStatsType"
	/** Malformed sensors type */
	data?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars["String"]["output"]>>>>
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	status?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type Mutations = {
	__typename?: "Mutations"
	investment_test_update?: EntireFieldWrapper<
		Maybe<InvestorTestMutationsPayload>
	>
	investor_update?: EntireFieldWrapper<Maybe<InvestorUpdatePayload>>
	organisation_update?: EntireFieldWrapper<Maybe<OrganisationUpdatePayload>>
	password_reset?: EntireFieldWrapper<Maybe<PasswordResetPayload>>
	profile_update?: EntireFieldWrapper<Maybe<ProfileUpdatePayload>>
	/** Mutations for sending solar scan information. */
	send_solar_scan_info?: EntireFieldWrapper<Maybe<SendSolarScanInfo>>
}

export type MutationsInvestment_Test_UpdateArgs = {
	input: InvestorTestMutationsInput
}

export type MutationsInvestor_UpdateArgs = {
	input: InvestorUpdateInput
}

export type MutationsOrganisation_UpdateArgs = {
	input: OrganisationUpdateInput
}

export type MutationsPassword_ResetArgs = {
	input: PasswordResetInput
}

export type MutationsProfile_UpdateArgs = {
	input: ProfileUpdateInput
}

export type MutationsSend_Solar_Scan_InfoArgs = {
	input: SolarScanInput
}

export type NearbyProjectListType = {
	__typename?: "NearbyProjectListType"
	/** Project list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<NearbyProjectType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type NearbyProjectListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

export type NearbyProjectType = {
	__typename?: "NearbyProjectType"
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** latitude */
	latitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** longitude */
	longitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Naam */
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type NotificationConfigurationSerializerInput = {
	disabled_yearly_interest_report_projects?: InputMaybe<
		Array<InputMaybe<Scalars["Int"]["input"]>>
	>
	receive_check_data?: InputMaybe<Scalars["Boolean"]["input"]>
	receive_monthly_report?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type NotificationConfigurationType = {
	__typename?: "NotificationConfigurationType"
	disabled_yearly_interest_report_projects?: EntireFieldWrapper<
		Maybe<Array<Maybe<ProjectForInvestorType>>>
	>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	receive_check_data?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	receive_monthly_report?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
}

/** An enumeration. */
export const OrganisationOrganisationTypeEnum = {
	/** Energieleverancier */
	EnergySupplier: "ENERGY_SUPPLIER",
	/** Installateur */
	Installer: "INSTALLER",
	/** Investeerder */
	Investor: "INVESTOR",
	/** Projecteigenaar */
	ProjectOwner: "PROJECT_OWNER",
} as const

export type OrganisationOrganisationTypeEnum =
	(typeof OrganisationOrganisationTypeEnum)[keyof typeof OrganisationOrganisationTypeEnum]
export type OrganisationType = {
	__typename?: "OrganisationType"
	/** BIC wordt automatisch gegenereerd bij opslaan, gebaseerd op IBAN. */
	bic?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Naam zoals inschrijving bij KVK */
	business_legal_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** KVK nummer */
	business_nr?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Organisatie e-mail adres */
	email?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** IBAN */
	iban?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	location?: EntireFieldWrapper<Maybe<LocationType>>
	/** Organisatie type */
	organisation_type?: EntireFieldWrapper<
		Maybe<OrganisationOrganisationTypeEnum>
	>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type OrganisationUpdateInput = {
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["String"]["input"]>
	location?: InputMaybe<LocationSerializerInput>
}

export type OrganisationUpdatePayload = {
	__typename?: "OrganisationUpdatePayload"
	clientMutationId?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** May contain more than one error for same field. */
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorType>>>>
	id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	location?: EntireFieldWrapper<Maybe<LocationType>>
}

export type OverallStatisticsType = {
	__typename?: "OverallStatisticsType"
	/** Average production per investor. */
	average_investor_production?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Average savings per investor. */
	average_investor_savings?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Average number of panels per investor, ignoring panels not associated with an investor. */
	average_solar_shares?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Appropriate resolution, as a month/day/hour string, for this timeframe. */
	by_time_resolution?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Capacity in watt = SUM of all projects (solar panels * capacity per panel) */
	capacity_in_watt?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Total CO2 saved by this production. */
	co2_saved?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Speed at which CO2 is being saved, based on the most recent measurement, per 250ms. Used for counters. */
	co2_saved_speed?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Total cost saved by this production. */
	cost_saved?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	cumulative_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	/** Average Dutch power usage in this timeframe. */
	dutch_power_usage?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Estimate Total production since the last measurement. */
	est_power_since_last_measurement?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	expected_cumulative_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	/** Gives total expected production in given time period */
	expected_production_by_time_period?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	expected_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	/**
	 * Expected production per panel for single project
	 *         return type: Decimal, units: kWh
	 *
	 */
	expected_production_per_panel?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Expected production per share for single project.
	 *         return type: Decimal, units: kWh
	 *
	 */
	expected_production_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total number of investors with panels in given projects. */
	investor_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** used for UI graph */
	max_production_in_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Total panels in given projects. */
	panel_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/**
	 * Total production with in the time period based on MeasurementDayView
	 *         Units: Watt
	 *
	 */
	production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	production_for_day?: EntireFieldWrapper<Maybe<StatsProductionForDay>>
	/**
	 * Total production with in the time period based on MeasurementDayView.
	 *         Units: kWh
	 *
	 */
	production_in_kwh?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	production_performance_ratio?: EntireFieldWrapper<
		Maybe<StatsPerformanceRatioData>
	>
	/**
	 * Ratio of panels owned by investors.
	 *         In some projects, some of the SolarShares are not associated with a client, having special handling outside
	 *         of Mijn Stroom.
	 *
	 */
	production_ratio_for_investors?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Speed at which power is being produced, based on the most recent measurement, per 250ms. Used for counters. */
	production_speed?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Human readable text of total production. */
	production_text?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Total shares in given projects. */
	shares_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Solar bonds volume(per all investors) */
	solar_bonds_volume?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Length of the timeframe in days. */
	timeframe_length?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/**
	 * Total production up to right now, i.e. including the estimated production since the last measurement.
	 *         The production since last measurement is important to make counters work intuitively: when the user loads the
	 *         page and the counter counts for a while, and they refresh, they should see roughly the same value.
	 *
	 */
	total_production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	total_production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	/**
	 * Total production up to right now, i.e. including the estimated production since the last measurement.
	 *         The production since last measurement is important to make counters work intuitively: when the user loads the
	 *         page and the counter counts for a while, and they refresh, they should see roughly the same value.
	 *
	 */
	total_production_for_counter?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Total power produced for project investors.
	 *         In some projects, some of the SolarShares are not associated with a client. The power produced by these special
	 *          panels is excluded in this property.
	 *
	 */
	total_production_for_investors?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * 'total_production_rough' per panel for single project.
	 *         return type: Decimal, units: kWh
	 *
	 */
	total_production_per_panel?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * 'total_production_rough' per share for single project.
	 *         return type: Decimal, units: kWh
	 *
	 */
	total_production_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Total production up to the latest measurement, i.e. not including the estimated production since the last
	 *         measurement. Lighter than total_production()
	 *
	 */
	total_production_rough?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Same method as total_production_rough(), but here user submitted time
	 *         value is ignored and therefore replaced with lowest possible time
	 *         values (00:00h and 23:59 h).
	 *         Data returned is in Watts.
	 *
	 */
	total_production_rough_full_day?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** 'total_production_rough' in kWhs */
	total_production_rough_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

export type OverallStatisticsTypeCumulative_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type OverallStatisticsTypeExpected_Cumulative_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type OverallStatisticsTypeExpected_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

export type OverallStatisticsTypeProduction_For_DayArgs = {
	day?: InputMaybe<Scalars["Date"]["input"]>
}

export type OverallStatisticsTypeProduction_Performance_RatioArgs = {
	grouped_by?: Scalars["String"]["input"]
	knmi_data?: Scalars["Boolean"]["input"]
}

export type OverallStatisticsTypeTotal_Production_DataArgs = {
	grouped_by?: Scalars["String"]["input"]
}

/** An enumeration. */
export const OwnerVerificationStateEnum = {
	/** Geaccepteerd */
	Accepted: "ACCEPTED",
	/** In afwachting */
	Pending: "PENDING",
	/** Afgewezen */
	Rejected: "REJECTED",
} as const

export type OwnerVerificationStateEnum =
	(typeof OwnerVerificationStateEnum)[keyof typeof OwnerVerificationStateEnum]
export type OwnerVerificationType = {
	__typename?: "OwnerVerificationType"
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** id */
	id?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Bericht */
	message?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Status */
	state?: EntireFieldWrapper<Maybe<OwnerVerificationStateEnum>>
}

/** An enumeration. */
export const PartnerPartnerTypeEnum = {
	/** Bank */
	Bank: "BANK",
	/** Energieleverancier */
	EnergySupplier: "ENERGY_SUPPLIER",
	/** Anders */
	Other: "OTHER",
} as const

export type PartnerPartnerTypeEnum =
	(typeof PartnerPartnerTypeEnum)[keyof typeof PartnerPartnerTypeEnum]
export type PartnerType = {
	__typename?: "PartnerType"
	/** Gemaakt op */
	created_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Verwijderd op */
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** Gewijzigd op */
	modified_at?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Naam */
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Typ */
	partner_type?: EntireFieldWrapper<Maybe<PartnerPartnerTypeEnum>>
	/** ProjectHorizon list */
	projects?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestmentCampaignProjectType>>>
	>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Korte versie van de universeel unieke identificatie. */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type PartnerTypeProjectsArgs = {
	category?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	owner?: InputMaybe<Scalars["ID"]["input"]>
	partners?: InputMaybe<Scalars["ID"]["input"]>
	project?: InputMaybe<Scalars["ID"]["input"]>
	title__icontains?: InputMaybe<Scalars["String"]["input"]>
}

export type PasswordResetInput = {
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>
	confirm_password: Scalars["String"]["input"]
	current_password: Scalars["String"]["input"]
	new_password: Scalars["String"]["input"]
}

export type PasswordResetPayload = {
	__typename?: "PasswordResetPayload"
	clientMutationId?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** May contain more than one error for same field. */
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorType>>>>
}

/**
 *
 *         Payment states are used to track the state of a payment entry. This is used to track whether a payment entry has been payed out.
 *
 */
export const PayOutState = {
	New: "new",
	PayoutCompleted: "payout_completed",
	PayoutFailed: "payout_failed",
	PayoutInitiated: "payout_initiated",
	TransferCompleted: "transfer_completed",
	TransferFailed: "transfer_failed",
	TransferInitiated: "transfer_initiated",
} as const

export type PayOutState = (typeof PayOutState)[keyof typeof PayOutState]
export type PaymentEntriesListType = {
	__typename?: "PaymentEntriesListType"
	/** PaymentEntry list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<PaymentEntryType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type PaymentEntriesListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

export type PaymentEntryType = {
	__typename?: "PaymentEntryType"
	/** Aflossing */
	amortization?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Zonne-rente */
	cost?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** energy supplier bonus */
	energy_supplier_bonus?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** IBAN nummer */
	iban?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	interest_period?: EntireFieldWrapper<Maybe<InterestPeriodType>>
	investor_email?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** investor payout */
	investor_payout?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	investor_phone_number?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Naam */
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Huis Nr. */
	number?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	payment_state?: EntireFieldWrapper<Maybe<State>>
	/** Postcode */
	postcode?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** kWh opgewekt */
	production?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	project?: EntireFieldWrapper<Maybe<ProjectType>>
	/** Afrekening */
	repayment?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** # zonne-delen */
	share_count?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	state?: EntireFieldWrapper<Maybe<PayOutState>>
	/** Huis Nr. Toevoeging */
	suffix?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	supplier?: EntireFieldWrapper<Maybe<EnergySupplierType>>
	/** klant nummer */
	supplier_account?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** supplier payout */
	supplier_payout?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	transaction_id?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

/** An enumeration. */
export const PaymentInterestPerShareLimitAppliedEnum = {
	/** CAP */
	Cap: "CAP",
	/** FLOOR */
	Floor: "FLOOR",
} as const

export type PaymentInterestPerShareLimitAppliedEnum =
	(typeof PaymentInterestPerShareLimitAppliedEnum)[keyof typeof PaymentInterestPerShareLimitAppliedEnum]
/** An enumeration. */
export const PaymentPaymentServiceProviderEnum = {
	/** Buckaroo */
	Buckaroo: "BUCKAROO",
	/** Mangopay */
	Mangopay: "MANGOPAY",
} as const

export type PaymentPaymentServiceProviderEnum =
	(typeof PaymentPaymentServiceProviderEnum)[keyof typeof PaymentPaymentServiceProviderEnum]
/** An enumeration. */
export const PaymentStateEnum = {
	/** Gedaan */
	Done: "DONE",
	/** Nieuw */
	New: "NEW",
	/** In afwachting van goedkeuring door zpd */
	PendingApprovalByZpd: "PENDING_APPROVAL_BY_ZPD",
	/** Werknemer in behandeling */
	PendingEmployee: "PENDING_EMPLOYEE",
	/** Doorgaan naar betalen */
	PendingInvestorPayment: "PENDING_INVESTOR_PAYMENT",
	/** Eigenaar in behandeling */
	PendingOwner: "PENDING_OWNER",
	/** Inkomende betaling */
	PendingOwnerPayment: "PENDING_OWNER_PAYMENT",
	/** Leverancier in behandeling */
	PendingSupplier: "PENDING_SUPPLIER",
	/** Geverifieerd */
	Verified: "VERIFIED",
} as const

export type PaymentStateEnum =
	(typeof PaymentStateEnum)[keyof typeof PaymentStateEnum]
/** An enumeration. */
export const PaymentsFinancialTransactionFeeAmountCurrencyChoices = {
	/** Euro */
	Eur: "EUR",
} as const

export type PaymentsFinancialTransactionFeeAmountCurrencyChoices =
	(typeof PaymentsFinancialTransactionFeeAmountCurrencyChoices)[keyof typeof PaymentsFinancialTransactionFeeAmountCurrencyChoices]
/** An enumeration. */
export const PaymentsFinancialTransactionPaymentAmountCurrencyChoices = {
	/** Euro */
	Eur: "EUR",
} as const

export type PaymentsFinancialTransactionPaymentAmountCurrencyChoices =
	(typeof PaymentsFinancialTransactionPaymentAmountCurrencyChoices)[keyof typeof PaymentsFinancialTransactionPaymentAmountCurrencyChoices]
/** An enumeration. */
export const PaymentsFinancialTransactionPaymentTypeChoices = {
	/** Bankoverschrijving */
	BankWire: "BANK_WIRE",
	/** iDeal betaling */
	IdealPayment: "IDEAL_PAYMENT",
} as const

export type PaymentsFinancialTransactionPaymentTypeChoices =
	(typeof PaymentsFinancialTransactionPaymentTypeChoices)[keyof typeof PaymentsFinancialTransactionPaymentTypeChoices]
/** An enumeration. */
export const PaymentsFinancialTransactionStateChoices = {
	/** Geannuleerd door klant */
	CancelledByCustomer: "CANCELLED_BY_CUSTOMER",
	/** Verlopen */
	Expired: "EXPIRED",
	/** Mislukt */
	Failed: "FAILED",
	/** Geïnitieerd  */
	Initialized: "INITIALIZED",
	/** Handmatige interventie */
	ManualIntervention: "MANUAL_INTERVENTION",
	/** Partiële refund compleet */
	PartialRefundCompleted: "PARTIAL_REFUND_COMPLETED",
	/** Partiële refund mislukt */
	PartialRefundFailed: "PARTIAL_REFUND_FAILED",
	/** Partiële refund verwerken */
	PartialRefundProcessing: "PARTIAL_REFUND_PROCESSING",
	/** In afwachting */
	Pending: "PENDING",
	/** Verwerken */
	Processing: "PROCESSING",
	/** PSP wacht op gebruiker */
	PspWaitingConsumer: "PSP_WAITING_CONSUMER",
	/** Refund voltooid */
	RefundCompleted: "REFUND_COMPLETED",
	/** Refund mislukt */
	RefundFailed: "REFUND_FAILED",
	/** Refund verwerken */
	RefundProcessing: "REFUND_PROCESSING",
	/** Afgewezen */
	Rejected: "REJECTED",
	/** Succes */
	Succeeded: "SUCCEEDED",
} as const

export type PaymentsFinancialTransactionStateChoices =
	(typeof PaymentsFinancialTransactionStateChoices)[keyof typeof PaymentsFinancialTransactionStateChoices]
/** An enumeration. */
export const PaymentsFinancialTransactionTransactionTypeChoices = {
	/** Inkomende betaling energieleverancier */
	EnergySupplierPayIn: "ENERGY_SUPPLIER_PAY_IN",
	/** Uitbetaling energieleverancier */
	EnergySupplierPayOut: "ENERGY_SUPPLIER_PAY_OUT",
	/** Inkomende betaling investeerder */
	InvestorPayIn: "INVESTOR_PAY_IN",
	/** Uitbetaling investeerder */
	InvestorPayOut: "INVESTOR_PAY_OUT",
	/** Inkomende betaling projecteigenaar */
	ProjectOwnerPayIn: "PROJECT_OWNER_PAY_IN",
	/** Uitbetaling projecteigenaar */
	ProjectOwnerPayOut: "PROJECT_OWNER_PAY_OUT",
	/** Overdracht van Project Wallet naar Energieleverancier Wallet */
	ProjectOwnerToEnergySupplierTransfer:
		"PROJECT_OWNER_TO_ENERGY_SUPPLIER_TRANSFER",
	/** Transactie van projecteigenaar naar investeerder */
	ProjectOwnerToInvestorTransfer: "PROJECT_OWNER_TO_INVESTOR_TRANSFER",
	/** Overdracht van Voucher Wallet naar Project Wallet */
	VoucherToProjectTransfer: "VOUCHER_TO_PROJECT_TRANSFER",
} as const

export type PaymentsFinancialTransactionTransactionTypeChoices =
	(typeof PaymentsFinancialTransactionTransactionTypeChoices)[keyof typeof PaymentsFinancialTransactionTransactionTypeChoices]
export type PerformanceInput = {
	number_of_solar_panels: Scalars["Int"]["input"]
	power_per_panel: Scalars["Float"]["input"]
	total_power: Scalars["Float"]["input"]
}

export type PersonContactInput = {
	email: Scalars["String"]["input"]
	name: Scalars["String"]["input"]
}

export type PlatformStatisticsType = {
	__typename?: "PlatformStatisticsType"
	avg_investment_per_investor?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	capacity_in_mwp?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	max_investment?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	percentage_of_project_wise_repeated_investors?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	project_partners?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	saved_co2?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	solar_bonds_volume?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	total_amount_invested?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	total_expected_production_in_gwh?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	total_investors?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	total_production_in_gwh?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	total_production_in_households_usage?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	total_production_in_households_usage_per_year?: EntireFieldWrapper<
		Maybe<Array<Maybe<TotalProductionInHouseholdsUsagePerYearType>>>
	>
	total_projects?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	total_solar_panels?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type ProductionInput = {
	end_date: Scalars["Date"]["input"]
	start_date: Scalars["Date"]["input"]
	total_production: Scalars["Float"]["input"]
}

export type ProfileUpdateInput = {
	address?: InputMaybe<LocationSerializerInput>
	clientMutationId?: InputMaybe<Scalars["String"]["input"]>
	date_of_birth?: InputMaybe<Scalars["Date"]["input"]>
	initials?: InputMaybe<Scalars["String"]["input"]>
	language?: InputMaybe<Language>
	nationality?: InputMaybe<Nationality>
	phone?: InputMaybe<Scalars["String"]["input"]>
}

export type ProfileUpdatePayload = {
	__typename?: "ProfileUpdatePayload"
	address?: EntireFieldWrapper<Maybe<LocationType>>
	clientMutationId?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	date_of_birth?: EntireFieldWrapper<Maybe<Scalars["Date"]["output"]>>
	/** May contain more than one error for same field. */
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorType>>>>
	initials?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	language?: EntireFieldWrapper<Maybe<Language>>
	nationality?: EntireFieldWrapper<Maybe<Nationality>>
	phone?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

/** An enumeration. */
export const ProjectAmortizationScheduleEnum = {
	/** Bullet */
	Bullet: "BULLET",
	/** Custom */
	Custom: "CUSTOM",
	/** Linear */
	Linear: "LINEAR",
} as const

export type ProjectAmortizationScheduleEnum =
	(typeof ProjectAmortizationScheduleEnum)[keyof typeof ProjectAmortizationScheduleEnum]
/** An enumeration. */
export const ProjectCountryEnum = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type ProjectCountryEnum =
	(typeof ProjectCountryEnum)[keyof typeof ProjectCountryEnum]
/** An enumeration. */
export const ProjectFixedInterestAmountPerShareCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type ProjectFixedInterestAmountPerShareCurrencyEnum =
	(typeof ProjectFixedInterestAmountPerShareCurrencyEnum)[keyof typeof ProjectFixedInterestAmountPerShareCurrencyEnum]
export type ProjectForInvestorType = {
	__typename?: "ProjectForInvestorType"
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	name: EntireFieldWrapper<Scalars["String"]["output"]>
}

/** An enumeration. */
export const ProjectHorizonAmountTotalCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type ProjectHorizonAmountTotalCurrencyEnum =
	(typeof ProjectHorizonAmountTotalCurrencyEnum)[keyof typeof ProjectHorizonAmountTotalCurrencyEnum]
/** An enumeration. */
export const ProjectHorizonCategoryEnum = {
	/** Pro */
	Pro: "PRO",
	/** Solar */
	Solar: "SOLAR",
} as const

export type ProjectHorizonCategoryEnum =
	(typeof ProjectHorizonCategoryEnum)[keyof typeof ProjectHorizonCategoryEnum]
/** An enumeration. */
export const ProjectHorizonMaximumAmountTotalCurrencyEnum = {
	/** Euro */
	Eur: "EUR",
} as const

export type ProjectHorizonMaximumAmountTotalCurrencyEnum =
	(typeof ProjectHorizonMaximumAmountTotalCurrencyEnum)[keyof typeof ProjectHorizonMaximumAmountTotalCurrencyEnum]
/** An enumeration. */
export const ProjectInterestPaymentCalculationTypeEnum = {
	/** Endex + vaste toeslag */
	EndexEnergyCost: "ENDEX_ENERGY_COST",
	/** Alleen vaste kosten */
	FixedEnergyCost: "FIXED_ENERGY_COST",
	/** Fixed interest amount */
	FixedInterestAmount: "FIXED_INTEREST_AMOUNT",
	/** Fixed interest rate */
	FixedInterestRate: "FIXED_INTEREST_RATE",
} as const

export type ProjectInterestPaymentCalculationTypeEnum =
	(typeof ProjectInterestPaymentCalculationTypeEnum)[keyof typeof ProjectInterestPaymentCalculationTypeEnum]
export type ProjectListType = {
	__typename?: "ProjectListType"
	/** Project list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<ProjectType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type ProjectListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

/**
 *
 *     Quick overview of Project performance.
 *
 */
export type ProjectOverviewStatsType = {
	__typename?: "ProjectOverviewStatsType"
	monitoring_status?: EntireFieldWrapper<Maybe<MonitoringStatusStatsType>>
	performance_ratio?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	pk?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	production_delta?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	targeted_performance_ratio?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	total_production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	yearly_production_factor?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

export type ProjectOwnerType = {
	__typename?: "ProjectOwnerType"
	email?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	logo?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	name: EntireFieldWrapper<Scalars["String"]["output"]>
}

/** An enumeration. */
export const ProjectPaymentPeriodDurationFormatEnum = {
	/** Dagen */
	Days: "DAYS",
	/** Maanden */
	Months: "MONTHS",
	/** Weken */
	Weeks: "WEEKS",
	/** Jaren */
	Years: "YEARS",
} as const

export type ProjectPaymentPeriodDurationFormatEnum =
	(typeof ProjectPaymentPeriodDurationFormatEnum)[keyof typeof ProjectPaymentPeriodDurationFormatEnum]
/** An enumeration. */
export const ProjectProjectObligationsStatusEnum = {
	/** Ja */
	Fulfilled: "FULFILLED",
	/** Nee */
	NotFulfilled: "NOT_FULFILLED",
	/** Afbetaald */
	PaidOff: "PAID_OFF",
} as const

export type ProjectProjectObligationsStatusEnum =
	(typeof ProjectProjectObligationsStatusEnum)[keyof typeof ProjectProjectObligationsStatusEnum]
/** An enumeration. */
export const ProjectStateEnum = {
	/** Being installed */
	BeingInstalled: "BEING_INSTALLED",
	/** Directly financed */
	DirectlyFinanced: "DIRECTLY_FINANCED",
	/** Vaste rente */
	FixedInterest: "FIXED_INTEREST",
	/** Investment allocation */
	InvestmentAllocation: "INVESTMENT_ALLOCATION",
	/** In financiering */
	InFunding: "IN_FUNDING",
	/** In productie */
	InProduction: "IN_PRODUCTION",
	/** Monitoring offline */
	MonitoringOffline: "MONITORING_OFFLINE",
	/** Geen productie */
	NoProduction: "NO_PRODUCTION",
	/** Afbetaald */
	PaidOff: "PAID_OFF",
} as const

export type ProjectStateEnum =
	(typeof ProjectStateEnum)[keyof typeof ProjectStateEnum]
export type ProjectStatisticsType = {
	__typename?: "ProjectStatisticsType"
	/** Gives delta of current energy price and expected energy price in the current interst period */
	delta_energy_price?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/** Percentage of total production to total expected production */
	delta_production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/**
	 * Gives expected Internal Rate of Return for project,
	 *         Internal Rate of Return (IRR) = total return on the investment after completion of all interest periods
	 *
	 *         for reference see the following excel sheet:
	 *         https://www.dropbox.com/s/b09zrtidbv70ca0/project%20stats%20overview.xlsx?dl=0
	 *
	 */
	expected_internal_rate_of_return?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/**
	 * Gives Internal Rate of Return for project,
	 *         Internal Rate of Return (IRR) = total return on the investment after completion of all interest periods
	 *
	 *         for reference see the following excel sheet:
	 *         https://www.dropbox.com/s/b09zrtidbv70ca0/project%20stats%20overview.xlsx?dl=0
	 *
	 */
	internal_rate_of_return?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	solar_interest_chart_data?: EntireFieldWrapper<
		Maybe<SolarInterestChartDataType>
	>
	stats_information_by_interest_period?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsInformationByInterestPeriodType>>>
	>
}

export type ProjectType = {
	__typename?: "ProjectType"
	/** Dit is ter referentie. Het wordt niet gebruikt in berekeningen. */
	actual_start_production?: EntireFieldWrapper<
		Maybe<Scalars["CustomDate"]["output"]>
	>
	/** AFM document (PDF) */
	afm_document?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Amortisatieschema */
	amortization_schedule?: EntireFieldWrapper<
		Maybe<ProjectAmortizationScheduleEnum>
	>
	/** CAP voor rente per aandeel (Euro) */
	cap_eur_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Variabele (in kWh per vierkante meter) gebruikt voor het berekenen van de prestatieratio van het project */
	cemm_correction_factor?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Woonplaats */
	city?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Na het invoeren van de ingebruikname datum, worden de onderhouds- en schoonmaakmomenten automatisch aangemaakt. */
	commissioning_date?: EntireFieldWrapper<
		Maybe<Scalars["CustomDate"]["output"]>
	>
	/** Land */
	country?: EntireFieldWrapper<Maybe<ProjectCountryEnum>>
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	current_interest_period?: EntireFieldWrapper<Maybe<InterestPeriodType>>
	documents?: EntireFieldWrapper<
		Maybe<Array<Maybe<InvestmentCampaignDocument>>>
	>
	/** Duration of the project in years */
	duration?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Installatie EAN Code */
	ean_code?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Project einde datum */
	end?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** Verwachte energieprijs (in eurocent per kWh) gebruikt in het Informatie Memorandum. */
	estimated_energy_tariff?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** In % (percentage), de verwachte jaarlijkse lineaire degradatiefactor. Standaard is dit 0,5% */
	expected_degradation?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Verwachte internal rate of return voor afbetaalde/voltooide projecten. Het kan leeg zijn voor andere projecten */
	expected_irr?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Verwachte startdatum productie */
	expected_start_production?: EntireFieldWrapper<
		Maybe<Scalars["CustomDate"]["output"]>
	>
	fiscal_overview?: EntireFieldWrapper<
		Maybe<Array<Maybe<FiscalOverviewType>>>
	>
	/** Energieprijs (in eurocent per kWh) voor elke zonne-renteperiode. Wanneer dit is ingevuld, wordt Endex-data niet gebruikt. */
	fixed_energy_cost_per_kwh?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Fixed interest amount per share for the interest periods payments. */
	fixed_interest_amount_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** fixed interest amount per share currency */
	fixed_interest_amount_per_share_currency?: EntireFieldWrapper<
		Maybe<ProjectFixedInterestAmountPerShareCurrencyEnum>
	>
	/** Fixed interest rate(%) for the interest periods payments. */
	fixed_interest_rate?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Variabele (in euro per mWh) gebruikt voor het berekenen van de prijs per kWh voor de Solar renteperiode. Opgeteld met Endex prijs. */
	fixed_surcharge?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** FLOOR voor rente per aandeel (Euro) */
	floor_eur_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	id?: EntireFieldWrapper<Maybe<Scalars["ID"]["output"]>>
	image_url?: EntireFieldWrapper<Maybe<Scalars["FileField"]["output"]>>
	installer?: EntireFieldWrapper<Maybe<InstallerType>>
	/** How to calculate interest for the interest periods payments? */
	interest_payment_calculation_type?: EntireFieldWrapper<
		Maybe<ProjectInterestPaymentCalculationTypeEnum>
	>
	interest_periods?: EntireFieldWrapper<
		Maybe<Array<Maybe<InterestPeriodType>>>
	>
	investor_production_stats?: EntireFieldWrapper<
		Maybe<InvestorProductionStatsType>
	>
	investor_shares_value_stats?: EntireFieldWrapper<
		Maybe<InvestorSharesValueStatsType>
	>
	investor_yearly_report_notification_status?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	/** Het inschakelen van deze optie verwijdert het project uit elke query in Django. Gebruik dit in plaats van het te verwijderen. */
	is_deleted?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Als dit is aangevinkt, wordt dit project niet weergegeven in het projectoverzicht (API). */
	is_hidden?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Dit veld is alleen verplicht voor Solar Bonds. */
	issuance_date_in_information_memorandum?: EntireFieldWrapper<
		Maybe<Scalars["CustomDate"]["output"]>
	>
	/** Variabele (in kWh per vierkante meter) gebruikt voor het berekenen van de prestatieratio van het project */
	knmi_correction_factor?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** latitude */
	latitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** longitude */
	longitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** (DEPRECATED) in kWh | Is an old calculation for dashboard. */
	max_power?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** in Watt */
	max_watt_panel?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Naam */
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	notificationconfiguration?: EntireFieldWrapper<
		Maybe<Array<NotificationConfigurationType>>
	>
	/** Huis Nr. */
	number?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Specific project stats */
	overall_statistics?: EntireFieldWrapper<Maybe<OverallStatisticsType>>
	overview?: EntireFieldWrapper<Maybe<ProjectOverviewStatsType>>
	owner?: EntireFieldWrapper<Maybe<ProjectOwnerType>>
	/** Vul dit in als het project is afbetaald. De berekening van de ZonneDelen zal hierdoor worden beïnvloed. */
	paid_off_on?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** Hoeveel dagen na het einde van de renteperiode is de uitbetaling gepland? */
	payment_period_deadline_days_default?: EntireFieldWrapper<
		Maybe<Scalars["Int"]["output"]>
	>
	/** Gebruikt alleen voor de eerste renteperiode. Laat leeg als de deadline standaard moet zijn. */
	payment_period_deadline_days_first_period_delay?: EntireFieldWrapper<
		Maybe<Scalars["Int"]["output"]>
	>
	/** Renteperiode duur */
	payment_period_duration?: EntireFieldWrapper<
		Maybe<Scalars["Int"]["output"]>
	>
	/** Gebruik altijd de grootste interval (1 jaar, geen 365 dagen, etc.) */
	payment_period_duration_format?: EntireFieldWrapper<
		Maybe<ProjectPaymentPeriodDurationFormatEnum>
	>
	/** pdf brochure */
	pdf_brochure?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	pdf_memorandum?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	pdf_service_agreement?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** Postcode */
	postcode?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Uitleg over meer projectdetails of als het project problemen had met betalingen of productie of om andere redenen */
	project_obligations_explanation_text?: EntireFieldWrapper<
		Maybe<Scalars["String"]["output"]>
	>
	/** project obligations status */
	project_obligations_status?: EntireFieldWrapper<
		Maybe<ProjectProjectObligationsStatusEnum>
	>
	/** Aantal ZonneDelen per zonnepaneel. */
	shares_per_panel?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Deze factor is afgeleid van de prestatieratio (PR = standaard 81%) minus 4%. Standaard is dit 0,81-0,04 = 0,77. Als de PR op basis van PV-sol of PV-syst hoger is dan 81, kan deze waarde worden aangepast. */
	solar_derate_factor?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	solardistribution?: EntireFieldWrapper<Maybe<Array<SolarDistributionType>>>
	/** Project startdatum */
	start?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	/** state */
	state?: EntireFieldWrapper<Maybe<ProjectStateEnum>>
	/** Straatnaam */
	street?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Huisnummer toevoeging */
	suffix?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	supplier?: EntireFieldWrapper<Maybe<EnergySupplierType>>
	/** Gerichte prestatieratio in % */
	targeted_performance_ratio?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** Het totale bedrag (in euro's) van de Solar Bond Loan zoals vermeld in het Informatie Memorandum. */
	total_amount?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** in Kilowatt */
	total_inverter_power?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Totaal aantal zonnepanelen */
	total_panels?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** Totale ZonneDelen uitgegeven */
	total_shares?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
	/** type */
	type?: EntireFieldWrapper<Maybe<ProjectTypeEnum>>
	/** Universeel unieke identificatie. */
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** uuid short */
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** in kWh */
	yearly_production?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type ProjectTypeInvestor_Production_StatsArgs = {
	end?: InputMaybe<Scalars["DateTime"]["input"]>
	start?: InputMaybe<Scalars["DateTime"]["input"]>
}

export type ProjectTypeInvestor_Shares_Value_StatsArgs = {
	valuation_date?: InputMaybe<Scalars["Date"]["input"]>
}

export type ProjectTypeOverall_StatisticsArgs = {
	end?: InputMaybe<Scalars["DateTime"]["input"]>
	start?: InputMaybe<Scalars["DateTime"]["input"]>
}

/** An enumeration. */
export const ProjectTypeEnum = {
	/** Portfolio */
	Portfolio: "PORTFOLIO",
	/** Solar bonds */
	SolarBonds: "SOLAR_BONDS",
	/** Solar funds */
	SolarFunds: "SOLAR_FUNDS",
	/** Solar lease */
	SolarLease: "SOLAR_LEASE",
	/** Solar shares */
	SolarShares: "SOLAR_SHARES",
} as const

export type ProjectTypeEnum =
	(typeof ProjectTypeEnum)[keyof typeof ProjectTypeEnum]
export type PublicProjectListType = {
	__typename?: "PublicProjectListType"
	/** Project list, paginated by LimitOffsetPaginator */
	results?: EntireFieldWrapper<Maybe<Array<Maybe<PublicProjectType>>>>
	/** Total count of matches elements */
	totalCount?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type PublicProjectListTypeResultsArgs = {
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

export type PublicProjectType = {
	__typename?: "PublicProjectType"
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	internal_rate_of_return?: EntireFieldWrapper<
		Maybe<Scalars["Float"]["output"]>
	>
	/** latitude */
	latitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** longitude */
	longitude?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Naam */
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** project obligations status */
	project_obligations_status?: EntireFieldWrapper<
		Maybe<ProjectProjectObligationsStatusEnum>
	>
	/** Project startdatum */
	start?: EntireFieldWrapper<Maybe<Scalars["CustomDate"]["output"]>>
	type?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type Query = {
	__typename?: "Query"
	debug?: EntireFieldWrapper<Maybe<DjangoDebug>>
	/** Energy Suppliers */
	energy_suppliers?: EntireFieldWrapper<Maybe<Array<EnergySupplierType>>>
	/** Interest Payments */
	interest_payments?: EntireFieldWrapper<Maybe<InterestPaymentListType>>
	/** Investment campaign project */
	investment_campaign_project?: EntireFieldWrapper<
		Maybe<InvestmentCampaignProjectType>
	>
	/** Investment campaign projects */
	investment_campaign_projects?: EntireFieldWrapper<
		Maybe<Array<InvestmentCampaignProjectType>>
	>
	me?: EntireFieldWrapper<Maybe<UserType>>
	/** Zonhub overall project stats */
	overall_statistics?: EntireFieldWrapper<Maybe<OverallStatisticsType>>
	platform_statistics?: EntireFieldWrapper<Maybe<PlatformStatisticsType>>
	/** Zonhub Project */
	project?: EntireFieldWrapper<Maybe<ProjectType>>
	/** Zonhub project stats */
	project_statistics?: EntireFieldWrapper<Maybe<ProjectStatisticsType>>
	/** Zonhub Projects */
	projects?: EntireFieldWrapper<Maybe<Array<ProjectType>>>
	/**
	 * Projects located nearby a specific location.
	 * @deprecated Deprecated use `public_projects`
	 */
	projects_with_location?: EntireFieldWrapper<Maybe<NearbyProjectListType>>
	/** Projects located nearby a specific location. */
	public_projects?: EntireFieldWrapper<Maybe<PublicProjectListType>>
}

export type QueryEnergy_SuppliersArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	is_partner?: InputMaybe<Scalars["Boolean"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryInterest_PaymentsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	interestperiod__project?: InputMaybe<Scalars["ID"]["input"]>
	interestperiod__project__name__icontains?: InputMaybe<
		Scalars["String"]["input"]
	>
	q?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type QueryInvestment_Campaign_ProjectArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryInvestment_Campaign_ProjectsArgs = {
	category?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	owner?: InputMaybe<Scalars["ID"]["input"]>
	partners?: InputMaybe<Scalars["ID"]["input"]>
	project?: InputMaybe<Scalars["ID"]["input"]>
	title__icontains?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryOverall_StatisticsArgs = {
	end: Scalars["DateTime"]["input"]
	id?: InputMaybe<Scalars["ID"]["input"]>
	start: Scalars["DateTime"]["input"]
}

export type QueryProjectArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryProject_StatisticsArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryProjectsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	supplier__name__icontains?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryProjects_With_LocationArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	latitude: Scalars["Decimal"]["input"]
	longitude: Scalars["Decimal"]["input"]
	radius?: InputMaybe<Scalars["Decimal"]["input"]>
}

export type QueryPublic_ProjectsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	latitude: Scalars["Decimal"]["input"]
	longitude: Scalars["Decimal"]["input"]
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	radius?: InputMaybe<Scalars["Decimal"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	supplier__name__icontains?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

/** An enumeration. */
export const RiskKycStateChoices = {
	/** Handmatig proces */
	ManualProcess: "MANUAL_PROCESS",
	/** PSP Verificatie in behandeling */
	PspVerificationPending: "PSP_VERIFICATION_PENDING",
	/** Uninitiated */
	Uninitiated: "UNINITIATED",
	/** Verificatie mislukt */
	VerificationFailed: "VERIFICATION_FAILED",
	/** Verification Invalid */
	VerificationInvalid: "VERIFICATION_INVALID",
	/** Interne Verificatie in behandeling */
	VerificationPending: "VERIFICATION_PENDING",
	/** Verificatie aangevraagd */
	VerificationRequested: "VERIFICATION_REQUESTED",
	/** Verificatie geslaagd */
	VerificationSuccess: "VERIFICATION_SUCCESS",
} as const

export type RiskKycStateChoices =
	(typeof RiskKycStateChoices)[keyof typeof RiskKycStateChoices]
/** Different Roles a user can have in the system */
export const Role = {
	Admin: "ADMIN",
	EnergySupplier: "ENERGY_SUPPLIER",
	Installer: "INSTALLER",
	Investor: "INVESTOR",
	ProjectOwner: "PROJECT_OWNER",
} as const

export type Role = (typeof Role)[keyof typeof Role]
/** we are using this mutation without authentication */
export type SendSolarScanInfo = {
	__typename?: "SendSolarScanInfo"
	errors?: EntireFieldWrapper<Maybe<Array<Maybe<ErrorDictType>>>>
	success?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
}

export const SharingTypeChoiceEnum = {
	Connected: "CONNECTED",
	Manual: "MANUAL",
} as const

export type SharingTypeChoiceEnum =
	(typeof SharingTypeChoiceEnum)[keyof typeof SharingTypeChoiceEnum]
/** An enumeration. */
export const SolarDistributionMonthEnum = {
	/** januari */
	A_1: "A_1",
	/** februari */
	A_2: "A_2",
	/** maart */
	A_3: "A_3",
	/** april */
	A_4: "A_4",
	/** mei */
	A_5: "A_5",
	/** juni */
	A_6: "A_6",
	/** juli */
	A_7: "A_7",
	/** augustus */
	A_8: "A_8",
	/** september */
	A_9: "A_9",
	/** oktober */
	A_10: "A_10",
	/** november */
	A_11: "A_11",
	/** december */
	A_12: "A_12",
} as const

export type SolarDistributionMonthEnum =
	(typeof SolarDistributionMonthEnum)[keyof typeof SolarDistributionMonthEnum]
export type SolarDistributionType = {
	__typename?: "SolarDistributionType"
	/** Distribution */
	distribution?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** Maand */
	month?: EntireFieldWrapper<Maybe<SolarDistributionMonthEnum>>
}

export type SolarInterestChartDataType = {
	__typename?: "SolarInterestChartDataType"
	expected_roi_of_interest_periods?: EntireFieldWrapper<
		Maybe<Array<Maybe<Scalars["Float"]["output"]>>>
	>
	interest_period_numbers?: EntireFieldWrapper<
		Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
	>
	roi_of_interest_periods?: EntireFieldWrapper<
		Maybe<Array<Maybe<Scalars["Float"]["output"]>>>
	>
}

export type SolarScanInput = {
	address: AddressInput
	connection?: InputMaybe<ConnectionInput>
	contact: PersonContactInput
	layout: Array<InputMaybe<LayoutInput>>
	performance: PerformanceInput
	production?: InputMaybe<ProductionInput>
	sharing_type: SharingTypeChoiceEnum
}

export type SolarShareType = {
	__typename?: "SolarShareType"
	/** Verworven op */
	acquired?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	collective_deposit?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Django object unique identification field */
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	/** Investeerder */
	investor?: EntireFieldWrapper<Maybe<InvestorType>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Nominale waarde */
	nominal_value?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	/** PaymentEntry list */
	paymententry?: EntireFieldWrapper<Maybe<Array<Maybe<PaymentEntryType>>>>
	/** PDF */
	pdf?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** ZonneDeel Nummer */
	share_number?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type SolarShareTypePaymententryArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	partner_supplier_only?: InputMaybe<Scalars["Boolean"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
	transaction_id?: InputMaybe<Scalars["String"]["input"]>
}

/** An enumeration. */
export const State = {
	Done: "DONE",
	New: "NEW",
	PendingApprovalByZpd: "PENDING_APPROVAL_BY_ZPD",
	PendingEmployee: "PENDING_EMPLOYEE",
	PendingInvestorPayment: "PENDING_INVESTOR_PAYMENT",
	PendingOwner: "PENDING_OWNER",
	PendingOwnerPayment: "PENDING_OWNER_PAYMENT",
	PendingSupplier: "PENDING_SUPPLIER",
	Verified: "VERIFIED",
} as const

export type State = (typeof State)[keyof typeof State]
export type StatsInformationByInterestPeriodType = {
	__typename?: "StatsInformationByInterestPeriodType"
	/**
	 * Gives energy price for given interest period in euros.
	 *         For future interest periods, energy price is ongoing interest period energy price
	 *
	 */
	energy_price?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	expected_energy_price?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	expected_payment_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	expected_production_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	expected_return_on_investment?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	expected_solar_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	is_completed_interest_period?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	is_current_interest_period?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	payment_per_share?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
	payment_status?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	production_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * Return on investment (in %) for Project given period, considering amortized value of the shares.
	 *
	 *                  solar interest for per 1 investor per 1 share
	 *         ROI =   ----------------------------------------------- * 100
	 *                     amortized value for 1 share
	 *
	 *         Note, ROI percentage does not depend on count of investor's shares.
	 *         ROI percentage is equal for all investors within time range of the same interest period.
	 *
	 */
	return_on_investment?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/**
	 * cache the return value of a method
	 *
	 *     This class is meant to be used as a decorator of methods. The return value
	 *     from a given method invocation will be cached on the instance whose method
	 *     was invoked. All arguments passed to a method decorated with memoize must
	 *     be hashable.
	 *
	 *     If a memoized method is invoked directly on its class the result will not
	 *     be cached. Instead the method will be invoked like a static method:
	 *     class Obj:
	 *         @memoize
	 *         def add_to(self, arg):
	 *             return self + arg
	 *     Obj.add_to(1) # not enough arguments
	 *     Obj.add_to(1, 2) # returns 3, result is not cached
	 *
	 */
	share_value_after_interest_period_end?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
	/** Solar interest should be in between cap(max) and floor(min) limits */
	solar_interest_per_share?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

export type StatsPerformanceRatioData = {
	__typename?: "StatsPerformanceRatioData"
	performance_ratio_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsPerformanceRatioGeneric>>>
	>
	total_performance_ratio?: EntireFieldWrapper<
		Maybe<Scalars["Decimal"]["output"]>
	>
}

export type StatsPerformanceRatioGeneric = {
	__typename?: "StatsPerformanceRatioGeneric"
	date?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	performance_ratio?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

export type StatsProductionForDay = {
	__typename?: "StatsProductionForDay"
	production_data?: EntireFieldWrapper<
		Maybe<Array<Maybe<StatsProductionGeneric>>>
	>
	total_production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

export type StatsProductionGeneric = {
	__typename?: "StatsProductionGeneric"
	date?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	production?: EntireFieldWrapper<Maybe<Scalars["Decimal"]["output"]>>
}

/** An enumeration. */
export const SupplierVerificationStateEnum = {
	/** Geaccepteerd */
	Accepted: "ACCEPTED",
	/** In afwachting */
	Pending: "PENDING",
	/** Afgewezen */
	Rejected: "REJECTED",
} as const

export type SupplierVerificationStateEnum =
	(typeof SupplierVerificationStateEnum)[keyof typeof SupplierVerificationStateEnum]
export type SupplierVerificationType = {
	__typename?: "SupplierVerificationType"
	/** Gemaakt op */
	created?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** id */
	id?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	/** Bericht */
	message?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Gewijzigd op */
	modified?: EntireFieldWrapper<Maybe<Scalars["CustomDateTime"]["output"]>>
	/** Opdrachtnummer */
	reference?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	/** Status */
	state?: EntireFieldWrapper<Maybe<SupplierVerificationStateEnum>>
}

export type TotalProductionInHouseholdsUsagePerYearType = {
	__typename?: "TotalProductionInHouseholdsUsagePerYearType"
	production?: EntireFieldWrapper<Maybe<Scalars["Float"]["output"]>>
	year?: EntireFieldWrapper<Maybe<Scalars["Int"]["output"]>>
}

export type UserProfileType = {
	__typename?: "UserProfileType"
	address?: EntireFieldWrapper<Maybe<LocationType>>
	country_of_birth?: EntireFieldWrapper<
		Maybe<UsersUserProfileCountryOfBirthChoices>
	>
	country_of_residence?: EntireFieldWrapper<
		Maybe<UsersUserProfileCountryOfResidenceChoices>
	>
	created_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	date_of_birth?: EntireFieldWrapper<Maybe<Scalars["Date"]["output"]>>
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["DateTime"]["output"]>>
	gender: EntireFieldWrapper<UsersUserProfileGenderChoices>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	identity_type: EntireFieldWrapper<UsersUserProfileIdentityTypeChoices>
	initials: EntireFieldWrapper<Scalars["String"]["output"]>
	language: EntireFieldWrapper<UsersUserProfileLanguageChoices>
	modified_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	nationality?: EntireFieldWrapper<Maybe<UsersUserProfileNationalityChoices>>
	phone?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	timezone: EntireFieldWrapper<UsersUserProfileTimezoneChoices>
}

export type UserRoles = {
	__typename?: "UserRoles"
	is_energy_supplier?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_installer?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_investor?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_project_owner?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_staff?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	is_superuser?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
}

export type UserType = {
	__typename?: "UserType"
	are_kyc_docs_requested?: EntireFieldWrapper<
		Maybe<Scalars["Boolean"]["output"]>
	>
	created_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	date_joined: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	deleted_at?: EntireFieldWrapper<Maybe<Scalars["DateTime"]["output"]>>
	email: EntireFieldWrapper<Scalars["String"]["output"]>
	energy_supplier_group?: EntireFieldWrapper<
		Maybe<Array<Maybe<EnergySupplierType>>>
	>
	/** Projects where the user is an owner, supplier or installer. */
	finance_projects?: EntireFieldWrapper<Maybe<ProjectListType>>
	first_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	full_first_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	full_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	id: EntireFieldWrapper<Scalars["ID"]["output"]>
	installer_group?: EntireFieldWrapper<Maybe<Array<Maybe<InstallerType>>>>
	/** Interest payments of the user. */
	interest_payments?: EntireFieldWrapper<Maybe<Array<InterestPaymentType>>>
	/** Projects investment campaigns where the user has invested in. */
	investment_campaigns?: EntireFieldWrapper<
		Maybe<Array<InvestmentCampaignProjectType>>
	>
	/** Projects where the user holds solar shares in. */
	investment_projects?: EntireFieldWrapper<Maybe<ProjectListType>>
	/** Investment tests that this user has taken */
	investment_tests?: EntireFieldWrapper<Maybe<Array<InvestmentTestType>>>
	investor?: EntireFieldWrapper<Maybe<InvestorType>>
	investor_production_stats?: EntireFieldWrapper<
		Maybe<InvestorProductionStatsType>
	>
	investor_shares_value_stats?: EntireFieldWrapper<
		Maybe<InvestorSharesValueStatsType>
	>
	/** Bepaalt of deze gebruiker als actief dient te worden behandeld. U kunt dit uitvinken in plaats van een gebruiker te verwijderen. */
	is_active: EntireFieldWrapper<Scalars["Boolean"]["output"]>
	is_kyc_verified?: EntireFieldWrapper<Maybe<Scalars["Boolean"]["output"]>>
	kyc?: EntireFieldWrapper<Maybe<KycType>>
	last_login?: EntireFieldWrapper<Maybe<Scalars["DateTime"]["output"]>>
	last_name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	messages?: EntireFieldWrapper<Maybe<MessagesType>>
	modified_at: EntireFieldWrapper<Scalars["DateTime"]["output"]>
	name?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
	organisations?: EntireFieldWrapper<Maybe<Array<Maybe<OrganisationType>>>>
	/** Interest Payments paid out to a investor */
	payment_entries?: EntireFieldWrapper<Maybe<PaymentEntriesListType>>
	profile?: EntireFieldWrapper<Maybe<UserProfileType>>
	project_owner_group?: EntireFieldWrapper<
		Maybe<Array<Maybe<ProjectOwnerType>>>
	>
	/** Provisional investments of the user. */
	provisional_investments?: EntireFieldWrapper<Maybe<InvestmentListType>>
	roles?: EntireFieldWrapper<Maybe<Array<Maybe<Role>>>>
	roles_old?: EntireFieldWrapper<Maybe<UserRoles>>
	/** Vereist. 150 tekens of minder. Alleen letters, cijfers en de tekens @/,/+/-/_ zijn toegestaan. */
	username: EntireFieldWrapper<Scalars["String"]["output"]>
	uuid?: EntireFieldWrapper<Maybe<Scalars["UUID"]["output"]>>
	uuid_short?: EntireFieldWrapper<Maybe<Scalars["String"]["output"]>>
}

export type UserTypeFinance_ProjectsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	supplier__name__icontains?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Scalars["String"]["input"]>
}

export type UserTypeInterest_PaymentsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	interestperiod__project?: InputMaybe<Scalars["ID"]["input"]>
	interestperiod__project__name__icontains?: InputMaybe<
		Scalars["String"]["input"]
	>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	q?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type UserTypeInvestment_CampaignsArgs = {
	category?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	owner?: InputMaybe<Scalars["ID"]["input"]>
	partners?: InputMaybe<Scalars["ID"]["input"]>
	project?: InputMaybe<Scalars["ID"]["input"]>
	title__icontains?: InputMaybe<Scalars["String"]["input"]>
}

export type UserTypeInvestment_ProjectsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	supplier__name__icontains?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Scalars["String"]["input"]>
}

export type UserTypeInvestment_TestsArgs = {
	category?: InputMaybe<CoreInvestmentTestCategoryChoices>
	experience?: InputMaybe<CoreInvestmentTestExperienceChoices>
	expiry_date?: InputMaybe<Scalars["Date"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	investor?: InputMaybe<Scalars["ID"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	select?: InputMaybe<Scalars["ID"]["input"]>
}

export type UserTypeInvestor_Production_StatsArgs = {
	end?: InputMaybe<Scalars["DateTime"]["input"]>
	project_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
	start?: InputMaybe<Scalars["DateTime"]["input"]>
}

export type UserTypeInvestor_Shares_Value_StatsArgs = {
	project_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
	valuation_date?: InputMaybe<Scalars["Date"]["input"]>
}

export type UserTypePayment_EntriesArgs = {
	end?: InputMaybe<Scalars["Date"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	partner_supplier_only?: InputMaybe<Scalars["Boolean"]["input"]>
	start?: InputMaybe<Scalars["Date"]["input"]>
	state?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
	transaction_id?: InputMaybe<Scalars["String"]["input"]>
}

export type UserTypeProvisional_InvestmentsArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
}

/** An enumeration. */
export const UsersUserProfileCountryOfBirthChoices = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type UsersUserProfileCountryOfBirthChoices =
	(typeof UsersUserProfileCountryOfBirthChoices)[keyof typeof UsersUserProfileCountryOfBirthChoices]
/** An enumeration. */
export const UsersUserProfileCountryOfResidenceChoices = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type UsersUserProfileCountryOfResidenceChoices =
	(typeof UsersUserProfileCountryOfResidenceChoices)[keyof typeof UsersUserProfileCountryOfResidenceChoices]
/** An enumeration. */
export const UsersUserProfileGenderChoices = {
	/** Vrouw */
	Female: "FEMALE",
	/** Man */
	Male: "MALE",
	/** Niet gespecificeerd */
	NotSpecified: "NOT_SPECIFIED",
	/** Anders */
	Other: "OTHER",
} as const

export type UsersUserProfileGenderChoices =
	(typeof UsersUserProfileGenderChoices)[keyof typeof UsersUserProfileGenderChoices]
/** An enumeration. */
export const UsersUserProfileIdentityTypeChoices = {
	/** Zakelijk */
	Business: "BUSINESS",
	/** Privé */
	Private: "PRIVATE",
	/** Niet beschikbaar */
	Unavailable: "UNAVAILABLE",
} as const

export type UsersUserProfileIdentityTypeChoices =
	(typeof UsersUserProfileIdentityTypeChoices)[keyof typeof UsersUserProfileIdentityTypeChoices]
/** An enumeration. */
export const UsersUserProfileLanguageChoices = {
	/** Engels */
	En: "EN",
	/** Frans */
	Fr: "FR",
	/** Nederlands */
	Nl: "NL",
} as const

export type UsersUserProfileLanguageChoices =
	(typeof UsersUserProfileLanguageChoices)[keyof typeof UsersUserProfileLanguageChoices]
/** An enumeration. */
export const UsersUserProfileNationalityChoices = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type UsersUserProfileNationalityChoices =
	(typeof UsersUserProfileNationalityChoices)[keyof typeof UsersUserProfileNationalityChoices]
/** An enumeration. */
export const UsersUserProfileTimezoneChoices = {
	/** Africa/Abidjan */
	AfricaAbidjan: "AFRICA_ABIDJAN",
	/** Africa/Accra */
	AfricaAccra: "AFRICA_ACCRA",
	/** Africa/Addis Ababa */
	AfricaAddisAbaba: "AFRICA_ADDIS_ABABA",
	/** Africa/Algiers */
	AfricaAlgiers: "AFRICA_ALGIERS",
	/** Africa/Asmara */
	AfricaAsmara: "AFRICA_ASMARA",
	/** Africa/Bamako */
	AfricaBamako: "AFRICA_BAMAKO",
	/** Africa/Bangui */
	AfricaBangui: "AFRICA_BANGUI",
	/** Africa/Banjul */
	AfricaBanjul: "AFRICA_BANJUL",
	/** Africa/Bissau */
	AfricaBissau: "AFRICA_BISSAU",
	/** Africa/Blantyre */
	AfricaBlantyre: "AFRICA_BLANTYRE",
	/** Africa/Brazzaville */
	AfricaBrazzaville: "AFRICA_BRAZZAVILLE",
	/** Africa/Bujumbura */
	AfricaBujumbura: "AFRICA_BUJUMBURA",
	/** Africa/Cairo */
	AfricaCairo: "AFRICA_CAIRO",
	/** Africa/Casablanca */
	AfricaCasablanca: "AFRICA_CASABLANCA",
	/** Africa/Ceuta */
	AfricaCeuta: "AFRICA_CEUTA",
	/** Africa/Conakry */
	AfricaConakry: "AFRICA_CONAKRY",
	/** Africa/Dakar */
	AfricaDakar: "AFRICA_DAKAR",
	/** Africa/Dar es Salaam */
	AfricaDarEsSalaam: "AFRICA_DAR_ES_SALAAM",
	/** Africa/Djibouti */
	AfricaDjibouti: "AFRICA_DJIBOUTI",
	/** Africa/Douala */
	AfricaDouala: "AFRICA_DOUALA",
	/** Africa/El Aaiun */
	AfricaElAaiun: "AFRICA_EL_AAIUN",
	/** Africa/Freetown */
	AfricaFreetown: "AFRICA_FREETOWN",
	/** Africa/Gaborone */
	AfricaGaborone: "AFRICA_GABORONE",
	/** Africa/Harare */
	AfricaHarare: "AFRICA_HARARE",
	/** Africa/Johannesburg */
	AfricaJohannesburg: "AFRICA_JOHANNESBURG",
	/** Africa/Juba */
	AfricaJuba: "AFRICA_JUBA",
	/** Africa/Kampala */
	AfricaKampala: "AFRICA_KAMPALA",
	/** Africa/Khartoum */
	AfricaKhartoum: "AFRICA_KHARTOUM",
	/** Africa/Kigali */
	AfricaKigali: "AFRICA_KIGALI",
	/** Africa/Kinshasa */
	AfricaKinshasa: "AFRICA_KINSHASA",
	/** Africa/Lagos */
	AfricaLagos: "AFRICA_LAGOS",
	/** Africa/Libreville */
	AfricaLibreville: "AFRICA_LIBREVILLE",
	/** Africa/Lome */
	AfricaLome: "AFRICA_LOME",
	/** Africa/Luanda */
	AfricaLuanda: "AFRICA_LUANDA",
	/** Africa/Lubumbashi */
	AfricaLubumbashi: "AFRICA_LUBUMBASHI",
	/** Africa/Lusaka */
	AfricaLusaka: "AFRICA_LUSAKA",
	/** Africa/Malabo */
	AfricaMalabo: "AFRICA_MALABO",
	/** Africa/Maputo */
	AfricaMaputo: "AFRICA_MAPUTO",
	/** Africa/Maseru */
	AfricaMaseru: "AFRICA_MASERU",
	/** Africa/Mbabane */
	AfricaMbabane: "AFRICA_MBABANE",
	/** Africa/Mogadishu */
	AfricaMogadishu: "AFRICA_MOGADISHU",
	/** Africa/Monrovia */
	AfricaMonrovia: "AFRICA_MONROVIA",
	/** Africa/Nairobi */
	AfricaNairobi: "AFRICA_NAIROBI",
	/** Africa/Ndjamena */
	AfricaNdjamena: "AFRICA_NDJAMENA",
	/** Africa/Niamey */
	AfricaNiamey: "AFRICA_NIAMEY",
	/** Africa/Nouakchott */
	AfricaNouakchott: "AFRICA_NOUAKCHOTT",
	/** Africa/Ouagadougou */
	AfricaOuagadougou: "AFRICA_OUAGADOUGOU",
	/** Africa/Porto-Novo */
	AfricaPortoNovo: "AFRICA_PORTO_NOVO",
	/** Africa/Sao Tome */
	AfricaSaoTome: "AFRICA_SAO_TOME",
	/** Africa/Tripoli */
	AfricaTripoli: "AFRICA_TRIPOLI",
	/** Africa/Tunis */
	AfricaTunis: "AFRICA_TUNIS",
	/** Africa/Windhoek */
	AfricaWindhoek: "AFRICA_WINDHOEK",
	/** America/Adak */
	AmericaAdak: "AMERICA_ADAK",
	/** America/Anchorage */
	AmericaAnchorage: "AMERICA_ANCHORAGE",
	/** America/Anguilla */
	AmericaAnguilla: "AMERICA_ANGUILLA",
	/** America/Antigua */
	AmericaAntigua: "AMERICA_ANTIGUA",
	/** America/Araguaina */
	AmericaAraguaina: "AMERICA_ARAGUAINA",
	/** America/Argentina/Buenos Aires */
	AmericaArgentinaBuenosAires: "AMERICA_ARGENTINA_BUENOS_AIRES",
	/** America/Argentina/Catamarca */
	AmericaArgentinaCatamarca: "AMERICA_ARGENTINA_CATAMARCA",
	/** America/Argentina/Cordoba */
	AmericaArgentinaCordoba: "AMERICA_ARGENTINA_CORDOBA",
	/** America/Argentina/Jujuy */
	AmericaArgentinaJujuy: "AMERICA_ARGENTINA_JUJUY",
	/** America/Argentina/La Rioja */
	AmericaArgentinaLaRioja: "AMERICA_ARGENTINA_LA_RIOJA",
	/** America/Argentina/Mendoza */
	AmericaArgentinaMendoza: "AMERICA_ARGENTINA_MENDOZA",
	/** America/Argentina/Rio Gallegos */
	AmericaArgentinaRioGallegos: "AMERICA_ARGENTINA_RIO_GALLEGOS",
	/** America/Argentina/Salta */
	AmericaArgentinaSalta: "AMERICA_ARGENTINA_SALTA",
	/** America/Argentina/San Juan */
	AmericaArgentinaSanJuan: "AMERICA_ARGENTINA_SAN_JUAN",
	/** America/Argentina/San Luis */
	AmericaArgentinaSanLuis: "AMERICA_ARGENTINA_SAN_LUIS",
	/** America/Argentina/Tucuman */
	AmericaArgentinaTucuman: "AMERICA_ARGENTINA_TUCUMAN",
	/** America/Argentina/Ushuaia */
	AmericaArgentinaUshuaia: "AMERICA_ARGENTINA_USHUAIA",
	/** America/Aruba */
	AmericaAruba: "AMERICA_ARUBA",
	/** America/Asuncion */
	AmericaAsuncion: "AMERICA_ASUNCION",
	/** America/Atikokan */
	AmericaAtikokan: "AMERICA_ATIKOKAN",
	/** America/Bahia */
	AmericaBahia: "AMERICA_BAHIA",
	/** America/Bahia Banderas */
	AmericaBahiaBanderas: "AMERICA_BAHIA_BANDERAS",
	/** America/Barbados */
	AmericaBarbados: "AMERICA_BARBADOS",
	/** America/Belem */
	AmericaBelem: "AMERICA_BELEM",
	/** America/Belize */
	AmericaBelize: "AMERICA_BELIZE",
	/** America/Blanc-Sablon */
	AmericaBlancSablon: "AMERICA_BLANC_SABLON",
	/** America/Boa Vista */
	AmericaBoaVista: "AMERICA_BOA_VISTA",
	/** America/Bogota */
	AmericaBogota: "AMERICA_BOGOTA",
	/** America/Boise */
	AmericaBoise: "AMERICA_BOISE",
	/** America/Cambridge Bay */
	AmericaCambridgeBay: "AMERICA_CAMBRIDGE_BAY",
	/** America/Campo Grande */
	AmericaCampoGrande: "AMERICA_CAMPO_GRANDE",
	/** America/Cancun */
	AmericaCancun: "AMERICA_CANCUN",
	/** America/Caracas */
	AmericaCaracas: "AMERICA_CARACAS",
	/** America/Cayenne */
	AmericaCayenne: "AMERICA_CAYENNE",
	/** America/Cayman */
	AmericaCayman: "AMERICA_CAYMAN",
	/** America/Chicago */
	AmericaChicago: "AMERICA_CHICAGO",
	/** America/Chihuahua */
	AmericaChihuahua: "AMERICA_CHIHUAHUA",
	/** America/Costa Rica */
	AmericaCostaRica: "AMERICA_COSTA_RICA",
	/** America/Creston */
	AmericaCreston: "AMERICA_CRESTON",
	/** America/Cuiaba */
	AmericaCuiaba: "AMERICA_CUIABA",
	/** America/Curacao */
	AmericaCuracao: "AMERICA_CURACAO",
	/** America/Danmarkshavn */
	AmericaDanmarkshavn: "AMERICA_DANMARKSHAVN",
	/** America/Dawson */
	AmericaDawson: "AMERICA_DAWSON",
	/** America/Dawson Creek */
	AmericaDawsonCreek: "AMERICA_DAWSON_CREEK",
	/** America/Denver */
	AmericaDenver: "AMERICA_DENVER",
	/** America/Detroit */
	AmericaDetroit: "AMERICA_DETROIT",
	/** America/Dominica */
	AmericaDominica: "AMERICA_DOMINICA",
	/** America/Edmonton */
	AmericaEdmonton: "AMERICA_EDMONTON",
	/** America/Eirunepe */
	AmericaEirunepe: "AMERICA_EIRUNEPE",
	/** America/El Salvador */
	AmericaElSalvador: "AMERICA_EL_SALVADOR",
	/** America/Fortaleza */
	AmericaFortaleza: "AMERICA_FORTALEZA",
	/** America/Fort Nelson */
	AmericaFortNelson: "AMERICA_FORT_NELSON",
	/** America/Glace Bay */
	AmericaGlaceBay: "AMERICA_GLACE_BAY",
	/** America/Goose Bay */
	AmericaGooseBay: "AMERICA_GOOSE_BAY",
	/** America/Grand Turk */
	AmericaGrandTurk: "AMERICA_GRAND_TURK",
	/** America/Grenada */
	AmericaGrenada: "AMERICA_GRENADA",
	/** America/Guadeloupe */
	AmericaGuadeloupe: "AMERICA_GUADELOUPE",
	/** America/Guatemala */
	AmericaGuatemala: "AMERICA_GUATEMALA",
	/** America/Guayaquil */
	AmericaGuayaquil: "AMERICA_GUAYAQUIL",
	/** America/Guyana */
	AmericaGuyana: "AMERICA_GUYANA",
	/** America/Halifax */
	AmericaHalifax: "AMERICA_HALIFAX",
	/** America/Havana */
	AmericaHavana: "AMERICA_HAVANA",
	/** America/Hermosillo */
	AmericaHermosillo: "AMERICA_HERMOSILLO",
	/** America/Indiana/Indianapolis */
	AmericaIndianaIndianapolis: "AMERICA_INDIANA_INDIANAPOLIS",
	/** America/Indiana/Knox */
	AmericaIndianaKnox: "AMERICA_INDIANA_KNOX",
	/** America/Indiana/Marengo */
	AmericaIndianaMarengo: "AMERICA_INDIANA_MARENGO",
	/** America/Indiana/Petersburg */
	AmericaIndianaPetersburg: "AMERICA_INDIANA_PETERSBURG",
	/** America/Indiana/Tell City */
	AmericaIndianaTellCity: "AMERICA_INDIANA_TELL_CITY",
	/** America/Indiana/Vevay */
	AmericaIndianaVevay: "AMERICA_INDIANA_VEVAY",
	/** America/Indiana/Vincennes */
	AmericaIndianaVincennes: "AMERICA_INDIANA_VINCENNES",
	/** America/Indiana/Winamac */
	AmericaIndianaWinamac: "AMERICA_INDIANA_WINAMAC",
	/** America/Inuvik */
	AmericaInuvik: "AMERICA_INUVIK",
	/** America/Iqaluit */
	AmericaIqaluit: "AMERICA_IQALUIT",
	/** America/Jamaica */
	AmericaJamaica: "AMERICA_JAMAICA",
	/** America/Juneau */
	AmericaJuneau: "AMERICA_JUNEAU",
	/** America/Kentucky/Louisville */
	AmericaKentuckyLouisville: "AMERICA_KENTUCKY_LOUISVILLE",
	/** America/Kentucky/Monticello */
	AmericaKentuckyMonticello: "AMERICA_KENTUCKY_MONTICELLO",
	/** America/Kralendijk */
	AmericaKralendijk: "AMERICA_KRALENDIJK",
	/** America/La Paz */
	AmericaLaPaz: "AMERICA_LA_PAZ",
	/** America/Lima */
	AmericaLima: "AMERICA_LIMA",
	/** America/Los Angeles */
	AmericaLosAngeles: "AMERICA_LOS_ANGELES",
	/** America/Lower Princes */
	AmericaLowerPrinces: "AMERICA_LOWER_PRINCES",
	/** America/Maceio */
	AmericaMaceio: "AMERICA_MACEIO",
	/** America/Managua */
	AmericaManagua: "AMERICA_MANAGUA",
	/** America/Manaus */
	AmericaManaus: "AMERICA_MANAUS",
	/** America/Marigot */
	AmericaMarigot: "AMERICA_MARIGOT",
	/** America/Martinique */
	AmericaMartinique: "AMERICA_MARTINIQUE",
	/** America/Matamoros */
	AmericaMatamoros: "AMERICA_MATAMOROS",
	/** America/Mazatlan */
	AmericaMazatlan: "AMERICA_MAZATLAN",
	/** America/Menominee */
	AmericaMenominee: "AMERICA_MENOMINEE",
	/** America/Merida */
	AmericaMerida: "AMERICA_MERIDA",
	/** America/Metlakatla */
	AmericaMetlakatla: "AMERICA_METLAKATLA",
	/** America/Mexico City */
	AmericaMexicoCity: "AMERICA_MEXICO_CITY",
	/** America/Miquelon */
	AmericaMiquelon: "AMERICA_MIQUELON",
	/** America/Moncton */
	AmericaMoncton: "AMERICA_MONCTON",
	/** America/Monterrey */
	AmericaMonterrey: "AMERICA_MONTERREY",
	/** America/Montevideo */
	AmericaMontevideo: "AMERICA_MONTEVIDEO",
	/** America/Montserrat */
	AmericaMontserrat: "AMERICA_MONTSERRAT",
	/** America/Nassau */
	AmericaNassau: "AMERICA_NASSAU",
	/** America/New York */
	AmericaNewYork: "AMERICA_NEW_YORK",
	/** America/Nipigon */
	AmericaNipigon: "AMERICA_NIPIGON",
	/** America/Nome */
	AmericaNome: "AMERICA_NOME",
	/** America/Noronha */
	AmericaNoronha: "AMERICA_NORONHA",
	/** America/North Dakota/Beulah */
	AmericaNorthDakotaBeulah: "AMERICA_NORTH_DAKOTA_BEULAH",
	/** America/North Dakota/Center */
	AmericaNorthDakotaCenter: "AMERICA_NORTH_DAKOTA_CENTER",
	/** America/North Dakota/New Salem */
	AmericaNorthDakotaNewSalem: "AMERICA_NORTH_DAKOTA_NEW_SALEM",
	/** America/Nuuk */
	AmericaNuuk: "AMERICA_NUUK",
	/** America/Ojinaga */
	AmericaOjinaga: "AMERICA_OJINAGA",
	/** America/Panama */
	AmericaPanama: "AMERICA_PANAMA",
	/** America/Pangnirtung */
	AmericaPangnirtung: "AMERICA_PANGNIRTUNG",
	/** America/Paramaribo */
	AmericaParamaribo: "AMERICA_PARAMARIBO",
	/** America/Phoenix */
	AmericaPhoenix: "AMERICA_PHOENIX",
	/** America/Porto Velho */
	AmericaPortoVelho: "AMERICA_PORTO_VELHO",
	/** America/Port-au-Prince */
	AmericaPortAuPrince: "AMERICA_PORT_AU_PRINCE",
	/** America/Port of Spain */
	AmericaPortOfSpain: "AMERICA_PORT_OF_SPAIN",
	/** America/Puerto Rico */
	AmericaPuertoRico: "AMERICA_PUERTO_RICO",
	/** America/Punta Arenas */
	AmericaPuntaArenas: "AMERICA_PUNTA_ARENAS",
	/** America/Rainy River */
	AmericaRainyRiver: "AMERICA_RAINY_RIVER",
	/** America/Rankin Inlet */
	AmericaRankinInlet: "AMERICA_RANKIN_INLET",
	/** America/Recife */
	AmericaRecife: "AMERICA_RECIFE",
	/** America/Regina */
	AmericaRegina: "AMERICA_REGINA",
	/** America/Resolute */
	AmericaResolute: "AMERICA_RESOLUTE",
	/** America/Rio Branco */
	AmericaRioBranco: "AMERICA_RIO_BRANCO",
	/** America/Santarem */
	AmericaSantarem: "AMERICA_SANTAREM",
	/** America/Santiago */
	AmericaSantiago: "AMERICA_SANTIAGO",
	/** America/Santo Domingo */
	AmericaSantoDomingo: "AMERICA_SANTO_DOMINGO",
	/** America/Sao Paulo */
	AmericaSaoPaulo: "AMERICA_SAO_PAULO",
	/** America/Scoresbysund */
	AmericaScoresbysund: "AMERICA_SCORESBYSUND",
	/** America/Sitka */
	AmericaSitka: "AMERICA_SITKA",
	/** America/St Barthelemy */
	AmericaStBarthelemy: "AMERICA_ST_BARTHELEMY",
	/** America/St Johns */
	AmericaStJohns: "AMERICA_ST_JOHNS",
	/** America/St Kitts */
	AmericaStKitts: "AMERICA_ST_KITTS",
	/** America/St Lucia */
	AmericaStLucia: "AMERICA_ST_LUCIA",
	/** America/St Thomas */
	AmericaStThomas: "AMERICA_ST_THOMAS",
	/** America/St Vincent */
	AmericaStVincent: "AMERICA_ST_VINCENT",
	/** America/Swift Current */
	AmericaSwiftCurrent: "AMERICA_SWIFT_CURRENT",
	/** America/Tegucigalpa */
	AmericaTegucigalpa: "AMERICA_TEGUCIGALPA",
	/** America/Thule */
	AmericaThule: "AMERICA_THULE",
	/** America/Thunder Bay */
	AmericaThunderBay: "AMERICA_THUNDER_BAY",
	/** America/Tijuana */
	AmericaTijuana: "AMERICA_TIJUANA",
	/** America/Toronto */
	AmericaToronto: "AMERICA_TORONTO",
	/** America/Tortola */
	AmericaTortola: "AMERICA_TORTOLA",
	/** America/Vancouver */
	AmericaVancouver: "AMERICA_VANCOUVER",
	/** America/Whitehorse */
	AmericaWhitehorse: "AMERICA_WHITEHORSE",
	/** America/Winnipeg */
	AmericaWinnipeg: "AMERICA_WINNIPEG",
	/** America/Yakutat */
	AmericaYakutat: "AMERICA_YAKUTAT",
	/** America/Yellowknife */
	AmericaYellowknife: "AMERICA_YELLOWKNIFE",
	/** Antarctica/Casey */
	AntarcticaCasey: "ANTARCTICA_CASEY",
	/** Antarctica/Davis */
	AntarcticaDavis: "ANTARCTICA_DAVIS",
	/** Antarctica/DumontDUrville */
	AntarcticaDumontdurville: "ANTARCTICA_DUMONTDURVILLE",
	/** Antarctica/Macquarie */
	AntarcticaMacquarie: "ANTARCTICA_MACQUARIE",
	/** Antarctica/Mawson */
	AntarcticaMawson: "ANTARCTICA_MAWSON",
	/** Antarctica/McMurdo */
	AntarcticaMcmurdo: "ANTARCTICA_MCMURDO",
	/** Antarctica/Palmer */
	AntarcticaPalmer: "ANTARCTICA_PALMER",
	/** Antarctica/Rothera */
	AntarcticaRothera: "ANTARCTICA_ROTHERA",
	/** Antarctica/Syowa */
	AntarcticaSyowa: "ANTARCTICA_SYOWA",
	/** Antarctica/Troll */
	AntarcticaTroll: "ANTARCTICA_TROLL",
	/** Antarctica/Vostok */
	AntarcticaVostok: "ANTARCTICA_VOSTOK",
	/** Arctic/Longyearbyen */
	ArcticLongyearbyen: "ARCTIC_LONGYEARBYEN",
	/** Asia/Aden */
	AsiaAden: "ASIA_ADEN",
	/** Asia/Almaty */
	AsiaAlmaty: "ASIA_ALMATY",
	/** Asia/Amman */
	AsiaAmman: "ASIA_AMMAN",
	/** Asia/Anadyr */
	AsiaAnadyr: "ASIA_ANADYR",
	/** Asia/Aqtau */
	AsiaAqtau: "ASIA_AQTAU",
	/** Asia/Aqtobe */
	AsiaAqtobe: "ASIA_AQTOBE",
	/** Asia/Ashgabat */
	AsiaAshgabat: "ASIA_ASHGABAT",
	/** Asia/Atyrau */
	AsiaAtyrau: "ASIA_ATYRAU",
	/** Asia/Baghdad */
	AsiaBaghdad: "ASIA_BAGHDAD",
	/** Asia/Bahrain */
	AsiaBahrain: "ASIA_BAHRAIN",
	/** Asia/Baku */
	AsiaBaku: "ASIA_BAKU",
	/** Asia/Bangkok */
	AsiaBangkok: "ASIA_BANGKOK",
	/** Asia/Barnaul */
	AsiaBarnaul: "ASIA_BARNAUL",
	/** Asia/Beirut */
	AsiaBeirut: "ASIA_BEIRUT",
	/** Asia/Bishkek */
	AsiaBishkek: "ASIA_BISHKEK",
	/** Asia/Brunei */
	AsiaBrunei: "ASIA_BRUNEI",
	/** Asia/Chita */
	AsiaChita: "ASIA_CHITA",
	/** Asia/Choibalsan */
	AsiaChoibalsan: "ASIA_CHOIBALSAN",
	/** Asia/Colombo */
	AsiaColombo: "ASIA_COLOMBO",
	/** Asia/Damascus */
	AsiaDamascus: "ASIA_DAMASCUS",
	/** Asia/Dhaka */
	AsiaDhaka: "ASIA_DHAKA",
	/** Asia/Dili */
	AsiaDili: "ASIA_DILI",
	/** Asia/Dubai */
	AsiaDubai: "ASIA_DUBAI",
	/** Asia/Dushanbe */
	AsiaDushanbe: "ASIA_DUSHANBE",
	/** Asia/Famagusta */
	AsiaFamagusta: "ASIA_FAMAGUSTA",
	/** Asia/Gaza */
	AsiaGaza: "ASIA_GAZA",
	/** Asia/Hebron */
	AsiaHebron: "ASIA_HEBRON",
	/** Asia/Hong Kong */
	AsiaHongKong: "ASIA_HONG_KONG",
	/** Asia/Hovd */
	AsiaHovd: "ASIA_HOVD",
	/** Asia/Ho Chi Minh */
	AsiaHoChiMinh: "ASIA_HO_CHI_MINH",
	/** Asia/Irkutsk */
	AsiaIrkutsk: "ASIA_IRKUTSK",
	/** Asia/Jakarta */
	AsiaJakarta: "ASIA_JAKARTA",
	/** Asia/Jayapura */
	AsiaJayapura: "ASIA_JAYAPURA",
	/** Asia/Jerusalem */
	AsiaJerusalem: "ASIA_JERUSALEM",
	/** Asia/Kabul */
	AsiaKabul: "ASIA_KABUL",
	/** Asia/Kamchatka */
	AsiaKamchatka: "ASIA_KAMCHATKA",
	/** Asia/Karachi */
	AsiaKarachi: "ASIA_KARACHI",
	/** Asia/Kathmandu */
	AsiaKathmandu: "ASIA_KATHMANDU",
	/** Asia/Khandyga */
	AsiaKhandyga: "ASIA_KHANDYGA",
	/** Asia/Kolkata */
	AsiaKolkata: "ASIA_KOLKATA",
	/** Asia/Krasnoyarsk */
	AsiaKrasnoyarsk: "ASIA_KRASNOYARSK",
	/** Asia/Kuala Lumpur */
	AsiaKualaLumpur: "ASIA_KUALA_LUMPUR",
	/** Asia/Kuching */
	AsiaKuching: "ASIA_KUCHING",
	/** Asia/Kuwait */
	AsiaKuwait: "ASIA_KUWAIT",
	/** Asia/Macau */
	AsiaMacau: "ASIA_MACAU",
	/** Asia/Magadan */
	AsiaMagadan: "ASIA_MAGADAN",
	/** Asia/Makassar */
	AsiaMakassar: "ASIA_MAKASSAR",
	/** Asia/Manila */
	AsiaManila: "ASIA_MANILA",
	/** Asia/Muscat */
	AsiaMuscat: "ASIA_MUSCAT",
	/** Asia/Nicosia */
	AsiaNicosia: "ASIA_NICOSIA",
	/** Asia/Novokuznetsk */
	AsiaNovokuznetsk: "ASIA_NOVOKUZNETSK",
	/** Asia/Novosibirsk */
	AsiaNovosibirsk: "ASIA_NOVOSIBIRSK",
	/** Asia/Omsk */
	AsiaOmsk: "ASIA_OMSK",
	/** Asia/Oral */
	AsiaOral: "ASIA_ORAL",
	/** Asia/Phnom Penh */
	AsiaPhnomPenh: "ASIA_PHNOM_PENH",
	/** Asia/Pontianak */
	AsiaPontianak: "ASIA_PONTIANAK",
	/** Asia/Pyongyang */
	AsiaPyongyang: "ASIA_PYONGYANG",
	/** Asia/Qatar */
	AsiaQatar: "ASIA_QATAR",
	/** Asia/Qostanay */
	AsiaQostanay: "ASIA_QOSTANAY",
	/** Asia/Qyzylorda */
	AsiaQyzylorda: "ASIA_QYZYLORDA",
	/** Asia/Riyadh */
	AsiaRiyadh: "ASIA_RIYADH",
	/** Asia/Sakhalin */
	AsiaSakhalin: "ASIA_SAKHALIN",
	/** Asia/Samarkand */
	AsiaSamarkand: "ASIA_SAMARKAND",
	/** Asia/Seoul */
	AsiaSeoul: "ASIA_SEOUL",
	/** Asia/Shanghai */
	AsiaShanghai: "ASIA_SHANGHAI",
	/** Asia/Singapore */
	AsiaSingapore: "ASIA_SINGAPORE",
	/** Asia/Srednekolymsk */
	AsiaSrednekolymsk: "ASIA_SREDNEKOLYMSK",
	/** Asia/Taipei */
	AsiaTaipei: "ASIA_TAIPEI",
	/** Asia/Tashkent */
	AsiaTashkent: "ASIA_TASHKENT",
	/** Asia/Tbilisi */
	AsiaTbilisi: "ASIA_TBILISI",
	/** Asia/Tehran */
	AsiaTehran: "ASIA_TEHRAN",
	/** Asia/Thimphu */
	AsiaThimphu: "ASIA_THIMPHU",
	/** Asia/Tokyo */
	AsiaTokyo: "ASIA_TOKYO",
	/** Asia/Tomsk */
	AsiaTomsk: "ASIA_TOMSK",
	/** Asia/Ulaanbaatar */
	AsiaUlaanbaatar: "ASIA_ULAANBAATAR",
	/** Asia/Urumqi */
	AsiaUrumqi: "ASIA_URUMQI",
	/** Asia/Ust-Nera */
	AsiaUstNera: "ASIA_UST_NERA",
	/** Asia/Vientiane */
	AsiaVientiane: "ASIA_VIENTIANE",
	/** Asia/Vladivostok */
	AsiaVladivostok: "ASIA_VLADIVOSTOK",
	/** Asia/Yakutsk */
	AsiaYakutsk: "ASIA_YAKUTSK",
	/** Asia/Yangon */
	AsiaYangon: "ASIA_YANGON",
	/** Asia/Yekaterinburg */
	AsiaYekaterinburg: "ASIA_YEKATERINBURG",
	/** Asia/Yerevan */
	AsiaYerevan: "ASIA_YEREVAN",
	/** Atlantic/Azores */
	AtlanticAzores: "ATLANTIC_AZORES",
	/** Atlantic/Bermuda */
	AtlanticBermuda: "ATLANTIC_BERMUDA",
	/** Atlantic/Canary */
	AtlanticCanary: "ATLANTIC_CANARY",
	/** Atlantic/Cape Verde */
	AtlanticCapeVerde: "ATLANTIC_CAPE_VERDE",
	/** Atlantic/Faroe */
	AtlanticFaroe: "ATLANTIC_FAROE",
	/** Atlantic/Madeira */
	AtlanticMadeira: "ATLANTIC_MADEIRA",
	/** Atlantic/Reykjavik */
	AtlanticReykjavik: "ATLANTIC_REYKJAVIK",
	/** Atlantic/South Georgia */
	AtlanticSouthGeorgia: "ATLANTIC_SOUTH_GEORGIA",
	/** Atlantic/Stanley */
	AtlanticStanley: "ATLANTIC_STANLEY",
	/** Atlantic/St Helena */
	AtlanticStHelena: "ATLANTIC_ST_HELENA",
	/** Australia/Adelaide */
	AustraliaAdelaide: "AUSTRALIA_ADELAIDE",
	/** Australia/Brisbane */
	AustraliaBrisbane: "AUSTRALIA_BRISBANE",
	/** Australia/Broken Hill */
	AustraliaBrokenHill: "AUSTRALIA_BROKEN_HILL",
	/** Australia/Currie */
	AustraliaCurrie: "AUSTRALIA_CURRIE",
	/** Australia/Darwin */
	AustraliaDarwin: "AUSTRALIA_DARWIN",
	/** Australia/Eucla */
	AustraliaEucla: "AUSTRALIA_EUCLA",
	/** Australia/Hobart */
	AustraliaHobart: "AUSTRALIA_HOBART",
	/** Australia/Lindeman */
	AustraliaLindeman: "AUSTRALIA_LINDEMAN",
	/** Australia/Lord Howe */
	AustraliaLordHowe: "AUSTRALIA_LORD_HOWE",
	/** Australia/Melbourne */
	AustraliaMelbourne: "AUSTRALIA_MELBOURNE",
	/** Australia/Perth */
	AustraliaPerth: "AUSTRALIA_PERTH",
	/** Australia/Sydney */
	AustraliaSydney: "AUSTRALIA_SYDNEY",
	/** Canada/Atlantic */
	CanadaAtlantic: "CANADA_ATLANTIC",
	/** Canada/Central */
	CanadaCentral: "CANADA_CENTRAL",
	/** Canada/Eastern */
	CanadaEastern: "CANADA_EASTERN",
	/** Canada/Mountain */
	CanadaMountain: "CANADA_MOUNTAIN",
	/** Canada/Newfoundland */
	CanadaNewfoundland: "CANADA_NEWFOUNDLAND",
	/** Canada/Pacific */
	CanadaPacific: "CANADA_PACIFIC",
	/** Europe/Amsterdam */
	EuropeAmsterdam: "EUROPE_AMSTERDAM",
	/** Europe/Andorra */
	EuropeAndorra: "EUROPE_ANDORRA",
	/** Europe/Astrakhan */
	EuropeAstrakhan: "EUROPE_ASTRAKHAN",
	/** Europe/Athens */
	EuropeAthens: "EUROPE_ATHENS",
	/** Europe/Belgrade */
	EuropeBelgrade: "EUROPE_BELGRADE",
	/** Europe/Berlin */
	EuropeBerlin: "EUROPE_BERLIN",
	/** Europe/Bratislava */
	EuropeBratislava: "EUROPE_BRATISLAVA",
	/** Europe/Brussels */
	EuropeBrussels: "EUROPE_BRUSSELS",
	/** Europe/Bucharest */
	EuropeBucharest: "EUROPE_BUCHAREST",
	/** Europe/Budapest */
	EuropeBudapest: "EUROPE_BUDAPEST",
	/** Europe/Busingen */
	EuropeBusingen: "EUROPE_BUSINGEN",
	/** Europe/Chisinau */
	EuropeChisinau: "EUROPE_CHISINAU",
	/** Europe/Copenhagen */
	EuropeCopenhagen: "EUROPE_COPENHAGEN",
	/** Europe/Dublin */
	EuropeDublin: "EUROPE_DUBLIN",
	/** Europe/Gibraltar */
	EuropeGibraltar: "EUROPE_GIBRALTAR",
	/** Europe/Guernsey */
	EuropeGuernsey: "EUROPE_GUERNSEY",
	/** Europe/Helsinki */
	EuropeHelsinki: "EUROPE_HELSINKI",
	/** Europe/Isle of Man */
	EuropeIsleOfMan: "EUROPE_ISLE_OF_MAN",
	/** Europe/Istanbul */
	EuropeIstanbul: "EUROPE_ISTANBUL",
	/** Europe/Jersey */
	EuropeJersey: "EUROPE_JERSEY",
	/** Europe/Kaliningrad */
	EuropeKaliningrad: "EUROPE_KALININGRAD",
	/** Europe/Kiev */
	EuropeKiev: "EUROPE_KIEV",
	/** Europe/Kirov */
	EuropeKirov: "EUROPE_KIROV",
	/** Europe/Lisbon */
	EuropeLisbon: "EUROPE_LISBON",
	/** Europe/Ljubljana */
	EuropeLjubljana: "EUROPE_LJUBLJANA",
	/** Europe/London */
	EuropeLondon: "EUROPE_LONDON",
	/** Europe/Luxembourg */
	EuropeLuxembourg: "EUROPE_LUXEMBOURG",
	/** Europe/Madrid */
	EuropeMadrid: "EUROPE_MADRID",
	/** Europe/Malta */
	EuropeMalta: "EUROPE_MALTA",
	/** Europe/Mariehamn */
	EuropeMariehamn: "EUROPE_MARIEHAMN",
	/** Europe/Minsk */
	EuropeMinsk: "EUROPE_MINSK",
	/** Europe/Monaco */
	EuropeMonaco: "EUROPE_MONACO",
	/** Europe/Moscow */
	EuropeMoscow: "EUROPE_MOSCOW",
	/** Europe/Oslo */
	EuropeOslo: "EUROPE_OSLO",
	/** Europe/Paris */
	EuropeParis: "EUROPE_PARIS",
	/** Europe/Podgorica */
	EuropePodgorica: "EUROPE_PODGORICA",
	/** Europe/Prague */
	EuropePrague: "EUROPE_PRAGUE",
	/** Europe/Riga */
	EuropeRiga: "EUROPE_RIGA",
	/** Europe/Rome */
	EuropeRome: "EUROPE_ROME",
	/** Europe/Samara */
	EuropeSamara: "EUROPE_SAMARA",
	/** Europe/San Marino */
	EuropeSanMarino: "EUROPE_SAN_MARINO",
	/** Europe/Sarajevo */
	EuropeSarajevo: "EUROPE_SARAJEVO",
	/** Europe/Saratov */
	EuropeSaratov: "EUROPE_SARATOV",
	/** Europe/Simferopol */
	EuropeSimferopol: "EUROPE_SIMFEROPOL",
	/** Europe/Skopje */
	EuropeSkopje: "EUROPE_SKOPJE",
	/** Europe/Sofia */
	EuropeSofia: "EUROPE_SOFIA",
	/** Europe/Stockholm */
	EuropeStockholm: "EUROPE_STOCKHOLM",
	/** Europe/Tallinn */
	EuropeTallinn: "EUROPE_TALLINN",
	/** Europe/Tirane */
	EuropeTirane: "EUROPE_TIRANE",
	/** Europe/Ulyanovsk */
	EuropeUlyanovsk: "EUROPE_ULYANOVSK",
	/** Europe/Uzhgorod */
	EuropeUzhgorod: "EUROPE_UZHGOROD",
	/** Europe/Vaduz */
	EuropeVaduz: "EUROPE_VADUZ",
	/** Europe/Vatican */
	EuropeVatican: "EUROPE_VATICAN",
	/** Europe/Vienna */
	EuropeVienna: "EUROPE_VIENNA",
	/** Europe/Vilnius */
	EuropeVilnius: "EUROPE_VILNIUS",
	/** Europe/Volgograd */
	EuropeVolgograd: "EUROPE_VOLGOGRAD",
	/** Europe/Warsaw */
	EuropeWarsaw: "EUROPE_WARSAW",
	/** Europe/Zagreb */
	EuropeZagreb: "EUROPE_ZAGREB",
	/** Europe/Zaporozhye */
	EuropeZaporozhye: "EUROPE_ZAPOROZHYE",
	/** Europe/Zurich */
	EuropeZurich: "EUROPE_ZURICH",
	/** GMT */
	Gmt: "GMT",
	/** Indian/Antananarivo */
	IndianAntananarivo: "INDIAN_ANTANANARIVO",
	/** Indian/Chagos */
	IndianChagos: "INDIAN_CHAGOS",
	/** Indian/Christmas */
	IndianChristmas: "INDIAN_CHRISTMAS",
	/** Indian/Cocos */
	IndianCocos: "INDIAN_COCOS",
	/** Indian/Comoro */
	IndianComoro: "INDIAN_COMORO",
	/** Indian/Kerguelen */
	IndianKerguelen: "INDIAN_KERGUELEN",
	/** Indian/Mahe */
	IndianMahe: "INDIAN_MAHE",
	/** Indian/Maldives */
	IndianMaldives: "INDIAN_MALDIVES",
	/** Indian/Mauritius */
	IndianMauritius: "INDIAN_MAURITIUS",
	/** Indian/Mayotte */
	IndianMayotte: "INDIAN_MAYOTTE",
	/** Indian/Reunion */
	IndianReunion: "INDIAN_REUNION",
	/** Pacific/Apia */
	PacificApia: "PACIFIC_APIA",
	/** Pacific/Auckland */
	PacificAuckland: "PACIFIC_AUCKLAND",
	/** Pacific/Bougainville */
	PacificBougainville: "PACIFIC_BOUGAINVILLE",
	/** Pacific/Chatham */
	PacificChatham: "PACIFIC_CHATHAM",
	/** Pacific/Chuuk */
	PacificChuuk: "PACIFIC_CHUUK",
	/** Pacific/Easter */
	PacificEaster: "PACIFIC_EASTER",
	/** Pacific/Efate */
	PacificEfate: "PACIFIC_EFATE",
	/** Pacific/Enderbury */
	PacificEnderbury: "PACIFIC_ENDERBURY",
	/** Pacific/Fakaofo */
	PacificFakaofo: "PACIFIC_FAKAOFO",
	/** Pacific/Fiji */
	PacificFiji: "PACIFIC_FIJI",
	/** Pacific/Funafuti */
	PacificFunafuti: "PACIFIC_FUNAFUTI",
	/** Pacific/Galapagos */
	PacificGalapagos: "PACIFIC_GALAPAGOS",
	/** Pacific/Gambier */
	PacificGambier: "PACIFIC_GAMBIER",
	/** Pacific/Guadalcanal */
	PacificGuadalcanal: "PACIFIC_GUADALCANAL",
	/** Pacific/Guam */
	PacificGuam: "PACIFIC_GUAM",
	/** Pacific/Honolulu */
	PacificHonolulu: "PACIFIC_HONOLULU",
	/** Pacific/Kiritimati */
	PacificKiritimati: "PACIFIC_KIRITIMATI",
	/** Pacific/Kosrae */
	PacificKosrae: "PACIFIC_KOSRAE",
	/** Pacific/Kwajalein */
	PacificKwajalein: "PACIFIC_KWAJALEIN",
	/** Pacific/Majuro */
	PacificMajuro: "PACIFIC_MAJURO",
	/** Pacific/Marquesas */
	PacificMarquesas: "PACIFIC_MARQUESAS",
	/** Pacific/Midway */
	PacificMidway: "PACIFIC_MIDWAY",
	/** Pacific/Nauru */
	PacificNauru: "PACIFIC_NAURU",
	/** Pacific/Niue */
	PacificNiue: "PACIFIC_NIUE",
	/** Pacific/Norfolk */
	PacificNorfolk: "PACIFIC_NORFOLK",
	/** Pacific/Noumea */
	PacificNoumea: "PACIFIC_NOUMEA",
	/** Pacific/Pago Pago */
	PacificPagoPago: "PACIFIC_PAGO_PAGO",
	/** Pacific/Palau */
	PacificPalau: "PACIFIC_PALAU",
	/** Pacific/Pitcairn */
	PacificPitcairn: "PACIFIC_PITCAIRN",
	/** Pacific/Pohnpei */
	PacificPohnpei: "PACIFIC_POHNPEI",
	/** Pacific/Port Moresby */
	PacificPortMoresby: "PACIFIC_PORT_MORESBY",
	/** Pacific/Rarotonga */
	PacificRarotonga: "PACIFIC_RAROTONGA",
	/** Pacific/Saipan */
	PacificSaipan: "PACIFIC_SAIPAN",
	/** Pacific/Tahiti */
	PacificTahiti: "PACIFIC_TAHITI",
	/** Pacific/Tarawa */
	PacificTarawa: "PACIFIC_TARAWA",
	/** Pacific/Tongatapu */
	PacificTongatapu: "PACIFIC_TONGATAPU",
	/** Pacific/Wake */
	PacificWake: "PACIFIC_WAKE",
	/** Pacific/Wallis */
	PacificWallis: "PACIFIC_WALLIS",
	/** US/Alaska */
	UsAlaska: "US_ALASKA",
	/** US/Arizona */
	UsArizona: "US_ARIZONA",
	/** US/Central */
	UsCentral: "US_CENTRAL",
	/** US/Eastern */
	UsEastern: "US_EASTERN",
	/** US/Hawaii */
	UsHawaii: "US_HAWAII",
	/** US/Mountain */
	UsMountain: "US_MOUNTAIN",
	/** US/Pacific */
	UsPacific: "US_PACIFIC",
	/** UTC */
	Utc: "UTC",
} as const

export type UsersUserProfileTimezoneChoices =
	(typeof UsersUserProfileTimezoneChoices)[keyof typeof UsersUserProfileTimezoneChoices]
/** An enumeration. */
export const Country = {
	/** Oostenrijk */
	At: "AT",
	/** België */
	Be: "BE",
	/** Bulgarije */
	Bg: "BG",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Denemarken */
	Dk: "DK",
	/** Estland */
	Ee: "EE",
	/** Spanje */
	Es: "ES",
	/** Finland */
	Fi: "FI",
	/** Frankrijk */
	Fr: "FR",
	/** Griekenland */
	Gr: "GR",
	/** Kroatië */
	Hr: "HR",
	/** Hongarije */
	Hu: "HU",
	/** Ierland */
	Ie: "IE",
	/** Italië */
	It: "IT",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Malta */
	Mt: "MT",
	/** Nederland */
	Nl: "NL",
	/** Polen */
	Pl: "PL",
	/** Portugal */
	Pt: "PT",
	/** Roemenië */
	Ro: "RO",
	/** Zweden */
	Se: "SE",
	/** Slovenië */
	Si: "SI",
	/** Slowakije */
	Sk: "SK",
} as const

export type Country = (typeof Country)[keyof typeof Country]
/** An enumeration. */
export const Language = {
	/** Engels */
	En: "EN",
	/** Frans */
	Fr: "FR",
	/** Nederlands */
	Nl: "NL",
} as const

export type Language = (typeof Language)[keyof typeof Language]
/** An enumeration. */
export const Nationality = {
	/** Andorra */
	Ad: "AD",
	/** Verenigde Arabische Emiraten */
	Ae: "AE",
	/** Afghanistan */
	Af: "AF",
	/** Antigua en Barbuda */
	Ag: "AG",
	/** Anguilla */
	Ai: "AI",
	/** Albanië */
	Al: "AL",
	/** Armenië */
	Am: "AM",
	/** Angola */
	Ao: "AO",
	/** Antarctica */
	Aq: "AQ",
	/** Argentinië */
	Ar: "AR",
	/** Amerikaans-Samoa */
	As: "AS",
	/** Oostenrijk */
	At: "AT",
	/** Australië */
	Au: "AU",
	/** Aruba */
	Aw: "AW",
	/** Ålandseilanden */
	Ax: "AX",
	/** Azerbeidzjan */
	Az: "AZ",
	/** Bosnië en Herzegovina */
	Ba: "BA",
	/** Barbados */
	Bb: "BB",
	/** Bangladesh */
	Bd: "BD",
	/** België */
	Be: "BE",
	/** Burkina Faso */
	Bf: "BF",
	/** Bulgarije */
	Bg: "BG",
	/** Bahrein */
	Bh: "BH",
	/** Burundi */
	Bi: "BI",
	/** Benin */
	Bj: "BJ",
	/** Saint-Barthélemy */
	Bl: "BL",
	/** Bermuda */
	Bm: "BM",
	/** Brunei */
	Bn: "BN",
	/** Bolivia */
	Bo: "BO",
	/** Bonaire, Sint Eustatius en Saba */
	Bq: "BQ",
	/** Brazilië */
	Br: "BR",
	/** Bahama's */
	Bs: "BS",
	/** Bhutan */
	Bt: "BT",
	/** Bouvet */
	Bv: "BV",
	/** Botswana */
	Bw: "BW",
	/** Wit-Rusland */
	By: "BY",
	/** Belize */
	Bz: "BZ",
	/** Canada */
	Ca: "CA",
	/** Cocoseilanden */
	Cc: "CC",
	/** Democratische Republiek Congo (DRC) */
	Cd: "CD",
	/** Centraal-Afrikaanse Republiek */
	Cf: "CF",
	/** Congo-Brazzaville */
	Cg: "CG",
	/** Zwitserland */
	Ch: "CH",
	/** Ivoorkust */
	Ci: "CI",
	/** Cookeilanden */
	Ck: "CK",
	/** Chili */
	Cl: "CL",
	/** Kameroen */
	Cm: "CM",
	/** China */
	Cn: "CN",
	/** Colombia */
	Co: "CO",
	/** Costa Rica */
	Cr: "CR",
	/** Cuba */
	Cu: "CU",
	/** Kaapverdië */
	Cv: "CV",
	/** Curaçao */
	Cw: "CW",
	/** Christmaseiland */
	Cx: "CX",
	/** Cyprus */
	Cy: "CY",
	/** Tsjechië */
	Cz: "CZ",
	/** Duitsland */
	De: "DE",
	/** Djibouti */
	Dj: "DJ",
	/** Denemarken */
	Dk: "DK",
	/** Dominica */
	Dm: "DM",
	/** Dominicaanse Republiek */
	Do: "DO",
	/** Algerije */
	Dz: "DZ",
	/** Ecuador */
	Ec: "EC",
	/** Estland */
	Ee: "EE",
	/** Egypte */
	Eg: "EG",
	/** Westelijke Sahara */
	Eh: "EH",
	/** Eritrea */
	Er: "ER",
	/** Spanje */
	Es: "ES",
	/** Ethiopië */
	Et: "ET",
	/** Finland */
	Fi: "FI",
	/** Fiji */
	Fj: "FJ",
	/** Falklandeilanden */
	Fk: "FK",
	/** Micronesië */
	Fm: "FM",
	/** Faeröer */
	Fo: "FO",
	/** Frankrijk */
	Fr: "FR",
	/** Gabon */
	Ga: "GA",
	/** Verenigd Koninkrijk */
	Gb: "GB",
	/** Grenada */
	Gd: "GD",
	/** Georgië */
	Ge: "GE",
	/** Frans-Guyana */
	Gf: "GF",
	/** Guernsey */
	Gg: "GG",
	/** Ghana */
	Gh: "GH",
	/** Gibraltar */
	Gi: "GI",
	/** Groenland */
	Gl: "GL",
	/** Gambia */
	Gm: "GM",
	/** Guinee */
	Gn: "GN",
	/** Guadeloupe */
	Gp: "GP",
	/** Equatoriaal-Guinea */
	Gq: "GQ",
	/** Griekenland */
	Gr: "GR",
	/** Zuid-Georgië en de Zuidelijke Sandwicheilanden */
	Gs: "GS",
	/** Guatemala */
	Gt: "GT",
	/** Guam */
	Gu: "GU",
	/** Guinee-Bissau */
	Gw: "GW",
	/** Guyana */
	Gy: "GY",
	/** Hongkong */
	Hk: "HK",
	/** Heard en McDonaldeilanden */
	Hm: "HM",
	/** Honduras */
	Hn: "HN",
	/** Kroatië */
	Hr: "HR",
	/** Haïti */
	Ht: "HT",
	/** Hongarije */
	Hu: "HU",
	/** Indonesië */
	Id: "ID",
	/** Ierland */
	Ie: "IE",
	/** Israël */
	Il: "IL",
	/** Het Eiland Man */
	Im: "IM",
	/** India */
	In: "IN",
	/** Brits Territorium in de Indische Oceaan */
	Io: "IO",
	/** Irak */
	Iq: "IQ",
	/** Iran */
	Ir: "IR",
	/** IJsland */
	Is: "IS",
	/** Italië */
	It: "IT",
	/** Jersey */
	Je: "JE",
	/** Jamaica */
	Jm: "JM",
	/** Jordanië */
	Jo: "JO",
	/** Japan */
	Jp: "JP",
	/** Kenia */
	Ke: "KE",
	/** Kirgizië */
	Kg: "KG",
	/** Cambodja */
	Kh: "KH",
	/** Kiribati */
	Ki: "KI",
	/** Comoren */
	Km: "KM",
	/** Saint Kitts en Nevis */
	Kn: "KN",
	/** Noord-Korea */
	Kp: "KP",
	/** Zuid-Korea */
	Kr: "KR",
	/** Koeweit */
	Kw: "KW",
	/** Caymaneilanden */
	Ky: "KY",
	/** Kazachstan */
	Kz: "KZ",
	/** Laos */
	La: "LA",
	/** Libanon */
	Lb: "LB",
	/** Saint Lucia */
	Lc: "LC",
	/** Liechtenstein */
	Li: "LI",
	/** Sri Lanka */
	Lk: "LK",
	/** Liberia */
	Lr: "LR",
	/** Lesotho */
	Ls: "LS",
	/** Litouwen */
	Lt: "LT",
	/** Luxemburg */
	Lu: "LU",
	/** Letland */
	Lv: "LV",
	/** Libië */
	Ly: "LY",
	/** Marokko */
	Ma: "MA",
	/** Monaco */
	Mc: "MC",
	/** Moldavië */
	Md: "MD",
	/** Montenegro */
	Me: "ME",
	/** Sint-Maarten (Franse Antillen) */
	Mf: "MF",
	/** Madagaskar */
	Mg: "MG",
	/** Marshalleilanden */
	Mh: "MH",
	/** Noord-Macedonië */
	Mk: "MK",
	/** Mali */
	Ml: "ML",
	/** Myanmar */
	Mm: "MM",
	/** Mongolië */
	Mn: "MN",
	/** Macau */
	Mo: "MO",
	/** Noordelijke Marianen */
	Mp: "MP",
	/** Martinique */
	Mq: "MQ",
	/** Mauritanië */
	Mr: "MR",
	/** Montserrat */
	Ms: "MS",
	/** Malta */
	Mt: "MT",
	/** Mauritius */
	Mu: "MU",
	/** Maldiven */
	Mv: "MV",
	/** Malawi */
	Mw: "MW",
	/** Mexico */
	Mx: "MX",
	/** Maleisië */
	My: "MY",
	/** Mozambique */
	Mz: "MZ",
	/** Namibië */
	Na: "NA",
	/** Nieuw-Caledonië */
	Nc: "NC",
	/** Niger */
	Ne: "NE",
	/** Norfolk */
	Nf: "NF",
	/** Nigeria */
	Ng: "NG",
	/** Nicaragua */
	Ni: "NI",
	/** Nederland */
	Nl: "NL",
	/** Noorwegen */
	No: "NO",
	/** Nepal */
	Np: "NP",
	/** Nauru */
	Nr: "NR",
	/** Niue */
	Nu: "NU",
	/** Nieuw-Zeeland */
	Nz: "NZ",
	/** Oman */
	Om: "OM",
	/** Panama */
	Pa: "PA",
	/** Peru */
	Pe: "PE",
	/** Frans-Polynesië */
	Pf: "PF",
	/** Papoea-Nieuw-Guinea */
	Pg: "PG",
	/** Filippijnen */
	Ph: "PH",
	/** Pakistan */
	Pk: "PK",
	/** Polen */
	Pl: "PL",
	/** Saint-Pierre en Miquelon */
	Pm: "PM",
	/** Pitcairneilanden */
	Pn: "PN",
	/** Puerto Rico */
	Pr: "PR",
	/** Palestina, Staat */
	Ps: "PS",
	/** Portugal */
	Pt: "PT",
	/** Palau */
	Pw: "PW",
	/** Paraguay */
	Py: "PY",
	/** Qatar */
	Qa: "QA",
	/** Réunion */
	Re: "RE",
	/** Roemenië */
	Ro: "RO",
	/** Servië */
	Rs: "RS",
	/** Rusland */
	Ru: "RU",
	/** Rwanda */
	Rw: "RW",
	/** Saoedi-Arabië */
	Sa: "SA",
	/** Salomonseilanden */
	Sb: "SB",
	/** Seychellen */
	Sc: "SC",
	/** Soedan */
	Sd: "SD",
	/** Zweden */
	Se: "SE",
	/** Singapore */
	Sg: "SG",
	/** Sint-Helena, Ascension en Tristan da Cunha */
	Sh: "SH",
	/** Slovenië */
	Si: "SI",
	/** Jan Mayen */
	Sj: "SJ",
	/** Slowakije */
	Sk: "SK",
	/** Sierra Leone */
	Sl: "SL",
	/** San Marino */
	Sm: "SM",
	/** Senegal */
	Sn: "SN",
	/** Somalië */
	So: "SO",
	/** Suriname */
	Sr: "SR",
	/** Zuid Soedan */
	Ss: "SS",
	/** Sao Tomé en Principe */
	St: "ST",
	/** El Salvador */
	Sv: "SV",
	/** Sint-Maarten */
	Sx: "SX",
	/** Syrië */
	Sy: "SY",
	/** Eswatini */
	Sz: "SZ",
	/** Turks- en Caicoseilanden */
	Tc: "TC",
	/** Tsjaad */
	Td: "TD",
	/** Franse Zuidelijke en Antarctische Gebieden */
	Tf: "TF",
	/** Togo */
	Tg: "TG",
	/** Thailand */
	Th: "TH",
	/** Tadzjikistan */
	Tj: "TJ",
	/** Tokelau-eilanden */
	Tk: "TK",
	/** Oost-Timor */
	Tl: "TL",
	/** Turkmenistan */
	Tm: "TM",
	/** Tunesië */
	Tn: "TN",
	/** Tonga */
	To: "TO",
	/** Turkije */
	Tr: "TR",
	/** Trinidad en Tobago */
	Tt: "TT",
	/** Tuvalu */
	Tv: "TV",
	/** Taiwan */
	Tw: "TW",
	/** Tanzania */
	Tz: "TZ",
	/** Oekraïne */
	Ua: "UA",
	/** Oeganda */
	Ug: "UG",
	/** Kleine Pacifische eilanden van de Verenigde Staten */
	Um: "UM",
	/** Verenigde Staten van Amerika */
	Us: "US",
	/** Uruguay */
	Uy: "UY",
	/** Oezbekistan */
	Uz: "UZ",
	/** Heilige Stoel */
	Va: "VA",
	/** Saint Vincent en de Grenadines */
	Vc: "VC",
	/** Venezuela */
	Ve: "VE",
	/** Britse Maagdeneilanden */
	Vg: "VG",
	/** Amerikaanse Maagdeneilanden */
	Vi: "VI",
	/** Vietnam */
	Vn: "VN",
	/** Vanuatu */
	Vu: "VU",
	/** Wallis en Futuna */
	Wf: "WF",
	/** Samoa */
	Ws: "WS",
	/** Jemen */
	Ye: "YE",
	/** Mayotte */
	Yt: "YT",
	/** Zuid-Afrika */
	Za: "ZA",
	/** Zambia */
	Zm: "ZM",
	/** Zimbabwe */
	Zw: "ZW",
} as const

export type Nationality = (typeof Nationality)[keyof typeof Nationality]
/** An enumeration. */
export const Region = {
	/** Drenthe */
	Dr: "DR",
	/** Flevoland */
	Fl: "FL",
	/** Friesland */
	Fr: "FR",
	/** Gelderland */
	Gl: "GL",
	/** Groningen */
	Gr: "GR",
	/** Limburg */
	Lb: "LB",
	/** Noord-Brabant */
	Nb: "NB",
	/** Noord-Holland */
	Nh: "NH",
	/** Overijssel */
	Ov: "OV",
	/** Utrecht */
	Ut: "UT",
	/** Zeeland */
	Ze: "ZE",
	/** Zuid-Holland */
	Zh: "ZH",
} as const

export type Region = (typeof Region)[keyof typeof Region]
export type UpdateUserLanguageMutationVariables = Exact<{
	input: ProfileUpdateInput
}>

export type UpdateUserLanguageMutation = {
	__typename?: "Mutations"
	profile_update?: {
		__typename?: "ProfileUpdatePayload"
		language?: Language | null
	} | null
}

export type AllProjectNotificationsQueryVariables = Exact<{
	[key: string]: never
}>

export type AllProjectNotificationsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor?: {
			__typename?: "InvestorType"
			notification_configuration?: {
				__typename?: "NotificationConfigurationType"
				id: string
				receive_check_data?: boolean | null
				receive_monthly_report?: boolean | null
				disabled_yearly_interest_report_projects?: Array<{
					__typename?: "ProjectForInvestorType"
					id: string
				} | null> | null
			} | null
		} | null
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				name?: string | null
				id?: string | null
				investor_yearly_report_notification_status?: boolean | null
			} | null> | null
		} | null
	} | null
}

export type BankDetailsQueryVariables = Exact<{ [key: string]: never }>

export type BankDetailsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		full_name?: string | null
		investor?: {
			__typename?: "InvestorType"
			masked_iban?: string | null
		} | null
	} | null
}

export type ChangePasswordMutationVariables = Exact<{
	input: PasswordResetInput
}>

export type ChangePasswordMutation = {
	__typename?: "Mutations"
	password_reset?: {
		__typename?: "PasswordResetPayload"
		errors?: Array<{
			__typename?: "ErrorType"
			messages: Array<string>
			field: string
		} | null> | null
	} | null
}

export type CompanyDetailsQueryQueryVariables = Exact<{ [key: string]: never }>

export type CompanyDetailsQueryQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		organisations?: Array<{
			__typename?: "OrganisationType"
			id: string
			business_legal_name?: string | null
			business_nr?: string | null
			bic?: string | null
			iban?: string | null
			location?: {
				__typename?: "LocationType"
				address_line_primary: string
				address_line_secondary: string
				city: string
				country: CoreLocationCountryChoices
				postal_code: string
			} | null
		} | null> | null
	} | null
}

export type CompanyDetailsMutationMutationVariables = Exact<{
	input: OrganisationUpdateInput
}>

export type CompanyDetailsMutationMutation = {
	__typename?: "Mutations"
	organisation_update?: {
		__typename?: "OrganisationUpdatePayload"
		id?: string | null
		location?: {
			__typename?: "LocationType"
			address_line_primary: string
			address_line_secondary: string
			city: string
			region?: CoreLocationRegionChoices | null
			country: CoreLocationCountryChoices
			postal_code: string
		} | null
		errors?: Array<{
			__typename?: "ErrorType"
			messages: Array<string>
			field: string
		} | null> | null
	} | null
}

export type InvestorUpdateMutationVariables = Exact<{
	investor: InvestorUpdateInput
}>

export type InvestorUpdateMutation = {
	__typename?: "Mutations"
	investor_update?: {
		__typename?: "InvestorUpdatePayload"
		masked_iban?: string | null
		notification_configuration?: {
			__typename?: "NotificationConfigurationType"
			receive_check_data?: boolean | null
			receive_monthly_report?: boolean | null
			disabled_yearly_interest_report_projects?: Array<{
				__typename?: "ProjectForInvestorType"
				id: string
			} | null> | null
		} | null
	} | null
}

export type InvestorIbanMutationVariables = Exact<{
	investor: InvestorUpdateInput
}>

export type InvestorIbanMutation = {
	__typename?: "Mutations"
	investor_update?: {
		__typename?: "InvestorUpdatePayload"
		masked_iban?: string | null
		errors?: Array<{
			__typename?: "ErrorType"
			messages: Array<string>
			field: string
		} | null> | null
	} | null
}

export type PersonalDetailsQueryVariables = Exact<{ [key: string]: never }>

export type PersonalDetailsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		full_name?: string | null
		email: string
		profile?: {
			__typename?: "UserProfileType"
			initials: string
			phone?: string | null
			date_of_birth?: any | null
			address?: {
				__typename?: "LocationType"
				address_line_primary: string
				address_line_secondary: string
				postal_code: string
				city: string
				country: CoreLocationCountryChoices
			} | null
		} | null
		investor?: {
			__typename?: "InvestorType"
			supplier_account?: string | null
			supplier?: {
				__typename?: "EnergySupplierType"
				id: string
				name: string
			} | null
		} | null
	} | null
	energy_suppliers?: Array<{
		__typename?: "EnergySupplierType"
		name: string
		id: string
	}> | null
}

export type ProfileUpdateMutationVariables = Exact<{
	profile: ProfileUpdateInput
	investor: InvestorUpdateInput
}>

export type ProfileUpdateMutation = {
	__typename?: "Mutations"
	profile_update?: {
		__typename?: "ProfileUpdatePayload"
		initials?: string | null
		phone?: string | null
		date_of_birth?: any | null
		address?: {
			__typename?: "LocationType"
			address_line_primary: string
			address_line_secondary: string
			postal_code: string
			city: string
			country: CoreLocationCountryChoices
		} | null
		errors?: Array<{
			__typename?: "ErrorType"
			field: string
			messages: Array<string>
		} | null> | null
	} | null
	investor_update?: {
		__typename?: "InvestorUpdatePayload"
		supplier_account?: string | null
	} | null
}

export type UserContextQueryVariables = Exact<{ [key: string]: never }>

export type UserContextQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		id: string
		first_name?: string | null
		last_name?: string | null
		full_name?: string | null
		email: string
		roles_old?: {
			__typename?: "UserRoles"
			is_superuser?: boolean | null
			is_staff?: boolean | null
			is_investor?: boolean | null
			is_installer?: boolean | null
			is_project_owner?: boolean | null
			is_energy_supplier?: boolean | null
		} | null
		profile?: {
			__typename?: "UserProfileType"
			identity_type: UsersUserProfileIdentityTypeChoices
			timezone: UsersUserProfileTimezoneChoices
			language: UsersUserProfileLanguageChoices
		} | null
		kyc?: { __typename?: "KycType"; state: RiskKycStateChoices } | null
		investment_tests?: Array<{
			__typename?: "InvestmentTestType"
			id: string
			category: CoreInvestmentTestCategoryChoices
			created_at: any
			expiry_date?: any | null
			experience?: CoreInvestmentTestExperienceChoices | null
			data?: any | null
		}> | null
		messages?: {
			__typename?: "MessagesType"
			list?: {
				__typename?: "MessageConnectionType"
				total_count?: number | null
			} | null
		} | null
		payment_entries?: {
			__typename?: "PaymentEntriesListType"
			totalCount?: number | null
		} | null
		provisional_investments?: {
			__typename?: "InvestmentListType"
			totalCount?: number | null
		} | null
		investment_projects?: {
			__typename?: "ProjectListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "ProjectType"
				state?: ProjectStateEnum | null
			} | null> | null
		} | null
	} | null
}

export type FinanceGraphCumulativeProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	endTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
	id: Scalars["ID"]["input"]
}>

export type FinanceGraphCumulativeProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				overall_statistics?: {
					__typename?: "OverallStatisticsType"
					expected_cumulative_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
					cumulative_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type FinanceGraphDailyProductionQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
	date: Scalars["Date"]["input"]
}>

export type FinanceGraphDailyProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				overall_statistics?: {
					__typename?: "OverallStatisticsType"
					production_for_day?: {
						__typename?: "StatsProductionForDay"
						total_production?: string | null
						production_data?: Array<{
							__typename?: "StatsProductionGeneric"
							date?: string | null
							production?: string | null
						} | null> | null
					} | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type FinanceGraphProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	endTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
	knmiDataIncluded?: InputMaybe<Scalars["Boolean"]["input"]>
	id: Scalars["ID"]["input"]
}>

export type FinanceGraphProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				targeted_performance_ratio?: number | null
				overall_statistics?: {
					__typename?: "OverallStatisticsType"
					production_performance_ratio?: {
						__typename?: "StatsPerformanceRatioData"
						total_performance_ratio?: string | null
						performance_ratio_data?: Array<{
							__typename?: "StatsPerformanceRatioGeneric"
							date?: string | null
							performance_ratio?: string | null
						} | null> | null
					} | null
					expected_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
					total_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type FinanceProjectIndexQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type FinanceProjectIndexQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				type?: ProjectTypeEnum | null
				state?: ProjectStateEnum | null
				start?: any | null
				end?: any | null
			} | null> | null
		} | null
	} | null
}

export type FinanceDashboardQueryVariables = Exact<{
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
}>

export type FinanceDashboardQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				latitude?: number | null
				longitude?: number | null
				overview?: {
					__typename?: "ProjectOverviewStatsType"
					total_production?: string | null
					performance_ratio?: string | null
					production_delta?: string | null
					yearly_production_factor?: string | null
					targeted_performance_ratio?: string | null
					monitoring_status?: {
						__typename?: "MonitoringStatusStatsType"
						name?: string | null
						status?: number | null
						data?: Array<string | null> | null
					} | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type FinanceDashboardMapsQueryVariables = Exact<{
	latitude: Scalars["Decimal"]["input"]
	longitude: Scalars["Decimal"]["input"]
	radius?: InputMaybe<Scalars["Decimal"]["input"]>
}>

export type FinanceDashboardMapsQuery = {
	__typename?: "Query"
	public_projects?: {
		__typename?: "PublicProjectListType"
		results?: Array<{
			__typename?: "PublicProjectType"
			name?: string | null
			id: string
			latitude?: number | null
			longitude?: number | null
		} | null> | null
	} | null
}

export type FinanceBondsLoanQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type FinanceBondsLoanQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		finance_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				state?: ProjectStateEnum | null
				duration?: number | null
				start?: any | null
				end?: any | null
				pdf_memorandum?: string | null
				pdf_service_agreement?: string | null
				total_amount?: number | null
				amortization_schedule?: ProjectAmortizationScheduleEnum | null
				interest_payment_calculation_type?: ProjectInterestPaymentCalculationTypeEnum | null
				fixed_surcharge?: number | null
				fixed_energy_cost_per_kwh?: number | null
				fixed_interest_amount_per_share?: number | null
				fixed_interest_rate?: number | null
				fiscal_overview?: Array<{
					__typename?: "FiscalOverviewType"
					year?: string | null
					value_start?: string | null
					value_end?: string | null
				} | null> | null
				interest_periods?: Array<{
					__typename?: "InterestPeriodType"
					id: string
					is_current_period?: boolean | null
					is_first_period?: boolean | null
					end?: any | null
					payment_deadline_date?: any | null
					amortization?: number | null
					interest_amount?: string | null
					repayment?: number | null
				} | null> | null
			} | null> | null
		} | null
	} | null
}

export type FinanceBondsCurrentPaymentQueryVariables = Exact<{
	state?: InputMaybe<
		| Array<InputMaybe<Scalars["String"]["input"]>>
		| InputMaybe<Scalars["String"]["input"]>
	>
	q?: InputMaybe<Scalars["String"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
}>

export type FinanceBondsCurrentPaymentQuery = {
	__typename?: "Query"
	interest_payments?: {
		__typename?: "InterestPaymentListType"
		results?: Array<{
			__typename?: "InterestPaymentType"
			state?: PaymentStateEnum | null
			payment_link?: string | null
			interestperiod?: {
				__typename?: "InterestPeriodType"
				id: string
				is_current_period?: boolean | null
				is_first_period?: boolean | null
				end?: any | null
				payment_deadline_date?: any | null
				amortization?: number | null
				interest_amount?: string | null
				repayment?: number | null
			} | null
		} | null> | null
	} | null
}

export type FinancePerformanceQueryVariables = Exact<{ [key: string]: never }>

export type FinancePerformanceQuery = {
	__typename?: "Query"
	projects?: Array<{
		__typename?: "ProjectType"
		name?: string | null
		id?: string | null
	}> | null
}

export type MessagesQueryVariables = Exact<{
	count?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	subject?: InputMaybe<Scalars["String"]["input"]>
}>

export type MessagesQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		messages?: {
			__typename?: "MessagesType"
			list?: {
				__typename?: "MessageConnectionType"
				total_count?: number | null
				results?: Array<{
					__typename?: "MessageType"
					message_id?: any | null
					subject?: string | null
					received_at?: string | null
				} | null> | null
			} | null
		} | null
	} | null
}

export type MessageDetailQueryVariables = Exact<{
	message_id?: InputMaybe<Scalars["UUID"]["input"]>
}>

export type MessageDetailQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		messages?: {
			__typename?: "MessagesType"
			detail?: {
				__typename?: "MessageType"
				subject?: string | null
				text_body?: string | null
				html_body?: string | null
				message_events?: any | null
				attachments?: any | null
			} | null
		} | null
	} | null
}

export type InvestmentsQueryVariables = Exact<{
	offset?: InputMaybe<Scalars["Int"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
}>

export type InvestmentsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		provisional_investments?: {
			__typename?: "InvestmentListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "InvestmentType"
				id: string
				amount_total?: number | null
				created_at?: any | null
				state?: InvestmentStateEnum | null
				agreement_file?: any | null
				comment?: string | null
				refund_amount?: number | null
				refund_reason?: InvestmentRefundReasonEnum | null
				refund_amount_currency?: InvestmentRefundAmountCurrencyEnum | null
				crowdfunding_campaign?: {
					__typename?: "InvestmentCampaignType"
					page_url?: string | null
					project?: {
						__typename?: "InvestmentCampaignProjectType"
						title?: string | null
						project?: {
							__typename?: "ProjectType"
							issuance_date_in_information_memorandum?: any | null
						} | null
					} | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type AllInvestorPaymentsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	startTime?: InputMaybe<Scalars["Date"]["input"]>
	endTime?: InputMaybe<Scalars["Date"]["input"]>
	transactionId?: InputMaybe<Scalars["String"]["input"]>
}>

export type AllInvestorPaymentsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		payment_entries?: {
			__typename?: "PaymentEntriesListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "PaymentEntryType"
				state?: PayOutState | null
				payment_state?: State | null
				share_count?: number | null
				cost?: number | null
				amortization?: number | null
				repayment?: number | null
				investor_payout?: number | null
				transaction_id?: string | null
				supplier?: {
					__typename?: "EnergySupplierType"
					is_solar_interest_paid_to_supplier: boolean
					name: string
				} | null
				project?: {
					__typename?: "ProjectType"
					id?: string | null
					name?: string | null
					type?: ProjectTypeEnum | null
					amortization_schedule?: ProjectAmortizationScheduleEnum | null
				} | null
				interest_period?: {
					__typename?: "InterestPeriodType"
					start?: any | null
					end?: any | null
					is_first_period?: boolean | null
					payment_deadline_date?: any | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type FiscalOverviewByYearQueryVariables = Exact<{
	start?: InputMaybe<Scalars["Date"]["input"]>
	end?: InputMaybe<Scalars["Date"]["input"]>
}>

export type FiscalOverviewByYearQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		full_name?: string | null
		uuid?: any | null
		profile?: {
			__typename?: "UserProfileType"
			address?: {
				__typename?: "LocationType"
				address_line_primary: string
				address_line_secondary: string
				city: string
				region?: CoreLocationRegionChoices | null
				country: CoreLocationCountryChoices
				postal_code: string
			} | null
		} | null
		organisations?: Array<{
			__typename?: "OrganisationType"
			business_legal_name?: string | null
			business_nr?: string | null
			location?: {
				__typename?: "LocationType"
				address_line_primary: string
				address_line_secondary: string
				city: string
				country: CoreLocationCountryChoices
				region?: CoreLocationRegionChoices | null
				postal_code: string
			} | null
		} | null> | null
		start?: {
			__typename?: "InvestorSharesValueStatsType"
			total_investment_value?: string | null
		} | null
		end?: {
			__typename?: "InvestorSharesValueStatsType"
			total_investment_value?: string | null
		} | null
		payment_entries?: {
			__typename?: "PaymentEntriesListType"
			results?: Array<{
				__typename?: "PaymentEntryType"
				state?: PayOutState | null
				cost?: number | null
				amortization?: number | null
				project?: {
					__typename?: "ProjectType"
					id?: string | null
				} | null
			} | null> | null
		} | null
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				name?: string | null
				id?: string | null
				start?: {
					__typename?: "InvestorSharesValueStatsType"
					share_value?: string | null
					total_shares?: number | null
					total_investment_value?: string | null
				} | null
				end?: {
					__typename?: "InvestorSharesValueStatsType"
					share_value?: string | null
					total_shares?: number | null
					total_investment_value?: string | null
					total_nominal_investment?: string | null
					shares?: Array<{
						__typename?: "SolarShareType"
						share_number?: number | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type InvestmentsGridViewQueryVariables = Exact<{
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Scalars["String"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	start?: InputMaybe<Scalars["DateTime"]["input"]>
	valuationDate?: InputMaybe<Scalars["Date"]["input"]>
}>

export type InvestmentsGridViewQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "ProjectType"
				name?: string | null
				state?: ProjectStateEnum | null
				type?: ProjectTypeEnum | null
				id?: string | null
				image_url?: any | null
				installer?: {
					__typename?: "InstallerType"
					name: string
				} | null
				investor_shares_value_stats?: {
					__typename?: "InvestorSharesValueStatsType"
					total_investment_for_project?: string | null
					total_repaid_for_project?: string | null
				} | null
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					investor_generated_power_in_kwh?: string | null
				} | null
				current_interest_period?: {
					__typename?: "InterestPeriodType"
					end?: any | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type InvestmentsGraphDailyProductionQueryVariables = Exact<{
	day: Scalars["Date"]["input"]
}>

export type InvestmentsGraphDailyProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor_production_stats?: {
			__typename?: "InvestorProductionStatsType"
			production_for_day?: {
				__typename?: "StatsProductionForDay"
				total_production?: string | null
				production_data?: Array<{
					__typename?: "StatsProductionGeneric"
					date?: string | null
					production?: string | null
				} | null> | null
			} | null
		} | null
	} | null
}

export type InvestmentsGraphProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	endTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
}>

export type InvestmentsGraphProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor_production_stats?: {
			__typename?: "InvestorProductionStatsType"
			production_data?: Array<{
				__typename?: "StatsProductionGeneric"
				date?: string | null
				production?: string | null
			} | null> | null
			expected_production_data?: Array<{
				__typename?: "StatsProductionGeneric"
				date?: string | null
				production?: string | null
			} | null> | null
		} | null
	} | null
}

export type InvestmentsTableViewQueryVariables = Exact<{
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	state?: InputMaybe<Scalars["String"]["input"]>
	type?: InputMaybe<Scalars["String"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	valuationDate?: InputMaybe<Scalars["Date"]["input"]>
}>

export type InvestmentsTableViewQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			totalCount?: number | null
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				investor_shares_value_stats?: {
					__typename?: "InvestorSharesValueStatsType"
					total_investment_for_project?: string | null
					total_repaid_for_project?: string | null
					total_shares?: number | null
					shares?: Array<{
						__typename?: "SolarShareType"
						nominal_value?: number | null
					} | null> | null
					internal_rate_of_returns?: {
						__typename?: "InvestorInternalRateOfReturns"
						internal_rate_of_return?: string | null
						expected_internal_rate_of_return?: string | null
					} | null
				} | null
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					investor_generated_power_in_kwh?: string | null
				} | null
				current_interest_period?: {
					__typename?: "InterestPeriodType"
					end?: any | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type CurrentFinancialOverviewQueryVariables = Exact<{
	valuationDate?: InputMaybe<Scalars["Date"]["input"]>
}>

export type CurrentFinancialOverviewQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				name?: string | null
				id?: string | null
				investor_shares_value_stats?: {
					__typename?: "InvestorSharesValueStatsType"
					share_value?: string | null
					total_shares?: number | null
					total_investment_value?: string | null
					total_nominal_investment?: string | null
					shares?: Array<{
						__typename?: "SolarShareType"
						share_number?: number | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type StatisticsMySavingsQueryVariables = Exact<{ [key: string]: never }>

export type StatisticsMySavingsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor_production_stats?: {
			__typename?: "InvestorProductionStatsType"
			total_production_for_counter?: string | null
			production_speed?: string | null
		} | null
	} | null
}

export type StatisticsMySavingsTotalsQueryVariables = Exact<{
	date?: InputMaybe<Scalars["Date"]["input"]>
}>

export type StatisticsMySavingsTotalsQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				type?: ProjectTypeEnum | null
			} | null> | null
		} | null
		investor_shares_value_stats?: {
			__typename?: "InvestorSharesValueStatsType"
			total_shares?: number | null
		} | null
	} | null
}

export type StatisticsMySavingsTotalsSlowQueryVariables = Exact<{
	date?: InputMaybe<Scalars["Date"]["input"]>
}>

export type StatisticsMySavingsTotalsSlowQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor_shares_value_stats?: {
			__typename?: "InvestorSharesValueStatsType"
			total_nominal_investment?: string | null
			total_investment_value?: string | null
			total_cost?: string | null
			total_amortization?: string | null
			total_repayment?: string | null
			total_repaid?: string | null
		} | null
	} | null
}

export type AllInvestmentProjectsDatesQueryVariables = Exact<{
	[key: string]: never
}>

export type AllInvestmentProjectsDatesQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				start?: any | null
				end?: any | null
			} | null> | null
		} | null
	} | null
}

export type ProjectMonthlyProductionTableQueryVariables = Exact<{
	startTime?: InputMaybe<Scalars["DateTime"]["input"]>
	endTime?: InputMaybe<Scalars["DateTime"]["input"]>
}>

export type ProjectMonthlyProductionTableQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				name?: string | null
				state?: ProjectStateEnum | null
				investor_shares_value_stats?: {
					__typename?: "InvestorSharesValueStatsType"
					total_shares?: number | null
				} | null
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					investor_generated_power_in_kwh?: string | null
					total_production_percentage?: string | null
					investor_co2_saving?: string | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type MonthlyOverviewQueryVariables = Exact<{
	end?: InputMaybe<Scalars["Date"]["input"]>
}>

export type MonthlyOverviewQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		full_name?: string | null
		investment_projects?: {
			__typename?: "ProjectListType"
			totalCount?: number | null
		} | null
		end?: {
			__typename?: "InvestorSharesValueStatsType"
			total_investment_value?: string | null
			total_investment_value_per_project_type?: Array<{
				__typename?: "InvestorStatsInvestorShareValuePerProjectType"
				project_type?: string | null
				share_value?: string | null
			} | null> | null
		} | null
	} | null
}

export type InvestmentsGraphCumulativeProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	endTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
}>

export type InvestmentsGraphCumulativeProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investor_production_stats?: {
			__typename?: "InvestorProductionStatsType"
			cumulative_production_data?: Array<{
				__typename?: "StatsProductionGeneric"
				date?: string | null
				production?: string | null
			} | null> | null
			expected_cumulative_production_data?: Array<{
				__typename?: "StatsProductionGeneric"
				date?: string | null
				production?: string | null
			} | null> | null
		} | null
	} | null
}

export type UserKycStatusQueryVariables = Exact<{ [key: string]: never }>

export type UserKycStatusQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		uuid?: any | null
		kyc?: {
			__typename?: "KycType"
			id: string
			state: RiskKycStateChoices
			human_readable_kyc_error?: string | null
			last_verified_on?: any | null
		} | null
	} | null
}

export type InvestorTestMutationsInputMutationVariables = Exact<{
	input: InvestorTestMutationsInput
}>

export type InvestorTestMutationsInputMutation = {
	__typename?: "Mutations"
	investment_test_update?: {
		__typename?: "InvestorTestMutationsPayload"
		investor_test?: {
			__typename?: "InvestmentTestType"
			id: string
			category: CoreInvestmentTestCategoryChoices
			expiry_date?: any | null
			experience?: CoreInvestmentTestExperienceChoices | null
		} | null
		errors?: Array<{
			__typename?: "ErrorType"
			messages: Array<string>
			field: string
		} | null> | null
	} | null
}

export type PaymentEntryEnergySupplierFragment = {
	__typename?: "PaymentEntryType"
	id: string
	share_count?: number | null
	name?: string | null
	postcode?: string | null
	number?: string | null
	suffix?: string | null
	iban?: string | null
	supplier_account?: string | null
	production?: number | null
	investor_email?: string | null
	investor_phone_number?: string | null
	cost?: number | null
	amortization?: number | null
	repayment?: number | null
	energy_supplier_bonus?: number | null
	supplier?: {
		__typename?: "EnergySupplierType"
		id: string
		name: string
	} | null
}

export type PaymentDetailEnergySupplierQueryVariables = Exact<{
	id?: InputMaybe<Scalars["ID"]["input"]>
}>

export type PaymentDetailEnergySupplierQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		first_name?: string | null
		full_name?: string | null
	} | null
	energy_suppliers?: Array<{
		__typename?: "EnergySupplierType"
		name: string
		id: string
		is_partner: boolean
		is_solar_interest_paid_to_supplier: boolean
	}> | null
	interest_payments?: {
		__typename?: "InterestPaymentListType"
		results?: Array<{
			__typename?: "InterestPaymentType"
			id: string
			state?: PaymentStateEnum | null
			energy_supplier_acceptance_date_time?: any | null
			payment_date?: any | null
			download_csv_url?: string | null
			download_pdf_url?: string | null
			transaction_id?: string | null
			total_production?: number | null
			interest_per_share_limit_applied?: PaymentInterestPerShareLimitAppliedEnum | null
			total_production_of_investors_with_supplier?: string | null
			total_production_of_investors_with_supplier_limited?: string | null
			interest_amount?: string | null
			project?: {
				__typename?: "ProjectType"
				name?: string | null
				city?: string | null
				total_shares?: number | null
				supplier?: {
					__typename?: "EnergySupplierType"
					id: string
					name: string
				} | null
				owner?: { __typename?: "ProjectOwnerType"; name: string } | null
			} | null
			interestperiod?: {
				__typename?: "InterestPeriodType"
				start?: any | null
				end?: any | null
				amortization?: number | null
				repayment?: number | null
				payment_deadline_date?: any | null
				cost_kwh?: number | null
				average_endex_tariff?: number | null
			} | null
			payment_entries?: Array<{
				__typename?: "PaymentEntryType"
				id: string
			}> | null
			payment_entries_with_supplier?: Array<{
				__typename?: "PaymentEntryType"
				id: string
				share_count?: number | null
				name?: string | null
				postcode?: string | null
				number?: string | null
				suffix?: string | null
				iban?: string | null
				supplier_account?: string | null
				production?: number | null
				investor_email?: string | null
				investor_phone_number?: string | null
				cost?: number | null
				amortization?: number | null
				repayment?: number | null
				energy_supplier_bonus?: number | null
				supplier?: {
					__typename?: "EnergySupplierType"
					id: string
					name: string
				} | null
			}> | null
		} | null> | null
	} | null
}

export type PaymentEntryProjectOwnerFragment = {
	__typename?: "PaymentEntryType"
	share_count?: number | null
	name?: string | null
	supplier_account?: string | null
	production?: number | null
	cost?: number | null
	amortization?: number | null
	repayment?: number | null
}

export type PaymentDetailProjectOwnerQueryVariables = Exact<{
	id?: InputMaybe<Scalars["ID"]["input"]>
}>

export type PaymentDetailProjectOwnerQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		first_name?: string | null
		full_name?: string | null
	} | null
	energy_suppliers?: Array<{
		__typename?: "EnergySupplierType"
		name: string
		id: string
		is_partner: boolean
		is_solar_interest_paid_to_supplier: boolean
	}> | null
	interest_payments?: {
		__typename?: "InterestPaymentListType"
		results?: Array<{
			__typename?: "InterestPaymentType"
			id: string
			state?: PaymentStateEnum | null
			project_owner_acceptance_date_time?: any | null
			payment_date?: any | null
			download_csv_url?: string | null
			download_pdf_url?: string | null
			transaction_id?: string | null
			total_production?: number | null
			interest_per_share_limit_applied?: PaymentInterestPerShareLimitAppliedEnum | null
			total_production_investor?: string | null
			total_production_investor_limited?: string | null
			interest_amount?: string | null
			project?: {
				__typename?: "ProjectType"
				name?: string | null
				city?: string | null
				total_shares?: number | null
				supplier?: {
					__typename?: "EnergySupplierType"
					id: string
					name: string
				} | null
				owner?: { __typename?: "ProjectOwnerType"; name: string } | null
			} | null
			interestperiod?: {
				__typename?: "InterestPeriodType"
				start?: any | null
				end?: any | null
				amortization?: number | null
				repayment?: number | null
				payment_deadline_date?: any | null
				cost_kwh?: number | null
				average_endex_tariff?: number | null
			} | null
			payment_entries?: Array<{
				__typename?: "PaymentEntryType"
				share_count?: number | null
				name?: string | null
				supplier_account?: string | null
				production?: number | null
				cost?: number | null
				amortization?: number | null
				repayment?: number | null
			}> | null
		} | null> | null
	} | null
}

export type PaymentsQueryVariables = Exact<{
	state?: InputMaybe<
		| Array<InputMaybe<Scalars["String"]["input"]>>
		| InputMaybe<Scalars["String"]["input"]>
	>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	ordering?: InputMaybe<Scalars["String"]["input"]>
	q?: InputMaybe<Scalars["String"]["input"]>
}>

export type PaymentsQuery = {
	__typename?: "Query"
	interest_payments?: {
		__typename?: "InterestPaymentListType"
		totalCount?: number | null
		results?: Array<{
			__typename?: "InterestPaymentType"
			state?: PaymentStateEnum | null
			id: string
			transaction_id?: string | null
			progress_percentage?: number | null
			payment_link?: string | null
			interest_amount?: string | null
			project?: {
				__typename?: "ProjectType"
				name?: string | null
			} | null
			interestperiod?: {
				__typename?: "InterestPeriodType"
				start?: any | null
				end?: any | null
				amortization?: number | null
				repayment?: number | null
				payment_deadline_date?: any | null
			} | null
		} | null> | null
	} | null
}

export type ProfileSecurityIntroQueryVariables = Exact<{ [key: string]: never }>

export type ProfileSecurityIntroQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		email: string
		profile?: {
			__typename?: "UserProfileType"
			phone?: string | null
		} | null
	} | null
}

export type ProjectGraphCumulativeProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
	endTime: Scalars["DateTime"]["input"]
	projectId: Scalars["ID"]["input"]
}>

export type ProjectGraphCumulativeProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					expected_cumulative_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
					cumulative_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type ProjectGraphDailyProductionQueryVariables = Exact<{
	projectId: Scalars["ID"]["input"]
	day: Scalars["Date"]["input"]
}>

export type ProjectGraphDailyProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					production_for_day?: {
						__typename?: "StatsProductionForDay"
						total_production?: string | null
						production_data?: Array<{
							__typename?: "StatsProductionGeneric"
							date?: string | null
							production?: string | null
						} | null> | null
					} | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type ProjectGraphProductionQueryVariables = Exact<{
	startTime: Scalars["DateTime"]["input"]
	endTime: Scalars["DateTime"]["input"]
	interval: Scalars["String"]["input"]
	projectId: Scalars["ID"]["input"]
}>

export type ProjectGraphProductionQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				investor_production_stats?: {
					__typename?: "InvestorProductionStatsType"
					expected_production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
					production_data?: Array<{
						__typename?: "StatsProductionGeneric"
						date?: string | null
						production?: string | null
					} | null> | null
				} | null
			} | null> | null
		} | null
	} | null
}

export type ProjectIndexQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ProjectIndexQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "UserType"
		investment_projects?: {
			__typename?: "ProjectListType"
			results?: Array<{
				__typename?: "ProjectType"
				id?: string | null
				name?: string | null
				state?: ProjectStateEnum | null
				type?: ProjectTypeEnum | null
				image_url?: any | null
				start?: any | null
				end?: any | null
				supplier?: {
					__typename?: "EnergySupplierType"
					name: string
					logo?: any | null
				} | null
				owner?: {
					__typename?: "ProjectOwnerType"
					email?: string | null
				} | null
				current_interest_period?: {
					__typename?: "InterestPeriodType"
					start?: any | null
					end?: any | null
					payment_deadline_date?: any | null
				} | null
				investor_shares_value_stats?: {
					__typename?: "InvestorSharesValueStatsType"
					total_investment_for_project?: string | null
					total_repaid_for_project?: string | null
					total_shares?: number | null
					shares?: Array<{
						__typename?: "SolarShareType"
						nominal_value?: number | null
					} | null> | null
				} | null
				documents?: Array<{
					__typename?: "InvestmentCampaignDocument"
					title?: string | null
					url?: string | null
					description?: string | null
				} | null> | null
			} | null> | null
		} | null
	} | null
}

export type ProjectNotificationsQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ProjectNotificationsQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		id?: string | null
		name?: string | null
		investor_yearly_report_notification_status?: boolean | null
	} | null
}

export type ProjectNotificationsUpdateMutationVariables = Exact<{
	investor: InvestorUpdateInput
}>

export type ProjectNotificationsUpdateMutation = {
	__typename?: "Mutations"
	investor_update?: {
		__typename?: "InvestorUpdatePayload"
		notification_configuration?: {
			__typename?: "NotificationConfigurationType"
			disabled_yearly_interest_report_projects?: Array<{
				__typename?: "ProjectForInvestorType"
				id: string
			} | null> | null
		} | null
	} | null
}

export type ProjectRevenueQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ProjectRevenueQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		name?: string | null
		type?: ProjectTypeEnum | null
		investor_shares_value_stats?: {
			__typename?: "InvestorSharesValueStatsType"
			internal_rate_of_returns?: {
				__typename?: "InvestorInternalRateOfReturns"
				expected_internal_rate_of_return?: string | null
				internal_rate_of_return?: string | null
			} | null
		} | null
	} | null
}

export type InterestPaymentsQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
	ordering?: InputMaybe<Scalars["String"]["input"]>
	project_id: Scalars["ID"]["input"]
}>

export type InterestPaymentsQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		type?: ProjectTypeEnum | null
	} | null
	me?: {
		__typename?: "UserType"
		investment_campaigns?: Array<{
			__typename?: "InvestmentCampaignProjectType"
			id: string
			description?: string | null
			project?: { __typename?: "ProjectType"; id?: string | null } | null
		}> | null
		interest_payments?: Array<{
			__typename?: "InterestPaymentType"
			transaction_id?: string | null
			interestperiod?: {
				__typename?: "InterestPeriodType"
				start?: any | null
				end?: any | null
				is_first_period?: boolean | null
				payment_deadline_date?: any | null
			} | null
			project?: {
				__typename?: "ProjectType"
				amortization_schedule?: ProjectAmortizationScheduleEnum | null
			} | null
			payment_entries?: Array<{
				__typename?: "PaymentEntryType"
				state?: PayOutState | null
				payment_state?: State | null
				share_count?: number | null
				cost?: number | null
				amortization?: number | null
				repayment?: number | null
				investor_payout?: number | null
				supplier?: {
					__typename?: "EnergySupplierType"
					is_solar_interest_paid_to_supplier: boolean
					name: string
				} | null
			}> | null
		}> | null
	} | null
}

export type ProjectsStatsTableQueryVariables = Exact<{
	latitude: Scalars["Decimal"]["input"]
	longitude: Scalars["Decimal"]["input"]
	radius?: InputMaybe<Scalars["Decimal"]["input"]>
	name__icontains?: InputMaybe<Scalars["String"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	offset?: InputMaybe<Scalars["Int"]["input"]>
	type?: InputMaybe<
		| Array<InputMaybe<Scalars["String"]["input"]>>
		| InputMaybe<Scalars["String"]["input"]>
	>
}>

export type ProjectsStatsTableQuery = {
	__typename?: "Query"
	public_projects?: {
		__typename?: "PublicProjectListType"
		totalCount?: number | null
		results?: Array<{
			__typename?: "PublicProjectType"
			id: string
			name?: string | null
			type?: string | null
			start?: any | null
			project_obligations_status?: ProjectProjectObligationsStatusEnum | null
		} | null> | null
	} | null
}

export type ProjectInternalStateOfReturnQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ProjectInternalStateOfReturnQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		overall_statistics?: {
			__typename?: "OverallStatisticsType"
			total_production?: string | null
		} | null
	} | null
	project_statistics?: {
		__typename?: "ProjectStatisticsType"
		delta_production?: string | null
		delta_energy_price?: string | null
		solar_interest_chart_data?: {
			__typename?: "SolarInterestChartDataType"
			interest_period_numbers?: Array<number | null> | null
			roi_of_interest_periods?: Array<number | null> | null
			expected_roi_of_interest_periods?: Array<number | null> | null
		} | null
	} | null
}

export type AllPlatformStatisticsAdditionalQueryVariables = Exact<{
	[key: string]: never
}>

export type AllPlatformStatisticsAdditionalQuery = {
	__typename?: "Query"
	platform_statistics?: {
		__typename?: "PlatformStatisticsType"
		avg_investment_per_investor?: number | null
		capacity_in_mwp?: number | null
		solar_bonds_volume?: number | null
		max_investment?: number | null
		project_partners?: number | null
		total_projects?: number | null
		total_amount_invested?: number | null
		total_production_in_households_usage?: number | null
		total_production_in_gwh?: number | null
		total_expected_production_in_gwh?: number | null
		total_production_in_households_usage_per_year?: Array<{
			__typename?: "TotalProductionInHouseholdsUsagePerYearType"
			year?: number | null
			production?: number | null
		} | null> | null
	} | null
}

export type AllPlatformStatisticsQueryVariables = Exact<{
	[key: string]: never
}>

export type AllPlatformStatisticsQuery = {
	__typename?: "Query"
	platform_statistics?: {
		__typename?: "PlatformStatisticsType"
		total_investors?: number | null
		percentage_of_project_wise_repeated_investors?: number | null
		saved_co2?: number | null
		total_solar_panels?: number | null
	} | null
}

export type LifeTimeProductionChartQueryVariables = Exact<{
	[key: string]: never
}>

export type LifeTimeProductionChartQuery = {
	__typename?: "Query"
	platform_statistics?: {
		__typename?: "PlatformStatisticsType"
		total_production_in_gwh?: number | null
		total_expected_production_in_gwh?: number | null
	} | null
}

export type AllPlatformStatisticsTotalProductionQueryVariables = Exact<{
	start?: Scalars["DateTime"]["input"]
	end?: Scalars["DateTime"]["input"]
}>

export type AllPlatformStatisticsTotalProductionQuery = {
	__typename?: "Query"
	overall_statistics?: {
		__typename?: "OverallStatisticsType"
		total_production_for_counter?: string | null
		production_speed?: string | null
	} | null
}

export type SingleProjectStatsQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type SingleProjectStatsQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		name?: string | null
		start?: any | null
		project_obligations_status?: ProjectProjectObligationsStatusEnum | null
		project_obligations_explanation_text?: string | null
		type?: ProjectTypeEnum | null
		amortization_schedule?: ProjectAmortizationScheduleEnum | null
		cap_eur_interest_per_share?: number | null
		floor_eur_interest_per_share?: number | null
		supplier?: { __typename?: "EnergySupplierType"; name: string } | null
		overall_statistics?: {
			__typename?: "OverallStatisticsType"
			total_production?: string | null
		} | null
	} | null
}

export type SingleProjectStatsIrrQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type SingleProjectStatsIrrQuery = {
	__typename?: "Query"
	project?: {
		__typename?: "ProjectType"
		name?: string | null
		start?: any | null
		project_obligations_status?: ProjectProjectObligationsStatusEnum | null
		project_obligations_explanation_text?: string | null
		type?: ProjectTypeEnum | null
		amortization_schedule?: ProjectAmortizationScheduleEnum | null
		cap_eur_interest_per_share?: number | null
		floor_eur_interest_per_share?: number | null
		supplier?: { __typename?: "EnergySupplierType"; name: string } | null
	} | null
	project_statistics?: {
		__typename?: "ProjectStatisticsType"
		internal_rate_of_return?: number | null
		expected_internal_rate_of_return?: number | null
	} | null
}

export type SingleProjectStatsInterestPeriodsQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type SingleProjectStatsInterestPeriodsQuery = {
	__typename?: "Query"
	projects?: Array<{
		__typename?: "ProjectType"
		type?: ProjectTypeEnum | null
	}> | null
	project_statistics?: {
		__typename?: "ProjectStatisticsType"
		stats_information_by_interest_period?: Array<{
			__typename?: "StatsInformationByInterestPeriodType"
			is_current_interest_period?: boolean | null
			is_completed_interest_period?: boolean | null
			production_per_share?: string | null
			energy_price?: string | null
			solar_interest_per_share?: string | null
			payment_status?: string | null
			payment_per_share?: string | null
			return_on_investment?: string | null
			expected_production_per_share?: string | null
			expected_solar_interest_per_share?: string | null
			expected_payment_per_share?: string | null
		} | null> | null
	} | null
}

export const PaymentEntryEnergySupplierFragmentDoc = `
    fragment PaymentEntryEnergySupplier on PaymentEntryType {
  id
  share_count
  name
  postcode
  number
  suffix
  iban
  supplier_account
  production
  investor_email
  investor_phone_number
  supplier {
    id
    name
  }
  cost
  amortization
  repayment
  energy_supplier_bonus
}
    `
export const PaymentEntryProjectOwnerFragmentDoc = `
    fragment PaymentEntryProjectOwner on PaymentEntryType {
  share_count
  name
  supplier_account
  production
  cost
  amortization
  repayment
}
    `
export const UpdateUserLanguageDocument = `
    mutation UpdateUserLanguage($input: ProfileUpdateInput!) {
  profile_update(input: $input) {
    language
  }
}
    `

export const useUpdateUserLanguageMutation = <
	TError = unknown,
	TContext = unknown,
>(
	options?: UseMutationOptions<
		UpdateUserLanguageMutation,
		TError,
		UpdateUserLanguageMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		UpdateUserLanguageMutation,
		TError,
		UpdateUserLanguageMutationVariables,
		TContext
	>({
		mutationKey: ["UpdateUserLanguage"],
		mutationFn: (variables?: UpdateUserLanguageMutationVariables) =>
			queryFetcher<
				UpdateUserLanguageMutation,
				UpdateUserLanguageMutationVariables
			>(UpdateUserLanguageDocument, variables)(),
		...options,
	})
}

export const AllProjectNotificationsDocument = `
    query AllProjectNotifications {
  me {
    investor {
      notification_configuration {
        id
        receive_check_data
        receive_monthly_report
        disabled_yearly_interest_report_projects {
          id
        }
      }
    }
    investment_projects {
      results {
        name
        id
        investor_yearly_report_notification_status
      }
    }
  }
}
    `

export const useAllProjectNotificationsQuery = <
	TData = AllProjectNotificationsQuery,
	TError = unknown,
>(
	variables?: AllProjectNotificationsQueryVariables,
	options?: Omit<
		UseQueryOptions<AllProjectNotificationsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllProjectNotificationsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllProjectNotificationsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllProjectNotifications"]
				: ["AllProjectNotifications", variables],
		queryFn: queryFetcher<
			AllProjectNotificationsQuery,
			AllProjectNotificationsQueryVariables
		>(AllProjectNotificationsDocument, variables),
		...options,
	})
}

export const BankDetailsDocument = `
    query BankDetails {
  me {
    full_name
    investor {
      masked_iban
    }
  }
}
    `

export const useBankDetailsQuery = <TData = BankDetailsQuery, TError = unknown>(
	variables?: BankDetailsQueryVariables,
	options?: Omit<
		UseQueryOptions<BankDetailsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<BankDetailsQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<BankDetailsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["BankDetails"]
				: ["BankDetails", variables],
		queryFn: queryFetcher<BankDetailsQuery, BankDetailsQueryVariables>(
			BankDetailsDocument,
			variables,
		),
		...options,
	})
}

export const ChangePasswordDocument = `
    mutation ChangePassword($input: PasswordResetInput!) {
  password_reset(input: $input) {
    errors {
      messages
      field
    }
  }
}
    `

export const useChangePasswordMutation = <TError = unknown, TContext = unknown>(
	options?: UseMutationOptions<
		ChangePasswordMutation,
		TError,
		ChangePasswordMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		ChangePasswordMutation,
		TError,
		ChangePasswordMutationVariables,
		TContext
	>({
		mutationKey: ["ChangePassword"],
		mutationFn: (variables?: ChangePasswordMutationVariables) =>
			queryFetcher<
				ChangePasswordMutation,
				ChangePasswordMutationVariables
			>(ChangePasswordDocument, variables)(),
		...options,
	})
}

export const CompanyDetailsQueryDocument = `
    query companyDetailsQuery {
  me {
    organisations {
      id
      business_legal_name
      business_nr
      bic
      iban
      location {
        address_line_primary
        address_line_secondary
        city
        country
        postal_code
      }
    }
  }
}
    `

export const useCompanyDetailsQueryQuery = <
	TData = CompanyDetailsQueryQuery,
	TError = unknown,
>(
	variables?: CompanyDetailsQueryQueryVariables,
	options?: Omit<
		UseQueryOptions<CompanyDetailsQueryQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			CompanyDetailsQueryQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<CompanyDetailsQueryQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["companyDetailsQuery"]
				: ["companyDetailsQuery", variables],
		queryFn: queryFetcher<
			CompanyDetailsQueryQuery,
			CompanyDetailsQueryQueryVariables
		>(CompanyDetailsQueryDocument, variables),
		...options,
	})
}

export const CompanyDetailsMutationDocument = `
    mutation companyDetailsMutation($input: OrganisationUpdateInput!) {
  organisation_update(input: $input) {
    id
    location {
      address_line_primary
      address_line_secondary
      city
      region
      country
      postal_code
    }
    errors {
      messages
      field
    }
  }
}
    `

export const useCompanyDetailsMutationMutation = <
	TError = unknown,
	TContext = unknown,
>(
	options?: UseMutationOptions<
		CompanyDetailsMutationMutation,
		TError,
		CompanyDetailsMutationMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		CompanyDetailsMutationMutation,
		TError,
		CompanyDetailsMutationMutationVariables,
		TContext
	>({
		mutationKey: ["companyDetailsMutation"],
		mutationFn: (variables?: CompanyDetailsMutationMutationVariables) =>
			queryFetcher<
				CompanyDetailsMutationMutation,
				CompanyDetailsMutationMutationVariables
			>(CompanyDetailsMutationDocument, variables)(),
		...options,
	})
}

export const InvestorUpdateDocument = `
    mutation InvestorUpdate($investor: InvestorUpdateInput!) {
  investor_update(input: $investor) {
    notification_configuration {
      disabled_yearly_interest_report_projects {
        id
      }
      receive_check_data
      receive_monthly_report
    }
    masked_iban
  }
}
    `

export const useInvestorUpdateMutation = <TError = unknown, TContext = unknown>(
	options?: UseMutationOptions<
		InvestorUpdateMutation,
		TError,
		InvestorUpdateMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		InvestorUpdateMutation,
		TError,
		InvestorUpdateMutationVariables,
		TContext
	>({
		mutationKey: ["InvestorUpdate"],
		mutationFn: (variables?: InvestorUpdateMutationVariables) =>
			queryFetcher<
				InvestorUpdateMutation,
				InvestorUpdateMutationVariables
			>(InvestorUpdateDocument, variables)(),
		...options,
	})
}

export const InvestorIbanDocument = `
    mutation InvestorIban($investor: InvestorUpdateInput!) {
  investor_update(input: $investor) {
    masked_iban
    errors {
      messages
      field
    }
  }
}
    `

export const useInvestorIbanMutation = <TError = unknown, TContext = unknown>(
	options?: UseMutationOptions<
		InvestorIbanMutation,
		TError,
		InvestorIbanMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		InvestorIbanMutation,
		TError,
		InvestorIbanMutationVariables,
		TContext
	>({
		mutationKey: ["InvestorIban"],
		mutationFn: (variables?: InvestorIbanMutationVariables) =>
			queryFetcher<InvestorIbanMutation, InvestorIbanMutationVariables>(
				InvestorIbanDocument,
				variables,
			)(),
		...options,
	})
}

export const PersonalDetailsDocument = `
    query PersonalDetails {
  me {
    full_name
    email
    profile {
      initials
      phone
      date_of_birth
      address {
        address_line_primary
        address_line_secondary
        postal_code
        city
        country
      }
    }
    investor {
      supplier_account
      supplier {
        id
        name
      }
    }
  }
  energy_suppliers(limit: 1000) {
    name
    id
  }
}
    `

export const usePersonalDetailsQuery = <
	TData = PersonalDetailsQuery,
	TError = unknown,
>(
	variables?: PersonalDetailsQueryVariables,
	options?: Omit<
		UseQueryOptions<PersonalDetailsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			PersonalDetailsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<PersonalDetailsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["PersonalDetails"]
				: ["PersonalDetails", variables],
		queryFn: queryFetcher<
			PersonalDetailsQuery,
			PersonalDetailsQueryVariables
		>(PersonalDetailsDocument, variables),
		...options,
	})
}

export const ProfileUpdateDocument = `
    mutation ProfileUpdate($profile: ProfileUpdateInput!, $investor: InvestorUpdateInput!) {
  profile_update(input: $profile) {
    initials
    phone
    date_of_birth
    address {
      address_line_primary
      address_line_secondary
      postal_code
      city
      country
    }
    errors {
      field
      messages
    }
  }
  investor_update(input: $investor) {
    supplier_account
  }
}
    `

export const useProfileUpdateMutation = <TError = unknown, TContext = unknown>(
	options?: UseMutationOptions<
		ProfileUpdateMutation,
		TError,
		ProfileUpdateMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		ProfileUpdateMutation,
		TError,
		ProfileUpdateMutationVariables,
		TContext
	>({
		mutationKey: ["ProfileUpdate"],
		mutationFn: (variables?: ProfileUpdateMutationVariables) =>
			queryFetcher<ProfileUpdateMutation, ProfileUpdateMutationVariables>(
				ProfileUpdateDocument,
				variables,
			)(),
		...options,
	})
}

export const UserContextDocument = `
    query UserContext {
  me {
    id
    first_name
    last_name
    full_name
    email
    roles_old {
      is_superuser
      is_staff
      is_investor
      is_installer
      is_project_owner
      is_energy_supplier
    }
    profile {
      identity_type
      timezone
      language
    }
    kyc {
      state
    }
    investment_tests {
      id
      category
      created_at
      expiry_date
      experience
      data
    }
    messages {
      list {
        total_count
      }
    }
    payment_entries {
      totalCount
    }
    provisional_investments {
      totalCount
    }
    investment_projects {
      totalCount
      results(limit: null) {
        state
      }
    }
  }
}
    `

export const useUserContextQuery = <TData = UserContextQuery, TError = unknown>(
	variables?: UserContextQueryVariables,
	options?: Omit<
		UseQueryOptions<UserContextQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<UserContextQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<UserContextQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["UserContext"]
				: ["UserContext", variables],
		queryFn: queryFetcher<UserContextQuery, UserContextQueryVariables>(
			UserContextDocument,
			variables,
		),
		...options,
	})
}

export const FinanceGraphCumulativeProductionDocument = `
    query FinanceGraphCumulativeProduction($startTime: DateTime!, $endTime: DateTime!, $interval: String!, $id: ID!) {
  me {
    finance_projects(id: $id) {
      results {
        overall_statistics(start: $startTime, end: $endTime) {
          expected_cumulative_production_data(grouped_by: $interval) {
            date
            production
          }
          cumulative_production_data(grouped_by: $interval) {
            date
            production
          }
        }
      }
    }
  }
}
    `

export const useFinanceGraphCumulativeProductionQuery = <
	TData = FinanceGraphCumulativeProductionQuery,
	TError = unknown,
>(
	variables: FinanceGraphCumulativeProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceGraphCumulativeProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceGraphCumulativeProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceGraphCumulativeProductionQuery, TError, TData>({
		queryKey: ["FinanceGraphCumulativeProduction", variables],
		queryFn: queryFetcher<
			FinanceGraphCumulativeProductionQuery,
			FinanceGraphCumulativeProductionQueryVariables
		>(FinanceGraphCumulativeProductionDocument, variables),
		...options,
	})
}

export const FinanceGraphDailyProductionDocument = `
    query FinanceGraphDailyProduction($id: ID!, $date: Date!) {
  me {
    finance_projects(id: $id) {
      results {
        overall_statistics {
          production_for_day(day: $date) {
            production_data {
              date
              production
            }
            total_production
          }
        }
      }
    }
  }
}
    `

export const useFinanceGraphDailyProductionQuery = <
	TData = FinanceGraphDailyProductionQuery,
	TError = unknown,
>(
	variables: FinanceGraphDailyProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceGraphDailyProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceGraphDailyProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceGraphDailyProductionQuery, TError, TData>({
		queryKey: ["FinanceGraphDailyProduction", variables],
		queryFn: queryFetcher<
			FinanceGraphDailyProductionQuery,
			FinanceGraphDailyProductionQueryVariables
		>(FinanceGraphDailyProductionDocument, variables),
		...options,
	})
}

export const FinanceGraphProductionDocument = `
    query FinanceGraphProduction($startTime: DateTime!, $endTime: DateTime!, $interval: String!, $knmiDataIncluded: Boolean, $id: ID!) {
  me {
    finance_projects(id: $id) {
      results {
        targeted_performance_ratio
        overall_statistics(start: $startTime, end: $endTime) {
          production_performance_ratio(
            knmi_data: $knmiDataIncluded
            grouped_by: $interval
          ) {
            performance_ratio_data {
              date
              performance_ratio
            }
            total_performance_ratio
          }
          expected_production_data(grouped_by: $interval) {
            date
            production
          }
          total_production_data(grouped_by: $interval) {
            date
            production
          }
        }
      }
    }
  }
}
    `

export const useFinanceGraphProductionQuery = <
	TData = FinanceGraphProductionQuery,
	TError = unknown,
>(
	variables: FinanceGraphProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceGraphProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceGraphProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceGraphProductionQuery, TError, TData>({
		queryKey: ["FinanceGraphProduction", variables],
		queryFn: queryFetcher<
			FinanceGraphProductionQuery,
			FinanceGraphProductionQueryVariables
		>(FinanceGraphProductionDocument, variables),
		...options,
	})
}

export const FinanceProjectIndexDocument = `
    query FinanceProjectIndex($id: ID!) {
  me {
    finance_projects(id: $id) {
      results {
        id
        name
        type
        state
        start
        end
      }
    }
  }
}
    `

export const useFinanceProjectIndexQuery = <
	TData = FinanceProjectIndexQuery,
	TError = unknown,
>(
	variables: FinanceProjectIndexQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceProjectIndexQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceProjectIndexQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceProjectIndexQuery, TError, TData>({
		queryKey: ["FinanceProjectIndex", variables],
		queryFn: queryFetcher<
			FinanceProjectIndexQuery,
			FinanceProjectIndexQueryVariables
		>(FinanceProjectIndexDocument, variables),
		...options,
	})
}

export const FinanceDashboardDocument = `
    query FinanceDashboard($limit: Int, $offset: Int) {
  me {
    finance_projects {
      totalCount
      results(limit: $limit, offset: $offset) {
        id
        name
        latitude
        longitude
        overview {
          monitoring_status {
            name
            status
            data
          }
          total_production
          performance_ratio
          production_delta
          yearly_production_factor
          targeted_performance_ratio
        }
      }
    }
  }
}
    `

export const useFinanceDashboardQuery = <
	TData = FinanceDashboardQuery,
	TError = unknown,
>(
	variables?: FinanceDashboardQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceDashboardQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceDashboardQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceDashboardQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["FinanceDashboard"]
				: ["FinanceDashboard", variables],
		queryFn: queryFetcher<
			FinanceDashboardQuery,
			FinanceDashboardQueryVariables
		>(FinanceDashboardDocument, variables),
		...options,
	})
}

export const FinanceDashboardMapsDocument = `
    query FinanceDashboardMaps($latitude: Decimal!, $longitude: Decimal!, $radius: Decimal) {
  public_projects(latitude: $latitude, longitude: $longitude, radius: $radius) {
    results(limit: null) {
      name
      id
      latitude
      longitude
    }
  }
}
    `

export const useFinanceDashboardMapsQuery = <
	TData = FinanceDashboardMapsQuery,
	TError = unknown,
>(
	variables: FinanceDashboardMapsQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceDashboardMapsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceDashboardMapsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceDashboardMapsQuery, TError, TData>({
		queryKey: ["FinanceDashboardMaps", variables],
		queryFn: queryFetcher<
			FinanceDashboardMapsQuery,
			FinanceDashboardMapsQueryVariables
		>(FinanceDashboardMapsDocument, variables),
		...options,
	})
}

export const FinanceBondsLoanDocument = `
    query FinanceBondsLoan($id: ID!) {
  me {
    finance_projects(id: $id) {
      results {
        id
        name
        state
        duration
        start
        end
        pdf_memorandum
        pdf_service_agreement
        total_amount
        amortization_schedule
        fiscal_overview {
          year
          value_start
          value_end
        }
        interest_periods {
          id
          is_current_period
          is_first_period
          end
          payment_deadline_date
          amortization
          interest_amount
          repayment
        }
        interest_payment_calculation_type
        fixed_surcharge
        fixed_energy_cost_per_kwh
        fixed_interest_amount_per_share
        fixed_interest_rate
      }
    }
  }
}
    `

export const useFinanceBondsLoanQuery = <
	TData = FinanceBondsLoanQuery,
	TError = unknown,
>(
	variables: FinanceBondsLoanQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceBondsLoanQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceBondsLoanQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceBondsLoanQuery, TError, TData>({
		queryKey: ["FinanceBondsLoan", variables],
		queryFn: queryFetcher<
			FinanceBondsLoanQuery,
			FinanceBondsLoanQueryVariables
		>(FinanceBondsLoanDocument, variables),
		...options,
	})
}

export const FinanceBondsCurrentPaymentDocument = `
    query FinanceBondsCurrentPayment($state: [String], $q: String, $limit: Int) {
  interest_payments(state: $state, q: $q) {
    results(limit: $limit) {
      state
      payment_link
      interestperiod {
        id
        is_current_period
        is_first_period
        end
        payment_deadline_date
        amortization
        interest_amount
        repayment
      }
    }
  }
}
    `

export const useFinanceBondsCurrentPaymentQuery = <
	TData = FinanceBondsCurrentPaymentQuery,
	TError = unknown,
>(
	variables?: FinanceBondsCurrentPaymentQueryVariables,
	options?: Omit<
		UseQueryOptions<FinanceBondsCurrentPaymentQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinanceBondsCurrentPaymentQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinanceBondsCurrentPaymentQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["FinanceBondsCurrentPayment"]
				: ["FinanceBondsCurrentPayment", variables],
		queryFn: queryFetcher<
			FinanceBondsCurrentPaymentQuery,
			FinanceBondsCurrentPaymentQueryVariables
		>(FinanceBondsCurrentPaymentDocument, variables),
		...options,
	})
}

export const FinancePerformanceDocument = `
    query FinancePerformance {
  projects(limit: null) {
    name
    id
  }
}
    `

export const useFinancePerformanceQuery = <
	TData = FinancePerformanceQuery,
	TError = unknown,
>(
	variables?: FinancePerformanceQueryVariables,
	options?: Omit<
		UseQueryOptions<FinancePerformanceQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FinancePerformanceQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FinancePerformanceQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["FinancePerformance"]
				: ["FinancePerformance", variables],
		queryFn: queryFetcher<
			FinancePerformanceQuery,
			FinancePerformanceQueryVariables
		>(FinancePerformanceDocument, variables),
		...options,
	})
}

export const MessagesDocument = `
    query Messages($count: Int, $offset: Int, $subject: String) {
  me {
    messages {
      list(count: $count, offset: $offset, subject: $subject) {
        total_count
        results {
          message_id
          subject
          received_at
        }
      }
    }
  }
}
    `

export const useMessagesQuery = <TData = MessagesQuery, TError = unknown>(
	variables?: MessagesQueryVariables,
	options?: Omit<
		UseQueryOptions<MessagesQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<MessagesQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<MessagesQuery, TError, TData>({
		queryKey:
			variables === undefined ? ["Messages"] : ["Messages", variables],
		queryFn: queryFetcher<MessagesQuery, MessagesQueryVariables>(
			MessagesDocument,
			variables,
		),
		...options,
	})
}

export const MessageDetailDocument = `
    query MessageDetail($message_id: UUID) {
  me {
    messages {
      detail(message_id: $message_id) {
        subject
        text_body
        html_body
        message_events
        attachments
      }
    }
  }
}
    `

export const useMessageDetailQuery = <
	TData = MessageDetailQuery,
	TError = unknown,
>(
	variables?: MessageDetailQueryVariables,
	options?: Omit<
		UseQueryOptions<MessageDetailQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			MessageDetailQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<MessageDetailQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["MessageDetail"]
				: ["MessageDetail", variables],
		queryFn: queryFetcher<MessageDetailQuery, MessageDetailQueryVariables>(
			MessageDetailDocument,
			variables,
		),
		...options,
	})
}

export const InvestmentsDocument = `
    query Investments($offset: Int, $limit: Int) {
  me {
    provisional_investments {
      totalCount
      results(offset: $offset, limit: $limit) {
        id
        amount_total
        created_at
        state
        agreement_file
        comment
        refund_amount
        refund_reason
        refund_amount_currency
        crowdfunding_campaign {
          page_url
          project {
            title
            project {
              issuance_date_in_information_memorandum
            }
          }
        }
      }
    }
  }
}
    `

export const useInvestmentsQuery = <TData = InvestmentsQuery, TError = unknown>(
	variables?: InvestmentsQueryVariables,
	options?: Omit<
		UseQueryOptions<InvestmentsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<InvestmentsQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<InvestmentsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["Investments"]
				: ["Investments", variables],
		queryFn: queryFetcher<InvestmentsQuery, InvestmentsQueryVariables>(
			InvestmentsDocument,
			variables,
		),
		...options,
	})
}

export const AllInvestorPaymentsDocument = `
    query AllInvestorPayments($limit: Int, $offset: Int, $ordering: String, $startTime: Date, $endTime: Date, $transactionId: String) {
  me {
    payment_entries(
      start: $startTime
      end: $endTime
      transaction_id: $transactionId
    ) {
      totalCount
      results(limit: $limit, offset: $offset, ordering: $ordering) {
        state
        payment_state
        share_count
        cost
        amortization
        repayment
        supplier {
          is_solar_interest_paid_to_supplier
          name
        }
        investor_payout
        state
        transaction_id
        project {
          id
          name
          type
          amortization_schedule
        }
        interest_period {
          start
          end
          is_first_period
          payment_deadline_date
        }
      }
    }
  }
}
    `

export const useAllInvestorPaymentsQuery = <
	TData = AllInvestorPaymentsQuery,
	TError = unknown,
>(
	variables?: AllInvestorPaymentsQueryVariables,
	options?: Omit<
		UseQueryOptions<AllInvestorPaymentsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllInvestorPaymentsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllInvestorPaymentsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllInvestorPayments"]
				: ["AllInvestorPayments", variables],
		queryFn: queryFetcher<
			AllInvestorPaymentsQuery,
			AllInvestorPaymentsQueryVariables
		>(AllInvestorPaymentsDocument, variables),
		...options,
	})
}

export const FiscalOverviewByYearDocument = `
    query FiscalOverviewByYear($start: Date, $end: Date) {
  me {
    full_name
    uuid
    profile {
      address {
        address_line_primary
        address_line_secondary
        city
        region
        country
        postal_code
      }
    }
    organisations {
      business_legal_name
      business_nr
      location {
        address_line_primary
        address_line_secondary
        city
        country
        region
        postal_code
      }
    }
    start: investor_shares_value_stats(valuation_date: $start) {
      total_investment_value
    }
    end: investor_shares_value_stats(valuation_date: $end) {
      total_investment_value
    }
    payment_entries(start: $start, end: $end) {
      results {
        project {
          id
        }
        state
        cost
        amortization
      }
    }
    investment_projects {
      results(limit: null) {
        name
        id
        start: investor_shares_value_stats(valuation_date: $start) {
          share_value
          total_shares
          total_investment_value
        }
        end: investor_shares_value_stats(valuation_date: $end) {
          share_value
          total_shares
          total_investment_value
          total_nominal_investment
          shares {
            share_number
          }
        }
      }
    }
  }
}
    `

export const useFiscalOverviewByYearQuery = <
	TData = FiscalOverviewByYearQuery,
	TError = unknown,
>(
	variables?: FiscalOverviewByYearQueryVariables,
	options?: Omit<
		UseQueryOptions<FiscalOverviewByYearQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			FiscalOverviewByYearQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<FiscalOverviewByYearQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["FiscalOverviewByYear"]
				: ["FiscalOverviewByYear", variables],
		queryFn: queryFetcher<
			FiscalOverviewByYearQuery,
			FiscalOverviewByYearQueryVariables
		>(FiscalOverviewByYearDocument, variables),
		...options,
	})
}

export const InvestmentsGridViewDocument = `
    query InvestmentsGridView($limit: Int, $offset: Int, $ordering: String, $state: String, $type: String, $name: String, $start: DateTime, $valuationDate: Date) {
  me {
    investment_projects(name__icontains: $name, state: $state, type: $type) {
      results(limit: $limit, offset: $offset, ordering: $ordering) {
        name
        state
        type
        id
        image_url
        installer {
          name
        }
        investor_shares_value_stats(valuation_date: $valuationDate) {
          total_investment_for_project
          total_repaid_for_project
        }
        investor_production_stats(start: $start) {
          investor_generated_power_in_kwh
        }
        current_interest_period {
          end
        }
      }
      totalCount
    }
  }
}
    `

export const useInvestmentsGridViewQuery = <
	TData = InvestmentsGridViewQuery,
	TError = unknown,
>(
	variables?: InvestmentsGridViewQueryVariables,
	options?: Omit<
		UseQueryOptions<InvestmentsGridViewQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InvestmentsGridViewQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InvestmentsGridViewQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["InvestmentsGridView"]
				: ["InvestmentsGridView", variables],
		queryFn: queryFetcher<
			InvestmentsGridViewQuery,
			InvestmentsGridViewQueryVariables
		>(InvestmentsGridViewDocument, variables),
		...options,
	})
}

export const InvestmentsGraphDailyProductionDocument = `
    query InvestmentsGraphDailyProduction($day: Date!) {
  me {
    investor_production_stats {
      production_for_day(day: $day) {
        production_data {
          date
          production
        }
        total_production
      }
    }
  }
}
    `

export const useInvestmentsGraphDailyProductionQuery = <
	TData = InvestmentsGraphDailyProductionQuery,
	TError = unknown,
>(
	variables: InvestmentsGraphDailyProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<InvestmentsGraphDailyProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InvestmentsGraphDailyProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InvestmentsGraphDailyProductionQuery, TError, TData>({
		queryKey: ["InvestmentsGraphDailyProduction", variables],
		queryFn: queryFetcher<
			InvestmentsGraphDailyProductionQuery,
			InvestmentsGraphDailyProductionQueryVariables
		>(InvestmentsGraphDailyProductionDocument, variables),
		...options,
	})
}

export const InvestmentsGraphProductionDocument = `
    query InvestmentsGraphProduction($startTime: DateTime!, $endTime: DateTime!, $interval: String!) {
  me {
    investor_production_stats(start: $startTime, end: $endTime) {
      production_data(grouped_by: $interval) {
        date
        production
      }
      expected_production_data(grouped_by: $interval) {
        date
        production
      }
    }
  }
}
    `

export const useInvestmentsGraphProductionQuery = <
	TData = InvestmentsGraphProductionQuery,
	TError = unknown,
>(
	variables: InvestmentsGraphProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<InvestmentsGraphProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InvestmentsGraphProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InvestmentsGraphProductionQuery, TError, TData>({
		queryKey: ["InvestmentsGraphProduction", variables],
		queryFn: queryFetcher<
			InvestmentsGraphProductionQuery,
			InvestmentsGraphProductionQueryVariables
		>(InvestmentsGraphProductionDocument, variables),
		...options,
	})
}

export const InvestmentsTableViewDocument = `
    query InvestmentsTableView($limit: Int, $offset: Int, $ordering: String, $state: String, $type: String, $name: String, $valuationDate: Date) {
  me {
    investment_projects(name__icontains: $name, state: $state, type: $type) {
      results(limit: $limit, offset: $offset, ordering: $ordering) {
        id
        name
        investor_shares_value_stats(valuation_date: $valuationDate) {
          total_investment_for_project
          total_repaid_for_project
          total_shares
          shares {
            nominal_value
          }
          internal_rate_of_returns {
            internal_rate_of_return
            expected_internal_rate_of_return
          }
        }
        investor_production_stats {
          investor_generated_power_in_kwh
        }
        current_interest_period {
          end
        }
      }
      totalCount
    }
  }
}
    `

export const useInvestmentsTableViewQuery = <
	TData = InvestmentsTableViewQuery,
	TError = unknown,
>(
	variables?: InvestmentsTableViewQueryVariables,
	options?: Omit<
		UseQueryOptions<InvestmentsTableViewQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InvestmentsTableViewQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InvestmentsTableViewQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["InvestmentsTableView"]
				: ["InvestmentsTableView", variables],
		queryFn: queryFetcher<
			InvestmentsTableViewQuery,
			InvestmentsTableViewQueryVariables
		>(InvestmentsTableViewDocument, variables),
		...options,
	})
}

export const CurrentFinancialOverviewDocument = `
    query CurrentFinancialOverview($valuationDate: Date) {
  me {
    investment_projects {
      results(limit: null) {
        name
        id
        investor_shares_value_stats(valuation_date: $valuationDate) {
          share_value
          total_shares
          total_investment_value
          total_nominal_investment
          shares {
            share_number
          }
        }
      }
    }
  }
}
    `

export const useCurrentFinancialOverviewQuery = <
	TData = CurrentFinancialOverviewQuery,
	TError = unknown,
>(
	variables?: CurrentFinancialOverviewQueryVariables,
	options?: Omit<
		UseQueryOptions<CurrentFinancialOverviewQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			CurrentFinancialOverviewQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<CurrentFinancialOverviewQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["CurrentFinancialOverview"]
				: ["CurrentFinancialOverview", variables],
		queryFn: queryFetcher<
			CurrentFinancialOverviewQuery,
			CurrentFinancialOverviewQueryVariables
		>(CurrentFinancialOverviewDocument, variables),
		...options,
	})
}

export const StatisticsMySavingsDocument = `
    query StatisticsMySavings {
  me {
    investor_production_stats {
      total_production_for_counter
      production_speed
    }
  }
}
    `

export const useStatisticsMySavingsQuery = <
	TData = StatisticsMySavingsQuery,
	TError = unknown,
>(
	variables?: StatisticsMySavingsQueryVariables,
	options?: Omit<
		UseQueryOptions<StatisticsMySavingsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			StatisticsMySavingsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<StatisticsMySavingsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["StatisticsMySavings"]
				: ["StatisticsMySavings", variables],
		queryFn: queryFetcher<
			StatisticsMySavingsQuery,
			StatisticsMySavingsQueryVariables
		>(StatisticsMySavingsDocument, variables),
		...options,
	})
}

export const StatisticsMySavingsTotalsDocument = `
    query StatisticsMySavingsTotals($date: Date) {
  me {
    investment_projects {
      results(limit: null) {
        id
        type
      }
    }
    investor_shares_value_stats(valuation_date: $date) {
      total_shares
    }
  }
}
    `

export const useStatisticsMySavingsTotalsQuery = <
	TData = StatisticsMySavingsTotalsQuery,
	TError = unknown,
>(
	variables?: StatisticsMySavingsTotalsQueryVariables,
	options?: Omit<
		UseQueryOptions<StatisticsMySavingsTotalsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			StatisticsMySavingsTotalsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<StatisticsMySavingsTotalsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["StatisticsMySavingsTotals"]
				: ["StatisticsMySavingsTotals", variables],
		queryFn: queryFetcher<
			StatisticsMySavingsTotalsQuery,
			StatisticsMySavingsTotalsQueryVariables
		>(StatisticsMySavingsTotalsDocument, variables),
		...options,
	})
}

export const StatisticsMySavingsTotalsSlowDocument = `
    query StatisticsMySavingsTotalsSlow($date: Date) {
  me {
    investor_shares_value_stats(valuation_date: $date) {
      total_nominal_investment
      total_investment_value
      total_cost
      total_amortization
      total_repayment
      total_repaid
    }
  }
}
    `

export const useStatisticsMySavingsTotalsSlowQuery = <
	TData = StatisticsMySavingsTotalsSlowQuery,
	TError = unknown,
>(
	variables?: StatisticsMySavingsTotalsSlowQueryVariables,
	options?: Omit<
		UseQueryOptions<StatisticsMySavingsTotalsSlowQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			StatisticsMySavingsTotalsSlowQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<StatisticsMySavingsTotalsSlowQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["StatisticsMySavingsTotalsSlow"]
				: ["StatisticsMySavingsTotalsSlow", variables],
		queryFn: queryFetcher<
			StatisticsMySavingsTotalsSlowQuery,
			StatisticsMySavingsTotalsSlowQueryVariables
		>(StatisticsMySavingsTotalsSlowDocument, variables),
		...options,
	})
}

export const AllInvestmentProjectsDatesDocument = `
    query AllInvestmentProjectsDates {
  me {
    investment_projects {
      results {
        id
        name
        start
        end
      }
    }
  }
}
    `

export const useAllInvestmentProjectsDatesQuery = <
	TData = AllInvestmentProjectsDatesQuery,
	TError = unknown,
>(
	variables?: AllInvestmentProjectsDatesQueryVariables,
	options?: Omit<
		UseQueryOptions<AllInvestmentProjectsDatesQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllInvestmentProjectsDatesQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllInvestmentProjectsDatesQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllInvestmentProjectsDates"]
				: ["AllInvestmentProjectsDates", variables],
		queryFn: queryFetcher<
			AllInvestmentProjectsDatesQuery,
			AllInvestmentProjectsDatesQueryVariables
		>(AllInvestmentProjectsDatesDocument, variables),
		...options,
	})
}

export const ProjectMonthlyProductionTableDocument = `
    query ProjectMonthlyProductionTable($startTime: DateTime, $endTime: DateTime) {
  me {
    investment_projects {
      results(limit: null) {
        name
        state
        investor_shares_value_stats {
          total_shares
        }
        investor_production_stats(start: $startTime, end: $endTime) {
          investor_generated_power_in_kwh
          total_production_percentage
          investor_co2_saving
        }
      }
    }
  }
}
    `

export const useProjectMonthlyProductionTableQuery = <
	TData = ProjectMonthlyProductionTableQuery,
	TError = unknown,
>(
	variables?: ProjectMonthlyProductionTableQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectMonthlyProductionTableQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectMonthlyProductionTableQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectMonthlyProductionTableQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["ProjectMonthlyProductionTable"]
				: ["ProjectMonthlyProductionTable", variables],
		queryFn: queryFetcher<
			ProjectMonthlyProductionTableQuery,
			ProjectMonthlyProductionTableQueryVariables
		>(ProjectMonthlyProductionTableDocument, variables),
		...options,
	})
}

export const MonthlyOverviewDocument = `
    query MonthlyOverview($end: Date) {
  me {
    full_name
    investment_projects {
      totalCount
    }
    end: investor_shares_value_stats(valuation_date: $end) {
      total_investment_value
      total_investment_value_per_project_type {
        project_type
        share_value
      }
    }
  }
}
    `

export const useMonthlyOverviewQuery = <
	TData = MonthlyOverviewQuery,
	TError = unknown,
>(
	variables?: MonthlyOverviewQueryVariables,
	options?: Omit<
		UseQueryOptions<MonthlyOverviewQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			MonthlyOverviewQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<MonthlyOverviewQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["MonthlyOverview"]
				: ["MonthlyOverview", variables],
		queryFn: queryFetcher<
			MonthlyOverviewQuery,
			MonthlyOverviewQueryVariables
		>(MonthlyOverviewDocument, variables),
		...options,
	})
}

export const InvestmentsGraphCumulativeProductionDocument = `
    query InvestmentsGraphCumulativeProduction($startTime: DateTime!, $endTime: DateTime!, $interval: String!) {
  me {
    investor_production_stats(start: $startTime, end: $endTime) {
      cumulative_production_data(grouped_by: $interval) {
        date
        production
      }
      expected_cumulative_production_data(grouped_by: $interval) {
        date
        production
      }
    }
  }
}
    `

export const useInvestmentsGraphCumulativeProductionQuery = <
	TData = InvestmentsGraphCumulativeProductionQuery,
	TError = unknown,
>(
	variables: InvestmentsGraphCumulativeProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<
			InvestmentsGraphCumulativeProductionQuery,
			TError,
			TData
		>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InvestmentsGraphCumulativeProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InvestmentsGraphCumulativeProductionQuery, TError, TData>({
		queryKey: ["InvestmentsGraphCumulativeProduction", variables],
		queryFn: queryFetcher<
			InvestmentsGraphCumulativeProductionQuery,
			InvestmentsGraphCumulativeProductionQueryVariables
		>(InvestmentsGraphCumulativeProductionDocument, variables),
		...options,
	})
}

export const UserKycStatusDocument = `
    query UserKycStatus {
  me {
    uuid
    kyc {
      id
      state
      human_readable_kyc_error
      last_verified_on
    }
  }
}
    `

export const useUserKycStatusQuery = <
	TData = UserKycStatusQuery,
	TError = unknown,
>(
	variables?: UserKycStatusQueryVariables,
	options?: Omit<
		UseQueryOptions<UserKycStatusQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			UserKycStatusQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<UserKycStatusQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["UserKycStatus"]
				: ["UserKycStatus", variables],
		queryFn: queryFetcher<UserKycStatusQuery, UserKycStatusQueryVariables>(
			UserKycStatusDocument,
			variables,
		),
		...options,
	})
}

export const InvestorTestMutationsInputDocument = `
    mutation InvestorTestMutationsInput($input: InvestorTestMutationsInput!) {
  investment_test_update(input: $input) {
    investor_test {
      id
      category
      expiry_date
      experience
    }
    errors {
      messages
      field
    }
  }
}
    `

export const useInvestorTestMutationsInputMutation = <
	TError = unknown,
	TContext = unknown,
>(
	options?: UseMutationOptions<
		InvestorTestMutationsInputMutation,
		TError,
		InvestorTestMutationsInputMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		InvestorTestMutationsInputMutation,
		TError,
		InvestorTestMutationsInputMutationVariables,
		TContext
	>({
		mutationKey: ["InvestorTestMutationsInput"],
		mutationFn: (variables?: InvestorTestMutationsInputMutationVariables) =>
			queryFetcher<
				InvestorTestMutationsInputMutation,
				InvestorTestMutationsInputMutationVariables
			>(InvestorTestMutationsInputDocument, variables)(),
		...options,
	})
}

export const PaymentDetailEnergySupplierDocument = `
    query PaymentDetailEnergySupplier($id: ID) {
  me {
    first_name
    full_name
  }
  energy_suppliers(limit: 1000) {
    name
    id
    is_partner
    is_solar_interest_paid_to_supplier
  }
  interest_payments(id: $id) {
    results {
      id
      state
      energy_supplier_acceptance_date_time
      payment_date
      download_csv_url
      download_pdf_url
      project {
        name
        city
        total_shares
        supplier {
          id
          name
        }
        owner {
          name
        }
      }
      transaction_id
      total_production
      interest_per_share_limit_applied
      total_production_of_investors_with_supplier
      total_production_of_investors_with_supplier_limited
      interest_amount
      interestperiod {
        start
        end
        amortization
        repayment
        payment_deadline_date
        cost_kwh
        average_endex_tariff
      }
      payment_entries(limit: null) {
        id
      }
      payment_entries_with_supplier: payment_entries(
        partner_supplier_only: true
        limit: null
      ) {
        ...PaymentEntryEnergySupplier
      }
    }
  }
}
    ${PaymentEntryEnergySupplierFragmentDoc}`

export const usePaymentDetailEnergySupplierQuery = <
	TData = PaymentDetailEnergySupplierQuery,
	TError = unknown,
>(
	variables?: PaymentDetailEnergySupplierQueryVariables,
	options?: Omit<
		UseQueryOptions<PaymentDetailEnergySupplierQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			PaymentDetailEnergySupplierQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<PaymentDetailEnergySupplierQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["PaymentDetailEnergySupplier"]
				: ["PaymentDetailEnergySupplier", variables],
		queryFn: queryFetcher<
			PaymentDetailEnergySupplierQuery,
			PaymentDetailEnergySupplierQueryVariables
		>(PaymentDetailEnergySupplierDocument, variables),
		...options,
	})
}

export const PaymentDetailProjectOwnerDocument = `
    query PaymentDetailProjectOwner($id: ID) {
  me {
    first_name
    full_name
  }
  energy_suppliers(limit: 1000) {
    name
    id
    is_partner
    is_solar_interest_paid_to_supplier
  }
  interest_payments(id: $id) {
    results {
      id
      state
      project_owner_acceptance_date_time
      payment_date
      download_csv_url
      download_pdf_url
      project {
        name
        city
        total_shares
        supplier {
          id
          name
        }
        owner {
          name
        }
      }
      transaction_id
      total_production
      interest_per_share_limit_applied
      total_production_investor
      total_production_investor_limited
      interest_amount
      interestperiod {
        start
        end
        amortization
        repayment
        payment_deadline_date
        cost_kwh
        average_endex_tariff
      }
      payment_entries(limit: null) {
        ...PaymentEntryProjectOwner
      }
    }
  }
}
    ${PaymentEntryProjectOwnerFragmentDoc}`

export const usePaymentDetailProjectOwnerQuery = <
	TData = PaymentDetailProjectOwnerQuery,
	TError = unknown,
>(
	variables?: PaymentDetailProjectOwnerQueryVariables,
	options?: Omit<
		UseQueryOptions<PaymentDetailProjectOwnerQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			PaymentDetailProjectOwnerQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<PaymentDetailProjectOwnerQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["PaymentDetailProjectOwner"]
				: ["PaymentDetailProjectOwner", variables],
		queryFn: queryFetcher<
			PaymentDetailProjectOwnerQuery,
			PaymentDetailProjectOwnerQueryVariables
		>(PaymentDetailProjectOwnerDocument, variables),
		...options,
	})
}

export const PaymentsDocument = `
    query Payments($state: [String], $limit: Int, $offset: Int, $ordering: String, $q: String) {
  interest_payments(state: $state, q: $q) {
    totalCount
    results(limit: $limit, offset: $offset, ordering: $ordering) {
      state
      id
      transaction_id
      progress_percentage
      project {
        name
      }
      payment_link
      interest_amount
      interestperiod {
        start
        end
        amortization
        repayment
        payment_deadline_date
      }
    }
  }
}
    `

export const usePaymentsQuery = <TData = PaymentsQuery, TError = unknown>(
	variables?: PaymentsQueryVariables,
	options?: Omit<
		UseQueryOptions<PaymentsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<PaymentsQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<PaymentsQuery, TError, TData>({
		queryKey:
			variables === undefined ? ["Payments"] : ["Payments", variables],
		queryFn: queryFetcher<PaymentsQuery, PaymentsQueryVariables>(
			PaymentsDocument,
			variables,
		),
		...options,
	})
}

export const ProfileSecurityIntroDocument = `
    query ProfileSecurityIntro {
  me {
    email
    profile {
      phone
    }
  }
}
    `

export const useProfileSecurityIntroQuery = <
	TData = ProfileSecurityIntroQuery,
	TError = unknown,
>(
	variables?: ProfileSecurityIntroQueryVariables,
	options?: Omit<
		UseQueryOptions<ProfileSecurityIntroQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProfileSecurityIntroQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProfileSecurityIntroQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["ProfileSecurityIntro"]
				: ["ProfileSecurityIntro", variables],
		queryFn: queryFetcher<
			ProfileSecurityIntroQuery,
			ProfileSecurityIntroQueryVariables
		>(ProfileSecurityIntroDocument, variables),
		...options,
	})
}

export const ProjectGraphCumulativeProductionDocument = `
    query ProjectGraphCumulativeProduction($startTime: DateTime!, $interval: String!, $endTime: DateTime!, $projectId: ID!) {
  me {
    investment_projects(id: $projectId) {
      results {
        investor_production_stats(start: $startTime, end: $endTime) {
          expected_cumulative_production_data(grouped_by: $interval) {
            date
            production
          }
          cumulative_production_data(grouped_by: $interval) {
            date
            production
          }
        }
      }
    }
  }
}
    `

export const useProjectGraphCumulativeProductionQuery = <
	TData = ProjectGraphCumulativeProductionQuery,
	TError = unknown,
>(
	variables: ProjectGraphCumulativeProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectGraphCumulativeProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectGraphCumulativeProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectGraphCumulativeProductionQuery, TError, TData>({
		queryKey: ["ProjectGraphCumulativeProduction", variables],
		queryFn: queryFetcher<
			ProjectGraphCumulativeProductionQuery,
			ProjectGraphCumulativeProductionQueryVariables
		>(ProjectGraphCumulativeProductionDocument, variables),
		...options,
	})
}

export const ProjectGraphDailyProductionDocument = `
    query ProjectGraphDailyProduction($projectId: ID!, $day: Date!) {
  me {
    investment_projects(id: $projectId) {
      results {
        investor_production_stats {
          production_for_day(day: $day) {
            total_production
            production_data {
              date
              production
            }
          }
        }
      }
    }
  }
}
    `

export const useProjectGraphDailyProductionQuery = <
	TData = ProjectGraphDailyProductionQuery,
	TError = unknown,
>(
	variables: ProjectGraphDailyProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectGraphDailyProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectGraphDailyProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectGraphDailyProductionQuery, TError, TData>({
		queryKey: ["ProjectGraphDailyProduction", variables],
		queryFn: queryFetcher<
			ProjectGraphDailyProductionQuery,
			ProjectGraphDailyProductionQueryVariables
		>(ProjectGraphDailyProductionDocument, variables),
		...options,
	})
}

export const ProjectGraphProductionDocument = `
    query ProjectGraphProduction($startTime: DateTime!, $endTime: DateTime!, $interval: String!, $projectId: ID!) {
  me {
    investment_projects(id: $projectId) {
      results {
        investor_production_stats(start: $startTime, end: $endTime) {
          expected_production_data(grouped_by: $interval) {
            date
            production
          }
          production_data(grouped_by: $interval) {
            date
            production
          }
        }
      }
    }
  }
}
    `

export const useProjectGraphProductionQuery = <
	TData = ProjectGraphProductionQuery,
	TError = unknown,
>(
	variables: ProjectGraphProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectGraphProductionQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectGraphProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectGraphProductionQuery, TError, TData>({
		queryKey: ["ProjectGraphProduction", variables],
		queryFn: queryFetcher<
			ProjectGraphProductionQuery,
			ProjectGraphProductionQueryVariables
		>(ProjectGraphProductionDocument, variables),
		...options,
	})
}

export const ProjectIndexDocument = `
    query ProjectIndex($id: ID!) {
  me {
    investment_projects(id: $id) {
      results {
        id
        name
        state
        type
        image_url
        supplier {
          name
          logo
        }
        owner {
          email
        }
        start
        end
        current_interest_period {
          start
          end
          payment_deadline_date
        }
        investor_shares_value_stats {
          total_investment_for_project
          total_repaid_for_project
          total_shares
          shares {
            nominal_value
          }
        }
        documents {
          title
          url
          description
        }
      }
    }
  }
}
    `

export const useProjectIndexQuery = <
	TData = ProjectIndexQuery,
	TError = unknown,
>(
	variables: ProjectIndexQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectIndexQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<ProjectIndexQuery, TError, TData>["queryKey"]
	},
) => {
	return useQuery<ProjectIndexQuery, TError, TData>({
		queryKey: ["ProjectIndex", variables],
		queryFn: queryFetcher<ProjectIndexQuery, ProjectIndexQueryVariables>(
			ProjectIndexDocument,
			variables,
		),
		...options,
	})
}

export const ProjectNotificationsDocument = `
    query ProjectNotifications($id: ID!) {
  project(id: $id) {
    id
    name
    investor_yearly_report_notification_status
  }
}
    `

export const useProjectNotificationsQuery = <
	TData = ProjectNotificationsQuery,
	TError = unknown,
>(
	variables: ProjectNotificationsQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectNotificationsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectNotificationsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectNotificationsQuery, TError, TData>({
		queryKey: ["ProjectNotifications", variables],
		queryFn: queryFetcher<
			ProjectNotificationsQuery,
			ProjectNotificationsQueryVariables
		>(ProjectNotificationsDocument, variables),
		...options,
	})
}

export const ProjectNotificationsUpdateDocument = `
    mutation ProjectNotificationsUpdate($investor: InvestorUpdateInput!) {
  investor_update(input: $investor) {
    notification_configuration {
      disabled_yearly_interest_report_projects {
        id
      }
    }
  }
}
    `

export const useProjectNotificationsUpdateMutation = <
	TError = unknown,
	TContext = unknown,
>(
	options?: UseMutationOptions<
		ProjectNotificationsUpdateMutation,
		TError,
		ProjectNotificationsUpdateMutationVariables,
		TContext
	>,
) => {
	return useMutation<
		ProjectNotificationsUpdateMutation,
		TError,
		ProjectNotificationsUpdateMutationVariables,
		TContext
	>({
		mutationKey: ["ProjectNotificationsUpdate"],
		mutationFn: (variables?: ProjectNotificationsUpdateMutationVariables) =>
			queryFetcher<
				ProjectNotificationsUpdateMutation,
				ProjectNotificationsUpdateMutationVariables
			>(ProjectNotificationsUpdateDocument, variables)(),
		...options,
	})
}

export const ProjectRevenueDocument = `
    query ProjectRevenue($id: ID!) {
  project(id: $id) {
    name
    type
    investor_shares_value_stats {
      internal_rate_of_returns {
        expected_internal_rate_of_return
        internal_rate_of_return
      }
    }
  }
}
    `

export const useProjectRevenueQuery = <
	TData = ProjectRevenueQuery,
	TError = unknown,
>(
	variables: ProjectRevenueQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectRevenueQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectRevenueQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectRevenueQuery, TError, TData>({
		queryKey: ["ProjectRevenue", variables],
		queryFn: queryFetcher<
			ProjectRevenueQuery,
			ProjectRevenueQueryVariables
		>(ProjectRevenueDocument, variables),
		...options,
	})
}

export const InterestPaymentsDocument = `
    query InterestPayments($id: ID!, $ordering: String = "interestperiod__end", $project_id: ID!) {
  project(id: $project_id) {
    type
  }
  me {
    investment_campaigns(project: $project_id) {
      id
      description
      project {
        id
      }
    }
    interest_payments(interestperiod__project: $id, ordering: $ordering) {
      interestperiod {
        start
        end
        is_first_period
        payment_deadline_date
      }
      project {
        amortization_schedule
      }
      transaction_id
      payment_entries {
        state
        payment_state
        share_count
        cost
        amortization
        repayment
        supplier {
          is_solar_interest_paid_to_supplier
          name
        }
        investor_payout
        state
      }
    }
  }
}
    `

export const useInterestPaymentsQuery = <
	TData = InterestPaymentsQuery,
	TError = unknown,
>(
	variables: InterestPaymentsQueryVariables,
	options?: Omit<
		UseQueryOptions<InterestPaymentsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			InterestPaymentsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<InterestPaymentsQuery, TError, TData>({
		queryKey: ["InterestPayments", variables],
		queryFn: queryFetcher<
			InterestPaymentsQuery,
			InterestPaymentsQueryVariables
		>(InterestPaymentsDocument, variables),
		...options,
	})
}

export const ProjectsStatsTableDocument = `
    query ProjectsStatsTable($latitude: Decimal!, $longitude: Decimal!, $radius: Decimal, $name__icontains: String, $limit: Int, $offset: Int, $type: [String]) {
  public_projects(
    latitude: $latitude
    longitude: $longitude
    radius: $radius
    name__icontains: $name__icontains
    type: $type
  ) {
    totalCount
    results(limit: $limit, offset: $offset) {
      id
      name
      type
      start
      project_obligations_status
    }
  }
}
    `

export const useProjectsStatsTableQuery = <
	TData = ProjectsStatsTableQuery,
	TError = unknown,
>(
	variables: ProjectsStatsTableQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectsStatsTableQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectsStatsTableQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectsStatsTableQuery, TError, TData>({
		queryKey: ["ProjectsStatsTable", variables],
		queryFn: queryFetcher<
			ProjectsStatsTableQuery,
			ProjectsStatsTableQueryVariables
		>(ProjectsStatsTableDocument, variables),
		...options,
	})
}

export const ProjectInternalStateOfReturnDocument = `
    query ProjectInternalStateOfReturn($id: ID!) {
  project(id: $id) {
    overall_statistics {
      total_production
    }
  }
  project_statistics(id: $id) {
    solar_interest_chart_data {
      interest_period_numbers
      roi_of_interest_periods
      expected_roi_of_interest_periods
    }
    delta_production
    delta_energy_price
  }
}
    `

export const useProjectInternalStateOfReturnQuery = <
	TData = ProjectInternalStateOfReturnQuery,
	TError = unknown,
>(
	variables: ProjectInternalStateOfReturnQueryVariables,
	options?: Omit<
		UseQueryOptions<ProjectInternalStateOfReturnQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			ProjectInternalStateOfReturnQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<ProjectInternalStateOfReturnQuery, TError, TData>({
		queryKey: ["ProjectInternalStateOfReturn", variables],
		queryFn: queryFetcher<
			ProjectInternalStateOfReturnQuery,
			ProjectInternalStateOfReturnQueryVariables
		>(ProjectInternalStateOfReturnDocument, variables),
		...options,
	})
}

export const AllPlatformStatisticsAdditionalDocument = `
    query AllPlatformStatisticsAdditional {
  platform_statistics {
    avg_investment_per_investor
    capacity_in_mwp
    solar_bonds_volume
    max_investment
    project_partners
    total_projects
    total_amount_invested
    total_production_in_households_usage
    total_production_in_households_usage_per_year {
      year
      production
    }
    total_production_in_gwh
    total_expected_production_in_gwh
  }
}
    `

export const useAllPlatformStatisticsAdditionalQuery = <
	TData = AllPlatformStatisticsAdditionalQuery,
	TError = unknown,
>(
	variables?: AllPlatformStatisticsAdditionalQueryVariables,
	options?: Omit<
		UseQueryOptions<AllPlatformStatisticsAdditionalQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllPlatformStatisticsAdditionalQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllPlatformStatisticsAdditionalQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllPlatformStatisticsAdditional"]
				: ["AllPlatformStatisticsAdditional", variables],
		queryFn: queryFetcher<
			AllPlatformStatisticsAdditionalQuery,
			AllPlatformStatisticsAdditionalQueryVariables
		>(AllPlatformStatisticsAdditionalDocument, variables),
		...options,
	})
}

export const AllPlatformStatisticsDocument = `
    query AllPlatformStatistics {
  platform_statistics {
    total_investors
    percentage_of_project_wise_repeated_investors
    saved_co2
    total_solar_panels
  }
}
    `

export const useAllPlatformStatisticsQuery = <
	TData = AllPlatformStatisticsQuery,
	TError = unknown,
>(
	variables?: AllPlatformStatisticsQueryVariables,
	options?: Omit<
		UseQueryOptions<AllPlatformStatisticsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllPlatformStatisticsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllPlatformStatisticsQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllPlatformStatistics"]
				: ["AllPlatformStatistics", variables],
		queryFn: queryFetcher<
			AllPlatformStatisticsQuery,
			AllPlatformStatisticsQueryVariables
		>(AllPlatformStatisticsDocument, variables),
		...options,
	})
}

export const LifeTimeProductionChartDocument = `
    query LifeTimeProductionChart {
  platform_statistics {
    total_production_in_gwh
    total_expected_production_in_gwh
  }
}
    `

export const useLifeTimeProductionChartQuery = <
	TData = LifeTimeProductionChartQuery,
	TError = unknown,
>(
	variables?: LifeTimeProductionChartQueryVariables,
	options?: Omit<
		UseQueryOptions<LifeTimeProductionChartQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			LifeTimeProductionChartQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<LifeTimeProductionChartQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["LifeTimeProductionChart"]
				: ["LifeTimeProductionChart", variables],
		queryFn: queryFetcher<
			LifeTimeProductionChartQuery,
			LifeTimeProductionChartQueryVariables
		>(LifeTimeProductionChartDocument, variables),
		...options,
	})
}

export const AllPlatformStatisticsTotalProductionDocument = `
    query AllPlatformStatisticsTotalProduction($start: DateTime! = "1970-01-01T00:00:00Z", $end: DateTime! = "${new Date().toISOString()}") {
  overall_statistics(start: $start, end: $end) {
    total_production_for_counter
    production_speed
  }
}
    `

export const useAllPlatformStatisticsTotalProductionQuery = <
	TData = AllPlatformStatisticsTotalProductionQuery,
	TError = unknown,
>(
	variables?: AllPlatformStatisticsTotalProductionQueryVariables,
	options?: Omit<
		UseQueryOptions<
			AllPlatformStatisticsTotalProductionQuery,
			TError,
			TData
		>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			AllPlatformStatisticsTotalProductionQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<AllPlatformStatisticsTotalProductionQuery, TError, TData>({
		queryKey:
			variables === undefined
				? ["AllPlatformStatisticsTotalProduction"]
				: ["AllPlatformStatisticsTotalProduction", variables],
		queryFn: queryFetcher<
			AllPlatformStatisticsTotalProductionQuery,
			AllPlatformStatisticsTotalProductionQueryVariables
		>(AllPlatformStatisticsTotalProductionDocument, variables),
		...options,
	})
}

export const SingleProjectStatsDocument = `
    query SingleProjectStats($id: ID!) {
  project(id: $id) {
    name
    start
    project_obligations_status
    project_obligations_explanation_text
    type
    supplier {
      name
    }
    amortization_schedule
    cap_eur_interest_per_share
    floor_eur_interest_per_share
    overall_statistics {
      total_production
    }
  }
}
    `

export const useSingleProjectStatsQuery = <
	TData = SingleProjectStatsQuery,
	TError = unknown,
>(
	variables: SingleProjectStatsQueryVariables,
	options?: Omit<
		UseQueryOptions<SingleProjectStatsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			SingleProjectStatsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<SingleProjectStatsQuery, TError, TData>({
		queryKey: ["SingleProjectStats", variables],
		queryFn: queryFetcher<
			SingleProjectStatsQuery,
			SingleProjectStatsQueryVariables
		>(SingleProjectStatsDocument, variables),
		...options,
	})
}

export const SingleProjectStatsIrrDocument = `
    query SingleProjectStatsIRR($id: ID!) {
  project(id: $id) {
    name
    start
    project_obligations_status
    project_obligations_explanation_text
    type
    supplier {
      name
    }
    amortization_schedule
    cap_eur_interest_per_share
    floor_eur_interest_per_share
  }
  project_statistics(id: $id) {
    internal_rate_of_return
    expected_internal_rate_of_return
  }
}
    `

export const useSingleProjectStatsIrrQuery = <
	TData = SingleProjectStatsIrrQuery,
	TError = unknown,
>(
	variables: SingleProjectStatsIrrQueryVariables,
	options?: Omit<
		UseQueryOptions<SingleProjectStatsIrrQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			SingleProjectStatsIrrQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<SingleProjectStatsIrrQuery, TError, TData>({
		queryKey: ["SingleProjectStatsIRR", variables],
		queryFn: queryFetcher<
			SingleProjectStatsIrrQuery,
			SingleProjectStatsIrrQueryVariables
		>(SingleProjectStatsIrrDocument, variables),
		...options,
	})
}

export const SingleProjectStatsInterestPeriodsDocument = `
    query SingleProjectStatsInterestPeriods($id: ID!) {
  projects(id: $id) {
    type
  }
  project_statistics(id: $id) {
    stats_information_by_interest_period {
      is_current_interest_period
      is_completed_interest_period
      production_per_share
      energy_price
      solar_interest_per_share
      payment_status
      payment_per_share
      return_on_investment
      expected_production_per_share
      expected_solar_interest_per_share
      expected_payment_per_share
    }
  }
}
    `

export const useSingleProjectStatsInterestPeriodsQuery = <
	TData = SingleProjectStatsInterestPeriodsQuery,
	TError = unknown,
>(
	variables: SingleProjectStatsInterestPeriodsQueryVariables,
	options?: Omit<
		UseQueryOptions<SingleProjectStatsInterestPeriodsQuery, TError, TData>,
		"queryKey"
	> & {
		queryKey?: UseQueryOptions<
			SingleProjectStatsInterestPeriodsQuery,
			TError,
			TData
		>["queryKey"]
	},
) => {
	return useQuery<SingleProjectStatsInterestPeriodsQuery, TError, TData>({
		queryKey: ["SingleProjectStatsInterestPeriods", variables],
		queryFn: queryFetcher<
			SingleProjectStatsInterestPeriodsQuery,
			SingleProjectStatsInterestPeriodsQueryVariables
		>(SingleProjectStatsInterestPeriodsDocument, variables),
		...options,
	})
}
