import { QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 60 * 1000,
			gcTime: 10 * 60 * 1000,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: 1,
		},
	},
})

// Export
export { ReactQueryDevtools }
export {
	QueryClientProvider,
	QueryClient,
	useIsFetching,
	useQueryErrorResetBoundary,

	// Types
	type QueryFunctionContext,

	// Pass this instance to the placeholderData identity function
	keepPreviousData,
} from "@tanstack/react-query"
