import { ReactNode } from "react"
import { TooltipWithBounds } from "@visx/tooltip"
import { tooltipStyles } from "./tooltipStyles"
import type { TooltipProps } from "@visx/tooltip/lib/tooltips/Tooltip"

export const Tooltip = ({ children, ...props }: TooltipProps) => {
	return (
		<>
			{/* @ts-ignore */}
			<TooltipWithBounds style={tooltipStyles} {...props}>
				{children}
			</TooltipWithBounds>
		</>
	)
}

interface TooltipLineProps {
	x: number
	y: number
	y2?: number
	width: number
	height: number
	children?: ReactNode
	fill?: string
}

export const TooltipLine = ({
	width,
	height,
	x,
	y,
	children,
	fill,
}: TooltipLineProps) => {
	return (
		<g pointerEvents="none">
			<rect
				x={x - 2}
				y={y}
				width={width}
				height={height}
				fill={fill}
				pointerEvents="none"
			/>
			{children}
		</g>
	)
}

export function TooltipPoint({
	x,
	y,
	radius = 5,
	fill = "#000",
}: {
	x: number
	y: number
	radius?: number
	fill?: string
}) {
	return (
		<circle
			cx={x}
			cy={y}
			r={radius}
			fill={fill}
			strokeWidth={0}
			pointerEvents="none"
		/>
	)
}
