// React
import {
	ReactNode,
	HTMLProps,
	useRef,
	useEffect,
	useState,
	Dispatch,
	SetStateAction,
} from "react"

// UI
import { classNames } from "@/lib/ui"
import { Subheading } from "@/components/Typography"
import { ContentHint } from "@/components/ContentHint"

// Types
import { SortingState } from "@/lib/table"

// Icons
import { FiChevronDown, FiChevronUp } from "@/lib/icons"

// CSS Styles
import "@/styles/ResponsiveTable.css"

// Types
interface TableProps extends HTMLProps<HTMLTableElement> {
	children: ReactNode
	layout?: "fixed" | "auto" | "responsive"
}

/**
 * Main table component
 *
 * All tables are to be used trough TableItems instead of using HTML tables directly
 *
 * @param param0
 * @returns
 */
export const Table = ({
	children,
	className,
	layout = "fixed",
	...rest
}: TableProps) => {
	const refTable = useRef<HTMLDivElement>(null)

	return (
		<ContentHint ref={refTable}>
			<div className="w-full overflow-auto" ref={refTable}>
				<table
					role="table"
					{...rest}
					className={classNames(
						className,
						"w-full",
						layout === "fixed" && "2xl:table-fixed",
						layout === "responsive" && "responsive-table",
					)}
				>
					{children || null}
				</table>
			</div>
		</ContentHint>
	)
}

export const TableBody = ({
	className,
	children,
	...rest
}: HTMLProps<HTMLTableSectionElement>) => {
	return (
		<tbody
			className={classNames(className, "border-b border-gray-200")}
			{...rest}
		>
			{children || null}
		</tbody>
	)
}

export const TableHead = ({
	children,
	...rest
}: Omit<HTMLProps<HTMLTableSectionElement>, "className">) => {
	return (
		<thead
			className="sticky top-0 border-b border-t border-gray-200 border-t-gray-100 bg-gray-50"
			{...rest}
		>
			{children}
		</thead>
	)
}

type TableDataCellProps = HTMLProps<HTMLTableDataCellElement> & {
	multiline?: boolean
	showHeaderOnlyOnMobile?: boolean
	fieldName?: string
}

/**
 * TableDataCell
 */
export const TableDataCell = ({
	className = "",
	children,
	multiline = false,
	showHeaderOnlyOnMobile = false,
	fieldName,
	...props
}: TableDataCellProps) => {
	return (
		<>
			{/** Responsive identifier header */}
			{showHeaderOnlyOnMobile === true && fieldName && (
				<h4
					className={classNames(
						"sm:hidden",
						"text-left font-medium tracking-wider text-gray-500",
						"border-b border-t border-gray-200 border-t-gray-100 bg-white",
						"px-[0.5rem] pb-2 pt-6",
					)}
				>
					{children}
				</h4>
			)}

			{/** Default table cell */}

			<td
				className={classNames(
					showHeaderOnlyOnMobile === true && "!hidden sm:!block",
					"px-6 py-4 text-sm text-gray-700",
					"truncate whitespace-nowrap", // Default is single-line
					multiline && "break-word whitespace-pre-wrap", // Multiline optional

					// Classname overrides
					className,
				)}
				{...props}
			>
				{/* Header label */}
				{fieldName && (
					<h4 className="mr-auto flex items-center text-xs uppercase tracking-wider text-gray-500 sm:hidden">
						{fieldName}
					</h4>
				)}

				{/* Cell content */}
				{children || null}
			</td>
		</>
	)
}

/**
 * TableRowCell
 * @param param0
 * @returns
 */
interface TableRowCellProps extends HTMLProps<HTMLTableRowElement> {
	isOdd?: boolean
	variant?: TableRowCellVariant
	withHover?: boolean
	children: ReactNode
}
export type TableRowCellVariant = "neutral" | "valid" | "invalid"
export const TableRowCell = ({
	isOdd,
	variant = "neutral",
	withHover = true,
	className = "",
	children,
	...rest
}: TableRowCellProps) => {
	const applyEvenOrOddstyles = {
		neutral: isOdd ? "bg-gray-50" : "bg-white",
		invalid: isOdd ? "bg-red-200" : "bg-red-100",
		valid: isOdd ? "bg-green-200" : "bg-green-100",
	}

	return (
		<tr
			role="row"
			className={classNames(
				applyEvenOrOddstyles[variant],
				withHover && "hover:bg-gray-100",
				className,
			)}
			{...rest}
		>
			{children || null}
		</tr>
	)
}

interface TableHeadingProps extends HTMLProps<HTMLTableHeaderCellElement> {
	// "sortable" includes hover styles
	variant?: "static" | "sortable"
	as?: "td" | "th"
	fieldName?: string
	sortType?: "client" | "server"
	sort?: string | SortingState
	setSort?:
		| ((sort: string) => void)
		| ((value: SetStateAction<SortingState>) => void)
}

/**
 * TableHeading
 */
export const TableHeading = ({
	children,
	variant = "sortable",
	fieldName,
	sort,
	sortType = "server",
	setSort,
	className = "",
	...rest
}: TableHeadingProps) => {
	// State
	const [isSorted, setIsSorted] = useState(false)
	const [isSortedDesc, setIsSortedDesc] = useState(false)

	// When sort is changed
	useEffect(() => {
		if (sort && fieldName) {
			// Server sorting uses django string prefixed with "-"
			if (sortType === "server" && typeof sort === "string") {
				setIsSorted(sort.endsWith(fieldName))
				setIsSortedDesc(
					sort.endsWith(fieldName) && sort.startsWith("-"),
				)
			}

			// Client sorting uses an array of objects with id and desc
			if (sortType === "client" && Array.isArray(sort)) {
				setIsSorted(sort[0]?.id === fieldName)
				setIsSortedDesc(!!sort[0]?.desc)
			}
		}
	}, [sort, fieldName, sortType])

	// Handle Click
	const handleClick = () => {
		// Sort header if sorting is enabled
		if (fieldName && setSort) {
			// Server sorting uses django string prefixed with "-"
			if (sortType === "server") {
				const serverSetSort = setSort as (sort: string) => void
				if (!isSorted && !isSortedDesc) {
					serverSetSort(`-${fieldName}`)
				} else if (isSortedDesc) {
					serverSetSort(fieldName)
				} else {
					serverSetSort("")
				}
			}

			// Client sorting uses an array of objects with id and desc
			if (sortType === "client") {
				const clientSetSort = setSort as Dispatch<
					SetStateAction<SortingState>
				>
				clientSetSort([
					{
						id: fieldName,
						desc: isSorted ? !isSortedDesc : true,
					},
				])
			}
		}
	}

	// Template
	return (
		<th
			{...rest}
			scope="col"
			aria-sort={
				isSorted
					? isSortedDesc
						? "descending"
						: "ascending"
					: undefined
			}
			className={classNames(
				"px-6 py-3 text-left",
				className,
				variant === "sortable" && "cursor-pointer hover:bg-gray-200",
			)}
			onClick={() => handleClick()}
		>
			<Subheading className="flex items-center">
				{children || null}
				{isSorted && (
					<span className="ml-2" aria-hidden="true">
						{isSortedDesc ? (
							<FiChevronDown size={16} />
						) : (
							<FiChevronUp size={16} />
						)}
					</span>
				)}
			</Subheading>
		</th>
	)
}

export const TableFooter = ({
	className,
	...rest
}: HTMLProps<HTMLTableSectionElement>) => {
	return (
		<tfoot
			className={classNames(
				className,
				"border-b border-gray-200 bg-gray-50 text-sm",
			)}
			{...rest}
		/>
	)
}
