import { useState, useEffect, ReactNode, ReactElement } from "react"

// UI
import { CardBody, CardWrapper } from "@/components/Card"
import { Heading } from "@/components/Typography"

// Translations
import { Trans } from "@/i18n"

// Queries
import { useStatisticsMySavingsQuery } from "@/api/graphql"

// Icons
import {
	IoBulbOutline,
	IoCarOutline,
	IoTabletPortraitOutline,
	IoLeafOutline,
	IoDiamondOutline,
	IoPizzaOutline,
	IoGlobeOutline,
} from "@/lib/icons"

// Types
type YourSavingsBlock = {
	id: string
	icon: ReactNode
	text?: ReactElement<any>
	utility: number
}
/**
 * YourSavings
 * @returns
 */
export function StatisticsYourSavings() {
	const { data, isLoading } = useStatisticsMySavingsQuery()
	const total_production_kwh = data?.me?.investor_production_stats
		?.total_production_for_counter
		? parseFloat(
				data.me.investor_production_stats.total_production_for_counter,
		  ) / 1000 // WH > KWH
		: 0
	const [savingsBlocks, setSavingsBlocks] = useState<YourSavingsBlock[]>([])

	// Return random savings block
	function getRandomSavingsBlock() {
		const slider_snippet_texts_with_utility_for_kwh = [
			{
				id: "led-light",
				icon: <IoBulbOutline size={40} />,
				utility: 125,
			},
			{
				id: "electric-scooter",
				icon: <IoLeafOutline size={40} />,
				utility: 51.95805,
			},
			{
				id: "tumble-dryer",
				icon: <IoDiamondOutline size={40} />,
				utility: 0.00425532,
			},
			{
				id: "led-household",
				icon: <IoBulbOutline size={40} />,
				utility: 0.01,
			},
			{
				id: "tesla-kms",
				icon: <IoCarOutline size={40} />,
				utility: 6.89655,
			},
			{
				id: "nissan-kms",
				icon: <IoCarOutline size={40} />,
				utility: 7.29927,
			},
			{
				id: "ipad",
				icon: <IoTabletPortraitOutline size={40} />,
				utility: 0.08,
			},
			{
				id: "washing-machine",
				icon: <IoDiamondOutline size={40} />,
				utility: 0.7,
			},
			{
				id: "refrigerator",
				icon: <IoPizzaOutline size={40} />,
				utility: 0.004717,
			},
			{
				id: "gas-free-household",
				icon: <IoGlobeOutline size={40} />,
				utility: 9.52381e-5,
			},
		]
		let random_integer = Math.floor(Math.random() * 9)
		return slider_snippet_texts_with_utility_for_kwh[random_integer]
	}

	// Randomize 3 blocks
	useEffect(() => {
		let savingsBlocks = []
		for (let i = 0; i < 3; i++) {
			let text_with_utility_per_kwh = getRandomSavingsBlock()
			let utilization = (
				text_with_utility_per_kwh.utility * total_production_kwh
			).toFixed(2)

			savingsBlocks.push({
				...text_with_utility_per_kwh,
				text: (
					<Trans
						ns="project"
						i18nKey={`project.your_savings.block.${text_with_utility_per_kwh.id}.copy`}
						values={{
							total_production: Math.round(total_production_kwh),
							utilization: Math.round(Number(utilization)),
						}}
						components={{
							strong: (
								<span className="font-bold text-gray-900" />
							),
						}}
					/>
				),
			})
		}

		// Set state
		setSavingsBlocks(savingsBlocks)
	}, [])

	// Loading state
	if (isLoading) {
		return <StatisticsYourSavingsLoading />
	}

	// Data state
	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h3" styleAs="h6" className="mb-4 mr-2 lg:mr-0">
					Jouw Bijdragen
				</Heading>
				<div className="mt-4 grid grid-cols-1 gap-4 text-gray-500 md:mt-6 xl:grid-cols-3">
					{savingsBlocks.map((block) => (
						<div
							key={block.id}
							className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8"
						>
							<dt>{block.icon}</dt>
							<dd className="mt-2 text-sm">{block.text}</dd>
						</div>
					))}
				</div>
			</CardBody>
		</CardWrapper>
	)
}

/**
 * StatisticsYourSavingsLoading
 * @returns
 */
export function StatisticsYourSavingsLoading() {
	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h3" styleAs="h6" className="mb-4 mr-2 lg:mr-0">
					Jouw Bijdragen
				</Heading>
				<div className="mt-4 grid grid-cols-1 gap-4 text-gray-500 md:mt-6 xl:grid-cols-3">
					{[...Array(3)].map((_, index) => (
						<div
							key={index}
							className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8"
						>
							<dt>
								<div className="h-10 w-10 animate-pulse rounded-full bg-gray-200" />
							</dt>
							<dd className="mt-2 text-sm">
								<div className="h-12 w-48 animate-pulse rounded bg-gray-200" />
							</dd>
						</div>
					))}
				</div>
			</CardBody>
		</CardWrapper>
	)
}
