import { memo, useState } from "react"
import { FormikProvider, useFormik, useFormikContext } from "formik"
import { motion } from "@/lib/animations"
import { Link, useLocation } from "@/lib/router"
import { ApiErrorResponseInterface } from "@/api/rest"

// UI
import { Card } from "@/components/Card"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { Label } from "@/components/form-controls/Label"
import { SecondaryHeading } from "@/components/Typography"

// I18n
import { useTrans } from "@/i18n"

// Analytics
import { sendEvent } from "@/lib/analytics"

// State
import { useSelector } from "@/state/StateProvider"
import { AuthSliceState } from "@/state/features/authSlice"

// Queries
import { apiAuthUsersResetPasswordCreate } from "@/api/rest/generated/api/api"

// Images
import logoDark from "@/assets/icons/brand/colour.svg"

export const Recover = () => {
	const t = useTrans("sign-in")

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("sign-in:sign-in.recover.title"),
				})}
			</title>
			<div className="mb-8 md:mb-16">
				<Header />
				<motion.div className="text-sm" layout>
					<Forms />
				</motion.div>
			</div>
		</>
	)
}

const Header = () => {
	const t = useTrans("sign-in")

	return (
		<motion.div className="mb-6 text-center" layout>
			<picture>
				<img src={logoDark} className="mx-auto h-7" alt="" />
			</picture>
			<SecondaryHeading styleAs="h2" className="mt-6 md:mb-9">
				{t("sign-in:sign-in.recover.heading")}
			</SecondaryHeading>
		</motion.div>
	)
}

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const Forms = () => {
	const query = useQuery()

	// State
	const [state, setState] = useState<"success" | "to-submit">(
		(query.get("state") as any) ?? "to-submit",
	)
	const lastEmail = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.lastEmail,
	)

	const form = useFormik({
		initialValues: { email: lastEmail || "" },
		onSubmit: async (values, helpers) => {
			sendEvent("recover", "form-submit")

			try {
				const response = await apiAuthUsersResetPasswordCreate({
					email: values.email,
				}) // @ts-ignore
				if (response.status !== "ok") {
					throw new Error("Unknown error")
				}
				setState("success")
			} catch (e) {
				setState("to-submit")
				const error = e as ApiErrorResponseInterface | null
				sendEvent("recover", "form-submit-failed", {
					label: error?.json?.message ?? "unknown",
				})

				if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	return (
		<FormikProvider value={form}>
			{state === "success" ? <Success /> : <ToSubmit />}
		</FormikProvider>
	)
}

interface Values {
	email: string
}

const ToSubmit = memo(() => {
	const t = useTrans("sign-in")
	const form = useFormikContext<Values>()

	return (
		<motion.div className="mb-5">
			<form onSubmit={form.handleSubmit}>
				<Label
					htmlFor="email"
					className="block"
					data-testid="recover.email.label"
					hasError={!!form.errors.email}
				>
					{t("sign-in:sign-in.form_field.email.label")}
				</Label>
				<div className="mb-4">
					<FormikInput
						name="email"
						type="email"
						autoComplete="email"
						required
						className="block w-full"
						placeholder={t(
							"sign-in:sign-in.form_field.email.placeholder",
						)}
						hasError={!!form.errors.email}
						data-testid="recover.email"
						aria-label="email"
						autoFocus
					/>
					<FormikError field="email" namespace="sign-in" />
				</div>
				<FormikErrors i18nNamespace="sign-in" />
				<FormikSubmitButton
					data-testid="sign_in.submit"
					className="mt-5 flex w-full"
				>
					{t("sign-in:sign-in.recover.form_action.submit")}
				</FormikSubmitButton>
			</form>
		</motion.div>
	)
})

const Success = memo(() => {
	const t = useTrans("sign-in")

	return (
		<Card>
			<p className="mb-5">{t("sign-in:sign-in.recover.confirm")}</p>
			<Link
				to="/"
				className="font-medium text-gray-700 hover:text-gray-900"
			>
				{t("sign-in:sign-in.recover.confirm.back")}
			</Link>
		</Card>
	)
})
ToSubmit.displayName = "ToSubmit"
Success.displayName = "Success"
