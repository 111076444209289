import { forwardRef, HTMLProps, memo, ReactNode } from "react"
import { classNames } from "@/lib/ui"

const BACKGROUND_COLORS = {
	white: "bg-white",
	light: "bg-gray-50",
	gray: "bg-gray-100",
	dark: "bg-gray-200",
}

type CardVariants = keyof typeof BACKGROUND_COLORS
export interface CardProps extends Omit<HTMLProps<HTMLDivElement>, "ref"> {
	className?: string
	variant?: CardVariants
}

export const Card = ({ variant, children, ...rest }: CardProps) => {
	return (
		<CardWrapper variant={variant} {...rest}>
			<CardBody>{children}</CardBody>
		</CardWrapper>
	)
}

export const CardWrapper = memo(
	({ className = "", variant = "white", ...props }: CardProps) => (
		<div
			className={classNames(
				className,
				BACKGROUND_COLORS[variant],
				"rounded-sm shadow sm:rounded-lg",
			)}
			{...props}
		/>
	),
)
CardWrapper.displayName = "CardWrapper"

export const CardHeader = memo(
	({ className = "", variant = "white", ...props }: CardProps) => (
		<div
			className={classNames(
				className,
				BACKGROUND_COLORS[variant],
				"px-4 py-5 sm:p-6",
			)}
			{...props}
		/>
	),
)
CardHeader.displayName = "CardHeader"
export const CardBody = forwardRef<HTMLDivElement, Omit<CardProps, "variant">>(
	({ className = "", ...props }, ref) => (
		<div
			className={classNames(className, "px-4 py-5 sm:p-6")}
			{...props}
			ref={ref}
		/>
	),
)
CardBody.displayName = "CardBody"

interface CardFooterProps {
	className?: string
	children?: ReactNode
}

export const CardFooter = ({ className = "", ...props }: CardFooterProps) => (
	<div
		className={classNames(
			className,
			"rounded-b-sm bg-gray-50 px-4 py-3 sm:rounded-b-lg sm:px-6",
		)}
		{...props}
	/>
)
