import { useEffect, useState } from "react"
import { FormikProvider, useFormik, useFormikContext } from "formik"
import { FiChevronLeft } from "@/lib/icons"
import { useNavigate, useLocation } from "@/lib/router"
import { ApiErrorResponseInterface } from "@/api/rest"
import { useAuth } from "@/context/auth"
import { useSignIn } from "@/context/signIn"
import { useTrans } from "@/i18n"
import { Yup } from "@/lib/forms"
import { Anchor } from "../Anchor"
import { FormikError, InlineError } from "../form-controls/Errors"
import { FormikErrors, FormikSubmitButton } from "../form-controls/formik"
import { Input } from "../form-controls/Input"
import { twoFACodeLength } from "@/constants/constants"

// State
import { AuthSliceState } from "@/state/features/authSlice"
import { useSelector } from "@/state/StateProvider"

// Queries
import { apiAuthLoginCodeCreate } from "@/api/rest/generated/api/api"

const initialValues = {
	code: "",
	errors: { local: "", common: "" },
}

interface TwoFactorAuthFormProps {
	className?: string
}

const validationSchema = Yup.object().shape({
	code: Yup.string()
		.required("sign-in.form_errors.code_required")
		.min(twoFACodeLength, "sign-in.form_errors.code_length")
		// TODO: find out max length of backup codes
		.max(20, "sign-in.form_errors.code_length"),
})

const AutoSubmitToken = () => {
	const { values, submitForm, isSubmitting, isValid } = useFormikContext<{
		code: string
	}>()

	useEffect(() => {
		if (
			String(values.code).length === twoFACodeLength &&
			!isSubmitting &&
			isValid
		) {
			void submitForm()
		}
	}, [values, submitForm, isSubmitting, isValid])

	return null
}

/**
 * TwoFactorAuthForm
 * @param param0
 * @returns
 */
export const TwoFactorAuthForm = ({
	className = "",
}: TwoFactorAuthFormProps) => {
	const t = useTrans(["sign-in", "profile"])
	const auth = useAuth()
	const { setState, initialEmail: email, method } = useSignIn()

	// Navigation
	const navigate = useNavigate()
	const location = useLocation()

	// State
	const ephemeralToken = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.ephemeralToken,
	)
	const [attempt, setAttempt] = useState<number>(0)

	const onBack = () => {
		setState("sign-in")
	}

	const form = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, helpers) => {
			try {
				setAttempt(attempt + 1)

				const response = await apiAuthLoginCodeCreate({
					code: values.code,
					ephemeral_token: ephemeralToken || "",
				})

				// @ts-ignore
				if (!response || !response?.auth_token) {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
					return
				}

				// @ts-ignore
				auth.setAuth({ authToken: response.auth_token })
				navigate((location.state as { from: string })?.from ?? "/", {
					replace: true,
				})
			} catch (e) {
				const error = e as ApiErrorResponseInterface | null

				if (error?.json?.error === "Invalid or expired code.") {
					helpers.setFieldError(
						"errors.local",
						"sign-in.form_errors.code_incorrect",
					)
				} else if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit} className={className}>
				<Anchor
					href="/"
					className="mb-6 flex items-center text-sm text-gray-500 hover:text-gray-900 sm:mb-8"
					onClick={(evt) => {
						evt.preventDefault()
						onBack()
					}}
				>
					<FiChevronLeft className="mr-1" size={16} />
					{t("sign-in:sign-in.form.action.code.go_back", { email })}
				</Anchor>

				{method && (
					<p className="text-sm text-gray-700">
						{t("sign-in:sign-in.code.message", {
							method: t(
								`profile:profile.security.mfa_method.${method}`,
							),
						})}
					</p>
				)}

				<div className="mt-6">
					<label htmlFor="code" className="sr-only">
						{t("sign-in:sign-in.form_labels.code")}
					</label>
					<Input
						autoFocus
						id="code"
						name="code"
						type="text"
						required
						className="block w-full font-mono"
						onChange={form.handleChange}
						value={form.values.code}
						placeholder={t(
							"sign-in:sign-in.form_field.code.placeholder",
						)}
						hasError={!!form.errors.code && form.submitCount > 1}
						data-testid="sign_in.code"
						aria-label="code"
					/>
					<AutoSubmitToken />
					<FormikError field="code" namespace="sign-in" />
				</div>

				<div className="items-bottom mt-2 flex h-full flex-col">
					<FormikErrors i18nNamespace="sign-in" />
					{attempt >= 3 && (
						<InlineError
							id="use_backup_code"
							error={t(
								"sign-in:sign-in.form_action.code.use_backup_code",
							)}
						/>
					)}
					<FormikSubmitButton
						data-testid="sign_in.submit"
						className="mt-4 flex w-full"
					>
						{t("sign-in:sign-in.form.action.code.submit")}
					</FormikSubmitButton>
				</div>
			</form>
		</FormikProvider>
	)
}
