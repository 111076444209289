// React
import { ReactNode, useEffect } from "react"

// Router
import { useLocation } from "@/lib/router"

// Translations
import { useTrans } from "@/i18n"

/**
 * Payments
 * @returns
 */
export const PaymentsLayout = ({ children }: { children: ReactNode }) => {
	const t = useTrans(["payments", "finance"])
	const { pathname } = useLocation()

	useEffect(() => {
		if (pathname !== "/finance/interest-payments/") {
			window.scrollTo(0, 0)
		}
	}, [pathname])

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("payments:payments.title"),
				})}
			</title>
			{children}
		</>
	)
}
