// React
import { Fragment, useMemo, useState } from "react"

// Router
import { useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Queries
import {
	ProjectTypeEnum,
	ProjectProjectObligationsStatusEnum,
	PublicProjectType,
	useProjectsStatsTableQuery,
} from "@/api/graphql"

// Translate
import { useTrans } from "@/i18n"

// Tables
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
	TableHead,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { CardBody, CardWrapper } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { SearchInput } from "@/components/form-controls/Input"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"

// Constants
import { mapCoordinatesCenterOfNetherlands } from "@/constants/constants"

/**
 * ProjectStatsTable
 * @returns
 */
export function ProjectStatsTable() {
	// State
	const [filter, setFilter] = useState<string | undefined>()
	const limit = 20
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Translations
	const t = useTrans(["project", "common"])

	// Query
	const { data, isLoading } = useProjectsStatsTableQuery({
		latitude: mapCoordinatesCenterOfNetherlands.lat,
		longitude: mapCoordinatesCenterOfNetherlands.lng,
		radius: "500",
		limit,
		offset: currentPage * limit,
		name__icontains: filter,
		type: [
			ProjectTypeEnum.SolarShares,
			ProjectTypeEnum.SolarBonds,
			ProjectTypeEnum.Portfolio,
		],
	})

	// Memo
	const projects = useMemo(() => {
		return (
			(data?.public_projects?.results?.filter(
				Boolean,
			) as PublicProjectType[]) || []
		)
	}, [data?.public_projects?.results])

	// PaginatorInfo
	const navigate = useNavigate()
	const totalProjectsCount = useMemo(
		() => data?.public_projects?.totalCount ?? 0,
		[data?.public_projects?.totalCount],
	)

	// Tables
	const columnHelper = createColumnHelper<PublicProjectType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor("name", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.project",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PublicProjectType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			}),
			columnHelper.accessor("type", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.type",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PublicProjectType, string>) => (
					<TableDataCell className="font-medium">
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl md:ml-4 md:mt-2 lg:mt-0">
							{t(`common:common.project.type.${info.getValue()}`)}
						</div>
					</TableDataCell>
				),
			}),
			columnHelper.accessor("start", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.start_date",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PublicProjectType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			}),
			columnHelper.accessor("project_obligations_status", {
				header: () => (
					<TableHeading>
						{t("common:common.project.obligations_status")}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						PublicProjectType,
						ProjectProjectObligationsStatusEnum
					>,
				) => (
					<TableDataCell className="font-medium">
						{t(
							`common:common.project.obligations_status.${info.getValue()}`,
						)}
					</TableDataCell>
				),
			}),
		],
		[t],
	)

	// Table
	const table = useReactTable({
		columns,
		data: projects,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),

		state: {
			pagination: {
				pageIndex: 0,
				pageSize: limit,
			},
		},
	})

	// Loading state
	if (isLoading) {
		return <ProjectStatsTableLoading />
	}

	// Data state
	return (
		<CardWrapper className="mb-4">
			<CardBody>
				<div className="md:flex">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("dashboard:dashboard.stats.projects_table.title")}
					</Heading>
					<div className="mb-4 ml-auto flex gap-4">
						<div className="sm:ml-auto">
							<SearchInput
								onChange={(event) => {
									setFilter(event.currentTarget.value)
								}}
								label={"Zoeken"}
								className="md:width-auto min-w-full"
							/>
						</div>
					</div>
				</div>

				<Table
					className="min-w-[64rem] lg:min-w-0"
					data-testid="tablebody"
				>
					<TableHead>
						{table.getHeaderGroups().map((headerGroup) => {
							return (
								<TableRowCell key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Fragment key={header.id}>
												{flexRender(
													header.column.columnDef
														.header,
													header.getContext(),
												)}
											</Fragment>
										)
									})}
								</TableRowCell>
							)
						})}
					</TableHead>
					<TableBody
						data-testid="tablebody-overview"
						data-pageindex={table.getState().pagination.pageIndex}
					>
						{table.getRowModel().rows.map((row) => {
							const isOdd = row.index % 2 === 0
							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										className={"cursor-pointer"}
										onClick={() =>
											navigate(
												`${Routes.StatisticsPlatformSingleProject}/${row.original.id}`,
											)
										}
									>
										{row
											.getAllCells()
											.map((cell, index) => {
												return (
													<Fragment key={index}>
														{flexRender(
															cell.column
																.columnDef.cell,
															cell.getContext(),
														)}
													</Fragment>
												)
											})}
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{table.getRowModel().rows.length <
							table.getState().pagination.pageSize &&
						table.getState().pagination.pageIndex !== 0 ? (
							<>
								{Array(
									Math.max(
										table.getState().pagination.pageSize -
											table.getRowModel().rows.length,
										1,
									),
								)
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>
				<CardBody>
					{totalProjectsCount !== 0 && (
						<PaginationAsButtons
							countPerPage={limit}
							totalCount={totalProjectsCount ?? 0}
							itemType={"common.pagination.item_types.project"}
							currentPage={currentPage + 1}
							currentItemsAmount={totalProjectsCount ?? 0}
							onNextPage={() =>
								setCurrentPage((currentPage) => currentPage + 1)
							}
							onPrevPage={() =>
								setCurrentPage((currentPage) =>
									Math.max(currentPage - 1, 0),
								)
							}
							analyticsId="investments"
						/>
					)}
				</CardBody>
			</CardBody>
		</CardWrapper>
	)
}

/**
 * ProjectStatsTableLoading
 * @returns
 */
export function ProjectStatsTableLoading() {
	// Translations
	const t = useTrans(["project", "common"])

	return (
		<CardWrapper className="mb-4">
			<CardBody>
				<div className="md:flex">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("dashboard:dashboard.stats.projects_table.title")}
					</Heading>
					<div className="mb-4 ml-auto flex gap-4">
						<div className="sm:ml-auto">
							<SearchInput
								disabled
								label={"Zoeken"}
								className="md:width-auto min-w-full"
							/>
						</div>
					</div>
				</div>

				<Table className="min-w-[64rem] lg:min-w-0">
					<TableHead>
						<TableRowCell>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.project",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.type",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.start_date",
								)}
							</TableHeading>
							<TableHeading>
								{t("common:common.project.obligations_status")}
							</TableHeading>
						</TableRowCell>
					</TableHead>
					<TableBody>
						{[...Array(5)].map((_, index) => (
							<TableRowCell key={index} isOdd={index % 2 === 0}>
								<TableDataCell>
									<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
								</TableDataCell>
								<TableDataCell>
									<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
								</TableDataCell>
								<TableDataCell>
									<div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
								</TableDataCell>
								<TableDataCell>
									<div className="h-4 w-32 animate-pulse rounded bg-gray-200" />
								</TableDataCell>
							</TableRowCell>
						))}
					</TableBody>
				</Table>
			</CardBody>
		</CardWrapper>
	)
}
