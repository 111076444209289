import { GeneralNotifications } from "@/components/forms/GeneralNotifications"
import { AllProjectNotifications } from "@/components/forms/AllProjectNotifications"
import { useTrans } from "@/i18n"

export const ProfileNotifications = () => {
	const t = useTrans(["profile", "common"])

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("profile:profile.notifications.title"),
				})}
			</title>
			<div className="space-y-6 md:space-y-8 lg:space-y-12">
				<GeneralNotifications />
				<AllProjectNotifications />
			</div>
		</>
	)
}
