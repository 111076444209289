import { useTrans } from "@/i18n"

// Forms
import { InvestorIdentityForm } from "../forms/InvestorIdentityForm"

/**
 * InvestorIdentity
 * @returns
 */
export const InvestorIdentity = () => {
	const t = useTrans("investor")

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("investor:investor.identity.title"),
				})}
			</title>

			<InvestorIdentityForm />
		</>
	)
}
