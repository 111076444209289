import {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
	Suspense,
} from "react"
import { getChildByType } from "react-nanny"

// UI
import { CardBody } from "../../../Card"
import { GraphHeaderPreloader } from "../../header/GraphHeader"
import { EmptyGridStateAnimated } from "../../../EmptyGridState"
import { ProductionGraphActions } from "./ProductionGraphActions"

// DateTime
import { DateTime, DateTimeUnit } from "@/lib/dates"

// Context
import { FinancePerformanceContext } from "@/pages/finance/pages/Performance"

export type GraphIntervalType = "year" | "month" | "all"
export const AvailableDatePeriods = {
	DAY: "day",
	MONTH: "month",
	YEAR: "year",
	ALL: "all",
}

export type ProductionGraphContextType = {
	graphInterval: "day" | "month"
	setGraphInterval(graphInterval: "day" | "month"): void
	startTime: any
	setStartTime(startTime: any): void
	endTime: any
	setEndTime(endTime: any): void
	knmiDataStatus: "included" | "excluded" | null
	setKnmiDataStatus(
		knmiDataStatus: ProductionGraphContextType["knmiDataStatus"],
	): void
}

const ProductionGraphContext = createContext<ProductionGraphContextType>(null!)

export function useProductionGraphContext() {
	const context = useContext(ProductionGraphContext)

	if (!context) {
		throw new Error(
			"ProductionGraphContext must be used within a ProductionGraphContextProvider",
		)
	}

	return context
}

/// useFinancePerformanceContextOrState
const useFinancePerformanceContextOrState = () => {
	const context = useContext(FinancePerformanceContext)

	// State
	const [localStartTime, setLocalStartTime] = useState(
		DateTime.local().toJSDate(),
	)
	const [localEndTime, setLocalEndTime] = useState(
		DateTime.local().toJSDate(),
	)
	const [localGraphInterval, setLocalGraphInterval] = useState<
		"day" | "month"
	>("month")

	// Return context or state
	return {
		startTime: context?.selectedDateRangeStart || localStartTime,
		setStartTime: context?.setSelectedDateRangeStart || setLocalStartTime,
		endTime: context?.selectedDateRangeEnd || localEndTime,
		setEndTime: context?.setSelectedDateRangeEnd || setLocalEndTime,
		graphInterval: context?.graphInterval || localGraphInterval,
		setGraphInterval: context?.setGraphInterval || setLocalGraphInterval,
	}
}

type ProductionGraphProps = {
	children: ReactNode
}

// Define the type for components with displayName
type ProductionGraphComponent = {
	({ children }: { children: ReactNode }): React.ReactElement
	displayName?: string
}

/**
 * ProductionGraph
 * @param param0
 * @returns
 */
export function ProductionGraph({ children }: ProductionGraphProps) {
	// Content template
	const content = getChildByType(children, ProductionGraph.Content)
	const footer = getChildByType(children, ProductionGraph.Footer)
	const header = getChildByType(children, ProductionGraph.Header)
	const actions = getChildByType(children, ProductionGraph.Actions)

	// State
	const [knmiDataStatus, setKnmiDataStatus] =
		useState<ProductionGraphContextType["knmiDataStatus"]>(null)

	// Hooks
	const {
		startTime,
		setStartTime,
		endTime,
		setEndTime,
		graphInterval,
		setGraphInterval,
	} = useFinancePerformanceContextOrState()

	// Determine start and end dates based on interval
	useEffect(() => {
		// When day, start at the beginning of the month
		if (graphInterval === AvailableDatePeriods.DAY) {
			setStartTime(
				DateTime.local()
					.toUTC()
					.startOf(AvailableDatePeriods.MONTH as DateTimeUnit)
					.toJSDate(),
			)
			setEndTime(
				DateTime.local()
					.toUTC()
					.endOf(AvailableDatePeriods.MONTH as DateTimeUnit)
					.toJSDate(),
			)
		}

		if (
			graphInterval === AvailableDatePeriods.MONTH ||
			graphInterval === AvailableDatePeriods.YEAR ||
			graphInterval === AvailableDatePeriods.ALL
		) {
			setStartTime(
				DateTime.local()
					.toUTC()
					.startOf(AvailableDatePeriods.YEAR as DateTimeUnit)
					.toJSDate(),
			)
			setEndTime(
				DateTime.local()
					.toUTC()
					.endOf(AvailableDatePeriods.YEAR as DateTimeUnit)
					.toJSDate(),
			)
		}
	}, [graphInterval, setStartTime, setEndTime])

	// Return provider
	return (
		<ProductionGraphContext.Provider
			value={{
				graphInterval,
				setGraphInterval,
				knmiDataStatus,
				setKnmiDataStatus,
				startTime,
				setStartTime,
				endTime,
				setEndTime,
			}}
		>
			<CardBody className="lg:flex lg:items-center lg:justify-between">
				<div className="w-full lg:w-72">
					<Suspense fallback={<GraphHeaderPreloader />}>
						{header}
					</Suspense>
				</div>
				<div className="mt-4 lg:mt-0 lg:flex">
					{actions ? actions : <ProductionGraphActions />}
				</div>
			</CardBody>
			<div className="h-96">
				<Suspense
					fallback={
						<EmptyGridStateAnimated className="h-[30%] w-full" />
					}
				>
					{content}
				</Suspense>
			</div>
			{footer}
		</ProductionGraphContext.Provider>
	)
}

// Add display name
ProductionGraph.displayName = "ProductionGraph"

function Footer({ children }: { children: ReactNode }) {
	return <>{children}</>
}
ProductionGraph.Footer = Footer as ProductionGraphComponent
ProductionGraph.Footer.displayName = "ProductionGraph.Footer"

function Content({ children }: { children: ReactNode }) {
	return <>{children}</>
}
ProductionGraph.Content = Content as ProductionGraphComponent
ProductionGraph.Content.displayName = "ProductionGraph.Content"

function Header({ children }: { children: ReactNode }) {
	return <>{children}</>
}
ProductionGraph.Header = Header as ProductionGraphComponent
ProductionGraph.Header.displayName = "ProductionGraph.Header"

function Actions({ children }: { children: ReactNode }) {
	return <>{children}</>
}
ProductionGraph.Actions = Actions as ProductionGraphComponent
ProductionGraph.Actions.displayName = "ProductionGraph.Actions"

function LineGraph({ children }: { children: ReactNode }) {
	return <>{children}</>
}
ProductionGraph.LineGraph = LineGraph as ProductionGraphComponent
ProductionGraph.LineGraph.displayName = "ProductionGraph.LineGraph"
