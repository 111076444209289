import { useEffect, useContext } from "react"

// Debug tools
import { mountVercelToolbar } from "@vercel/toolbar"

// Router
import { Routes } from "@/constants/routes"

// UI
import {
	classNames,
	Menu,
	MenuButton,
	MenuItems,
	MenuItem,
	Transition,
} from "@/lib/ui"
import { LanguageSelector } from "@/components/LanguageSelector"
import { Link } from "@/components/Anchor"
import { Heading } from "@/components/Typography"

// Icons
import { LogoutIcon, FiUser, FiChevronDown, FiChevronUp } from "@/lib/icons"

// Animations
import { getTransitionPopoverProps } from "@/lib/animations"

import { UserRoles } from "@/constants/constants"

// Translations
import { useTrans, Trans } from "@/i18n"

// Hooks
import { useDeviceCssType } from "@/lib/device"
import { useAuth } from "@/context/auth"
import { useToasts } from "@/context/toasts"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Assets
import BrandLogo from "@/assets/icons/brand/logo.svg?react"

// Context
import { useCurrentUserInfo, useCurrentUserRoles } from "@/context/user"
import { RedirectContext } from "@/context/redirectContext"

// Env variables
import { API_URL, EXTERNAL_PLATFORM_ADMIN_PANEL, VERCEL_ENV } from "@/lib/env"

/**
 * UserMenu
 * @returns
 */
export function UserMenu() {
	// Translations
	const t = useTrans()

	// Hooks
	const auth = useAuth()
	const toasts = useToasts()
	const { hasOneOfRoles } = useCurrentUserRoles()
	const { first_name, last_name } = useCurrentUserInfo()
	const deviceTypeCss = useDeviceCssType()

	// Context
	const { redirect } = useContext(RedirectContext)

	// On Mount, check for admin permissions
	useEffect(() => {
		if (hasOneOfRoles(UserRoles.admin)) {
			// Show vercel toolbar
			if (VERCEL_ENV !== "production") {
				mountVercelToolbar()
			}
		}
	}, [hasOneOfRoles])

	return (
		<Menu as="div" className="relative order-1 md:order-2">
			{({ open }) => (
				<>
					<MenuButton
						className={classNames(
							deviceTypeCss === "desktop"
								? "cursor-default"
								: "cursor-pointer",
							"flex w-full px-2",
						)}
						onClick={() => {
							sendEvent("layout", "usermenu_icon_onclick")
						}}
					>
						<div
							className={classNames(
								"relative block overflow-hidden rounded-[50%] border-2 border-gray-400 bg-white",
								"mr-3 h-6 w-6", // Sizes responsive
							)}
						>
							<span className="sr-only">
								{t("common.layout.open_user_menu")}
							</span>
							<UserIcon className="absolute -bottom-1 left-[50%] h-[95%] w-[95%] -translate-x-1/2 fill-gray-400 text-gray-400" />
						</div>

						<p className="text-md block truncate text-left text-gray-400">
							{first_name} {last_name}
						</p>

						{/** Show arrow icon on mobile */}
						{deviceTypeCss === "mobile" && (
							<span className="ml-auto flex items-center">
								{open === true ? (
									<FiChevronDown className="h-4 w-4 text-gray-400" />
								) : (
									<FiChevronUp className="h-4 w-4 text-gray-400" />
								)}
							</span>
						)}
					</MenuButton>

					<Transition
						{...getTransitionPopoverProps()}
						show={open || deviceTypeCss === "desktop"} // Do not transition on desktop
					>
						<MenuItems
							static={deviceTypeCss === "desktop"} // Always show on desktop
							className={classNames(
								"right-0 z-50 mt-2 w-full origin-top-right rounded-md  pb-1 pt-1 focus:outline-none",
							)}
						>
							{/* Menu items */}
							<>
								<MenuItem>
									<Link
										href={
											Routes.SettingsProfilePersonalDetails
										}
										className={classNames(
											"cursor-pointer hover:bg-gray-100",
											"block px-4 py-2 text-sm text-gray-700",
										)}
										onClick={() =>
											sendEvent(
												"layout",
												"usermenu_item_onclick",
												{
													label: t(
														"common.layout.quick_menu.your_profile",
													),
												},
											)
										}
									>
										{t(
											"common.layout.quick_menu.your_profile",
										)}
									</Link>
								</MenuItem>

								{/** Language selector */}
								<MenuItem>
									<LanguageSelector
										className={classNames(
											"cursor-pointer hover:bg-gray-100",
											"relative block px-4 py-2 text-sm text-gray-700",
										)}
									/>
								</MenuItem>

								{/** Admin items */}
								{hasOneOfRoles(UserRoles.admin) && (
									<>
										{/** App information */}
										<MenuItem>
											<Link
												href={Routes.Info}
												className={classNames(
													"hover:bg-gray-100",
													"block px-4 py-2 text-sm text-gray-700",
												)}
												onClick={() =>
													sendEvent(
														"layout",
														"usermenu_item_onclick",
														{
															label: "App information",
														},
													)
												}
											>
												App information
											</Link>
										</MenuItem>

										<MenuItem>
											<button
												onClick={() => {
													sendEvent(
														"layout",
														"usermenu_item_onclick",
														{
															label: "Admin panel",
														},
													)
													// Redirect to admin panel
													redirect(
														EXTERNAL_PLATFORM_ADMIN_PANEL,
													)
												}}
												className={classNames(
													"cursor-pointer hover:bg-gray-100",
													"block w-full px-4 py-2 text-left text-sm text-gray-700",
												)}
											>
												Admin panel
											</button>
										</MenuItem>
									</>
								)}

								{/** Logout */}
								<MenuItem>
									<button
										onClick={() => {
											sendEvent(
												"layout",
												"usermenu_item_onclick",
												{
													label: t(
														"common.layout.quick_menu.sign_out",
													),
												},
											)
											// Try logout
											try {
												void auth.logOut()
											} catch (e) {
												console.error(
													"Could not log out",
													e,
												)
												toasts.addToast({
													id: "common.layout.quick_menu.sign_out",
													text: "Failed to log out, try again later.",
												})
											}
										}}
										className={classNames(
											"cursor-pointer hover:bg-gray-100",
											"flex w-full items-center px-4 py-2 text-sm text-gray-700",
										)}
									>
										<LogoutIcon
											className="mr-3 h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										{t("common.layout.quick_menu.sign_out")}
									</button>
								</MenuItem>
							</>
						</MenuItems>
					</Transition>
				</>
			)}
		</Menu>
	)
}

export function UserIcon({ className = "" }) {
	return (
		<>
			<FiUser
				className={classNames(
					className,
					"h-[95%] w-[95%] fill-gray-400 text-gray-400",
				)}
			/>
		</>
	)
}

/**
 * Logo
 * @returns
 */
export function Logo() {
	return (
		<Heading
			as="h2"
			styleAs="h5"
			className="flex h-full w-full items-center truncate pl-1 text-black md:px-4"
		>
			<a href={API_URL} className="flex">
				<BrandLogo className="mr-2 h-6 w-6 brightness-0 grayscale" />
				<Trans
					ns="common"
					i18nKey="common.layout.title"
					components={{
						thin: <span className="font-medium" />,
					}}
				/>
			</a>
		</Heading>
	)
}
