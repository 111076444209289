// UI
import { CompanyDetails } from "@/components/forms/CompanyDetails"
import { useTrans } from "@/i18n"

export const ProfileCompanyDetails = () => {
	const t = useTrans("profile")

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("profile:profile.company_details.title"),
				})}
			</title>
			<CompanyDetails />
		</>
	)
}
