// React
import { memo, useState } from "react"

// UI
import { Link, useLocation } from "@/lib/router"
import { ApiErrorResponseInterface } from "@/api/rest"
import { Card } from "@/components/Card"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { ShowPassword } from "@/components/ShowPassword"
import { SecondaryHeading } from "@/components/Typography"

// I18n
import { useTrans } from "@/i18n"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Forms
import { FormikProvider, useFormik, useFormikContext } from "formik"
import { Yup } from "@/lib/forms"

// State
import { useSelector } from "@/state/StateProvider"
import { AuthSliceState } from "@/state/features/authSlice"

// Queries
import { apiAuthUsersResetPasswordCreate } from "@/api/rest/generated/api/api"

// Images
import logoDark from "@/assets/icons/brand/colour.svg"
import { Routes } from "@/constants/routes"

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

// Types
interface ResetProps {
	initialPage?: PageState
}
type PageState = "success" | "to-submit"

/**
 * Reset
 * @param param0
 * @returns
 */
export const Reset = ({ initialPage = "to-submit" }: ResetProps) => {
	const t = useTrans("sign-in")
	const query = useQuery()

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("sign-in:sign-in.reset.title"),
				})}
			</title>
			<div className="mb-8 text-sm md:mb-16">
				<Header />
				{query.get("code") ? (
					<Forms initialPage={initialPage} />
				) : (
					<NoCode />
				)}
			</div>
		</>
	)
}

const Header = () => {
	const t = useTrans("sign-in")

	return (
		<div className="mb-6 text-center">
			<picture>
				<img src={logoDark} className="mx-auto h-7" alt="" />
			</picture>
			<SecondaryHeading styleAs="h2" className="mt-6 md:mb-9">
				{t("sign-in.reset.heading")}
			</SecondaryHeading>
		</div>
	)
}

const validationSchema = Yup.object().shape({
	email: Yup.string().required("sign-in.reset.form_errors.email.required"),
	password: Yup.string().required(
		"sign-in.reset.form_errors.new_password.required",
	),
	passwordConfirmation: Yup.string()
		.oneOf(
			[Yup.ref("password")],
			"sign-in.reset.form_errors.passwords_do_not_match",
		)
		.required(
			"sign-in.reset.form_errors.new_password_confirmation.required",
		),
})

const Forms = ({ initialPage }: { initialPage: PageState }) => {
	const [state, setState] = useState<PageState>(initialPage)
	const lastEmail = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.lastEmail,
	)

	const form = useFormik({
		initialValues: {
			email: lastEmail || "",
			password: "",
			passwordConfirmation: "",
		},
		validationSchema,
		onSubmit: async (values, helpers) => {
			sendEvent("reset", "form-submit")

			try {
				await apiAuthUsersResetPasswordCreate({
					email: values.email, // @ts-ignore
					newPassword: values.password,
					newPasswordConfirmation: values.passwordConfirmation,
				})
				setState("success")
			} catch (e) {
				setState("to-submit")
				const error = e as ApiErrorResponseInterface | null
				sendEvent("reset", "form-submit-failed", {
					label: error?.json?.message ?? "unknown",
				})

				if (error?.json?.message === "passwords_do_not_match") {
					helpers.setFieldError(
						"errors.local",
						"sign-in.reset.form_errors.passwords_do_not_match",
					)
				} else if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	return (
		<FormikProvider value={form}>
			{state === "to-submit" && <ToSubmit />}
			{state === "success" && <Success />}
		</FormikProvider>
	)
}

interface Values {
	email: string
}

const ToSubmit = memo(() => {
	const t = useTrans("sign-in")
	const form = useFormikContext<Values>()
	const [showPassword, setPasswordVisibility] = useState(false)

	return (
		<div className="mb-5">
			<form onSubmit={form.handleSubmit}>
				<div className="mb-4 space-y-4">
					<div>
						<FormikLabel htmlFor="email" className="mt-5 block">
							{t("sign-in:sign-in.form_field.email.label")}
						</FormikLabel>
						<FormikInput
							name="email"
							type="email"
							autoComplete="email"
							required
							className="block w-full"
							placeholder={t(
								"sign-in:sign-in.form_field.email.placeholder",
							)}
							data-testid="sign-in.form_field.email.placeholder"
							aria-label="email"
							autoFocus
						/>
						<FormikError field="email" namespace="sign-in" />
					</div>
					<div>
						<div className="flex items-center">
							<FormikLabel htmlFor="password" className="block">
								{t("sign-in:sign-in.form_field.password.label")}
							</FormikLabel>
							<ShowPassword
								name="showPassword"
								display={showPassword}
								setDisplay={setPasswordVisibility}
							/>
						</div>
						<FormikInput
							name="password"
							type={showPassword ? "text" : "password"}
							autoComplete="current-password"
							required
							className="block w-full"
							data-testid="sign_in.password"
							aria-label="password"
						/>
						<FormikError field="password" namespace="sign-in" />
					</div>
					<div>
						<FormikLabel
							htmlFor="passwordConfirmation"
							className="block"
						>
							{t(
								"sign-in:sign-in.form_field.password_confirmation.label",
							)}
						</FormikLabel>
						<FormikInput
							name="passwordConfirmation"
							type={showPassword ? "text" : "password"}
							autoComplete="current-password"
							required
							className="block w-full"
							aria-label="passwordConfirmation"
						/>
						<FormikError
							field="passwordConfirmation"
							namespace="sign-in"
						/>
					</div>
				</div>
				<div data-testid="errors">
					<FormikErrors i18nNamespace="sign-in" />
					<FormikSubmitButton
						data-testid="sign_in.submit"
						className="mt-5 flex w-full"
					>
						{t("sign-in:sign-in.reset.form_action.submit")}
					</FormikSubmitButton>
				</div>
			</form>
		</div>
	)
})
ToSubmit.displayName = "ToSubmit"

const Success = memo(() => {
	const t = useTrans("sign-in")

	return (
		<Card>
			<p className="mb-5">{t("sign-in:sign-in.reset.confirm")}</p>
			<Link
				to="/"
				className="font-medium text-gray-700 hover:text-gray-900"
			>
				{t("sign-in:sign-in.reset.confirm.back")}
			</Link>
		</Card>
	)
})
Success.displayName = "Success"

const NoCode = () => {
	const t = useTrans("sign-in")

	return (
		<Card>
			<p className="mb-5">{t("sign-in:sign-in.reset.no_code")}</p>
			<Link
				to={Routes.RecoverPassword}
				className="font-medium text-gray-700 hover:text-gray-900"
			>
				{t("sign-in:sign-in.reset.no_code.back")}
			</Link>
		</Card>
	)
}
