// React
import { useEffect, useState } from "react"

// Translations
import { useTrans } from "@/i18n"

// Hooks
import { useCurrentProject } from "../hooks"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// UI
import { CardWrapper } from "@/components/Card"
import { GraphLabels } from "@/components/graphs/GraphLabels"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { ProjectGraphCumulativeProduction } from "../components/ProjectGraphCumulativeProduction"
import { ProjectGraphProductionDaily } from "../components/ProjectGraphDailyProduction"
import { ProjectGraphProduction } from "../components/ProjectGraphProduction"
import { ProjectGraphProductionHeader } from "../components/ProjectGraphProductionHeader"
import { ProjectGraphProductionActions } from "../components/ProjectGraphProductionActions"

// Graphs
import { PerformanceGauge } from "@/components/PerformanceGauge"
import { AreaGraph } from "@/components/graphs/types/area/AreaGraph"
import { ProductionGraph } from "@/components/graphs/types/bar/ProductionGraph"
import { ProductionCumulative } from "@/components/graphs/types/stacked-area/ProductionCumulative"

// REST queries
// import { useApiV1ComparisonProjectLivePerformanceList } from "@/api/rest/generated/api/api"

/**
 *
 * @returns ProjectProduction
 */
export const ProjectProduction = () => {
	const { getFeatureFlagValue } = useFeatureFlags()
	const t = useTrans(["project", "common"])

	// State
	const [live_performance_value, setLive_performance_value] =
		useState<number>(0)

	// Queries
	const { data } = useCurrentProject() // @ts-ignore
	const { data: dataLiveProjectPerfomance } = []

	// Calculate live performance when data recieved from API
	useEffect(() => {
		if (dataLiveProjectPerfomance?.[0]) {
			const performanceObject =
				dataLiveProjectPerfomance[dataLiveProjectPerfomance.length - 1] // Last in array
			const performanceKey = Object.keys(performanceObject)[0]
			if (performanceKey) {
				const number = parseFloat(performanceObject[performanceKey])
				const live_performance_value = Math.floor(number * 100) / 100 // Round down to 2 decimals
				setLive_performance_value(live_performance_value)
			}
		}
	}, [dataLiveProjectPerfomance])

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("project:project.data.page_title", {
						projectName: data?.project?.name,
					}),
				})}
			</title>
			<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
				{/** Production Dail (Area graph) */}
				<CardWrapper className="flex md:col-span-4">
					<div className="w-full">
						<ErrorBoundaryWithErrorState>
							<AreaGraph>
								<AreaGraph.Content>
									<ProjectGraphProductionDaily />
								</AreaGraph.Content>
							</AreaGraph>
						</ErrorBoundaryWithErrorState>
					</div>

					{getFeatureFlagValue(
						"ENABLE_PROJECT_DASHBOARD_PRODUCTION_LIVE_PERFORMANCE",
					) === true && (
						<PerformanceGauge value={live_performance_value} />
					)}
				</CardWrapper>

				{/** Production period (Bar graph) */}
				<CardWrapper className="md:col-span-4">
					<ErrorBoundaryWithErrorState>
						<ProductionGraph>
							<ProductionGraph.Header>
								<ProjectGraphProductionHeader />
							</ProductionGraph.Header>
							<ProductionGraph.Actions>
								<ProjectGraphProductionActions />
							</ProductionGraph.Actions>
							<ProductionGraph.Content>
								<ProjectGraphProduction />
							</ProductionGraph.Content>
							<ProductionGraph.Footer>
								<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
									<GraphLabels.TotalProduction />
									<GraphLabels.ExpectedProduction />
								</GraphLabels>
							</ProductionGraph.Footer>
						</ProductionGraph>
					</ErrorBoundaryWithErrorState>
				</CardWrapper>

				{/** Production cummulative (Stacked Area) */}
				<CardWrapper className="md:col-span-4">
					<ErrorBoundaryWithErrorState>
						<ProductionCumulative>
							<ProductionCumulative.Content>
								<ProjectGraphCumulativeProduction />
							</ProductionCumulative.Content>
							<ProductionCumulative.Footer>
								<GraphLabels className="px-4 pb-3 sm:px-6 sm:pb-5">
									<GraphLabels.TotalProduction />
									<GraphLabels.ExpectedProduction />
								</GraphLabels>
							</ProductionCumulative.Footer>
						</ProductionCumulative>
					</ErrorBoundaryWithErrorState>
				</CardWrapper>
			</div>
		</>
	)
}
