import { DateTime } from "@/lib/dates"
import { HTMLProps, memo, useState } from "react"
import { FiChevronLeft, FiChevronRight } from "@/lib/icons"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"
import { Button } from "../Button"
import { P } from "../Typography"

export type DatePickerDate = {
	year: number
	month: number
	day: number
}

export type DatePickerDateWithTimezone = DatePickerDate & {
	zone?: string
}

interface DatePickerProps extends HTMLProps<HTMLDivElement> {
	startOnMonday?: boolean
	initialMonth?: number
	initialYear?: number
	selectedDate?: DatePickerDate
	onSelectDate?: (date: DatePickerDateWithTimezone) => void
	isDateDisabled?: (date: DatePickerDate) => boolean
	className?: string
	today?: DateTime
	months?: Array<number>
	showChangeOfYear?: boolean
	showChangeOfMonth?: boolean
	showNavigation?: boolean
	variant?: "year" | "day"
}

const monthMap: { [key: number]: string } = {
	1: "common.date.january_short",
	2: "common.date.feburary_short",
	3: "common.date.march_short",
	4: "common.date.april_short",
	5: "common.date.may_short",
	6: "common.date.june_short",
	7: "common.date.july_short",
	8: "common.date.august_short",
	9: "common.date.september_short",
	10: "common.date.october_short",
	11: "common.date.november_short",
	12: "common.date.december_short",
}

const CURRENT_YEAR = new Date().getFullYear()
// show years until 2013
const years = Array(CURRENT_YEAR - 2012)
	.fill(true)
	.map((_, index) => CURRENT_YEAR - index)

/**
 * Transform to date picker date
 * @param dateTime
 * @returns
 */
export function transformToDatePickerDate(dateTime: Date): DatePickerDate {
	const parsedDate = DateTime.fromJSDate(dateTime)
	return {
		year: parsedDate.year,
		month: parsedDate.month,
		day: parsedDate.day,
	}
}

/**
 * DatePicker
 * @param param0
 * @returns
 */
export const DatePicker = ({
	startOnMonday = true,
	selectedDate,
	initialMonth,
	initialYear,
	onSelectDate,
	months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	showChangeOfYear = true,
	showChangeOfMonth = true,
	showNavigation = true,
	today: todayFromProps,
	className = "",
	isDateDisabled: isDisabled,
	variant = "year",
	...rest
}: DatePickerProps) => {
	const t = useTrans("common")
	const today = todayFromProps || DateTime.local()

	// State
	const [month, setMonth] = useState(initialMonth || today.month)
	const [year, setYear] = useState(initialYear || today.year)

	const daysInMonth = DateTime.fromObject({ year, month }).daysInMonth
	const dayOfWeek = new Date(year, month - 1).getDay()
	const emptyDaysCount = startOnMonday
		? dayOfWeek === 0
			? 6
			: dayOfWeek - 1
		: dayOfWeek - 0

	const emptyDays = Array(emptyDaysCount).fill(true)
	const daysArray = Array(daysInMonth).fill(true)

	const numberOfDays = daysArray

	const isToday = (day: number) => {
		return today.month === month && today.year === year && today.day === day
	}

	const isSelected = (index: number) => {
		if (!selectedDate) return false
		return (
			selectedDate.month === month &&
			selectedDate.year === year &&
			selectedDate.day === index
		)
	}

	const goBackAMonth = () => {
		// allow going backwards a year if variant is year
		if (month === 1 && variant === "year") {
			setYear(year - 1)
			setMonth(12)
		} else {
			setMonth(month - 1)
		}
	}
	const goForwardAMonth = () => {
		// allow going forward a year if variant is year
		if (month === 12 && variant === "year") {
			setYear(year + 1)
			setMonth(1)
		} else {
			setMonth(month + 1)
		}
	}

	return (
		<div
			{...rest}
			className={classNames(className, " relative w-full sm:w-72")}
		>
			<div className="left-0 top-0 rounded-lg bg-white p-4 shadow">
				<div className="flex flex-col items-center justify-between space-y-3 sm:flex-row sm:items-center sm:space-y-0">
					<div>
						{/** When showing change month select box at the top */}
						{showChangeOfMonth ? (
							months.length > 1 ? (
								<label
									htmlFor="month"
									className="relative mb-4 text-lg font-bold text-gray-800 sm:mb-8"
								>
									<Button variant="transparent" size="small">
										{t(monthMap[month])}
									</Button>
									<select
										name="month"
										className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
										value={month}
										onChange={(evt) =>
											setMonth(
												parseInt(
													evt.currentTarget.value,
												),
											)
										}
										data-testid="datepicker-month"
									>
										{months.map((month) => (
											<option value={month} key={month}>
												{t(monthMap[month])}
											</option>
										))}
									</select>
								</label>
							) : (
								<P className="mb-4 px-1.5">
									{t(monthMap[month])}
								</P>
							)
						) : null}

						{/** When showing change year select box at the top */}
						{showChangeOfYear && (
							<label
								className="relative ml-1 text-lg font-normal text-gray-600"
								htmlFor="year"
							>
								<Button
									variant="transparent"
									size="small"
									disabled={variant === "day"}
								>
									{year}
								</Button>
								<select
									className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
									data-testid="datepicker-year"
									name="year"
									onChange={(evt) =>
										setYear(
											parseInt(evt.currentTarget.value),
										)
									}
									disabled={variant === "day"}
									value={year}
								>
									{years.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
								</select>
							</label>
						)}
					</div>
					{showNavigation && (
						<div
							className="space-x-1"
							data-testid="datepicker-navigation"
						>
							<Button
								onClick={goBackAMonth}
								variant="transparent"
								size="small"
								data-testid="datepicker-gobackamonth"
								disabled={variant === "day" && month <= 1}
							>
								<FiChevronLeft />
							</Button>
							<Button
								onClick={goForwardAMonth}
								variant="transparent"
								size="small"
								data-testid="datepicker-goforwardamonth"
								disabled={variant === "day" && month >= 12}
							>
								<FiChevronRight />
							</Button>
						</div>
					)}
				</div>
				<div
					className={classNames(
						// if there is a dropdown, which is when the user can select
						// a month or year, then there needs to be a bit more spacing
						// between the day of the weeks and the dropdowns
						showChangeOfYear || showChangeOfMonth ? "mt-4" : null,
						"mb-3 flex flex-wrap",
					)}
				>
					<Heading startOnMonday={startOnMonday} />
				</div>
				<div className="flex flex-wrap text-gray-500">
					{emptyDays.map((_, index) => (
						<div
							key={`${year}-${month}-${index}`}
							style={{ width: `${(1 / 7) * 100}%` }}
							className="mb-1 border border-transparent p-1 px-1 text-center text-sm"
						/>
					))}
					{numberOfDays.map((_, index) => {
						if (isDisabled?.({ year, month, day: index })) {
							return (
								<div
									key={`${year}-${month}-${index + 1}`}
									style={{ width: `${(1 / 7) * 100}%` }}
									className="mb-1 px-1 sm:mb-2"
									data-testid={`datepickeritem-${year}-${month}-${
										index + 1
									}`}
								>
									<div
										className={classNames(
											"text-center text-sm leading-loose",
											"rounded-full opacity-50",
										)}
									>
										{index + 1}
									</div>
								</div>
							)
						}

						return (
							<div
								key={`${year}-${month}-${index + 1}`}
								style={{ width: `${(1 / 7) * 100}%` }}
								onClick={() =>
									onSelectDate?.({
										year,
										month,
										day: index + 1,
									})
								}
								className="group mb-1 cursor-pointer px-1 sm:mb-2"
								data-testid={`datepickeritem-${year}-${month}-${
									index + 1
								}`}
							>
								<div
									className={classNames(
										"text-center text-sm leading-loose",
										"rounded-full",
										"transition duration-200 ease-in-out",
										"group-hover:bg-primary-500 group-hover:text-primary-900",
										isSelected(index + 1)
											? "bg-primary-500 font-bold text-black"
											: isToday(index + 1)
											? "bg-gray-200 font-bold text-black"
											: null,
									)}
								>
									{index + 1}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

const Heading = memo(({ startOnMonday }: { startOnMonday: boolean }) => {
	const t = useTrans("common")
	let dates = [
		"common.date.tuesday_short",
		"common.date.wednesday_short",
		"common.date.thursday_short",
		"common.date.friday_short",
		"common.date.saturday_short",
		"common.date.sunday_short",
		"common.date.monday_short",
	]

	if (startOnMonday) {
		const item = dates.pop()
		if (item) dates.unshift(item)
	}

	return (
		<>
			{dates.map((text) => (
				<p
					className="text-center text-xs text-gray-700"
					style={{ width: `${(1 / 7) * 100}%` }}
					key={text}
				>
					{t(text)}
				</p>
			))}
		</>
	)
})
Heading.displayName = "DatePicker.Heading"
