import { ChangePassword } from "@/components/forms/ChangePassword"
import { useTrans } from "@/i18n"

export const ProfileChangePassword = () => {
	const t = useTrans("profile")

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("profile:profile.change_password.title"),
				})}
			</title>
			<ChangePassword />
		</>
	)
}
