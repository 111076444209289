import { Link, useLocation } from "@/lib/router"
import IconZPD from "@/assets/icons/brand/logo.svg"

// UI
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"

// I18n
import { useTrans } from "@/i18n"

/**
 * Confirm
 * @returns
 */
export const Confirm = () => {
	const t = useTrans("confirm")
	const location = useLocation()
	const state = location.state as { email?: string }

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: t("confirm:confirm.title"),
				})}
			</title>
			<>
				<div className="text-center">
					<picture>
						<img src={IconZPD} className="mx-auto h-12" alt="" />
					</picture>
					<Heading styleAs="h3" className="mt-6">
						{t("confirm:confirm.subtitle")}
					</Heading>
				</div>
				<Card className="mt-6 text-sm text-gray-800">
					{state?.email ? (
						<p>
							{t("confirm:confirm.text_with_email", {
								email: state.email,
							})}
						</p>
					) : (
						<p>{t("confirm:confirm.text_without_email")}</p>
					)}
					<p className="mt-4">{t("confirm:confirm.post_text")}</p>
				</Card>
				<Card className="mt-6 text-sm" variant="gray">
					<Link
						to="/"
						className="text-secondary-300 hover:text-secondary-700 font-medium"
						data-testid="confirm.back.text"
					>
						{t("confirm:confirm.back.text")}
					</Link>
				</Card>
			</>
		</>
	)
}
