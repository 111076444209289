import { Fragment } from "react"

// Router
import { Link } from "@/lib/router"
import { Routes } from "@/constants/routes"
// Translations
import { useTrans, Trans } from "@/i18n"

// UI
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper } from "@/components/Card"
import { FiscalOverviewByYear } from "./components/FiscalOverviewByYear"

// Errors
import { ErrorBoundary } from "@/components/errors/ErrorBoundary"
import { ErrorState } from "@/components/errors/ErrorState"

/**
 * Fiscal
 * @returns
 */
export const Fiscal = () => {
	const t = useTrans("investments")

	return (
		<div className={`space-y-8`} data-testid="fiscaloverview">
			{/** Fiscal year overview */}
			<CardWrapper>
				<CardBody>
					<Heading
						as="h2"
						styleAs="h5"
						className="mb-3 sm:truncate"
						data-testid="investments.fiscal.header"
					>
						{t("investments:investments.fiscal.header")}
					</Heading>
					<div className="space-y-4">
						{t("investments:investments.fiscal.copy")
							.split("\n\n")
							.map((text, index) => (
								<p
									className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2"
									key={`${text}.${index}`}
								>
									{text.split("\n").map((text, index) => (
										<Fragment key={`${text}.${index}`}>
											{text}
											<br />
										</Fragment>
									))}
								</p>
							))}
						<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
							<Trans
								ns="investments"
								i18nKey="investments:investments.fiscal.copy.current_overview"
								components={{
									a: (
										<Link
											to={
												Routes.InvestmentsProjectsCurrentOverview
											}
											className="text-secondary-300 hover:text-secondary-700 font-medium"
										/>
									),
								}}
							/>
						</p>
					</div>
				</CardBody>
			</CardWrapper>

			<ErrorBoundary
				fallback={
					<ErrorState
						title={t("investments:investments.fiscal.error.title")}
						message={t(
							"investments:investments.fiscal.error.message",
						)}
					/>
				}
			>
				<FiscalOverviewByYear />
			</ErrorBoundary>
		</div>
	)
}
