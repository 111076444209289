import { FormikProvider, useFormik } from "formik"
import { useEffect } from "react"
import { FiChevronLeft } from "@/lib/icons"
import { ApiErrorResponseInterface } from "@/api/rest"
import { apiAuthActivateConfirmCreate } from "@/api/rest/generated/api/api"
import { useSignIn } from "@/context/signIn"
import { useTrans } from "@/i18n"
import { Yup } from "@/lib/forms"
import { FormikError } from "../form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikSubmitButton,
} from "../form-controls/formik"
import { Label } from "../form-controls/Label"

const validationSchema = Yup.object().shape({
	code: Yup.string()
		.required("sign-in.form_errors.code_required")
		.min(6)
		.max(6),
})

export const ActivateEmail = () => {
	const t = useTrans("sign-in")
	const { setState, initialEmail: email, setRecoveryCodes } = useSignIn()

	const form = useFormik({
		validationSchema,
		initialValues: { code: "", errors: { local: "", common: "" } },
		onSubmit: async (values, helpers) => {
			try {
				const response = await apiAuthActivateConfirmCreate("email", {
					code: values.code,
				})

				if (!response || !response?.backup_codes) {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
					return
				}

				setRecoveryCodes(response.backup_codes)
				setState("recovery-codes")
			} catch (e) {
				const error = e as ApiErrorResponseInterface | null
				if (error?.json?.message === "code_incorrect") {
					helpers.setFieldError(
						"errors.local",
						"sign-in.form_errors.code_incorrect",
					)
				} else if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	const onBack = () => {
		setState("sign-in")
	}

	useEffect(() => {
		if (
			String(form.values.code).length === 6 &&
			!form.isSubmitting &&
			form.isValid
		) {
			void form.submitForm()
		}
	}, [form])

	return (
		<FormikProvider value={form}>
			<div className="mt-2 max-w-xl text-sm text-gray-700">
				<a
					href="/"
					className="mb-6 flex items-center text-sm text-gray-400 hover:text-gray-900 sm:mb-8"
					onClick={(evt) => {
						evt.preventDefault()
						onBack()
					}}
				>
					<FiChevronLeft className="mr-1" size={16} />
					{t("sign-in.form.action.code.go_back", { email })}
				</a>
				<p>{t("sign-in.activate_email.copy")}</p>
				<p className="mt-3">
					{t("sign-in.activate_email.explanation")}
				</p>
			</div>
			<form onSubmit={form.handleSubmit} className="mt-5">
				<Label htmlFor="code" className="sr-only">
					{t("sign-in.activate_email.form_field.code.label")}
				</Label>
				<FormikInput
					type="number"
					name="code"
					id="code"
					className="block w-full font-mono"
					placeholder="000000"
					autoFocus
					autoComplete="off"
				/>
				<FormikError field="code" namespace="sign-in" />
				<div>
					<FormikErrors i18nNamespace="profile" />
				</div>
				<FormikSubmitButton
					className="mt-6 w-full"
					data-testid="sign-in.activate_email.form_actions.submit"
				>
					{t("sign-in.activate_email.form_actions.submit")}
				</FormikSubmitButton>
			</form>
		</FormikProvider>
	)
}
