// React
import { useEffect, useState } from "react"

// Router
import { useParams } from "@/lib/router"

// UI
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { FiFile } from "react-icons/fi"
import { Anchor } from "@/components/Anchor"
import { Tooltip } from "@/components/tooltips/Tooltip"

// Security
import { DOMPurify } from "@/lib/security"

// Queries
import { useMessageDetailQuery } from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"

// Hook useMessageDetails
const useMessageDetails = () => {
	const params = useParams()
	const [sanitizedHtmlBody, setSanitizedHtmlBody] = useState("")
	const [attachmentsArray, setAttachmentsArray] = useState([])

	// Queries
	const { data } = useMessageDetailQuery({
		message_id: params.id as String,
	})

	useEffect(() => {
		if (data) {
			const detail = data?.me?.messages?.detail
			const htmlBody = detail?.html_body || detail?.text_body || ""

			/**
			 * Sanitize HTML content
			 *
			 * DOCS: https://deadsimplechat.com/blog/how-to-safely-use-dangerouslysetinnerhtml-in-react/
			 */
			const sanitizedHtml = DOMPurify.sanitize(htmlBody)
			setSanitizedHtmlBody(sanitizedHtml)

			// Parse attachments if available
			setAttachmentsArray(JSON.parse(detail?.attachments))
		}
	}, [data])

	// Return
	return {
		message: data?.me?.messages?.detail,
		sanitizedHtmlBody,
		attachmentsArray,
	}
}

/**
 * Message
 * @returns
 */
export const Message = () => {
	const { message, sanitizedHtmlBody, attachmentsArray } = useMessageDetails()
	const t = useTrans("project")

	return (
		<>
			<title>
				{t("common:common.web.title", {
					page: message?.subject,
				})}
			</title>
			<CardWrapper>
				<CardBody>
					<Heading as="h5" className="mb-3 sm:truncate">
						{message?.subject}
					</Heading>
					<div
						className="p-4"
						dangerouslySetInnerHTML={{
							__html: sanitizedHtmlBody,
						}}
					/>

					{/** If we have attachments */}
					{attachmentsArray.length !== 0 && (
						<Card className="grid gap-2">
							{attachmentsArray.map(
								(file_name: string, index: number) => (
									<Tooltip
										key={index}
										text={t(
											"project:project.communications.attachment.tooltip",
										)}
									>
										<div className="flex">
											<Anchor
												target="_blank"
												download
												disabled
												className="text-secondary-300 hover:text-secondary-900 flex cursor-pointer items-center text-sm font-medium"
											>
												<FiFile className="mr-2" />
												{file_name}
											</Anchor>
										</div>
									</Tooltip>
								),
							)}
						</Card>
					)}
				</CardBody>
			</CardWrapper>
		</>
	)
}
